.latest-news {
  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.range-slider:last-child {
  margin-bottom: 0;
}
.gm-svpc{
  display: none !important;
}
.for-sticky{
  header{
    position: fixed;
  }
}



.common.inner-banner {
  padding-top: 5em;
  &.landing-inner-banner-new{
    max-height: inherit !important;
    padding-top: em(65);
  }
  .container-fluid {
    top: 12em;
  }
  .mobile-banner {
    display: none;
  }
  &.big {
    .container-fluid {
      top: 22em;
      .txt {
        h2 {
          font-size: em(72);
          margin: 0 0 em(6);
        }
        p {
          font-size: em(34);
          letter-spacing: 0;
          .text-primary {
            font-weight: bold;
            color: #ed0000;
          }
        }
        a.btn-primary {
          min-width: 16em;
        }
      }
    }
  }
}
.mgt1{
  margin-top: 5vh;
}
.onPage.mgt1{
  margin-top: 0;
  padding-top: 4.0625em;
}
.NotFound{
  text-align: center;
}
.about-dealer-sec {
  h2 {
    &.condensed {
      line-height: .8125em;
      margin: 0 0 .625em 0;
      font-weight: 700;
      font-family: "univers condensed";
      text-transform: uppercase;
      color: #222222;
      letter-spacing: 0;
      font-size: em(60);
    }
  }
  hr {
    background: #222222;
    width: 20px;
  }
  h4 {
    font-size: em(26);
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: em(10);
    margin-bottom: em(15);
  }
}

.new-l-boxes {
  .about-dealer-sec {
    text-align: center;
    padding: 3em 0;
    font-size: initial;
    h2 {
      font-size: em(40);
      &.condensed {
        line-height: .8125em;
        margin: 0 0 .625em 0;
        font-weight: 700;
        font-family: "univers condensed";
        text-transform: uppercase;
        color: #222222;
        letter-spacing: 0;
        font-size: em(60);
      }
    }
    p {
      font-size: em(20);
    }
  }
  .l-box {
    border: 1px solid #ccc;
    margin-bottom: em(30);
    .newThumb {
      display: block;
      overflow: hidden;
      position: relative;
      width: 100%;
      float: left;
      margin-bottom: em(36);
      &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        @include transitions(0.5s);
        background: rgba($brand-primary, 0.4);
        opacity: 0;
      }
      img {
        width: 100%;
        @include scale(1);
        @include transitions(1s);
      }
      i {
        position: absolute;
        left: 0;
        top: 0;
        bottom: em(-30);
        margin: auto;
        right: 0;
        color: $brand-white;
        @include transitions(0.5s);
        z-index: 99;
        font-size: em(50);
        width: em(16);
        height: em(16);
        opacity: 0;
      }
      .play-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: em(56);
        height: em(56);
        background: $brand-primary;
        margin: auto;
        border-radius: 50%;
        text-align: center;
        line-height: 65px;
        color: #fff;
        font-size: 20px;
        z-index: 2;
        @include transitions(0.5s);
        @include scale(1);
        i {
          opacity: 1;
          bottom: 1px;
          font-size: 1.1em;
          width: .8em;
          height: 2.5em;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 6px solid rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          @include scale(1.2);
        }
      }
    }
    .text-area {
      padding: 0 em(30);
      display: inline-block;
      width: 100%;
      min-height: 15em;
      position: relative;
      h5 {
        font-size: em(16);
        margin: 0 0 em(28);
        color: $brand-primary;
        text-transform: uppercase;
        font-weight: bold;
      }
      h2 {
        color: #000;
        font-size: em(20);
        margin: 0 0 em(15) 0;
        font-weight: bold;
      }
      p {
        font-size: em(16);
        color: #3f3f3f;
        margin-bottom: em(15);
      }
      .moreLink {
        font-size: em(16);
        color: $brand-primary;
        padding: em(3) em(40) em(3) 0;
        position: absolute;
        bottom: 0;
        font-weight: 700;
        @include transitions(0.5s);
        overflow: hidden;
        display: inline-block;
        line-height: em(16);
        margin-bottom: em(15);
        i {
          position: absolute;
          right: em(3);
          top: 0;
          font-size: em(24);
          z-index: 99;
        }
        span {
          position: relative;
          z-index: 99;
        }
        &:hover, &:focus {
          text-decoration: none;
        }
        &:before {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 0;
          z-index: 1;
          content: '';
          background: lighten($brand-primary, 10%);
          -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
          transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
        }
      }

    }
  }
}



.news-d {
  padding: em(70) 0 0;
  .news-d-box {
    padding-right: em(48);
    .n-detail-img-box {
      margin-bottom: 2.25em;
      max-width: 47%;
      float: left;
      margin-right: 3%;
      img {
        max-width: 100%;
      }
    }
    .news-d-banner {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-bottom: em(30);
      overflow: hidden;
      a {
        img {
          @include transitions(1s);
        }
        &:after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
          -webkit-transition: all .5s ease-in-out;
          -moz-transition: all .5s ease-in-out;
          -o-transition: all .5s ease-in-out;
          transition: all .5s ease-in-out;
          background: rgba(204, 0, 0, 0.4);
          opacity: 0;
        }
        .play-icon {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: em(56);
          height: em(56);
          background: $brand-primary;
          margin: auto;
          border-radius: 50%;
          text-align: center;
          line-height: 65px;
          color: #fff;
          font-size: 20px;
          z-index: 2;
          @include transitions(0.5s);
          @include scale(1);
          i {
            opacity: 1;
            bottom: 0;
            font-size: 1.1em;
            width: .8em;
            height: 2.5em;
            top: 7px;
            position: relative;
            left: 2px;
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border: 6px solid rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            @include scale(1.2);
          }
        }
      }

    }
  }
  h5 {
    color: $brand-primary;
    text-transform: uppercase;
    font-size: em(16);
    margin: 0 0 em(16);
    font-weight: bold;
  }
  h3 {
    font-weight: bold;
    margin: 0 0 em(26);
    font-size: em(36);
    color: #000;
  }
  .detail-img {
    margin-bottom: em(40);
  }
  ol, ul{
    margin-bottom: 30px;
    li{
      text-align: justify;
      margin-bottom: em(15);
    }
  }
  p {    margin-bottom: em(36);
    text-align: justify;

    &.hilighted {
      font-style: italic;
      font-weight: 500;
      font-size: em(18);
      padding-left: em(26);
      border-left: 2px solid $brand-primary;

    }
  }
  .share-liner {
    width: 100%;
    display: inline-block;
    position: relative;
    height: 34px;
    border-bottom: 2px;
    padding-right: em(50);
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: #e7e7e7;
      margin: auto;
    }
    .theshare-button {
      background: $brand-white;
      width: em(50);
      position: absolute;
      right: 0;
      height: 34px;
      top: 0;
      @include transitions(0.5s);
      overflow: hidden;
      .share-ico {
        float: right;
        /*width: 100%;*/
        > img {
          float: right;
          top: 4px;
          position: relative;
        }
        .addthis_inline_share_toolbox {

          clear: none !important;

          @include opacity(0);
          @include transition(0.4s);
          position: absolute;
          top: 0;
          left: 150px;
        }
      }

    }
    &.active-share {
      .theshare-button {
        width: 205px;
        .share-ico {
          .addthis_inline_share_toolbox {
            width: initial;
            overflow: visible;
            @include opacity(1);
            left: 0px;

          }
        }
      }

    }
  }
  .related {
    h2 {
      font-weight: bold;
      margin: 0 0 em(26);
      font-size: em(36);
      color: #000;
    }
    .related-box {
      border: 1px solid #ccc;
      margin-bottom: em(30);
      .newThumb {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        float: left;
        margin-bottom: em(36);
        &:after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
          @include transitions(0.5s);
          background: rgba($brand-primary, 0.4);
          opacity: 0;
        }
        img {
          width: 100%;
          @include transitions(1s);
        }
        i {
          position: absolute;
          left: 0;
          top: 0;
          bottom: em(-30);
          margin: auto;
          right: 0;
          color: $brand-white;
          @include transitions(0.5s);
          z-index: 99;
          font-size: em(50);
          width: em(16);
          height: em(16);
          opacity: 0;
        }
        .play-icon {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: em(56);
          height: em(56);
          background: $brand-primary;
          margin: auto;
          border-radius: 50%;
          text-align: center;
          line-height: 65px;
          color: #fff;
          font-size: 20px;
          z-index: 2;
          @include transitions(0.5s);
          @include scale(1);
          i {
            opacity: 1;
            bottom: em(2);
            font-size: 1.1em;
            width: .8em;
            height: 2.5em;
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border: 6px solid rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            @include scale(1.2);
          }
        }
      }
      .text-area {
        padding: 0 em(30) em(50);
        display: inline-block;
        width: 100%;
        min-height: 18em;
        position: relative;
        h5 {
          font-size: em(16);
          margin: 0 0 em(28);
          color: $brand-primary;
          text-transform: uppercase;
          font-weight: bold;
        }
        h2 {
          color: #000;
          font-size: em(22);
          /*text-transform: uppercase;*/
          margin: 0 0 em(15) 0;
          font-weight: bold;
        }
        p {
          font-size: em(16);
          color: #3f3f3f;
          margin-bottom: em(15);
        }
        .moreLink {
          font-size: em(16);
          color: $brand-primary;
          padding: em(3) em(40) em(3) 0;
          position: absolute;
          bottom: em(10);
          left: em(30);
          font-weight: 700;
          @include transitions(0.5s);
          overflow: hidden;
          display: inline-block;
          line-height: em(16);
          margin-bottom: em(10);
          i {
            position: absolute;
            right: em(3);
            top: 0;
            font-size: em(24);
            z-index: 99;
          }
          span {
            position: relative;
            z-index: 99;
          }
          &:hover, &:focus {
            text-decoration: none;
          }
          &:before {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 0%;
            z-index: 1;
            content: '';
            background: lighten($brand-primary, 10%);
            -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
            transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
          }
        }
      }
    }
  }
  .archive-area {
    h2 {
      font-weight: bold;
      margin: 0 0 em(26);
      font-size: em(36);
      color: #000;
      &:last-child {
        padding-top: 0;
        margin: 0 0 em(14);
      }
    }
    .archive-box {
      border: 1px solid #ccc;
      margin-bottom: em(30);
      .text-area {
        padding: em(30);
        display: inline-block;
        width: 100%;
        .bar {
          background: #000;
          color: $brand-white;
          text-transform: uppercase;
          font-size: em(18);
          display: inline-block;
          width: 100%;
          padding: em(22) em(30);
          margin-bottom: em(38);
        }
        ul {
          float: left;
          width: 100%;
          margin: 0;
          padding: 0;
          li {
            width: 100%;
            border-bottom: 1px solid #e2e2e2;
            padding: 0 em(10) em(12);
            margin-bottom: em(14);
            list-style: none;
            letter-spacing: 1px;
            overflow: hidden;
            &:last-child {
              border: none;
            }
            a {
              color: #000;
              font-size: em(16);
              text-transform: uppercase;
              font-weight: 600;
              position: relative;
              padding: em(3) em(10) em(3) em(0);
              @include transition(0.6s);
              .red {
                color: $brand-primary;
                font-style: normal;
                float: right;
                @include transitions(0.5s);
              }
              span {
                position: relative;
                z-index: 1;
              }
              &:hover, &:focus {
                text-decoration: none;
              }
              &:before {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 0%;
                z-index: 1;
                content: '';
                background: lighten($brand-primary, 10%);
                -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
                transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
              }
            }

          }
        }
      }
    }
  }
}
.news_hr{
  margin-bottom: em(6);
  background: none;
  width: 100%;
}
.full-three-col {
  overflow: visible;
  .about-dealer-sec {
    padding: 4em .9375em 2em;
    font-size: inherit;
    width: 100%;
  }
  &.no-margin {
    .three-full {
      margin-bottom: 0;
    }
  }
}

.three-full {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  //&:last-child{
  //  margin-bottom: em(54);
  //}
  [class^="col-"] {
    padding: 0;
    .image{
      overflow: hidden;
      height: 100%;
      img {
        width: 100%;
        &.res-vers {
          display: none;
        }
      }
    }
  }
  .text-area {
    width: 100%;
    padding: 0 em(60) em(60);
    position: absolute;
    left: 0;
    bottom: 0;
    h3 {
      font-size: em(30);
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
    }
    hr {
      width: 100%;
      height: 2px;
      display: block;
      position: relative;
      background: none;
      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 2px;
        background: #000;
      }
    }
    p {
      margin-bottom: em(28);
      font-size: em(18);
    }
    &.white-txt {
      h3 {
        color: $brand-white;
      }
      hr {
        &:before {
          background: $brand-white;
        }
      }
      p {
        color: $brand-white;
        text-shadow: 1px 1px 5px #000;
      }
    }
    &.right-align {
      left: auto;
      right: 0;
    }
  }
  .fuel-efficient-txt {
    background: #f3f6fb;
    &:before {
      content: '';
      position: absolute;
      right: -12px;
      top: 0;
      bottom: 0;
      z-index: 1;
      margin: auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 11px 0 11px 12px;
      border-color: transparent transparent transparent #f3f6fb;

    }
    &.pointer-fliped {
      &:before {
        border-width: 11px 12px 11px 0;
        border-color: transparent #f3f6fb transparent transparent ;
        left: -12px;
        right: initial;
      }
    }
    &.red-box{
      background: $brand-primary;
      &:before {
        border-color: transparent $brand-primary transparent transparent ;
      }
      .text-area {
        h3, p {
          color: $brand-white;
        }
        hr {
          &:before{
            background: $brand-white;
          }
        }
        .btn{
          border:1px solid $brand-white;
        }
      }
    }
  }
}

.gecko.desktop{
  .search-by-filter .box .s-filters .search-by, .search-by-filter .box .s-filters .form-btn-sec{
    float: left;
  }
}


.generator-d{
  .table{
    display: block;
  }

}

.stepPopup {
  .modal-content{
    p{
      &.price-taq{
        margin-top: em(35);
        color:#3f3f3f;
        font-size:em(14);
      }
    }
    .close{
      &:before{
        background: url(/assets/svgs/cross-out.svg) no-repeat !important;
      }
    }
  }
}

.sharing-progress {
  margin-bottom: em(100);
  position: relative;
  .text-area {
    position: absolute;
    left: 0;
    right: 0;
    top: em(70);
    margin: auto;
    text-align: center;
    width: 70%;
    h3 {
      font-size: em(32);
      margin: 0 0 em(10);
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
    }
    p {
      font-size: em(15);
      margin-bottom: em(30);
    }
  }
}

.search-by-filter {
  padding: em(40) 0 0 0;
  margin-bottom: em(80);
  h3 {
    font-size: em(30);
    text-transform: uppercase;
    font-weight: bold;
    color: #313131;
    display: inline-block;
    margin: -16px auto 0;
    clear: both;
    background: #fff;
    padding: 0 20px;
    overflow: hidden;
  }
  hr {
    height: 4px;
    width: 40px;
    background: $brand-primary;
    display: block;
    margin: 0 auto;
  }
  .box {
    border: 1px solid #cccccc;
    display: inline-block;
    width: 100%;
    padding: 0 0 0;
    border-bottom: 0;
    .s-filters {
      width: 100%;
      padding: em(70) em(28) 0;
      display: inline-block;
      text-align: initial;

      .search-by {
        color: $brand-primary;
        font-size: em(22);
        font-weight: 600;
        padding-bottom: 8px;
        display: inline-block;
        border-bottom: 2px solid $brand-primary;
        margin-right: em(50);
        width: 8%;
      }
      .filters {
        display: inline-block;
        width: 87%;
      }
      .form-group {
        display: inline-block;
        width: 25%;
        margin-right: 3.2%;
        margin-bottom: 0;
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #1d1d1d;
        }
        ::-moz-placeholder { /* Firefox 19+ */
          color: #1d1d1d;
        }
        :-ms-input-placeholder { /* IE 10+ */
          color: #1d1d1d;
        }
        :-moz-placeholder { /* Firefox 18- */
          color: #1d1d1d;
        }
        .form-control {
          border: none;
          border-bottom: 1px solid #cccccc;
          box-shadow: none;
          padding: 0 0 9px;
          font-size: 1.2em;
          color: #000;
          border-radius: 0;
          text-transform: none;
        }
        .bootstrap-select {
          height: 42px;
          padding: 2px 0 0;
          .btn {
            color: #1d1d1d;
            .filter-option {
              font-weight: normal;
              color: #1d1d1d;
              font-size: 1.2em;
            }
            .caret {
              right: 5px;
              background: url("#{$websitePath}assets/svgs/dd-caret.svg") no-repeat 0 11px;
              width: 15px;
              height: 20px;
              background-size: 100%;
              &:before {
                display: none;
              }
            }
          }
          &.dropup {
            .btn .caret {
              @include rotate(180deg);
              margin-top: -14px;
            }
          }
        }
        .selectpicker {
          padding: 0;
          box-shadow: none;
          text-transform: none;
          li {
            a {
              font-size: em(18);
            }
          }
        }
      }
      .form-btn-sec {
        display: inline-block;
        width: 14%;
        .btn {
          min-width: 100%;
        }
      }
    }
  }
  &.scroll-to-fixed-fixed {
    width: 100% !important;
    left: 0 !important;
    padding: 0;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.1);
    z-index: 999;

    .box {
      border: none;
    }
    h3, hr {
      display: none;
    }
   .s-filters {
     padding: 0.5em 9em 0.6em 10em;
     background: #fff;
   }
  }
}
.honda-generator-sec {
  display: inline-block;
  width: 100%;
  padding: 6em 0;
  background: #f3f6fa;

  .heading {
    h2 {
      line-height: .8125em;
      margin: 0 0 38px 0;
      font-weight: 700;
      font-family: "univers condensed";
      text-transform: uppercase;
      color: #222222;
      letter-spacing: 0;
      font-size: em(70);
    }
    hr {
      width: 40px;
      margin: 0px auto;
      margin-bottom: 40px;
      height: 4px;
      background: $brand-primary;
      text-align: center;
      &:before{
        display: none;
      }
    }
    h3 {
      line-height: .8125em;
      margin: 0 0 em(40) 0;
      font-weight: 700;
      color: #222222;
      letter-spacing: 0;
      font-size: em(34);
      text-transform: uppercase;
    }
  }
  .gener-box-cont {
    [class^="col-"] {
      margin-bottom: em(60);
      float: none;
      display: inline-block;
    }
  }
  .col-md-3 {
    .gener-box {
      border: 1px solid #cccccc;
      position: relative;
      .gen-prices{
        position: absolute;
        padding: em(20) em(15) em(10);
        bottom: em(52);
        left: 0;
        right: 0;
        background-image: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(255,255,255,.6) 35%,rgb(255,255,255) 100%);
        width: 100%;
        h5{
          margin: 0 0 em(5);
          font-size: em(17);
          color:$brand-black;
          font-weight: 600;
        }
        h6{
          margin: 0;
          color: #3f3f3f;
          font-size: em(14);
        }
      }
      .model-name {
        display: inline-block;
        width: 100%;
        background: $brand-white;
        border-top: 1px solid #cccccc;
        padding-left: em(20);
        vertical-align: middle;
        .name {
          display: inline-block;
          font-size: em(20);
          font-weight: bold;
          padding-top: em(8);
        }

        .explore {
          float: right;
          background: $brand-primary;
          color: $brand-white;
          text-transform: uppercase;
          font-size: em(13);
          font-weight: bold;
          padding: em(20) em(75) em(20) em(22);
          height: 100%;
          position: relative;
          @include transitions(0.5s);
          span {
            position: relative;
            z-index: 9;
          }
          i {
            font-size: em(30);
            position: absolute;
            right: em(15);
            top: 0;
            bottom: 0;
            height: em(16);
            margin: auto;
            line-height: 0;
            z-index: 9;
          }
          &:before {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 0;
            z-index: 1;
            content: '';
            background: lighten($brand-primary, 10%);
            -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
            transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
          }
        }
      }
    }
  }
  &.blue-theme{
    .heading {
      hr{
        background: #023f88;
      }
    }
    .col-md-3 {
      .gener-box {
        .model-name {
          .explore{
            background:#023f88;
            &:before{
              background: #0b65d2 !important;
            }
            &:hover{
              background:#023f88;
            }
          }
        }
      }
    }
  }

}





.stepPopup.generator-d {
  .modal-lg {
    width: 95em;
    top: 9%;
    margin-top: 0;
  }
  .modal-content {
    padding: em(70) 0 em(50) 0;
    height: auto;
    display: inline-block;
    width: 100%;
    .product-area{
      padding: em(60) 0 0 em(60);
      .image {
        padding: 0 ;
        width: 100%;
        text-align: center;
        position: relative;
        margin-bottom: em(40);
        img {
          margin: 0 auto;
        }
        .gen-info {
          width: 100%;
          text-align: center;
          margin: 0 auto;
        }
        .bar-1 {
          width: em(267);
          height: 1px;
          background: #cccccc;
          position: relative;
          margin: 0 auto 5px;
          &:before{
            content: '';
            width: 1px;
            height: 10px;
            position: absolute;
            left: 0;
            top:-5px;
            background: #cccccc;
          }
          &:after{
            content: '';
            width: 1px;
            height: 10px;
            position: absolute;
            right: 0;
            top:-5px;
            background: #cccccc;
          }
        }
        .bar-2 {
          width: em(426);
          height: 1px;
          background: #cccccc;
          position: relative;
          margin: 0 auto 5px;
          &:before{
            content: '';
            width: 1px;
            height: 10px;
            position: absolute;
            left: 0;
            top:-5px;
            background: #cccccc;
          }
          &:after{
            content: '';
            width: 1px;
            height: 10px;
            position: absolute;
            right: 0;
            top:-5px;
            background: #cccccc;
          }
        }
        .gen-info-2 {
          width: 116px;
          text-align: center;
          margin: 0 auto;
          right: -120px;
          position: absolute;
          top: 0;
          .bar-1 {
            width: 1px;
            height: 298px;
            background: #cccccc;
            position: relative;
            margin: 0 auto 5px;
            &:before{
              content: '';
              width: 10px;
              height: 1px;
              position: absolute;
              left: -5px;
              top: 0;
              margin: auto;
              background: #cccccc;
            }
            &:after{
              content: '';
              width: 10px;
              height: 1px;
              position: absolute;
              right: -5px;
              top: initial;
              bottom: 0;
              margin: auto;
              background: #cccccc;
            }
          }
        }
      }
      .btn-area {
        margin-bottom: em(20);
        .btn {
          min-width: em(220);
          margin-right: 0;
          padding: em(15) em(25) em(13) em(60);
          .space-icon {
            left: em(12);
            top: em(10);
            right: inherit;
            width: em(16);
            height: em(16);
            img {
              width: 100%;
              vertical-align: top;
            }
          }
          i {
            top: .4875em;
          }
          &:last-child{
            margin: 0;
            float: left;
            padding: em(15) em(25) em(13) em(25);
          }
        }
      }
    }
    .specs-area {
      padding-left: 160px;
      padding-right: 35px;
    }
    .icon-text-bar {
      display: inline-block;
      width: 100%;
      font-family: "univers condensed";
      margin-bottom: em(20);
      .icon-text {
        float: left;
        display: inline-block;
        margin-right: em(50);
        &:first-child{
          width: 100%;
          margin-bottom: 10px;
          .text{
            .bigtext{
              text-transform: none;
            }
          }
        }
        &:last-child{
          margin-right: 0;
        }
        .text {
          float: left;
          .small-text{
            color: #949494;
            text-align: left;
            width: 100%;
            font-size: em(16);
            line-height: em(15);
            font-weight: 300;
            text-transform: uppercase;
          }
          .bigtext {
            font-size: em(40);
            text-transform: uppercase;
            font-weight: 700;
            line-height: em(20);
            span{
              color: #949494;
              width: 100%;
              font-size: em(8);
              line-height: em(15);
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
      }
      .icon {
        float: left;
        margin-right: em(10);
      }
    }
    .table {

      thead{
        th {
          background: #0c0c0c;
          background: -moz-linear-gradient(top, #0c0c0c 1%, #2c2c2c 100%);
          background: -webkit-linear-gradient(top, #0c0c0c 1%,#2c2c2c 100%);
          background: linear-gradient(to bottom, #0c0c0c 1%,#2c2c2c 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c0c0c', endColorstr='#2c2c2c',GradientType=0 );
          color: $brand-white;
          font-size: em(20);
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
        }
      }
      tbody{
        tr{
          &:nth-of-type(even) {
            background: #f4f5f6;
          }
          td {
            text-align: center;
            width: 55em;
            font-weight: bold;
            font-size: em(18);
            &:first-child {
              text-align: left;
              width: 75em;
              font-weight: normal;
              font-size: em(18);
            }
          }
        }
      }
    }
  }
  .modal-dialog .close{
    right: 1px;
    top: 1px;
  }

}
.mac .honda-generator-sec .col-md-3 .gener-box{
  border-bottom: 2px solid #ccc;
}
.f-w-b {
  > .container-fluid {
    padding: 0;
    .row {
      margin: 0;
      [class^="col-"] {
        padding: 0;
      }
    }
    .text-area {
      position: absolute;
      padding: 0 em(60) em(60);
      width: 100%;
      left: 0;
      top: 23%;
      color: $brand-white;
      h2 {
        font-size: em(80);
        font-weight: bold;
        text-transform: uppercase;
        font-family: "univers condensed";
      }
      hr {
        background: $brand-white;
        width: 20px;
        height: 2px;
      }
      p {
        font-size: em(26);
      }
    }
    .image {
      .res-vers{
        display: none;
      }
    }
  }
}
.location-map {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  #map-canvas {
      height: 100vh;
    &:before {
      display: none;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 16%;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.3) 100%);
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.3) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.3) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 );
      z-index: 1;
    }
  }
  .map-legal {
    position: absolute;
    left: 0;
    bottom: 0 ;
    z-index: 2;
    padding-left: 0;
    width: 100%;
    p {
      color: $brand-white;
      float: left;
      margin: 0;
      font-size: em(13);
      padding: 0 0 em(20) em(20);
    }
    ul {
      float: right;
      margin: 0;
      padding: 0 em(20) em(20) 0;
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        font-size: em(13);
        padding-left: em(15);
        margin-left: em(15);
        position: relative;
        &:before {
          content: '';
          height: em(13);
          width: 2px;
          background: $brand-white;
          position: absolute;
          left: 0;
          top: 5px;
        }
        &:first-child {
          padding-left: 0;
          margin-left: 0;
          &:before{
            display: none;
          }
        }
        a {
          color: $brand-white;
          @include transitions(0.5s);
        }
      }
    }
  }
  .search-fields{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: url("#{$websitePath}assets/images/location/static-bg.jpg") center ;
    background-size: cover;
    .search-fields-box {
      position: absolute;
      top: 15em;
      left: 0;
      right: 0;
      margin: auto;
      width: 28%;
      .form-group {
        margin-bottom: em(25);
        .form-control{
          height: em(50);
          border: 3px solid #FFFFFF;
        }
        .bootstrap-select{
          > .btn {
            height: em(40);
            font-size: em(18);
            font-weight: normal;
            text-transform: none;
            padding: em(8) em(15);
            .filter-option {
              color: #474747;
            }
            .caret {
              right: 20px;
              top: .5em;
            }
          }
        }
        &.location-caret {
          .bootstrap-select {
            > .btn {
              .caret {
               background: url("#{$websitePath}assets/svgs/location-dd.svg") 0 0 no-repeat;
                width: em(16);
                height: em(24);
                right: em(5);
                background-size: 70%;
                @include rotate(0);
                &:before {
                  display: none;
                }
              }
            }
          }
        }
        &.has-error {
          .form-control {
            border-color: #a94442;
          }
        }
        &.has-success {
          .form-control {
            border-color: #2c753d;
          }
        }
      }
      .btn {
        width: 100%;
        height: em(62);
        span {
          font-size: em(18);
        }
        i{
          top: .6125em;
        }
      }
    }
  }
  .main-popup {
    background: rgba(#5a5a5a, 0.95) !important;
    width: 200px !important;
    height: auto !important;
    padding: 0 !important;
    border-radius: 6px;
    margin: 35px 0 0 26px !important ;
    width: em(200) !important;
    #content {
      h3 {
        padding: 0 em(18);
        color: $brand-white;
        margin:  0 !important;
        text-align: center;
       span {
         text-transform: none;
         font-size: em(20);
       }
      }
    }
    .close-btn {
      display: none !important;
    }

  }
  .gm-style .gm-style-iw {
    padding: 0 !important;
  }
  .location-add-box {
    width: em(450);
    height: 100%;
    background: #fff;
    position: absolute;
    left: em(-505);
    top: 0;
    margin-top: 5em;
    z-index: 1;
    @include box-shadow(0 20px 30px rgba(0,0,0,0.4));
    @include transitions(0.5s);
    .map-heading {
      width: 100%;
      background: $brand-primary;
      padding: em(20) em(25);
      margin: 0;
      h3 {
        color: #fff;
        font-size: em(22);
        line-height: em(22);
        margin: 0 0 5px 0;
        font-weight: 700;
      }
      p {
        color: #fff;
        margin-bottom: 0;
        font-size: em(22);
        line-height: em(22);
        font-weight: 300;
        text-transform: uppercase;
      }
      &.map-heading2{
        padding: em(0) em(25);
        height: em(100);
        h3{
          margin-bottom: 0;
          line-height:100px;
        }
      }
    }
    .map-image {
      position: relative;
      display: block;
      margin-bottom: 10px;
      .direction-icon {
        width: em(76);
        height: em(76);
        position: absolute;
        right: 20px;
        bottom: -38px;
        @include borderRadius(50%);
        background: #fff;
        @include box-shadow(0 0 20px rgba(0,0,0,0.4));
        @include transitions(0.5s);
        i {

          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: em(36);
          height: em(36);
            .st0{fill:#CC0000; @include transitions(0.5s);}
            .st1{clip-path:url(#SVGID_2_); @include transitions(0.5s);}
            .st2{clip-path:url(#SVGID_4_);fill:#CC0000; @include transitions(0.5s);}
        }
        span{
          position: absolute;
          left: 0;
          bottom: -25px;
          width: 100%;
          text-align: center;
          color: #484848;
          font-size: em(16);
          font-weight: 700;
        }
      }
      .location-search {
        position: absolute;
        left: 20px;
        bottom: 20px;
        background: $brand-white;
        text-align: center;
        width: em(50);
        height: em(50);
        @include borderRadius(4px);
        @include box-shadow(0 0 20px rgba(0,0,0,0.4));
        @include transitions(0.5s);
        i {
          @include transitions(0.5s);
          font-size: em(20);
          line-height: 2.8em;
          color: #000;
        }
      }
    }
    .map-content {
      background: #fff;
      overflow: hidden;
      padding-top: em(40);
      .map-logo {
        float: left;
        width: 260px;
        margin: 0 0 em(18);
        padding: 0 0 em(25) em(35);
      }
      p {
        font-size: em(18);
        margin-bottom: em(10);
        padding: 0 em(35) em(10) em(35);
      }
    }
    .mapListing {
      margin: 0;
      padding: 0 35px 40px;
      li {
        display: block;
        list-style: none;
        padding: 10px 0 10px 30px;
        border-top: solid 1px #e2e2e3;
        border-bottom: solid 1px #e2e2e3;
        position: relative;
        i {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          color: $brand-primary;
          font-size: em(18);
        }
        i.icon-phone {
          width: 17px;
          height: 17px;
        }
        i.icon-email {
          width: 18px;
          height: 13px;
        }
        a{
          font-size: em(18);
          color: #000000;
          @include transitions(0.5s);
        }
      }
    }
    .map-footer {
      background: #f1f1f1;
      padding: em(26) em(40) em(26);
      left: 0;
      bottom: 5em;
      width: 100%;
      position: absolute;
      z-index: 2;
      p {
        margin-bottom: em(10);
        padding: 0 ;
        &.red-txt {
          font-size: em(14);
          text-transform: uppercase;
          color: $brand-primary;
          font-weight: 400;

        }
      }
      a.btn {
        min-width: 14.4375em;
      }
    }
    .map-close {
      position: absolute;
      right: em(-50);
      top: 0;
      width: em(50);
      height: em(100);
      background: #fff;
      -webkit-border-radius: 0 8px 8px 0;
      -moz-border-radius: 0 8px 8px 0;
      border-radius: 0 8px 8px 0;
      @include box-shadow(0 0 20px rgba(0, 0, 0, 0.4));
      @include transitions(0.5s);
      i {

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: em(22);
        height: em(22);
        .st0{fill:#919191;@include transitions(0.5s);}
      }
    }
    &.active{
      left:0;
    }

    &.loading {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $brand-white;
        z-index: 4;
      }
      .loader {
        display: block;
      }
      .map-close {
        right: 0;
        @include opacity(0);
      }
    }
    .loader {
      min-width: 6.25em;
      padding-top: 3.125em;
      color: #fff;
      width: auto !important;
      height: auto !important;
      text-align: center;
      font-style: normal;
      display: none;
      font-size: .9375em;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      z-index: 1000;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);

      &:before {
        content: '';
        width: 3.125em;
        height: 3.125em;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        border: 2px solid rgba(255,255,255,0.66);
      }

      &:after {
        content: '';
        width: 3.125em;
        height: 3.125em;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        -webkit-animation: loader .6s linear;
        animation: loader .6s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        border-radius: 500rem;
        border-color: #c00 transparent transparent;
        border-style: solid;
        border-width: .2em;
        box-shadow: 0 0 0 1px transparent;
      }
    }
    &.new-location-search{
      h3{
        text-transform: uppercase;
      }
      .sub-container{
        padding: em(35) em(40);
        margin-bottom: 10px;
        float: left;
        width: 100%;
        .mgb{
          margin-bottom: 35px;
        }
        h5{
          font-size: 18px;
          line-height:18px;
          color: #1d1d1d;
          margin-bottom: 25px;
        }
        .form-check.form-check-inline{
          float: left;
          width: 100%;
          margin-bottom: em(19);
          label{
            float: left;
            font-weight: normal;
            margin-top: 0;
            cursor: pointer;
            font-size: em(18);
          }
        }
        .form-check-input{
          width: em(18);
          height: em(18);
          background: none !important;
          border: 1px solid #e3e6eb !important;
          float: left;
          margin-right: em(19);
        }








        .btn-group.bootstrap-select.form-control{
          border-radius: 0;
          box-shadow: 0px 0px 0px !important;
          border: 0px;
          border-bottom: 1px solid #ccc;
          padding-left: 0;
          padding-right: 0;
          button{
            padding: 0;
            span{
              font-weight: normal;
              color: #1d1d1d;
            }
          }
          .btn{
            text-transform: none;
            font-size: em(21);
            color: #1d1d1d;
            .caret{
              right: 0;
            }
          }
        }

      }
      .search-result-map {
        float: left;
        width: 100%;
        background: #f1f1f1;
        padding: em(24) em(32);
        border-bottom:1px solid #dbdbdc;
        margin-left: em(20);
        .value{
          color: #626262;
          font-size:em(18);
          font-weight: 600;
          background: #dbdbdc;
          height: em(26);
          width: em(26);
          text-align: center;
          float: left;
          margin-right: em(19);
          border-radius: 50%;
          line-height:em(28);
        }
        .sub-content{
          float: left;
          width: 84%;
          .name{
            color: #000000;
            font-size:em(18);
            font-weight: 600;
            float: left;
            width: 100%;
            margin-bottom: em(18);
          }
          .distance{
            float: left;
            color: #000000;
            font-size:em(18);
          }
          .seedetail{
            float: left;
          }

        }

      }
      .scroll-area{
        background: #f1f1f1;
        padding: 5px 0;
        height: 42%;
        float: left;
        width: 100% ;
      }
    }
  }
  &.active {
    .map-legal {
      padding-left: 28.125em;
    }
  }
}
.gray-area-warranty {
  display: block;
  padding: em(100) 0;
  background: #f8f8f8;
  h2 {
    font-size: em(40);
    color: $brand-primary;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 36px;
  }
  p {
    margin-bottom: em(36);
  }
}
.exception {
  display: block;
  padding: em(100) 0;
  .excep-txt {
    padding-top: em(160);
    padding-left: em(115);
    h3{
      text-transform: uppercase;
      letter-spacing: em(3);
      margin: 0 0 30px;
      font-size: em(40);
    }
    p {
      margin-bottom: em(36);
    }
    ul {
      margin: 0;
      padding: 0 ;
      li {
        list-style: none;
        margin: 0 0 0 0;
        padding: 0 0 .9375em 1.625em;
        position: relative;
        font-size: em(16);
        color: $brand-primary;
        span {
          color: #3f3f3f;
        }
        &:before {
          font-size: em(17);
        }
      }
    }
    .btn-primary {
      width: 14.5em;
    }
  }
}
p {
  &.hilighted {

    font-style: italic;
    font-weight: 800;
    font-size: em(16);
    padding-left: em(26);
    border-left: 2px solid $brand-primary;
    .red-star {
      color: $brand-primary;
    }
  }
}
.overlap-image {
  width: 100%;
  position: relative;
  padding: em(160) 0 0;
    .backside {
      float: right;
      width: em(485);
      max-width: 100%;
      img{
        width: 100%;
      }
    }
    .frontside {
      position: absolute;
      left: 0;
      top:0;
      z-index: 2;
      width: em(322);

      img {
        width: 100%;
      }
    }
}
.offer-promo {
  display: inline-block;
  width: 100%;
  padding: 2em 0 0;
  h2 {
    font-size: em(34);
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin: 0 0 em(50);
  }
  .promo-box {
    border: 1px solid #cccccc;
    margin-bottom: em(28);
    .validity {
      width: 100%;
      height: em(33);
      text-align: center;
      background: #000;
      p {
        color: $brand-white;
        margin: 0;
        line-height: em(33);
      }
    }
    .image {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-bottom: em(50);
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.8) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.8) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
        @include transitions(0.5s);
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
       background: rgba($brand-primary, 0.4);
        @include transitions(0.5s);
        @include opacity(0);
      }
      .special-tag {
        position: absolute;
        left: 0;
        right: 0;
        bottom: em(-16);
        margin: 0 auto;
        width: em(204);
        height: em(34);
        background: $brand-primary;
        text-align: center;
        z-index: 9;
        i {
          color: $brand-white;
          font-style: normal;
          text-transform: uppercase;
          font-size: em(18);
          font-weight: 300;
          line-height: em(30);
        }
      }

    }
    .text-area {
      padding: 0 em(60) em(100);
      text-align: center;
      height: em(354);
      position: relative;
      h3 {
        font-size: em(34);
        margin: 0 0 em(26);
        font-weight: bold;
        color: #222222;
      }
      p {
        margin-bottom: em(64);
      }
      .btn-primary{
        width: 13em;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 4.75em;
      }
    }
    &.sm-box {
      .validity {
        background: $brand-primary;
      }
      .image{
        margin-bottom: em(26);
        &:before{
          display: none;
        }
      }
      .text-area {
        text-align: left;
        padding: 0 em(30) em(70);
        height: em(270);
        h5 {
          color: $brand-primary;
          font-size: 1em;
          text-transform: uppercase;
          margin: 0 0 em(24);
          font-weight: bold;
        }
        h3 {
          font-size: em(24);
          margin: 0 0 em(12);
        }
        p{
          margin: 0 0 em(24);
        }
        .btn-primary {
          right: auto;
          right: initial;
          left: 2.5em;
          bottom: 2em
        }
      }
    }
  }
}
.stepPopup.promo-pp {
  .modal-lg {
    width: 98em;
    top: 28%;
  }
  .modal-content {
    padding: em(40) em(36);
    height: auto;
    display: inline-block;
    width: 100%;
    .product-box {
      position: relative;
      border: 1px solid #cccccc;
      padding: em(60) 0 em(25);
      margin-top: em(10);
      text-align: center;
      .validity {
        width: em(450);
        height: em(32);
        text-align: center;
        background: $brand-primary;
        position: absolute;
        top: em(-16);
        left: 0;
        right: 0;
        margin: auto;
        p {
          color: $brand-white;
          margin: 0;
          line-height: em(33);
        }
      }
      img{
        display: inline-block;
      }
    }
    .promo-txt {
      padding-left: em(56);
      h5 {
        font-size: em(16);
        font-weight: bold;
        margin: 0 0 em(28);
        text-transform: uppercase;
        color: $brand-primary;
      }
      h2 {
        font-size: em(34);
        font-weight: bold;
        margin-bottom: em(36);
        color: #222222;
      }
      p {
        font-size: em(20);
        line-height: em(30);
        margin: 0 0 em(60);

      }
      h3 {
        font-size: em(18);
        font-weight: bolder;
        margin: 0 0 em(22);
        color: #222222;
      }
      ul {
        margin-bottom: em(30);
      }
      .btn-primary {
        width: 12.5em;
        &.book-bt{
          width: auto;
          i{
            display: none;
          }
        }
      }
    }
    .promo-txt-2{
      p{
        line-height: em(25);
        margin-bottom: em(18);
        font-size: em(20);

      }

    }
  }
  .modal-dialog .close{
    right: 1px;
    top: 1px;
  }

}
.stepPopup.sensing-pp {
  .modal-lg {
    width: 70em;
    top: 28%;
  }
  .modal-content {
    padding: em(40) em(36);
    height: auto;
    display: inline-block;
    width: 100%;
    h5 {
      font-size: 1em;
      font-weight: bold;
      margin: 0 0 1em;
      text-transform: uppercase;
      color: $brand-primary;
    }
    h2 {
      margin: 0 0 1em;
      font-size: 2.125em;
      font-weight: bold;
      color: #222222;
    }
    .video-box{
      display: block;
      margin-bottom: em(20);
      iframe {
        width: 70%;
        min-height: 25em;
        margin: 0 auto;
        float: none;
        display: block;
      }
    }
    .sensing-txt {
      p {
        font-size: em(18);
        margin-bottom: em(20);
      }
    }
    .twin-img {
      img{
        width: 100%;
      }
    }
  }
  .modal-dialog .close{
    right: 1px;
    top: 1px;
  }

}




hr.basic {
  margin-top: em(28);
  margin-bottom: em(30);
  border: 0;
  border-top: 1px solid #dbdbdb;
  width: 100%;
  height: 1px;
  background: none;
}
body.modal-open{
  overflow-y: hidden !important;
}
.basic-pagi {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.news-room{
.news-filter{
  float: right;
  text-align: right;
  margin: em(13) 0 0 0;
  h4{
    display: inline-block;
    font-size: em(18);
    color: $brand-black;
    font-weight: 500;
  }
  .form-control{
   float: right;
   width: auto;
    border: 0;
  }
  .bootstrap-select{
    &.btn-group{
      float: right;
      width: auto;
      border: 0;
      font-size: em(18);
      .btn {
        text-transform: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        .caret{
          right: em(15);
          top: em(10);
          color: $brand-black;
          &:before{
            color: $brand-black;
          }
        }
        .filter-option{
          color: $brand-black;
          font-weight: 500;
        }
        &:active, &.active{
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }
}

.news-item{
  .news-item-inr{
    border: 1px solid #e9ebed;
    h3{
      font-size: em(22);
    }
  }
}


}



.news-page {
  .new-l-boxes {
    .l-box {
      .newThumb {
        .img-responsive {
          height: 100%;
        }
      }
    }
  }
}

@media (min-width: 1601px) and (max-width: 2400px) {
  .location-map .location-add-box .map-content.mCustomScrollbar{
    max-height: 42%;
  }
  .news-page {
    .new-l-boxes {
      .l-box {
        .newThumb {
          height: 511px;
        }
      }
    }
  }
}

@media (min-width: 1530px) and (max-width: 1600px) {
  .news-page {
    .new-l-boxes {
      .l-box {
        .newThumb {
          height: 427px;
        }
      }
    }
  }
  .location-map .location-add-box{
    font-size: 13px;
  }

  .location-map {
    .location-add-box.new-location-search .scroll-area{
      height: 41%;
    }
    .location-add-box .map-heading.map-heading2 h3{
      line-height: inherit;
      padding: 9% 0;
    }
  }


  .common.inner-banner .container-fluid{
    top:10em;
  }

  .search-by-filter .box .s-filters {
    .search-by {
      width: 10%;
    }
    .filters {
      width: 84%;
    }
    .form-group {

    }
  }
  .stepPopup.generator-d {
    //.modal-lg {
    //  top: 29%;
    //}
    .modal-content .icon-text-bar {
      margin-bottom: em(15);
      .icon {
        img {
          max-width: 62px;
        }
      }
    }
  }
  .location-map .location-add-box .map-content.mCustomScrollbar{
    height: 39%;
  }
  .stepPopup.promo-pp {
    .modal-lg {
      width: 78em;
      top: 26%;
    }
  }
}

@media (min-width: 1330px) and (max-width: 1366px) {
  .news-page {
    .new-l-boxes {
      .l-box {
        .newThumb {
          height: 340px;
        }
      }
    }
  }
  .location-map .location-add-box.new-location-search .scroll-area{
    height: 45%;
  }

  .search-by-filter .box .s-filters {
    .search-by {
      width: 10%;
    }
    .filters {
      width: 84%;
    }
    .form-btn-sec .btn {
      margin-top: 15px;
    }
  }
  .new-l-boxes {
    .l-box {
      .newThumb {
        .play-icon {
          width: 2.5em;
          height: 2.5em;
          i {
            font-size: 0.8em;
            width: .8em;
            height: 3.8em;
          }
        }
      }
    }
      .about-dealer-sec {
        width: 100%;
      }
  }
  .stepPopup.generator-d {
    //.modal-lg {
    //  top: 29%;
    //}
    .modal-content .icon-text-bar {
      margin-bottom: em(15);
      .icon {
        img {
          max-width: 40px;
    }
  }
    }
  }
  .full-three-col {
    .about-dealer-sec {
      h4 {
        font-size: 1.5em;
        letter-spacing: .5em;
      }

    }

  }
  .news-d {
    h3 {
      font-size: 2.2em;
    }
    .related {
      h2 {
        font-size: 2.2em;
      }
    }
    .archive-area {
      h2 {
        font-size: 2.2em;
      }
    }
  }
  .location-map {
    .location-add-box {
      .map-content.mCustomScrollbar{
        max-height: 40%;
         .map-logo {
           width: 180px;
         }
      }

    }
    .search-fields {
      .search-fields-box {
        .form-group {
          .bootstrap-select>.btn {
            height: 2.2em;
            padding: .25em .9375em .5em;
          }
          &.location-caret {
            .bootstrap-select>.btn {
             .caret {
               right: em(10);
             }
            }
          }
        }
      }
    }

  }
  .stepPopup.promo-pp {
    .modal-lg {
      top: 24%;
    }
  }

  .stepPopup.sensing-pp .modal-content .video-box iframe {
    width: 71%;
  }
}

@media (min-width: 1201px) and (max-width: 1280px) {
  .latest-news {
    .newThumb{
      height: 300px;
    }
  }
  .news-page {
    .new-l-boxes {
      .l-box {
        .newThumb {
          height: 340px;
        }
      }
    }
  }
  .search-by-filter .box .s-filters {
    .search-by {
      width: 10%;
    }
    .filters {
      width: 84%;
    }
    .form-btn-sec .btn {
      margin-top: 15px;
    }
  }
  .new-l-boxes {
    .l-box {
      .text-area {
        min-height: 21em;
        p {
          font-size: 1.125em;
        }
      }
      .newThumb {
        .play-icon {
          width: 2.5em;
          height: 2.5em;
          i {
            font-size: 0.8em;
            width: .8em;
            height: 3.8em;
          }
        }
      }

    }
    .about-dealer-sec {
      width: 100%;
    }
  }
  .stepPopup.generator-d {
    //.modal-lg {
    //  top: 29%;
    //}
    .modal-content .icon-text-bar {
      margin-bottom: em(15);
      .icon {
        img {
          max-width: 40px;
        }
      }
    }
  }
  .full-three-col {
    .about-dealer-sec {
      h4 {
        letter-spacing: em(5);
      }
      .col-md-offset-1 {
        margin-left: 40px;
      }
    }
  }
  .news-d {
    p {
      font-size: 1.125em;
    }
    .related .related-box .text-area p {
      font-size: 1.125em;
    }
    .share-liner{
      .theshare-button .share-ico {
        width: 55%;
      }
      &.active-share .theshare-button .share-ico {
        width: 100%;
      }
    }
    h3 {
      font-size: 2.2em;
    }
    .related {
      h2 {
        font-size: 2.2em;
      }
    }
    .archive-area {
      h2 {
        font-size: 2.2em;
      }
    }
  }
  .location-map {
    .location-add-box .map-content.mCustomScrollbar{
      max-height: 38%;
      margin-top: 5em;
    }
    .search-fields {
      .search-fields-box {
        .form-group {
          .bootstrap-select>.btn {
            height: 2.2em;
            padding: .25em .9375em .5em;
          }
          &.location-caret {
            .bootstrap-select>.btn {
              .caret {
                right: em(10);
              }
            }
          }
        }
      }
    }
  }
  ul.arrow-listing {
    li {
      padding: 0 0 .9375em 2em;
    }
  }
  .stepPopup.sensing-pp .modal-content .video-box iframe {
    width: 71%;
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */

@media (min-width: 1200px) {
  .new-l-boxes {
    .l-box {
      .moreLink {
        &:hover {
          color: $brand-white;
          padding: em(3) em(40) em(3) em(5);
          &:before {
            left: 0;
            right: auto;
            width: 100%;
          }
        }
      }
      .newThumb {
        &:hover {
          img {

          }
          &:after {
            opacity: 1;
          }
          i {
            opacity: 1;
          }
          .play-icon {
            background: $brand-white;

            i{
              color: $brand-primary;
            }
          }
        }

      }
    }
    .about-dealer-sec {
      .headings {
        width: 75%;
        margin: 0 auto;
        float: none;
        display: inline-block;
      }
      p {
        font-size: em(20);
      }
    }
  }
  .news-d {
    .related {
      .related-box {
        .moreLink {
          &:hover {
            color: $brand-white;
            padding: em(3) em(40) em(3) em(5);

            &:before {
              left: 0;
              right: auto;
              width: 100%;
            }
          }
        }
        .newThumb {
          &:hover {
            img {

            }
            &:after {
              opacity: 1;
            }
            i {
              opacity: 1;
            }
            .play-icon {
              @include scale(0.9);
            }
          }
        }
      }
    }
    .archive-area .archive-box .text-area ul li a {
      &:hover {
        color: $brand-white;
        padding: em(3) em(10) em(3) em(10);
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
    .news-d-box .news-d-banner {
      a {
        &:hover {
          img {

          }
          &:after {
            opacity: 1;
          }
          .play-icon {
            @include scale(0.9);
          }
        }
      }
    }
  }
  .honda-generator-sec .col-md-3 .gener-box .model-name .explore{
      &:hover{
        color: $brand-white;
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
  .three-full {
    .text-area {
      width: 30em;
    }
    .fuel-efficient-txt {
      .text-area {
        width: 100%;
      }
    }
  }
  .full-three-col {
    .about-dealer-sec {
      .headings{
        width: 75%;
        margin: 0 auto;
        float: none;
        display: inline-block;
      }
      p {
        font-size: em(20);
      }
    }
  }
  .location-map {
    .location-add-box {
      .map-image {
        .direction-icon{
          &:hover {
            background: $brand-primary;
            i{
              .st0{fill:$brand-white;}
              .st1{clip-path:url(#SVGID_2_);}
              .st2{clip-path:url(#SVGID_4_);fill:$brand-white;}
            }
          }
        }
      }
      .location-search {
        &:hover{
          background: $brand-primary;
          i{
            color: $brand-white;
          }
        }
      }
      .mapListing {
        li {
          a {
            &:hover {
              color: $brand-primary;
            }
          }
        }
      }
      .map-close{
        &:hover {
          background: $brand-primary;
          i{
            .st0{fill:$brand-white;}
          }
        }
      }
    }
    .map-legal ul li a {
      &:hover{
        color: $brand-primary;
      }
    }
  }
  .gecko.desktop.no-flexboxlegacy {
    .search-by-filter .box {
      .s-filters {
        .form-group {
          float: left;
          &:first-child {
            margin-top: 6px;
          }
          .bootstrap-select .btn .caret {
            top: 4px;
          }
        }
      }
    }
  }
  .offer-promo .promo-box .image {
    i.icon-plus-icon {
      position: absolute;
      left: 0;
      top: 0;
      bottom: -1.875em;
      margin: auto;
      right: 0;
      color: #fff;
      z-index: 99;
      font-size: 3.125em;
      width: 1em;
      height: 1em;
      opacity: 0;
      @include transitions(0.5s);
    }
    &:hover{
      i.icon-plus-icon {
        bottom: 0;
        opacity: 1;
      }
      &:before{

      }
      &:after{
        @include opacity(1);
      }
    }
  }
}

@media (max-width: 1024px) {



  .location-map .location-add-box.new-location-search .sub-container .btn-group.bootstrap-select.form-control button span{
    font-size: em(19);
  }


  body.modal-open {
    overflow-y: hidden !important;
    position: fixed !important;
  }

  .promo-box.sm-box{
    .icon-plus-icon{
      display: none;
    }
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {


  .location-map .location-add-box .map-content {
    margin-top: 6em;
  }


  .location-map {
    .location-add-box.new-location-search .scroll-area{
      height: 42%;
    }
    .location-add-box .map-heading.map-heading2 h3{
      line-height: inherit;
      padding: 9% 0;
    }
  }

  .news-d {
    .archive-area {
      .archive-box {
        .text-area {
          ul {
            width: 46%;
            li {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
    .share-liner.active-share .theshare-button {
      width: 210px;
    }
  }
  .search-by-filter {
    .box {
      .s-filters {
        .search-by {
          width: 10%;
        }
        .filters {
          width: 84%;
        }
        .form-btn-sec .btn {
          margin-top: 15px;
        }
      }
    }
    &.scroll-to-fixed-fixed {
      .s-filters {
        padding: 1em 1em 1em 2em;
        .filters {
          .form-btn-sec {
            float: right;
          }
        }
      }

    }
  }
  .new-l-boxes {
    .l-box {
      .newThumb {
        .play-icon {
          width: 2.5em;
          height: 2.5em;
          i {
            font-size: 0.8em;
            width: .8em;
            height: 3.8em;
          }
        }
      }
    }
    .about-dealer-sec {
      .headings {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  .common.inner-banner .container-fluid {
    top: 10em;
  }
  .stepPopup.generator-d {
    .modal-lg {

      width: 85em;
    }
    .modal-content {
      .product-area {
        padding: 3.75em 0 0 1em;
        .image {
          .gen-info-2 {
            .bar-1 {
              height: 240px;
            }
          }
        }
      }
      .specs-area {
        padding-left: 126px;
      }
      .icon-text-bar {
        margin-bottom: em(15);
        .icon {
          img {
            max-width: 40px;
          }
        }
      }
    }
  }
  .about-dealer-sec h2.condensed {
    font-size: em(40);
  }
  .honda-generator-sec .heading {
    h2 {
      font-size: em(60);
    }
    h3 {
      font-size: em(30);
    }
  }
  .three-full {
    .text-area {
      width: 30em;
      padding: 0 3.75em 1.75em;
    }
    .fuel-efficient-txt {
      .text-area {
        width: 100%;
      }
    }
  }
  .full-three-col {
  .about-dealer-sec {
    h4 {
      font-size: 1.25em;
      letter-spacing: .5em;
    }
  }

}
  .location-map {
    overflow: hidden;
    height: 98vh;
    .search-fields {
      .search-fields-box {
        .form-group {
          .bootstrap-select>.btn {
            height: 2.2em;
            padding: .25em .9375em .5em;
            .filter-option {
              font-size: em(16);
            }
          }
          &.location-caret {
            .bootstrap-select>.btn {
              .caret {
                right: em(10);
              }
            }
          }
        }
        .form-btn-sec{
          .btn {
            font-size: .875em;
          }
        }
      }
    }

  }
  .offer-promo {
    h2 {
      margin: 0 0 2em
    }
  }
  .stepPopup.promo-pp {
    .modal-lg {
      top: 29%;
      width: 85em;
    }
    .modal-content {

    }
  }
  .header.scroll-to-fixed-fixed {
    width: 100% !important;
  }
  .stepPopup.sensing-pp {
    .modal-lg {
      top: 24%;
    }
    .modal-content .video-box iframe {
      width: 72%;
      min-height: 25.4em;
    }
  }
  .gray-area-warranty {
    p {
      font-size: em(18);
    }
  }
  .exception {
    .excep-txt{
      ul {
        li{
          font-size: em(18);
          &:before {
            top: 0;
          }
        }
      }
      p {
        font-size: em(18);
      }
    }
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .location-map .location-add-box .map-content {
    margin-top: 6em;
  }
  .location-map {
    .location-add-box.new-location-search .scroll-area{
      height: 57%;
    }
    .location-add-box .map-heading.map-heading2 h3{
      line-height: inherit;
      padding: 9% 0;
    }
  }
  .new-l-boxes .l-box .text-area{
    min-height: 17em;
  }
  .new-l-boxes {
    .l-box {
      .newThumb {
        .play-icon {
          width: 2.5em;
          height: 2.5em;
          i {
            font-size: 0.8em;
            width: .8em;
            height: 3.8em;
          }
        }
      }
      .text-area {
        p {
          font-size: 1.125em;
        }
      }
    }
    .about-dealer-sec {
      .headings {
        padding-left: 30px;
        padding-right: 30px;
        h2 {
          font-size: 2em;
        }
        p {
          font-size: 1em;
        }
      }

    }

  }
  .related {
    .related-box {
      width: 48%;
      float: left;
      &:last-child {
        float: right;
      }
    }
  }
  .news-d {
    .news-d-box {
      padding-right: 15px;
    }
    .share-liner.active-share .theshare-button {
      width: 218px;
    }
      p {
        font-size: 1.125em;
      }
      .related .related-box .text-area p {
        font-size: 1.125em;
      }
  }
  .common.inner-banner {
    .container-fluid {
      top: 6em;
      .txt-sec {
        hr {
          margin: 8px 0 10px 0;
        }
      }
    }
  }
  .common.inner-banner.big {
    .container-fluid {
      top: 15em;
    }
  }
  .three-full {
    display: block;
    [class^="col-"] {
      height: em(600);
      .text-area {
        max-width: 40%;
      }
      &.fuel-efficient-txt {
        .text-area {
          width: 100%;
          max-width: none;
        }
      }
    }
    &.inverse {
      .fuel-efficient{
        &:first-child {
          float: right;
        }
      }
      .fuel-efficient-txt {
        &:nth-child(2){
          &:before {
            content: '';
            border-width: 11px 0 11px 12px;
            border-color: transparent  transparent transparent #f3f6fb;
            right: -12px;
            left: initial;
          }
        }
        &.red-box {
          &:nth-child(2){
            &:before {
              border-color: transparent  transparent transparent $brand-primary;
            }
          }
        }
      }

    }
  }
  .search-by-filter {
    .box {
      .s-filters {
        .search-by {
          width: 10%;
          font-size: em(20);
        }
        .filters {
          width: 83%;
        }
        .form-group {
          width: 23.5%;
        }
        .form-btn-sec {
          width: 18%;
          .btn {
            margin-top: 15px;
          }
        }
      }
    }
    &.scroll-to-fixed-fixed {
      .s-filters {
        padding: 1em 1em 1em 2.4em;
        .filters {
          .form-btn-sec {
            float: right;
          }
        }
      }
    }
  }
  .stepPopup.generator-d {
      .modal-lg {
        width: 80%;
      }
      .modal-content {
        padding-top: 2em;
        .product-area {
          padding: 3.75em 0 0 1em;
          .image {
            img{
              max-width: 45%;
            }
            .gen-info-2 {
              right: 0;
              .bar-1 {
                height: 240px;
              }
            }
          }
          .btn-area {
            width: 50%;
            margin: 0 auto 3em;
            text-align: center;
            .btn:last-child{
              float: none;
            }
          }
        }
        .specs-area {
          padding-left: 15px;
        }
        .icon-text-bar {
          margin-bottom: em(15);
          text-align: center;
          .icon-text{
            margin-right: 3.125em;
          }
          .icon {
            img {
              max-width: 40px;
            }
          }
        }
      }
    }
  .about-dealer-sec {
    h2.condensed {
      font-size: em(40);
    }
    p{
      br{
        display: none;
      }
    }
  }
  .honda-generator-sec .heading {
    h2 {
      font-size: em(60);
    }
    h3 {
      font-size: em(30);
    }
  }
  .full-three-col {
    .about-dealer-sec h4 {
      font-size: 1.25em;
      letter-spacing: .3em;
    }
  }
  .f-w-b>.container-fluid {
    .text-area {
      h2{
        font-size: 3.5em;
      }
    }
  }
  .location-map {
    overflow: hidden;
    height: 98vh;
    .search-fields {
      .search-fields-box {
        width: 40%;
        .form-group {
          .bootstrap-select>.btn {
            height: 2em;
            padding: 0.3em .9375em;
          }
          &.location-caret {
            .bootstrap-select>.btn {
              .caret {
                right: 1em;
                background-size: 80%;
                top: 0.4em;
              }
            }
          }
        }
      }
    }
  }
  .exception {
    .excep-txt {
      padding-left: 0;
      ul {
        li{
          font-size: em(20);
          &:before {
            top: 0;
          }
        }
      }
      p {
        font-size: em(20);
      }
    }
  }
  .offer-promo {
    h2 {
      margin: 0 0 2em
    }
    .promo-box {
      .text-area {
        padding: 0 1.875em 4.375em;
      }
    }
  }
  .stepPopup.promo-pp {
    .modal-lg {
      top: 18%;
      width: 80%;
    }
    .modal-content {
      .product-box {
        margin-bottom: em(25);
        img {
          width: 50%;
          margin: 0 auto;
        }
      }
      .promo-txt {
        padding-left: 15px;
        h2 {
          margin: em(20) 0;
        }
        p {
          margin-bottom: em(20);
        }
      }
    }
  }
  .stepPopup.sensing-pp {
    .modal-content .video-box iframe {
      width: 72%;
      min-height: 25.4em;
    }
  }
  .gray-area-warranty {
    p {
      font-size: em(20);
    }
  }
  .stepPopup.generator-d .modal-content .specs-area{
    padding-right: 15px !important;
  }

  .honda-generator-sec {
    .col-md-3 {
      .gener-box {
        .gen-prices{
          padding-left: em(25);
        }
      }
    }
  }

}

@media (max-width: 768px){

  .location-map .location-add-box.new-location-search .scroll-area{
    height: 38%;
  }

  .stepPopup.generator-d .modal-content .specs-area{
    padding-right: 15px !important;
  }


  .honda-generator-sec .col-md-3 .gener-box .model-name .explore{
    padding: 1.25em 1.375em 1.25em 1.375em;
    text-align: center;
    i{
      display: none !important;

    }
  }
  .mgt1{
    margin-top: 60px;
  }

  .gray-area-warranty{
    padding: em(50) 0 em(20) 0;
    .pull-right.image-pane{
      margin-bottom: 30px;
    }
  }
  .offer-promo .promo-box .image .icon-plus-icon{
    display: none;
  }



}



/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .location-map .location-add-box.new-location-search .scroll-area{
//     height: 45%;
    .search-result-map{
      padding:.5em;
      .sub-content{
        width: 79%;
      }
    }
  }

  .disclaimer-txt{
    &.text2{
      margin-bottom: 25px;
    }
  }

  .stepPopup.generator-d .modal-content .product-area .btn-area {
    text-align: center;
    .btn:last-child{
      float: none;
    }
  }
  .common.inner-banner {
    img {
      display: none;
    }
    .mobile-banner {
      display: block;
      width: 100%;
      margin: 0;
    }
    .container-fluid .txt-sec hr {
      margin: 5px 0 6px;
    }
  }
  .new-l-boxes {
    .l-box {
      .text-area {
//        min-height: 14em;
        .moreLink i {
          display: none;
        }
      }
      .newThumb {
        .play-icon {
          width: 2.5em;
          height: 2.5em;
          i {
            font-size: 0.8em;
            width: .8em;
            height: 3.8em;
          }
        }
      }
    }
    .about-dealer-sec {
      padding: 1.5em 15px;
      h2 {
        letter-spacing: 3px;
        font-size: 1.8em;
      }
      p {
        font-size: 1em;
        br {
          display: none;
        }
      }
    }
  }
  .news-d {
    .news-d-box {
      padding-right: 15px;
    }
    .share-liner.active-share .theshare-button {
      width: 218px;
    }
    .related {
      .related-box {
        width: 48%;
        float: left;
        &:last-child {
          float: right;
        }
        .text-area {
          min-height: initial;
          height: 21em;
          .moreLink i {
            display: none;
          }
        }
      }
    }
  }
  .common.inner-banner.big {
    overflow: hidden;
    .dealer-banner {
      height: 85vh;
      overflow: hidden;
      .container-fluid {
        top: initial;
        bottom: 20px;

        .txt {
          h2 {
            font-size: 2.5em;
          }
          p {
            font-size: 1.7em;
            br {
              display: block;
            }
          }
        }
      }
    }
  }
  .full-three-col {
    .about-dealer-sec {
      padding: 2em .9375em 1em;
      h2.condensed {
        font-size: 1.875em;
      }
      p {
        br {
          display: none;
        }
      }
      h4 {
        font-size: 1.1em;
        letter-spacing: .2em;
        margin-bottom: em(10);
        margin-top: 0;
      }
      hr {
        margin-bottom: 15px;
      }
    }

  }
  .sharing-progress {
    margin-bottom: 25px;
    .text-area {
      width: 85%;
      top: 25px;
      p {
        display: none;
      }
    }
  }
  .search-by-filter {
    .box .s-filters {
      .search-by {
        width: 17%;
        float: left;
        padding-top: em(10);
        font-size: em(20);
      }
      .filters {
        width: 72%;
        float: left;

      }
      .form-group {
        width: 46%;
      }
      .form-btn-sec {
        width: 18%;
        .btn {
          margin-top: 15px;
        }
      }
    }
    &.scroll-to-fixed-fixed {
      background: #fff;
      z-index: 999999999 !important;
     .s-filters{
       padding: 1em 1.75em 1em ;
     }
    }
  }
  .common.inner-banner .container-fluid {
    left: 1.875em;
    top: 5em;
  }
  .stepPopup.generator-d {
    top: 0%;
    .modal-lg {
      top: 20px;
      width: 100%;
    }
    .modal-content {
      padding-top: 2em;
      .product-area {
        padding: 3.75em 0 0 1em;
        .image {
          img{
            max-width: 45%;
          }
          .bar-2 {
            width: 20.625em;
          }
          .gen-info-2 {
            right: -15px;
            .bar-1 {
              height: 12em;
            }
          }
        }
        .btn-area {
          width: 80%;
          margin: 0 auto 3em;
          .btn {
            padding: .9375em 1.5625em .8125em 1.75em;
          }
        }
      }
      .specs-area {
        padding-left: 15px;
      }
      .icon-text-bar {
        margin-bottom: em(15);
        text-align: center;
        .icon-text{
          margin-right: 1em;
          margin-bottom: em(16);
          .text {
            text-align: left;
            .small-text {
              font-size: 1.2em;
            }
            .bigtext {
              font-size: 2em;
            }
          }
        }
        .icon {
          img {
            max-width: 40px;
          }
        }
      }
    }
    .modal-dialog .close {
      top: 1px;
      right: 1px;
    }
  }


   .marine-page {
     .stepPopup.generator-d .modal-content .product-area .image{
       .gen-info, img{
          margin: 0 auto;
        }
      }
   }



  .honda-generator-sec {
    padding: 3em 0 0;
    .heading {
      h2 {
        font-size: em(40);
        margin-bottom: .875em;
      }
      hr {
        width: 30px;
        margin-bottom: 2em;
      }
      h3 {
        font-size: em(22);
        line-height: normal;
        margin-bottom: 2em;
      }
    }
  }
  .three-full {
    display: block;
    [class^="col-"] {
      height: em(340);
      .image {
        img.desktop-vers {
          display: none;
        }
        img.res-vers {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .text-area {
        width: 60%;
        p{
          display: none;
        }
      }
      &.fuel-efficient-txt {
        .text-area {
          width: 100%;
          max-width: none;
        }
      }
      &.fuel-efficient {
        .image {
          img.desktop-vers {
            display: none;
          }
          img.res-vers {
            display: block;
            width: auto;
            height: 100%;
          }
        }
      }
    }

    &:last-child{
      margin-bottom: 15px;
    }
    &.inverse {
      .fuel-efficient{
        &:first-child {
          float: right;
        }
      }
      .fuel-efficient-txt {
        &:nth-child(2){
          &:before {
            content: '';
            border-width: 11px 0 11px 12px;
            border-color: transparent  transparent transparent #f3f6fb;
            right: -12px;
            left: initial;
          }
        }
        &.red-box {
          &:nth-child(2){
            &:before {
              border-color: transparent  transparent transparent $brand-primary;
            }
          }
        }
      }

    }
  }
  .f-w-b>.container-fluid {
    .text-area {
      h2{
        font-size: 2.5em;
        margin-top: 0;
      }
      p{
        display: none;
      }

      hr {
      display: block;
      }
    }
    .image {
      .desktop-vers {
        display: none;
      }
      .res-vers {
        display: block;
        width: 100%;
      }
    }
  }
  /*.three-full [class^="col-"].fuel-efficient .image img {

  }*/
  .location-map {
    height: 730px;
     #map-canvas{
      height: 100%;
    }
    .location-add-box {
      width: em(320);
      .map-content.mCustomScrollbar{
        max-height: none;
        .mCSB_container {
          margin: 0;
        }
      }
      .map-content {
        margin-top: 6em;
        .map-logo {
          padding: 0 em(15) em(10) em(15);
        }
        p {
          padding: 0 em(15) em(10) em(15);
        }
        .mapListing {
          padding: 0 em(15) em(30) em(15);
        }
      }
      .map-footer{
        padding:  em(15) ;
      }
    }
      .search-fields {
        .search-fields-box {
          width: 70%;
          .form-group {
            .bootstrap-select>.btn {
              height: 2em;
              padding: 0.3em .9375em;
              .caret {
                margin: auto;
                height: 100%;
                top: em(3);
                bottom: 0;
              }
            }
            &.location-caret {
              .bootstrap-select>.btn {
                .caret {
                  right: .8em;
                  background-size: 60%;
                  margin: auto;
                  height: 100%;
                  top: em(3);
                  bottom: 0;
                  background-position: center;
                }
              }
            }

          }
        }

      }
    .bootstrap-select.btn-group {
      .dropdown-menu.inner {
        max-height: em(120) !important;
        overflow-y: scroll;
      }
    }

  }
  .gray-area-warranty {
    padding: 2em 0;
    h2 {
      text-align: center;
      font-size: 1.6em;
      margin-bottom: 1.2em;
    }
    p {
      font-size: em(18);
    }
  }
  .overlap-image {
    .frontside {
      max-width: 50%;
    }
    .backside {
      max-width: 70%;
      margin-right: 20%;
    }
  }
  .exception {
    padding: 3em 0;
    .excep-txt {
      padding-left: 15px;
      padding-top: 4em;
    }
  }
  .offer-promo {
    h2 {
      margin: 0 0 1.5em
    }
    .promo-box {
      .text-area {
        padding: 0 1em 3em;
        height: 26em;
        h3 {
          margin: 0 0 1em;
          font-size: 1.875em;
        }
        p {
          margin-bottom: 3em;
        }
        .btn-primary {
          bottom: 3em;
        }
      }
      &.sm-box .text-area {
        height: 20em;
        padding: 0 1em 3em;
        .btn-primary {
          left: 1.5em;
        }
      }
    }
  }
  .stepPopup.promo-pp {
    top: 0%;
    .modal-lg {
      top: 20px;
      width: 100%;
    }
    .modal-content {
      padding-top: 2em;
      .product-box {
        margin-bottom: em(15);
        img {
          width: 50%;
          margin: 0 auto;
        }
      }
      .promo-txt {
        padding-left: 15px;
        h2 {
          margin: em(10) 0 em(15);
        }
        p {
          margin-bottom: em(20);
        }
      }
    }
    .modal-dialog .close {
      top: 1px;
      right: 1px;
    }
  }
  .header.scroll-to-fixed-fixed {
    width: 100% !important;
  }
  .stepPopup.sensing-pp {
    top: 0%;
    .modal-lg {
      top: 20px;
      width: 100%;
    }
    .modal-content {
      padding: 2em 1em;
      .video-box iframe {
        width: 100%;
        min-height: 22.5em;
      }
    }
  }
  .location-map.active .map-legal {
    padding-left: 20em;
    text-align: center;
    p {
      float: none;
      text-align: center;
      padding: 0;
      display: block;
    }
    ul {
      float: none;
      text-align: center;
      padding: 0;
      display: block;
    }
  }
  .new-l-boxes .l-box .text-area{
    min-height: 17em;
  }
  body .location-map .location-add-box .map-content .map-logo{
    width: 200px;
  }

  .honda-generator-sec {
    .col-md-3 {
      .gener-box {
        .gen-prices{
          padding-left: em(25);
        }
      }
    }
  }

}

/* Portrait phones and smaller */
@media (max-width: 480px) {

   .new-l-boxes {
    [class^="col-"] {
      width: 100%;
    }
    .l-box {
      .text-area {
        min-height: initial;
        padding-bottom: 42px;
      }
    }
  }
  .news-d {
    .related {
      .related-box {
        width: 100%;
        float: left;
        &:last-child {
          float: right;
        }
        .text-area {
          padding: em(20);
        }
      }
    }
    .archive-area {
      .archive-box {
        .text-area {
          padding: em(20);
          ul {

            li {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  .search-by-filter .box .s-filters {
    .search-by {
      width: 100%;
      float: left;
      padding-top: 0;

      margin: 0 0 em(15);
    }
    .filters {
      width: 100%;
      float: left;

    }
    .form-group {
      width: 100%;
      margin: 0;
    }
    .form-btn-sec {
      width: 18%;
      .btn {
        margin-top: 25px;
      }
    }
  }
  .common.inner-banner.big .dealer-banner {
    height: 12.5em;
    .container-fluid {
      width: 100%;
      text-align: center;
      padding: 0 15px;
      left: 0;
    }
    .txt {
      h2 {
        text-align: center;
        font-size: 2.25em;
        line-height: 1em;
      }
      p {
        display: none;
      }
    }
  }

  .marine-page .common.inner-banner.big .dealer-banner{
    height: auto;
  }
  .common.inner-banner .container-fluid {
    left: 0;
    right: 0;
    text-align: center;
    top: 1em;
    h2 {
      font-size: 2.1875em;
      br {
        display: none;
      }
    }
  }
  .gener-box-cont {
    [class^="col-"] {
      width: 100%;
    }
  }
  .stepPopup.generator-d {
    width: 90%;
    left: 5%;
    .modal-lg {
      top: 20px;
      width: 100%;
    }
    .modal-content {
      .product-area {
        .image {
          img{
            margin: 0;
            margin-left: 10%;
          }
          .gen-info {
            width: 65%;
            margin: 0;
            .bar-1 {
              width: 12.6875em;
            }
            .bar-2 {
              width: 14.625em;
            }
          }
          .gen-info-2 {
            .bar-1 {
              height: 7em;
            }
          }
        }
        .btn-area{
          width: 100%;
          .btn {
            min-width: 11em;
            padding: .9375em 1.5625em .8125em 1.75em;
          }
        }
      }
      .icon-text-bar {
        .icon-text {
          width: 100%;
        }
      }
    }
  }
  .sharing-progress .text-area {
    width: 100%;
    h3 {
      font-size: 1.5em;
    }
    p {
      display: none;
    }
  }
  .three-full {
    [class^="col-"] {
      height: 13.875em;
      width: 100%;
      .text-area {
        width: 60%;
        padding: 0 2em 1em;
        h3 {
          font-size: 1.25em;
        }
      }
      &.fuel-efficient-txt{
        &:before {
          right: 0;
          left: 0;
          bottom: -10px;
          top: initial;
          margin: auto;
          border-style: solid;
          border-width: 11px 11px 0 12px;
          border-color: #f3f6fb transparent transparent transparent ;
        }
        &.pointer-fliped {
          &:before {
            right: 0;
            left: 0;
            top: -10px;
            bottom: initial;
            margin: auto;
            border-style: solid;
            border-width: 0 11.5px 11px 11.5px;
            border-color:  transparent transparent #f3f6fb transparent ;
          }
          &.red-box {
            &:before {
              border-color: transparent transparent $brand-primary transparent;
            }
          }
        }
      }
      &.fuel-efficient {
        .image {
          img.res-vers {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
    }

  }
  .full-three-col {
    .about-dealer-sec {
      h4{
        font-size: 0.875em;
        letter-spacing: 0.2em;
      }
      hr {
        margin-bottom: 10px;
      }
    }
  }
  .f-w-b>.container-fluid {
    .text-area {
      top: initial;
      bottom: 0;
      padding: 0 2em 1em;
      h2 {
        font-size: 1.5em;
      }
    }

  }
  .location-map {
    .search-fields {
      .search-fields-box {
        width: 90%;

      }
    }
  }
  .offer-promo{
    [class^="col-"] {
      width: 100%;
      .text-area {
        height: initial !important;
        padding: 0 1em 4em !important;
      }
    }
  }
  .stepPopup.promo-pp {
    .modal-content {
      padding: 2.5em 0;
      .product-box {
        img {
          width: 80%;
        }
        .validity {
          width: 100%;
          top:0;
        }
      }
    }
  }
  .stepPopup.sensing-pp {
    .modal-content {
      padding: 2.5em 0;
      .video-box iframe {
        min-height: initial;
      }
      .twin-img{
        width: 100%;
        margin-bottom: em(20);
      }
    }
  }
  .location-map {
    .map-legal {
      text-align: center;
      p {
        float: none;
        text-align: center;
        padding: 0;
        display: block;
      }
      ul {
        float: none;
        text-align: center;
        padding: 0;
        display: block;
      }
    }
    &.active {
      .map-legal {
        display: none;
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 420px) {



  .three-full .fuel-efficient-txt{
    height:em(160) !important;
  }


}