.fixed {
  overflow: hidden;
  position: fixed !important;
  width: 100%;
}

.homepage.for-sticky.loaded.fixed{
  .header{
    top: 0 !important;
  }
}
.header{
  background: $brand-white;
  padding: em(8) 0 em(29) 0;
  // height:em(80);
  height: auto;
  width:100%;
  -webkit-box-shadow:0 5px 5px 0 rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.10);
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.10);
  border-bottom:solid 1px #e3e3e3;
  left:0;
  top:0;
  z-index: 999999999;
  position: absolute;
  .fullContainer{
    width:100%;
    padding: 0;
    .row{
      margin: 0;
      [class^="col-"]{
        padding: 0;

      }
    }
  }
  &.sticky{
    position: fixed;
    // height:em(60);
    .logo-main{
      width: em(448);
      height: em(45);
      margin-top: em(10);
    }
    .toggle-btn-top{
      width:em(70);
      height:em(60);
      &:after{
        left:em(-3);
        width:em(70);
        height:em(70);
      }
    }
    #nav-icon3{
      width: em(24);
      height:em(40);
      span {
        height: 2px;
        &:nth-child(1) {
          top: em(13);
        }
        &:nth-child(2), &:nth-child(3) {
          top: em(20);
        }
        &:nth-child(4) {
          top: em(27);
        }
      }
      &:after{
        width: em(40);
        height: em(40);
        left: em(-8);
      }
    }
    .language-drop{
      margin-left: em(80);
      margin-top: em(20);
    }
    .topNav {
      margin: em(20) 0 0 0;
      li {
        a{
          font-size: em(15);
          &.arabic{
            font-size: em(14);
          }
        }

      }
    }
    .main-navigation{
      top: em(61);
    }
    .search-box{
      padding: em(32) 0;
    }
  }
  &.trans{
    .topNav{
      opacity: 0;
      visibility: hidden;
    }
    .language-drop{
      opacity: 0;
      visibility: hidden;
    }
  }
  &.scroll-to-fixed-fixed {
    max-width: 100%;
  }
}

.language-drop .bootstrap-select.btn-group .dropdown-menu{
  z-index: 99999;
}


.logo-main{
  display: inline-block;
  width:em(448);
  height:em(45);
  margin: em(20) auto 0 auto;
  position: absolute;
  left: 0;
  right:0;
  //@include transition(0.5s);

  -moz-transition:0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition:0.5s;

  img{
    width:100%;
  }
}
.gecko.desktop.no-flexboxlegacy {
  .logo-main {
    @include transitions(0);
  }
}

.topNav{
  margin: em(30) 0 0 0;
  padding: 0;
  float: right;
  @include transitions(0.5s);
  opacity: 1;
  visibility: visible;
  li{
    list-style:none;
    float: left;
    border-right:solid 1px #c9c9c9;
    padding: 0 em(20);
    line-height: em(17);
    &:last-child{
      border-right: none;
    }
    &:first-child{
      border-right: none;
      padding: 0;
    }
    a{
      color: #000000;
      font-size: em(20);
      @include transitions(0.5s);
      &.arabic{
        font-family: 'Droid Arabic Kufi', serif;
        color: #525354;
        font-size: em(17);
      }
      &:hover, &:focus{
        text-decoration: none;
      }
    }
    .btn-hollow {
      font-size: em(14);
      color: $brand-primary;
      background: $brand-white;
      border: 1px solid $brand-primary;
      margin: em(-10) 0 0;
      letter-spacing: em(.5);
      padding: 0px 1em 0 1em;
      line-height: 2.5em;
      i {
        font-size: 1em;
        position: relative;
        left: auto;
        right: auto;
        margin: 0 em(12) 0 0;
        top: em(2);
      }

    }
  }
}
.mobile-contact {
  float: left;
  position: absolute;
  left: 52px;
  top: 20px;
  width: 20px;
  display: none;
  img{
    width: 100%;
  }
}
.language-drop{
  float: left;
  margin-left: em(100);
  margin-top: em(28);
  @include transitions(0.5s);
  opacity: 1;
  visibility: visible;
  .form-control{
    background: none;
    border:none;
    outline:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: initial;
    color: #474747;
    padding: 0;
  }
  .bootstrap-select>.btn{
    height: auto;
    padding: em(5);
  }
  .bootstrap-select.btn-group .btn .filter-option{
    color: #474747;
  }
  .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open>.btn-default.dropdown-toggle:hover, .open>.btn-default.dropdown-toggle:focus, .open>.btn-default.dropdown-toggle.focus{
    background: none;
    border:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .btn-group.open .dropdown-toggle{
    background: none;
    border:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.main-navigation{
  background: $brand-white;
  padding: em(126) 0;
  border-bottom: solid 2px #c90e0e;
  display: none;
  position: absolute;
  left:0;
  top:em(81);
  width:100%;
  z-index:-1;
  [class^="col-"]{
    border-right:solid 1px $brand-gary;
    min-height:em(320);
    &:first-child{
      padding-left: em(15);
      padding-right: em(15);
    }
    padding-left: em(45);
    padding-right: em(100);
    position: relative;
    &.border-right{
      &:after{
        position: absolute;
        left:em(350);
        top:0;
        width:1px;
        height:100%;
        background: $brand-gary;
        content: '';
      }
    }
    &:last-child{
      border-right: none;
      padding-right: em(15);
    }
  }
  hr{
    border:none;
    margin: 0 0 em(40) em(23);
    height:1px;
    display: block;
    width:em(215);
    background: $brand-gary;
    text-align: left;
  }
  .listMenu {
    margin-left: 20px;
  }
  h5{
    color: $brand-black;
    font-size: em(16);
    text-transform: uppercase;
    margin: 0 0 em(30) 0;
    font-weight: 700;
  }
}
.liveMenu{
  margin: 0 0 em(40) 0;
  float: left;
  width:em(340);
  padding: 0 0 0 em(20);
  li{
    list-style:none;
    display: block;
    position: static;
    &:hover, &.active{
      >
      a{
        color: $brand-primary;
      }
    }
    a{
      font-size: em(26);
      color: $brand-black;
      display: block;
      font-weight:700;
      text-transform: uppercase;
      @include transitions(0.5s);
      line-height: 28px;
      padding-bottom: 5px;
      &:hover, &:focus{
        text-decoration: none;
      }
    }
    .bigList{
      margin: 0;
      padding: 0;
      position: absolute;
      left:em(386);
      width: em(332);
      height: 100%;
      padding-right: em(32);
      overflow: auto;
      top:0;
      visibility: hidden;
      opacity: 0;
      @include transitions(0.5s);
      background: $brand-white;
      li{
        list-style:none;
        display: block;
        padding: 0 0 em(15) 0;
        margin: 0 0 em(25) 0;
        border-bottom: solid 1px $brand-gary;
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }
        a{
          color: $brand-black;
          font-size: em(21);
          font-weight: 700;
          display: block;
          position: relative;
          padding: 0 0 em(10) 0;
          @include transitions(0.5s);
          text-transform: none;
          i{
            position: absolute;
            right:0;
            top: em(3);
            color: $brand-primary;
            z-index: 2;
            @include transitions(0.5s);
          }
          &:hover, &:focus{
            text-decoration: none;
          }
        }
      }
    }
    &.active{
      >
      .bigList{
        visibility: visible;
        opacity:1;
      }
    }
  }
}
.countryList{
  margin: 0 0 em(30) 0;
  float: left;
  width:100%;
  padding: 0;
  li{
    list-style:none;
    display: block;
    font-size: em(18);
    font-weight: 400;
    color: #959595;
    margin-bottom: em(40);
    float: left;
    width:100%;
    &:last-child{
      margin-bottom: 0;
    }
    span{
      float: left;
      margin-right: em(10);
    }
    .form-control{
      background: none;
      border:none;
      outline:none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      height: initial;
      padding: 0;
    }
    .bootstrap-select.form-control:not([class*="col-"]) {
      min-width: em(169);
      width: em(169);
      float: left;
    }
    .bootstrap-select>.btn{
      height: initial;
      padding:0 0 em(8) 0;
      border-bottom: solid 1px #c9c9c9;
    }
    .bootstrap-select.btn-group .btn .filter-option{
      color: #474747;
      font-weight: 700;
      margin-right: 0;
    }
    .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open>.btn-default.dropdown-toggle:hover, .open>.btn-default.dropdown-toggle:focus, .open>.btn-default.dropdown-toggle.focus{
      background: none;
      border:none;
      border-bottom: solid 1px #c9c9c9;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    .btn-group.open .dropdown-toggle{
      background: none;
      border:none;
      border-bottom: solid 1px #c9c9c9;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    .dropdown-menu{
      border-radius: 0;
      padding: em(5) 0;
      margin: 0;
      -webkit-box-shadow: 0 3px 10px rgba(0,0,0,0.105);
      -moz-box-shadow: 0 3px 10px rgba(0,0,0,0.105);
      box-shadow: 0 3px 10px rgba(0,0,0,0.105);
      border: solid 1px #c9c9c9;
      border-top: none;
    }
    .bootstrap-select.btn-group .dropdown-menu li {
      float: left;
      width: 100%;
      margin-bottom: 0;
      a{
        float: left;
        width: 100%;
        text-transform: uppercase;
      }
    }
  }
}
.countryLanguageClick{
  margin-bottom: em(25);
}
.emailList{
  margin: 0;
  padding: 0;
  li{
    list-style:none;
    margin-bottom: em(20);
    display: block;
    &:last-child{
      margin-bottom: 0;
    }
    &:nth-child(2){
      a{
        padding-left: em(50);
      }
    }
    a{
      color: $brand-black;
      position: relative;
      padding: 0 0 0 em(30);
      font-size: em(14);
      font-weight: 400;
      @include transitions(0.5s);
      &.call{
        font-size: em(22);
        font-weight: 700;
        i{
          top:em(0);
        }
      }
      i{
        position: absolute;
        left:0;
        top:em(3);
        color: $brand-primary;
        font-size: em(14);
        &.icon-email{
          font-size: em(26);
          top: em(-3);
        }
      }
      &:hover, &:focus{
        text-decoration: none;
      }
    }
  }
}
.followList{
  margin: 0;
  padding: 0;
  li{
    list-style:none;
    display: block;
    margin-bottom: em(35);
    a{
      position: relative;
      display: block;
      font-size: em(14);
      color: $brand-black;
      @include transitions(0.5s);
      padding: 0 0 0 em(70);
      font-weight:500;
      i{
        position: absolute;
        left:0;
        top:0;
        font-size: em(20);
      }
      &:after{
        position: absolute;
        left: em(30);
        top: em(10);
        content: '';
        width:em(20);
        height:1px;
        background: #c9c9c9;
        @include transitions(0.5s);
      }
      &:hover, &:focus{
        text-decoration: none;
      }
    }
  }
}
.search-box{
  width:100%;
  position: absolute;
  top:0;
  background: $brand-white;
  z-index: 9999;
  height:100%;
  left:0;
  padding: em(32) 0;
  display: none;
  .form-group{
    position: relative;
    margin: 0;
  }
  .form-control{
    border:none;
    background: $brand-white;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: em(26);
    padding:0 em(190) 0 em(40);
    color: $brand-black;
    height:em(22);
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $brand-black;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $brand-black;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color:$brand-black;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $brand-black;
    }
  }
  .magnify{
    position: absolute;
    left:0;
    top:3px;
    width:em(21);
    height:em(21);
    color: $brand-primary;
    font-size: em(26);
  }
  .btn{
    position: absolute;
    right:0;
    top:0;
  }
  .cross-btn{
    position: absolute;
    right:em(30);
    top: em(41);
    background: url("#{$websitePath}assets/images/cross.png") no-repeat;
    background-size: cover;
    width:em(18);
    height:em(18);
  }
}
.hide-btn{
  position: absolute;
  left:em(30);
  top: em(-51);
  background: url("#{$websitePath}assets/images/cross.png") no-repeat;
  width:em(18);
  height:em(18);
}
.black-overlay{
  width:100%;
  height:100%;
  position: fixed;
  background: rgba($brand-black, 0.8);
  top:0;
  left:0;
  z-index: 99999;
  @include transitions(0.2s);
  opacity:0;
  visibility: hidden;
  &.active{
    opacity: 1;
    visibility: visible;
  }
}
.listMenu{
  margin: 0;
  padding: 0;
  li{
    list-style:none;
    margin-bottom: em(8);
    a{
      color: #959595;
      text-transform: uppercase;
      font-size: em(16);
      margin: 0;
      font-weight: 700;
      @include transitions(0.5s);
      &:hover, &:focus{
        text-decoration: none;
      }
    }
  }
}
.mobile-navigation{
  background: $brand-white;
  position: fixed;
  left:0;
  top: 0;
  overflow-y: scroll;
  width:100%;
  height:100%;
  padding-top: em(100);
  display: none;
  z-index: 1;
  .listOne{
    margin: 0;
    padding: 0;
    li{
      list-style:none;
      display: block;
      border-bottom: solid 1px #eaeaea;
      a{
        font-size: em(24);
        color: $brand-black;
        position: relative;
        display: block;
        padding: em(15);
        text-transform: uppercase;
        font-weight: 700;
        i{
          position: absolute;
          right:em(10);
          top:0;
          width:em(40);
          height: em(25);
          margin: auto;
          bottom:0;
          background: #f1f1f1;
          &:before{
            position: absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            margin: auto;
            background: url("#{$websitePath}assets/svgs/black-plus.svg") no-repeat;
            background-size: 100% 100%;
            content: '';
            display: block;
            width:em(12);
            height:em(12);
          }
        }
        &.active{
          color: $brand-primary;
          i{
            &:before{
              background: url("#{$websitePath}assets/svgs/black-minus.svg") no-repeat;
              width:em(12);
              height:em(3);
            }
          }
        }
        &:hover, &:focus{
          text-decoration: none;
        }
      }
      ul{
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        margin: 0;
        padding: 0 em(5);
        li{
          border: none;
          a{
            font-size: em(20);
            border: none;
          }
        }
      }
    }
  }
  .listTwo{
    margin: 0;
    padding: em(10) 0;
    background: #f8f8f8;
    border-bottom: solid 1px #e4e4e4;
    li{
      display: block;
      list-style:none;
      a{
        display: block;
        color: #959595;
        text-transform: uppercase;
        font-size: em(22);
        padding:em(8) em(15);
        font-weight: 700;
      }
    }
  }
  .get-in-touch{
    padding: em(20);
    display: block;
    h3{
      color: $brand-black;
      font-weight: 700;
      font-size: em(26);
      text-transform: uppercase;
      margin: 0 0 em(15) 0;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style:none;
        display: inline-block;
        margin-right: em(10);
        &:last-child{
          margin-right: 0;
        }
        a{
          position: relative;
          padding: 0 0 0 em(25);
          display: block;
          font-size: em(20);
          color: $brand-black;
          font-weight: 700;
          i{
            position: absolute;
            left:0;
            top:em(3);
            color: $brand-primary;
          }
        }
      }
    }
  }
}
.menu-show{
  display: none;
}
.langMenu{
  display: none;
}


@media (min-width: 1601px) and (max-width: 2400px){}
@media (min-width: 1530px) and (max-width: 1600px){}
@media (min-width: 1330px) and (max-width: 1366px){
  .emailList li a i.icon-email {
    font-size: 12px;
    top: -1px;
  }
}
@media (min-width: 1201px) and (max-width: 1280px){
  .emailList li a i.icon-email {
    font-size: 12px;
    top: -1px;
  }

}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .topNav{
    li{
      a{
        &:hover{
          color: $brand-primary;
        }
      }
    }
  }
  .followList{
    li{
      a{
        &:hover{
          color: $brand-primary;
          padding: 0 0 0 em(35);
          &:after{
            opacity:0;
          }
        }
      }
    }
  }
  .emailList{
    li{
      a{
        &:hover{
          color: $brand-primary;
        }
      }
    }
  }
  .liveMenu{
    li{
      &.active, &:hover{
        >
        .bigList{
          visibility: visible;
          opacity:1;
        }
      }
      &:hover{
        >
        .bigList{
          z-index: 9;
        }
      }
      .bigList{
        li{
          a{
            &:hover{
              color: $brand-primary;
              i{
                color: $brand-primary;
                right:em(10);
              }
            }
          }
        }
      }
    }
  }
  .listMenu{
    li{
      a{
        &:hover{
          color: $brand-primary;
        }
      }
    }
  }
  .topNav li {
    .btn-hollow {
      @include transitions(0.5s);
      span{
        position: relative;
        z-index: 9;
      }
      i{
        z-index: 9;
      }
      &:before{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 0;
        z-index: 1;
        content: '';
        background: $brand-primary;
        -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      }
      &:hover{
        color: $brand-white;
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .main-navigation {
    [class^="col-"]{
      padding-left: em(15);
      padding-right: em(15);
      &.col-sm-6{
        width:50%;
      }
      &.col-sm-4{
        width:28%;
      }
      &.col-sm-2{
        width:22%;
      }
      &:last-child{
        padding-left: em(15);
        padding-right: em(15);
      }
    }
  }
  .liveMenu {
    li {
      &:hover{
        >
        .bigList{
          visibility: visible;
          opacity:1;
        }
      }
    }
  }
  .search-box{
    .container{
      width:90%;
    }
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .main-navigation {
    [class^="col-"]{
      padding-left: em(15);
      padding-right: em(15);
      &.col-sm-6{
        width:60%;
      }
      &.col-sm-4{
        width:25%;
      }
      &.col-sm-2{
        width:15%;
      }
      &:last-child{
        padding-left: em(15);
        padding-right: em(15);
      }
    }
  }
  .liveMenu {
    li {
      &:hover{
        >
        .bigList{
          visibility: visible;
          opacity:1;
        }
      }
    }
  }
  .search-box{
    .container{
      width:90%;
    }
  }
  body.fixed{
    .mobile-navigation {
      z-index: 10;
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .main-navigation{
    display: none !important;
  }
  //.black-overlay{
  //  display: none !important;
  //}
  .header{
    position: fixed;
    &.mobile-trans{
      .logo-main{
        display: none;
      }
      .topNav{
        display: none;
      }
      .langMenu{
        display: block;
      }
    }
    &.mobile-header-hide{
      &:after{
        height:100%;
        background: $brand-white;
        content: '';
        position: absolute;
        left: 0;
        top:0;
        width:100%;
        z-index: 999;
      }
    }
  }
  .topNav{
    li{
      &:first-child{
        display: none;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
  .language-drop{
    display: none;
  }
  .langMenu{
    margin: 0;
    padding: em(25) em(15) 0 0;
    float: right;
    display: none;
    position: relative;
    z-index: 999;
    >
    li{
      float: right;
      list-style:none;
      border-left: solid 1px #e3e3e3;
      padding-left: em(15);
      margin-left: em(30);
      &:last-child{
        border-left: none;
        padding-left: em(0);
        margin-left: em(0);
      }
      >
      a{
        color: $brand-black;
        &.arabic{
          font-family: 'Droid Arabic Kufi', serif;
          color: #525354;
          font-size: em(20);
        }
        &:hover, &:focus{
          text-decoration: none;
        }
      }
      .form-control{
        background: none;
        border:none;
        outline:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: initial;
        color: #474747;
        padding: 0;
        font-size: em(20);
      }
      .bootstrap-select>.btn{
        height: initial;
        padding: em(5);
      }
      .bootstrap-select.btn-group .btn .filter-option{
        color: #474747;
      }
      .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open>.btn-default.dropdown-toggle:hover, .open>.btn-default.dropdown-toggle:focus, .open>.btn-default.dropdown-toggle.focus{
        background: none;
        border:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      .btn-group.open .dropdown-toggle{
        background: none;
        border:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
  .menu-none{
    display: none;
  }
  .menu-show{
    display: block;
  }
  .topNav {
    li {
      a{
        font-size: em(22);
      }
    }
  }

  .header.sticky .topNav li a{
    font-size: em(22);
  }
  .header.sticky .search-box {
    padding: .45em 0;
    .cross-btn{
      top: 1.1em;
    }
  }
  .search-box {
    width: 100%;
    position: absolute;
    top: 0px;
    background: $brand-white;
    z-index: 9999;
    height: 100%;
    left: 0;
    padding: em(12.5) 0 0px 0px;
    display: none;
    .form-group {
      position: relative;
      margin: 0;
    }
    .form-control {
      border: none;
      background: $brand-white;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      font-size: em(26);
      padding: 0 em(190) 0 em(40);
      color: $brand-black;
      height: em(27);
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $brand-black;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: $brand-black;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: $brand-black;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: $brand-black;
      }
    }
    .magnify {
      position: absolute;
      left: 0;
      top: 5px;
      width: em(21);
      height: em(21);
      color: $brand-primary;
      font-size: em(26);
    }
    .btn {
      position: absolute;
      right: 29px;
      font-size: 1em;
      top: 0;
    }
    .cross-btn {
      position: absolute;
      background: url("#{$websitePath}assets/images/cross.png") no-repeat;
      right: 0.5em;
      top: 1.7em;
      width: 2em;
      height: 2em;


    }
    .container{
      padding-top: 4px;
    }
  }

  .sticky{
    .search-box{
      .container{
        padding-top: 0px;
      }
    }
  }
  body.fixed{
    .mobile-navigation {
      z-index: 99999999;
    }
  }
  .mobile-contact {
    display: block;
    top: 12px;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

  .header.sticky .logo-main {
      width: em(276);
      top: em(6);
  }
  .logo-main {
    width: em(276);
    top: em(6);
  }

  .search-box {
    .magnify{
      display: none;
    }
    .form-control {
      padding: .2em 6.6em 0 0;
    }
    .btn {
      min-width: initial;
      i{
        display: none;
      }
    }

  }
  .header{
    padding: 0;
    height: em(60);
  }

}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}