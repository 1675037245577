/* Icon 1 */

.toggle-btn-top{
  width:em(83);
  height:em(80);
  text-align: center;
  overflow: hidden;
  position: absolute;
  left:0;
  @include transition(0.4s);
  &:after{
    @include borderRadius(50%);
    background: #eeeeee;
    content: '';
    position: absolute;
    left:em(-3);
    top:em(-3);
    @include transitions(0.5s);
    margin: auto;
    right:0;
    bottom:0;
    width:em(86);
    height:em(86);
    opacity: 0;
    @include scale(0);
  }
  &.active{
    &:after{
      width:0;
      height:0;
    }
    #nav-icon3{
      &:after{
        width:0;
        height:0;
        left: em(12);
      }
    }
    &:hover{
      &:after{
        display: none;
      }
    }
  }
}
#nav-icon3, #nav-icon2{
  width: em(25);
  height: em(48);
  position: absolute;
  z-index: 999;
  text-align: center;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  @include transitions(0.5s);
  cursor: pointer;
  margin: auto;
  left: em(-3);
  right: 0;
  top: 0;
  bottom: 0;
  &:after{
    background: $brand-primary;
    width: em(48);
    height: em(48);
    position: absolute;
    left: em(-11);
    top:0;
    right:0;
    bottom:0;
    margin: auto;
    content: '';
    @include transitions(0.3s);
    @include borderRadius(50%);
  }
}

#nav-icon3 span, #nav-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $brand-white;
  opacity: 1;
  left: 0;
  right:0;
  margin: auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  @include borderRadius(2px);
  z-index: 55;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1), #nav-icon2 span:nth-child(1) {
  top: em(15);
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3), #nav-icon2 span:nth-child(2),#nav-icon2 span:nth-child(3) {
  top: em(23);
}

#nav-icon3 span:nth-child(4), #nav-icon2 span:nth-child(4) {
  top: em(31);
}

#nav-icon3.click span:nth-child(1), #nav-icon2.click span:nth-child(1) {
  top: em(18);
  width: 0%;
  left: 50%;
}

#nav-icon3.click span:nth-child(2), #nav-icon2.click span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.click span:nth-child(3), #nav-icon2.click span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.click span:nth-child(4), #nav-icon2.click span:nth-child(4) {
  top: em(18);
  width: 0%;
  left: 50%;
}
#nav-icon3.click, #nav-icon2.click{
  span{
    background: $brand-black;
    //background: $brand-white;
  }
}
@media (min-width: 1601px) and (max-width: 2400px){}
@media (min-width: 1530px) and (max-width: 1600px){}
@media (min-width: 1330px) and (max-width: 1366px){}
@media (min-width: 1201px) and (max-width: 1280px){}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .toggle-btn-top{
    &:hover{
      &:after{
        @include scale(1);
        opacity: 1;
      }
    }
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .toggle-btn-top{
    &:after{
      display: none;
    }
  }
  #nav-icon3, #nav-icon2 {
    margin: initial;
    left: em(15);
    right: initial;
    width: em(35);
    top: em(16);
    display: block;
    span {
      background: $brand-black;
      height: 2px;
      width: 100%;
    }
    &:after {
      display: none;
    }
  }

  //.menu-open.click{
  //  top:em(12) !important;
  //}
  .header.sticky{
    #nav-icon3, #nav-icon2 {
      top:em(3);
    }
  }


  #nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3), #nav-icon2 span:nth-child(2), #nav-icon2 span:nth-child(3) {
    top: em(25);
  }
  #nav-icon3 span:nth-child(4), #nav-icon2 span:nth-child(4) {
    top: em(34);
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}