.popup-content-scroll {
  height: 260px;
}

.stepPopup {
  user-select: none;
  .controls {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    a {
      position: absolute;
      min-width: em(180);
      padding: em(11) em(25);
      font-size: em(13);
      background: rgba($brand-white, 0.3);
      @include borderRadius(20px);
      color: $brand-white;
      font-weight: 700;
      text-transform: uppercase;
      @include transitions(0.5s);
      span {
        position: relative;
      }
      i {
        position: absolute;
        right: em(15);
        font-size: em(28);
        top: em(4);
      }
      &.right {
        right: 3%;
      }
      &.left {
        left: 3%;
        text-align: right;
        i {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          right: inherit;
          left: em(15);
          top: em(-2);
        }
      }
    }
  }
  .modal-dialog {
    margin: em(-200) auto 0px;
    top: 50%;
    user-select: initial;
    .close {
      background: $brand-primary;
      width: em(22);
      height: em(22);
      opacity: 1;
      position: absolute;
      right: 1px;
      top: 1px;
      @include transitions(0.5s);
      &:before {
        background: url("/assets/svgs/cross-out.svg") no-repeat;
        background-size: cover;
        width: em(18);
        height: em(18);
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        -webkit-transform: scale(.6);
        -moz-transform: scale(.6);
        -ms-transform: scale(.6);
        -o-transform: scale(.6);
        transform: scale(.6);
      }
    }
  }
  .modal-content {
    box-shadow: none;
    border-radius: 0;
    padding-right: em(15);
    height: em(400);
    .p0 {
      border: $brand-white 1px solid;
      padding: 0;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .p40 {
      padding: em(40) em(16) em(40) em(40);
    }
    .scroll-area {
      //height: em(336);
      height: em(304);
      overflow-y: auto;
    }
    h3 {
      color: $brand-primary;
      font-size: em(25);
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 0 em(20) 0 ;
    }
    p {
      font-size: em(16);
    }
    &.video-sec {




    }
  }
  &.maintenance {
    .modal-content {
      width: 32em;
      height: 18em;
      margin:  auto;
      padding: em(40) 0 0;
      text-align: center;
      .icon {
        width: em(90);
        height: em(90);
        line-height: em(90);
        background: $brand-primary;
        margin: 0 auto em(15);
        display: block;
        @include borderRadius(50%);
          img{
            margin: 0 auto;
            display: inline-block;
            max-width: 50%;
          }
      }
    }
  }
  &.iframe-popup{
    .modal-dialog{
      width: 80em;
      max-width: 95%;
      height: 46em;
      top: 10%;
      margin-top: 0;
    }
    .modal-content {
      width: 80em;
      max-width: 95%;
      height: 43.5em;
      margin:  auto;
      padding: 0 0 0;
      text-align: center;
      .icon {
        width: em(90);
        height: em(90);
        line-height: em(90);
        background: $brand-primary;
        margin: 0 auto;
        display: block;
        @include borderRadius(50%);
        img{
          margin: 0 auto;
          display: inline-block;
          max-width: 50%;
        }
      }
      iframe{
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        border: 0;
      }
    }
    &.recall-iframe{
      .modal-dialog{
        width: 100%;
        .modal-content{
           width: 100%;
        }
      }
    }
  }
  &.car-popup{
    .modal-content{
        height: 22em;
      .p40{
        padding: 0em 24px;
      }
    }
  }
}

.lg {
  .lg-thumb-item {
    border: none;
    @include borderRadius(0);
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0;
      background: $brand-primary;
      width: 100%;
      @include transitions(0.5s);
      content: '';
      z-index: 9;
    }
    img {
      opacity: 0.5;
      @include transitions(0.5s);
    }
    &.active {
      img {
        opacity: 1;
      }
      &:after {
        height: em(5);
      }
    }
  }
  .lg-actions {
    button {
      position: absolute;
      padding: em(16.655) em(80) em(16.655) em(25);
      font-size: em(13);
      background: rgba($brand-black, 0.4);
      outline: none !important;
      @include borderRadius(20px);
      color: $brand-white;
      font-weight: 700;
      text-transform: uppercase;
      @include transitions(0.5s);
      border: none;
      text-align: left;
      font-family: $univers;
      span {
        position: relative;
      }
      i {
        position: absolute;
        right: em(15);
        font-size: em(28);
        top: em(7);
      }
      &.lg-prev {
        text-align: right;
        padding: em(16.655) em(25) em(16.655) em(80);
        left: 3%;
        i {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          right: inherit;
          left: em(15);
          top: em(3);
        }
      }
      &.lg-next {
        right: 3%;
      }
      &:after, &:before {
        display: none !important;
      }
    }
  }
  #lg-counter {
    position: absolute;
    top: em(-60);
    color: $brand-primary;
    left: 0;
    font-size: em(20);
    font-weight: 600;
    font-family: $univers;
  }
  .lg-toolbar {
    background: none;
    top: em(70);
    right: em(60);
    width: auto;
    .lg-close {
      border: solid 1px $brand-primary;

      width: em(25);
      height: em(25);
      padding: 0;
      @include borderRadius(50%);
      text-align: center;
      color: $brand-primary;
      font-size: em(30);
      line-height: em(23);
      @include transitions(0.5s);
    }
  }
  .lg-img-wrap {
    padding: 0;
    &:before {
      height: 0;
    }
  }
}

body {
  &.modal-open {
    #fullpage {


    }
  }
}


.motorcycle{
  #model-no-location{
    .modal-content{
      .icon{
        margin-bottom: em(30);
      }
    }
  }
}

.mobileText{
  display: none;
}


.marine-page{
  .stepPopup {
    &.generator-d {
      .modal-content {
        .product-area {
          .btn-area{
            text-align: center;
            .btn {
              &:last-child {
                float: none;
              }
            }
          }
        }
      }
    }
  }
}


@media (min-width: 1601px) and (max-width: 2400px) {
}

@media (min-width: 1530px) and (max-width: 1540px){
  .stepPopup {
    .modal-content {
      .p40{
        padding: em(72) em(16) em(40) em(40);
      }
      .scroll-area {
        height: em(480);
      }
    }
  }
}

@media (min-width: 1530px) and (max-width: 1600px) {

  .stepPopup{
    &.iframe-popup {
      .modal-content{
        height: 46em;
      }
    }
  }
  .stepPopup .modal-content .scroll-area {
    //height: 25.375em;
  }
  }


@media (min-width: 1330px) and (max-width: 1366px) {
  .stepPopup .modal-content .p0 {
    overflow: hidden;
    img {
      height: auto;
      width: auto;
      max-height: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  .stepPopup.maintenance .modal-content p {
    font-size: 1.2em;
  }
  .stepPopup{
    &.iframe-popup {
      .modal-dialog{
        width: 100em;
        max-width: 100%;
        height: 63em;
        top: 4%;
      }
      .modal-content{
        width: 100em;
        max-width: 100%;
        height: 100%;
        margin: auto;
        padding: 0 0 0;
        text-align: center;
      }
    }
    .modal-content {
      .p40{
        padding: em(56) em(16) em(40) em(40);
      }
      .scroll-area{
        height: em(480);
      }
    }
  }

  .motorcycle{
    .stepPopup {
      .modal-content{
        height: 30em;
        .p0 {
          img{
            margin-left: 0;
          }
        }
      }
      .modal-lg{
        width: em(975);
      }
    }
  }

}



@media (min-width: 1201px) and (max-width: 1280px) {
  .stepPopup {
    .modal-content {
      .scroll-area{
        height: em(480);
      }
    }
  }
  .motorcycle{
    .stepPopup {
      .modal-content{
        height: 30em;
        .p0 {
          img{
            margin-left: 0;
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .modal-lg{
        width: em(975);
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1550px) {
  .stepPopup .modal-content {
    height: 36.3em;
  }
}


@media (min-width: 1270px) and (max-width: 1290px){
  .stepPopup {
    .modal-content {
      .p40{
        padding: em(72) em(16) em(40) em(40);
      }
      .scroll-area {
        height: em(480) !important;
      }
    }
  }
}
/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {

  .vin-modal.invalid .modal-dialog .modal-content .invalid_text p a{
    &:hover{
      color: $brand-black;
    }
  }
  .stepPopup, .vin-modal {
    .modal-dialog {
      .close {
        &:hover {
          background: darken($brand-primary, 10%);
        }
      }
    }
    .controls {
      a {
        &:hover {
          background: $brand-primary;
        }
      }
    }
  }
  .lg {
    .lg-actions {
      button {
        &:hover {
          background: $brand-primary;
        }
      }
    }
    .lg-toolbar {
      .lg-close {
        &:hover {
          background: $brand-primary;
          border: solid 1px $brand-primary;
          color: $brand-white;
        }
      }
    }
  }
}

.modal{
  height: 100% !important;
}


/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {


  .stepPopup .modal-content .scroll-area{
    height: em(400);
  }

  body .cd-image-container{
    width: 54%;
  }


  .stepPopup .controls{
    position: relative;
    top: auto;
    margin-top: 173px;
  }

  .stepPopup{
    &.iframe-popup {
      .modal-dialog{
        height: 55em;
      }
      .modal-content{
        height: 100%;
        padding: 4em 0 0;
      }
    }
  }


}


@media  (max-width: 1024px) {




  .owl-carousel {
    z-index: 1;
  }
  .lg .lg-actions button{
    background: none !important;
  }

}

@media screen and (min-width: 500px) and (max-width: 870px) and (orientation: landscape) {
  body {
    overflow: hidden !important;

    .mobileText {
      margin: 0 auto;
      padding: 0;
      text-align: center;
      font-size: em(20);
      line-height: em(22);
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 9999999999;
      height: 100%;
      overflow: hidden;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      i {
        margin: 0 auto;
        display: block;
        font-size: 3em;
        height: em(30);
        width: em(30);
      }

      p {
        font-size: em(18);
        margin: 0 auto;
      }

      a.logo {
        text-align: center;
        margin: 0 auto;
        height: auto;

        img {
          height: em(38);
          margin-top: em(35);
          margin-bottom: em(10);
        }
      }
    }

    #nav-icon3 {
      display: none;
    }
  }
}

@media screen and (max-device-width: 870px) and (orientation: landscape) {
  .safari {
    &.orientation_landscape {
      overflow: hidden !important;

      .mobileText {
        margin: 0 auto;
        padding: 0;
        text-align: center;
        font-size: em(24);
        line-height: em(20);
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 99999;
        height: 100%;
        overflow: hidden;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
          margin: 0 auto;
          font-size: 3em;
          bottom: 5%;
          height: em(30);
          width: em(30);
        }

        p {
          font-size: em(18);
          margin: 0 auto;
        }

        a.logo {
          text-align: center;
          margin: 0 auto;
          height: auto;

          img {
            height: em(38);
            margin-top: em(35);
            margin-bottom: em(10);
          }
        }

      }

      #nav-icon3 {
        display: none;
      }
    }
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .mobileText {
    display: none;
  }
}

/* 2688x1242px at 458ppi */
@media only screen and (device-height: 896px) and (orientation : landscape)  {
  body {
    overflow: hidden !important;

    .mobileText {
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: em(20);
      line-height: em(22);
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 9999999999;
      height: 100%;
      overflow: hidden;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      i {
        display: block;
        margin: 0 auto;
        font-size: 3em;
        bottom: 5%;
        height: em(30);
        width: em(30);
      }

      p {
        font-size: em(18);
        margin: 0 auto;
      }

      a.logo {
        text-align: center;
        margin: 0 auto;
        height: auto;

        img {
          height: em(60);
        }
      }
    }

    #nav-icon3 {
      display: none;
    }
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .stepPopup .controls{
    top:auto;
  }

  .lg .lg-actions {
    button.lg-next{
      right: 0px;
    }
    button.lg-prev{
      left: 0px;
    }



  }
  .lg .lg-toolbar{
    top: 2.375em;
  }

  .modal-dialog {
    width: em(600);
  }
  .stepPopup {
    .modal-dialog {
      top: 30%;
      .close {
        font-size: em(20);
      }
    }
    .modal-content {
      padding-right: 0;
      height: initial;
      border: none;
      .scroll-area{
        height: 20em;
      }
    }
    &.iframe-popup {
      .modal-dialog{
        height: 75em;
      }
      .modal-content{
        height: 100%;
      }
    }
  }
}



/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .stepPopup .modal-content .p0{
    display: none;
  }


  .lg .lg-actions button{
    text-indent: -500000px;
    padding-left:0px;
    padding-right:0px;
    width: 44px;
    &.lg-prev{
      padding-left:0px;
      padding-right:0px;
      text-align: left;
      left: 0.8em;

    }
    i{
      right: 0.8em;
      text-indent: 500000px;
    }
  }


  .stepPopup .modal-content .scroll-area{
    height: 190px;
  }
.controls{
  display: none;
}
  .stepPopup {
    /*background: $brand-white;*/
    top:25%;

    width: 80%;
    left: 10%;

    .modal-dialog {
      margin: 0;
      top: 0;
      height: auto;
      .close {
        font-size: em(20);
      }
    }
    .modal-content {
      padding-right: 0;
      height: initial;
      border: none;
      .p40 {
        padding: em(36);
      }
    }
    .controls {
      top: 50%;
      a {
        padding: em(22);
        background: $brand-primary;
        min-width: em(80);
        @include borderRadius(0);
        &.left {
          left: 0;
        }
        &.right {
          right: 0;
        }
        span {
          display: none;
        }
      }
    }
    &.iframe-popup{
      top: 5%;
      .modal-dialog{
        top: 0;
        height: 90%;
      }
      .modal-content{
        padding: 2em 0 0;
        height: 100%;
        overflow: scroll;
        iframe{
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }
  .lg-outer {
    .lg-img-wrap{
      padding-top: 3.5%;
    }
  }


}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .stepPopup.maintenance .modal-content {
    width: auto;
  }
  .lg .lg-toolbar .lg-close{
    width: 1.1625em;
    height: 1.1625em;line-height: 1.1em;
  }
  .lg {
    .lg-toolbar{
      right: em(10);
      top:em(16);
    }
  }
  .lg-outer {
    .lg-img-wrap{
      padding-top: 3.5%;
    }
  }
  .stepPopup {
    .modal-content p {
      br {
        display: none;
      }
    }
    &.promo-pp-mob{
      .modal-content p {
        br {
          display: block;

        }
      }
    }
    &.iframe-popup{
      width: 96%;
      left: 2%;
      .modal-dialog{
        max-width: 100%;
        height: 90%;
      }
    }
  }
}


/* Portrait phones and smaller */
@media (max-width: 320px) {
}