.recall{
  position: relative;
  background: #f3f6fa;
  #section5{
    background: $brand-white;
  }
  &:before{
    content: "";
    position: absolute;
    height: 1920px;
    width: 1920px;
    border-radius: 50%;
    background: #f0f3f8;
    top:-21%;
    margin:auto;
    right:0;
    left: 0;
    z-index: 1;
  }
  .form-control{border-width:2px !important;  }
}
.book-service .form-group .form-control .selectpicker{
  text-transform: none !important;
}
.recall-text{
  padding-top: 131px;
  overflow: inherit;
  position: relative;
  z-index: 9;
  &:before{
    content: "";
    position: absolute;
    height: 1400px;
    width: 1400px;
    border-radius: 50%;
    background: #f6f8fb;
    top: -11%;
    margin:auto;
    right: 0;
    left: 0;
    z-index: 1;
  }
  .container{
    position: relative;
    z-index: 99;
  }
  .vehicle-form{
    .container {
      z-index: 999;
    }
  }
  .recall-top-pane{
    text-align: center;
    .icon{
      width: 104px;
      height: 104px;
      border-radius: 50%;
      background: $brand-white;
      text-align: center;
      position: relative;
      margin-bottom: 35px;
      display: inline-block;
      box-shadow:0px 0px 50px #e5e8e8;
      i{
        width: 104px;
        height: 104px;
        padding: 20px;
        display: inline-block;
        background: url("#{$websitePath}assets/images/recall/icon-alaram.png") no-repeat center;
      }

    }
    h3{
      margin: 0px auto;
      text-transform: uppercase;
      font-family: $univers;
      color: #474747;
      font-size: em(26);
      line-height:26px;
      margin-bottom: 27px;
    }
    h4{
      margin: 0px auto;
      text-transform: uppercase;
      font-family: $univers-condensed;
      font-weight: bold;
      color: #222222;
      font-size: 70px;
      line-height:60px;
      margin-bottom: 37px;
      padding-bottom: 37px;
      position: relative;
      &.recall-head{
        &:before{
          background: #222222;
          content: "";
          height: 2px;
          width: 20px;
          position: absolute;
          left: 50%;
          margin-left: -10px;
          bottom: 0px;
          float: left;
        }
      }
    }
    h5{
      margin: 0px auto;
      text-transform: uppercase;
      letter-spacing: 3px;
      color: #cc0000;
      font-size: em(30);
      line-height:30px;
      margin-bottom: 40px;
      position: relative;
      font-family: $univers;
    }
    p{
      color: $brand-black;
      font-size: em(22);
      margin-bottom: 56px;
      b{
        font-weight: bold;
        &.red{
          color: $brand-primary;
        }
      }
    }
    .bt_area{
      margin-bottom: 60px;
      a{
        margin: 0px 20px;
        min-width: 361px;
      }
    }
  }
  .latest-recall{
    margin-bottom: em(100);
    padding: em(77) em(80);
    background: $brand-white;
    -webkit-box-shadow: 0px 0px 5px 5px rgba(235,235,235,1);
    -moz-box-shadow: 0px 0px 5px 5px rgba(235,235,235,1);
    box-shadow: 0px 0px 5px 5px rgba(235,235,235,1);
    .col-md-6{
      margin: 0px auto;
      float: none;
    }
    h6{
      font-family: $univers;
      margin: 0px auto;
      text-transform: uppercase;
      color: $brand-black;
      font-weight: bold;
      font-size: em(30);
      line-height:30px;
      position: relative;
      text-align: center;
      margin-bottom: 50px;
      span{
        display:inline-block;
        background: $brand-white;
        padding: 0px 85px;
        position: relative;
        z-index:2;
      }
      &:after{
        content: "";
        background: #949494;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 50%;
        margin-top: -0.5px;
        left: 0px;
      }
    }
    p{
      font-size:em(18);
      color: #3f3f3f;
      margin-bottom: 18px;
      text-align: left;
    }
    .latest_recalls_img{
      margin-bottom: em(20);
    }
    .latest_recalls_img_mob{
      display: none;
      margin-bottom: em(20);
    }
  }
}
.vehicle-form{
  background: #222222 url("#{$websitePath}assets/images/recall/vehicle-form-bg.jpg") no-repeat right bottom;
  padding: 100px 0px;
  h6{
    text-transform: uppercase;
    font-size: em(30);
    line-height: 30px;
    padding-bottom: 30px;
    margin: 0px 0px 40px 0px;
    position:relative;
    font-weight: bold;
    color: $brand-white;
    &:before{
      background: $brand-white;
      position: absolute;
      content: "";
      height: 2px;
      width: 20px;
      left: 0px;
      bottom: 0px;
    }
  }
  p{
    font-size: em(18);
    margin-bottom: 47px;
    color: $brand-white;
  }
  .col-md-6{
    padding-bottom: 50px;
    border-bottom: 1px solid #434343;
    margin-bottom: 40px;
    .btn-group{
      width: 100%;
      background:$brand-white;
      height: 52px;
      line-height: 52px;
      padding-left: 20px;
      .btn {
        padding: 0px;
        line-height: 52px;
        font-size: 15px;
        span{
          color: #222222;
          text-transform: initial;
          font-weight: normal;
        }
        .caret{
          right: 1.5em;
          top: 0;
        }
      }
    }
    &:first-child{
      padding-right: 65px;
    }
    &:last-child{
      padding-left: 65px;
    }

  }
  .btn.btn-primary{
    padding-right: 75px;
  }
}
.search-result{
  background: $brand-white;
  .spec-pane{
      background: $brand-primary;
      padding:54px 0px;
      position: relative;
      //height: 155px;
      .dt-pane{
        float: left;
        padding-right: em(36);
        .title{
          font-size: 14px;
          color: $brand-white;
          float: left;
          width: 100%;
          line-height: 14px;
          margin-bottom: 13px;
          text-transform: uppercase;
        }
        .value{
          text-transform: uppercase;
          line-height: 14px;
          float: left;
          width: 100%;
          font-size: 14px;
          color: $brand-white;
          font-weight:bold;
        }
      }
      .center-pane-head{
        font-size: 32px;
        line-height: 35px;
        text-align: center;
        text-transform: uppercase;
        color: $brand-white;
        font-weight: bold;
      }
    &:before{
        bottom: -14px;
        content: "";
        height: 14px;
        position: absolute;
        left: auto;
        margin:0px auto;
        overflow: auto;
        right: auto;
        width:100%;
        background:url("#{$websitePath}assets/images/recall/arrow.png") no-repeat center;
      text-align: center;
      }
    }
  }
.contact-recall{
  background: #464f5d;
  padding: 100px 0px;
  display: inline-block;
  width: 100%;
  .small-head{
    margin-top: 0px;
    color: $brand-white;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 3px;
    position: relative;
    padding-bottom: 20px;
    text-transform: uppercase;
    margin-bottom: 30px;
    &:before{
      content: "";
      width: 20px;
      height: 2px;
      background: $brand-white;
      position: absolute;
      left: 0px;
      bottom: 0px;
    }
  }
  .main-head{
    color: $brand-white;
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    padding-bottom:59px;
    text-transform: uppercase;
    border-bottom: 1px solid #90959e;
    margin-bottom: 59px;
  }
  .contact-detail-pane{
    padding-right: em(96);
    border-right: 1px solid #90959e;
    .contact-detail{
      p{
        font-size: 20px;
        color: $brand-white;
        margin-bottom: 80px;
        a{
          color: #4cbaff;
          text-decoration: underline;
        }
      }
      h4{
        font-size: 20px;
        font-weight: bold;
        color: $brand-white;
        position: relative;
        padding-bottom: 19px;
        margin-bottom: 50px;
        text-transform: uppercase;
        line-height: 23px;
        letter-spacing: 1px;
        &:before{
          content: "";
          width: 20px;
          height: 2px;
          background: $brand-white;
          position: absolute;
          left: 0px;
          bottom: 0px;
        }
      }
      ul{
        padding: 0px;
        list-style: none;
        li{
          padding-left: 52px;
          margin-bottom: 41px;
          padding-bottom: 41px;
          border-bottom: 1px solid #90959e;
          float: left;
          clear: left;
          padding-right: 48px;
          position: relative;
          &:before{
            content: "\78";
            font-family: "honda" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            position: absolute;
            left: 0px;
            top: 0px;
            color: $brand-white;
            font-size: 29px;
            line-height: 29px;
          }
            span{
              font-size: 14px;
              line-height: 14px;
              text-transform: uppercase;
              color: $brand-white;
              display: block;
              margin-bottom: 5px;
            }
          a{
            display: block;
            font-size: 30px;
            line-height: 30px;
            text-transform: uppercase;
            color: $brand-white;
          }
          &:last-child{
            border-bottom: 0px;
            margin-bottom: 0;
            padding-bottom: 23px;
          }
        }
      }
    }

  }
  .enq-form{
    padding-left: em(83);
    .form-wrapper{
      overflow: hidden;
      position: inherit;
    }
    h6{
      margin: 0px;
      padding: 0px;
      margin-bottom: 39px;
      font-size: 26px;
      line-height: 26px;
      font-weight: bold;
      text-transform: uppercase;
      color: $brand-white;
    }
    .form-group{
      padding-right: em(30);
      margin-bottom: 0px;
      input, .btn-group, textarea{
        width: 100%;
        line-height: 52px;
        height: 52px;
        background: $brand-white;
        padding-left:25px;
        //text-transform: uppercase;
        color: #222222;
        margin-bottom: 30px;
        border: 0;
        outline: none;
        font-size:15px;
        font-weight: normal;
        .btn{
          padding: 0px;
          height: 52px;
          text-transform: inherit;
          .filter-option{
            font-size:15px;
            color: #222222;
            font-weight: normal;
          }
          &:active{
            -webkit-box-shadow: inset 0 0px 0px rgba(0,0,0,0.125);
            box-shadow: inset 0 0px 0px rgba(0,0,0,0.125);
          }
        }
        .caret{
          top:em(24);
          right:em(24);
        }
      }
      &.has-error{
        input, .form-control{
          border: 2px solid $brand-primary;
        }
      }
      &.has-success{
        input{
          border: 2px solid #3c763d;
        }
        .form-control{
          border: 2px solid #3c763d;
        }
      }
      textarea{
        height: 134px !important;
        float: left;
      }
      .left{
        width: 49%;
        float: left;
      }
      .right{
        width: 49%;
        float: right;
      }
      &.captcha{
        .text-pane{
          width: 67%;
          float: left;
          &:last-child{
            width: 33%;
            border: 1px solid #6c7789;
            background: #525c6b;
            padding: 14px;
            height: 52px;
            .refresh-icon{
              margin-left: 5px;
              border-left:1px solid #868d98;
              height: 23px;
              line-height: 23px;
              padding-left:12px;
              a{
                i{
                  font-size: 15px;
                  color: $brand-white;
                  @include transition(0.5s);
                }
                &:hover{
                  i{
                    color:$brand-primary;
                  }
                }
              }

            }

          }
        }
      }

    }
    .btn.btn-primary{
      padding-right: em(75);
    }
    .col-md-6, .col-md-12{
      padding-left: 0px;
    }
  }
}


.recallEnquireForm{
  overflow: hidden;
  &.loading{
    &:before{
      background: #464f5d;
    }
  }
}
.text-anchor{
  position: relative;
  @include transition(0.5s);
  &:before{
    width: 0;
    height: 1px;
    position: absolute;
    content: '';
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    background: #4cbaff;
    right: 0;
    bottom: 0;
  }
}

.result-table{
  padding: 0px;
  border: 1px solid #e5e5e5;
  float: left;
  width: 100%;
  margin-top:79px;
  margin-bottom:100px;
  display: flex;
  .row{
    margin-left: -15px;
    margin-right: -15px;
  }
  .head-pane{
    background: #464f5d;
    height: 55px;
    line-height:55px;
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    color: $brand-white;
    text-transform: uppercase;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 50px;
  }
  ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
    li{
      &:before{
        content: "";
        width: 0px;
        height: 50px;
        left: 0px;
        float: left;
        position: absolute;
        top:0px;
        z-index: 1;
        @include transition(0.5s);
        background: #f3f6fa;
      }
      a{
        border-bottom: 1px solid #e5e5e5;
        font-size: em(15);
        line-height:50px;
        font-weight: bold;
        padding: 0px;
         padding-left:30px;
        color: $brand-black;
        display: block;
        z-index: 2;
        position: relative;
      }


    }
  }


  .col-md-7{
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    .center-pane{
      padding: 30px 50px 100px;

      .cl{
        font-size: 15px;
        line-height: 15px;
        display: block;
        margin-bottom: 21px;
        color: $brand-black;
        b{
          float: left;
          padding-right: 5px;
          text-transform: uppercase;
        }
        .opened{
          font-weight: bold;
          text-transform: uppercase;
          color: #39a200;
        }
      }
      .summary{
        margin-top: 49px;
        h6{
          margin: 0px 0px 15px 0px;
          font-size: 15px;
          font-weight: bold;
          color: $brand-primary;
          text-transform: uppercase;
        }
        p{
          font-size: 15px;
          color: $brand-black;
          margin-bottom: 43px;
        }
      }
    }
  }

  .right-pane{
    .right-pane-sub{
      padding: 30px 50px;
      p{
        font-size: 15px;
        color: $brand-black;
        margin-bottom: 30px;
      }
      input{
        font-size: 13px;
        width: 100%;
        border: 1px solid $brand-primary;
        height: 48px;
        line-height:48px;
        padding-left: 19px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $brand-primary;
        margin-bottom: 20px;
        padding-right: 19px;
        outline: none;
        &:focus{
          outline: none;
        }
      }
      .btn.btn-primary{
        margin-bottom: 111px;
        padding-right: 75px;
        width: 100%;
      }

      ul{
        list-style: none;
        padding: 0px;
        margin:0;
        li{
          border-top: 1px solid #e5e5e5;
          display: block;
          float: left;
          width: 100%;
          text-align: left;
          position: relative;
          &:before{
            height: 62px;
          }
          a{
            color: $brand-black;
            display: block;
            height: 62px;
            line-height: 62px;
            text-transform: uppercase;
            float: left;
            border-bottom: 0;
            padding-left: 0;
            width: 100%;
            @include transition(0.5s);
            i{
              float: right;
              font-size: 22px;
              color: $brand-primary;
              padding-top: 2px;
            }
          }
        }
      }
      &.second-step{
        .btn.btn-primary{
          width: 100%;
          padding-right: em(75);
          margin-bottom: 35px;
        }
        .questions{
          border-top: 1px solid #e5e5e5;
          display: block;
          border-bottom: 1px solid #e5e5e5;
          margin: 0px;
          position: relative;
          padding-top:35px;
          margin-bottom: 39px;
          float: left;
          .or{
            padding-right: 2px;
            font-size: 13px;
            font-style: normal;
            text-align: center;
            width: 34px;
            height: 34px;
            color: $brand-white;
            line-height: 34px;
            border-radius: 50%;
            font-weight: bold;
            text-transform: uppercase;
            position: absolute;
            left: 0px;
            right: 0px;
            margin: auto;
            bottom: -17px;
            background: #464f5d;
          }

          .row1{
            float: left;
            width: 100%;
            .col1{
              font-size: 15px;
              margin-bottom: 35px;
              span{
                float: left;
                width: 100%;
              }


            }
          }

        }
      }
    }
  }
}
.result-not-found{
  background: $brand-white;
  text-align: center;
  display: block;
  float: left;
  width: 100%;
  padding: 15em 0;
  .result-icon{
    width: 104px;
    height: 104px;
    border-radius: 50%;
    background: $brand-white;
    text-align: center;
    position: relative;
    margin-bottom: 35px;
    display: inline-block;
    box-shadow:0px 0px 50px #e5e8e8;
    i{
      width: 104px;
      height: 104px;
      padding: 20px;
      display: inline-block;
     background: url("#{$websitePath}assets/images/recall/not-found.png") no-repeat center;
    }

  }
  h5{
    margin: 0px auto;
    text-transform: uppercase;
    font-family: $univers-condensed;
    font-weight: bold;
    color: $brand-primary;
    font-size: 30px;
    line-height:30px;
    margin-bottom: 35px;
    padding-bottom: 30px;
    position: relative;
      &:before{
        background: #222222;
        content: "";
        height: 2px;
        width: 20px;
        position: absolute;
        left: 50%;
        margin-left: -10px;
        bottom: 0px;
        float: left;
      }
    }
  p{
    font-size: 22px;
    color: $brand-black;
  }
}
.res_ver{display: none !important;}

.vin-modal{
  .modal-dialog{
    max-width: 1400px;
    margin: 6% auto;
    width: auto;
    .modal-content{
      border-radius: 0px;
      padding: 70px 50px 50px 50px;
      display: inline-block;
      width: 100%;
      h5{
        font-size: 36px;
        line-height: 36px;
        color:#000000;
        font-weight: bold;
        margin-top: 0px;
        text-transform: uppercase;
        padding-bottom: 33px;
        margin-bottom: 54px;
        border-bottom: 1px solid #ccc;
      }
      .con_pane{
        display: flex;width: 100%;
      }
      .vin-left{
        border-right: 1px solid #ccc;
        padding-right: 100px;
        color: #3f3f3f;
        font-size: 18px;
        p{
          color: #3f3f3f;
          font-size: 18px;
          line-height:40px;
          margin-bottom: 60px;
        }
        b{
          margin-bottom: 25px;
          display: block;
        }
        ul{
          list-style: none;
          padding: 0px;
          li{
            color: #3f3f3f;
            font-size: 18px;
            line-height: 18px;
            padding-left: 32px;
            background: url("#{$websitePath}assets/images/recall/li.jpg") 4px  3px no-repeat;
            margin-bottom: 30px;
          }
        }
        &.col-md-12{
          border: 0px;
          padding-right: 0px;
          .last-child{
            margin-bottom:11px;
          }
          ul{
            padding-bottom: 30px;
            li{
              line-height: 30px;
              background-position: 4px 9px;
            }
          }
        }
      }
      .col-md-5{
        padding-bottom: 24px;
        text-align: center;
        .vin-head{
          line-height: 28px;
          font-size: 20px;
          padding-top:15px;
          text-transform: uppercase;
          font-weight: bold;
          color: $brand-black;
          margin: 0px 0px 40px 0px;
          text-align: center;
        }
        .vin-img{
            min-height: 270px;
          text-align: center;
          margin-bottom: 14px;
        }
        .vin-text{
          font-size: 18px;
          line-height:18px;
          color: #3f3f3f;
          margin-bottom: 35px;
        }
        .vin-number{
          color: $brand-primary;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
    }
    .close {
      background: $brand-primary;
      width: em(22);
      height: em(22);
      opacity: 1;
      position: absolute;
      right: 1px;
      top: 1px;
      @include transitions(0.5s);
      &:before {
        background: url("#{$websitePath}assets/svgs/cross-out.svg") no-repeat;
        background-size: cover;
        width: em(18);
        height: em(18);
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        -webkit-transform: scale(.6);
        -moz-transform: scale(.6);
        -ms-transform: scale(.6);
        -o-transform: scale(.6);
        transform: scale(.6);
      }
    }
  }
  &.invalid{
    .modal-dialog{
      .modal-content{
        background-image: url("#{$websitePath}assets/images/recall/caution.jpg");
        background-position: left bottom;
        background-repeat: no-repeat;
        h5{
          color: $brand-primary;
        }
        .invalid_text{
          padding-left: 32%;
          width: 100%;
          float: left;
          padding-bottom: 110px;
          p{
            margin-top: 27px;
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 40px;
            padding-right: 8em;
            a{
              color: $brand-primary;
              text-decoration: underline;
              @include transition(0.5s);
            }
          }
          .btn{
            padding-right: 68px;
          }

        }
      }
    }
  }
}

#countryLanguageClick{
  margin-bottom: 20px;
}



@media (min-width: 1530px) and (max-width: 1600px) {

  .recall{
     &:before{
      height: 1600px;
      width: 1600px;
      top:-12%;
    }
  }
  .search-result .spec-pane .dt-pane{
    padding-right: 0.2em;
  }
}

@media (min-width: 1330px) and (max-width: 1366px) {


  .vin-modal .modal-dialog{
    max-width: 1200px;
    margin: 1% auto;
  }

  body {
    &.recall{
      &:before{
        display: none;
      }
    }
    .vehicle-form{
      background-size: 76%;
      background-position: 133% 0px;
      .col-md-6 .btn-group{
        height: 42px;
        line-height: 42px;
        padding-left: em(20);
        .btn {
          line-height: 42px;
          font-size: em(15);
        }
      }
    }
    .search-result .spec-pane{
      .dt-pane{
        padding-right: em(0);
        .title{
          font-size: em(14);
          line-height: 14px;
          margin-bottom: em(13);

        }
        .value{
          line-height: 14px;
          font-size: em(17);
        }
      }
      .center-pane-head{
        font-size: em(32);
      }
    }

    .result-table {
      .col-md-7 .center-pane{
        //padding:em(30) em(50) em(100);
        padding:30px 30px 70px;
      }
      .head-pane{
        padding-left: 15px;
        font-size: em(18);
      }
      ul{
        li{
          a{
            font-size: em(16);
            i{
              display: none;
            }
          }
        }
      }
      .col-md-7 .center-pane {
        .cl{
          font-size: em(17);
          margin-bottom: 13px;
        }
        .summary {
          h6, p{
            font-size: em(17);
          }
        }
      }

      .right-pane .right-pane-sub{
        padding:30px 30px 70px;
        .btn.btn-primary{
          margin-bottom: 81px;
        }
        input{
          padding: 0px 10px;
          height: 40px;
          line-height: 40px;
          letter-spacing: 0px;
        }
        p{
          font-size: em(17);
          margin-bottom: 20px;
        }
        &.second-step .questions .row1 .col1{
          font-size: em(17);
        }
      }
    }
    .contact-recall{
      padding: 50px 0px;
      .small-head{
        font-size:em(22);
      }
      .main-head{
        padding-bottom: 30px;
        margin-bottom: 39px;
        font-size: em(40);
        line-height: 26px;
      }
      .contact-detail-pane {
        .contact-detail {
          p{
            font-size: em(20);
            margin-bottom: 50px;
          }
          h4{
            font-size: em(20);
          }
          ul {
            li {
              a{
                font-size: em(30);
                line-height: 20px;
              }
            }
          }
        }

      }
      .enq-form{
        padding-left: 2.1875em;
        .form-group{
          input, textarea, .btn-group{
            height: 42px;
            line-height:42px;
            padding-left: em(15);
            font-size:13px;
            margin-bottom: 26px;
            .btn {
              line-height:42px;
              height: 42px;
              .filter-option{
                font-size:13px;
              }
            }
            .caret{
              top:0;
              right: .8em; 
            }
          }
          &.captcha .text-pane{
            width: 50%;
            &:last-child{
              width: 50%;
              padding: 10px 4px;
              height: 42px;
              .refresh-icon a i{
                font-size: 12px;
              }
            }
          }
        }
      }

    }


  }

}

@media (min-width: 1201px) and (max-width: 1366px) {


  body {
    .container{
      width: 100em;
    }
    &.recall{
      &:before{
        display: none;
      }
      .recall-text:before{
        width: 1280px;
        height: 1280px;
      }
      main{
        .btn{
          font-size: em(16);
          padding: .6875em 1em;
        }
        &:before{
          display: none;
        }
      }


    }
    .vehicle-form{
      background-size: 76%;
      background-position: 133% 0px;
      .col-md-6 .btn-group{
        height: 42px;
        line-height: 42px;
        padding-left: em(20);
        .btn {
          line-height: 42px;
          font-size: em(15);
        }
      }
    }
    .search-result .spec-pane{
      .dt-pane{
        padding-right: em(0);
        .title{
          font-size: em(14);
          line-height: 14px;
          margin-bottom: em(13);

        }
        .value{
          line-height: 14px;
          font-size: em(17);
        }
      }
      .center-pane-head{
        font-size: em(32);
      }
    }
    .result-table {
      .col-md-7 .center-pane{
        //padding:em(30) em(50) em(100);
        padding:30px 30px 70px;
      }
      .head-pane{
        padding-left: 30px;
        font-size: em(18);
      }
      ul{
        li{
          a{
            font-size: em(16);
            i{
              display: none;
            }
          }
        }
      }
      .col-md-7 .center-pane {
        .cl{
          font-size: em(17);
          margin-bottom: 13px;
        }
        .summary {
          h6, p{
            font-size: em(17);
          }
        }
      }

      .right-pane .right-pane-sub{
        padding:30px 30px 70px;
        .btn.btn-primary{
          margin-bottom: 81px;
        }
        input{
          padding: 0px 10px;
          height: 40px;
          line-height: 40px;
          letter-spacing: 0px;
        }
        p{
          font-size: em(17);
          margin-bottom: 20px;
        }
        &.second-step .questions .row1 .col1{
          font-size: em(17);
        }
      }
    }
    .contact-recall{
      padding: 50px 0px;
      .small-head{
        font-size:em(22);
      }
      .main-head{
        padding-bottom: 30px;
        margin-bottom: 39px;
        font-size: em(40);
        line-height: 26px;
      }
      .contact-detail-pane {
        .contact-detail {
          p{
            font-size: em(20);
            margin-bottom: 50px;
          }
          h4{
            font-size: em(20);
          }
          ul {
            li {
              a{
                font-size: em(30);
                line-height: 20px;
              }
            }
          }
        }

      }
      .enq-form{
        padding-left: 2.1875em;
        .form-group{
          input, textarea, .btn-group{
            height: 42px;
            line-height:42px;
            padding-left: em(15);
            font-size:13px;
            margin-bottom: 23px;
            .btn {
              line-height:42px;
              height: 42px;
              .filter-option{
                font-size:13px;
              }
            }
            .caret{
              top:0;
              right: .8em;
            }
          }
          &.captcha .text-pane{
            width: 50%;
            &:last-child{
              width: 50%;
              padding: 10px 4px;
              height: 42px;
              .refresh-icon a i{
                font-size: 12px;
              }
            }
          }
        }
      }

    }

  }
}

@media (min-width: 1200px){

  .result-table{
    ul{
      li{
        &:hover, &.active{
          a{
            background: none;
          }
          &:before{
            width: 100%;
          }
        }
      }
    }
    .right-pane{
      .right-pane-sub{
        ul{
          li{
            &:hover{
              a{
                padding: 0px 10px;
              }
            }
          }
        }
      }
    }
}

  .contact-recall{

    .contact-detail-pane{
      .contact-detail{
        ul{

          li{
            a{
              &:hover{
                color: #4cbaff;
              }
            }
          }
        }
      }

    }
    .enq-form{



      .form-group{

        &.captcha{
          .text-pane{

            &:last-child{
              .refresh-icon{
                a{
                  &:hover{
                    i{
                      color:$brand-primary;
                    }
                  }
                }

              }

            }
          }
        }

      }

    }
  }



  .text-anchor{
    &:hover{
      &:before{
        left: 0px;
        right: auto;
        width: 100%;
      }
    }
  }




}


@media (min-width: 992px) and (max-width: 1199px) {
  .result-table .right-pane .right-pane-sub input {
    letter-spacing: 0.2px;
  }
  .recall {
    &:before {
      width: 100%;
    }
  }
  .recall-text {
    &:before {
      width: 100%;
    }
  }
}

@media (max-width: 1024px){

  .recall-text{
    &:before{
      display: none;
    }
  }
  .vin-modal {
    .modal-dialog{
      max-width: 90%;
      margin: 9% auto;
      .modal-content {
        .col-md-5{
          .vin-img{
            min-height: auto;
            margin-bottom:40px;
            img{
              width: 100%;
            }
          }
        }
        .vin-left  {
          padding-right: 70px;
          p{
            font-size: 16px;
            line-height:30px;
            margin-bottom: 40px;
          }
          ul{
            li{
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 20px;
            }
          }
        }
        .col-md-5 {
          .vin-head{
            font-size: 18px;
            line-height: 28px;
          }
        }
        h5{
          font-size: 30px;
          line-height: 30px;
          padding-bottom: 23px;
          margin-bottom: 34px;
        }
      }
    }
    &.invalid .modal-dialog .modal-content .invalid_text {
      padding-left: 42%;
      p{
        padding-right: 2em;
      }
    }
  }

  .recall:before{
    display: none;
  }
  .recall-text {
    .recall-top-pane {
      .bt_area {
        a{
          font-size: 11px;
        }
      }
    }
    .latest-recall {
      p{
        font-size: 13px;
      }
    }
  }
  .vehicle-form{
    padding: 50px 0;
    background: #222 !important;
    h6{
      text-align: center;
      &:before{
        left: 0px;
        right: 0px;
        margin: auto;
      }
    }
    p{
      text-align: center;
      font-size: 13px;
    }
    .container{
      text-align: center;
      .col-md-8{
        display: inline-block;
        float: none;
        margin: 0px auto;
        text-align: center;
      }
      .btn.btn-primary{
        font-size: 11px;
      }
    }
    .col-md-6 {
      .btn-group{
        height: 42px;
        line-height:42px;
        .btn{
          line-height: 42px;
          font-size: 11px;
          span{
            font-size: 13px;
          }
        }
      }
    }

  }
  body .search-result{
    .spec-pane{
      padding: 15px 0px;
      .container{
        text-align: center;
        .col-md-4{
          width: 100%;
          float: none;
          display: inline-block;
          margin: 0px auto;
          margin-bottom: 30px;
        }
      }
      &:before{
        top: auto;
        bottom: -14px;
      }
      .dt-pane{
        width: 33.33%;
        padding-right: 0;
      }
      .center-pane-head{
        margin:15px auto;
      }
      .res_ver{display: block !important;}
      .desk_ver{display: none !important;}
    }
  }
  .result-table{
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
    .head-pane{
      font-size: 15px;
      padding-left: 15px;
    }
    ul{
      li{
        a{
          font-size: 12px;
        }
      }
    }
    .col-md-7 {
      .center-pane{
        padding: 30px 15px 100px;
      }
    }
    .right-pane {
      .right-pane-sub{
        padding: 30px 15px;
        .btn.btn-primary{
          font-size: 11px;
        }

        &.second-step {
          .btn.btn-primary{
            font-size: 11px;
          }
          .questions .or{
            padding-right: 0px;
          }
        }
      }
    }
  }
  .contact-recall{
    padding: 50px 0px;
    .small-head{
      text-align: center;
      &:before{
        right: 0px;
        margin: auto;
        text-align: center;
      }
    }
    .main-head{
      text-align: center;
      font-size: 30px;
      line-height:30px;
      margin-bottom: 40px;
    }
    .contact-detail-pane .contact-detail {
      p{
        font-size: 16px;
      }
      h4{
        font-size: 16px;
      }
      ul{
        li{
          margin-bottom: 31px;
          padding-bottom: 31px;
          a{
            font-size: 20px;
            line-height:20px;
          }
        }
      }
      .enq-form {
        h6{
          font-size: 24px;
          line-height:24px;
          margin-bottom: 28px;
        }
        .form-group{
          input, .btn-group, textarea{
            height: 42px;
            line-height:42px;
            font-size: 13px;
            padding-left: 15px;
            .caret{
              top: 1.25em;
            }
            .btn{
              height: 42px;
              .filter-option{
                font-size: 13px;
              }
            }
          }
          textarea{
            height: 114px;
          }
          &.captcha .text-pane{
            width: 50%;
            &:last-child{
              height: 42px;
              padding: 9px;
              .refresh-icon a i{
                font-size: 12px;
              }
            }
          }
        }
        .btn.btn-primary{
          font-size: 11px;
        }
      }
    }
  }

}
@media (max-width: 768px){
  .vin-modal {
    .modal-dialog {
      margin: 3% auto;
      .modal-content {
        padding: 30px 50px 20px 50px;
        .con_pane{
          display: inline-block;
          .vin-left{
            padding-right:0;
            border-right: 0px;
            float: left;
            width: 100%;
            p{
              margin-bottom: 30px;
            }
          }
        }
        .col-md-5{
          float: left;
          width: 100%;
          .vin-head{
            br{
              display: none;
            }
          }
          .vin-img {
            img{
              width: 50%;
            }
          }
        }
      }
    }
    &.invalid .modal-dialog .modal-content {
      background-image: none;
      .invalid_text{
        padding-left: 0px;
        padding-bottom: 50px;
        p{
          margin-top: 0px;
          padding-right: 0em;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    .modal-dialog .modal-content .vin-left.col-md-12 ul{
      padding-bottom:0px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {



  .recall-text {
    .recall-top-pane {
      .bt_area {
        a{
          min-width: 261px;
        }
      }
    }
  }
  .vehicle-form {
    h6{
      padding-bottom: 13px;
      margin: 0px 0px 30px 0px;
    }
  }
  .vehicle-form {
    .col-md-8{
      width: 100%;
      .col-md-6{
        padding-bottom: 20px;
        margin-bottom: 20px;
        width: 36%;
        float: none;
        display: inline-block;
        padding: 0px 15px;
      }
    }

  }
  .result-table {
    border: 0px;
    &.row{
      display: block;
      .col-md-2{
        width: 100%;
        margin-bottom: 20px;
         ul{
           border: 1px solid #e5e5e5;
           border-top: 0px;
           li{
             float: left;
             a{
               padding: 0px 50px;
               border: 0px;
             }
           }
         }
      }
      .col-md-7{
        width: 100%;
        float: left;
        border: 1px solid #e5e5e5;
        border-top:0px;
        margin-bottom: 20px;
        .center-pane{
          padding-bottom: 0px;
        }
      }
      .col-md-3{
        width: 100%;
        float: left;
        border: 1px solid #e5e5e5;
        border-top:0px;
      }

    }
    .right-pane .right-pane-sub .btn.btn-primary{
      margin-bottom: 50px;
      width: 30%;
      float: left;
    }
    .right-pane .right-pane-sub.second-step {
      .questions{
        float: left;
        width: 100%;
        .row1 .col1{
          text-align: center;
          float: left;
          width: 50%;
        }
      }
      .btn.btn-primary{
        width: 30%;
        float: left;
      }
    }

  }
  .contact-recall {
    .main-head{
      padding-bottom: 30px;
    }
    .contact-detail-pane{
      border-right: 0px;
      p{
        margin-bottom: 50px;
      }
      .contact-detail {
        ul {
          li{
            width: 50%;
            clear: inherit;
            &:last-child{
              border-bottom: 1px solid #90959e;
              padding-bottom: 31px;
            }
          }
        }

      }
    }
    .enq-form{
      padding: 0px 15px;
      .col-md-6, .col-md-12{
        float: left;
        width: 100%;
        padding-right: 0px;
        .form-group{
          padding-right: 0px;
          &.captcha .text-pane{
            width: 80%;
            &:last-child{
              width: 20%;
            }
          }
        }
      }
      .btn.btn-primary{
        font-size: 11px;
        width: 30%;
      }
    }
  }
}

@media (max-width: 767px){


  .recall-text .latest-recall {
    .latest_recalls_img{
      display: none;
    }
    .latest_recalls_img_mob{
      display: block;
      width: 100%;
    }
  }

  .vin-modal {
    &.invalid .modal-dialog .modal-content .invalid_text {
      .btn{
        padding-right: 1.5625em;
      }
      padding-bottom: 20px;
      p{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }

    .modal-dialog {
      .modal-content{
        padding:40px 30px 20px;
        .vin-left {
          p{
            line-height: 20px;
          }
        }
        h5{
          font-size: 20px;
          line-height: 20px;
          padding-bottom: 13px;
          margin-bottom: 24px;
        }
        .col-md-5{
          padding: 0px;
        }
      }
    }
  }

  .contact-recall .contact-detail-pane{

    border-right: 0px;
    padding-right: 15px;
    .contact-detail {
      ul {
        li{
          clear: inherit;
          width: 50%;
          &:last-child{
            border-bottom: 1px solid #90959e;
            padding-bottom: 31px;
          }
        }
      }
    }
  }
  .contact-recall{
    .enq-form{
     padding-left: 15px;
      .row{
        margin: 0;
        .col-md-6, .col-md-12{
          padding-right: 0px;
          .form-group{
            padding-right: 0px;
          }
        }
      }
      .btn-primary.btn{
        padding-right:1.5625em;
        float: left;
        font-size: 11px;
        clear: left;
      }
      .form-group.captcha {
        .text-pane{
          width: 75%;
          &:last-child{
            width: 25%;
            padding: 14px 5px;
            .refresh-icon{
              float: right;
              margin-left: 0px;
              margin-right: 5px;
              a{
                padding-top: 2px;
                float: left;
              }
            }
          }
        }
      }
    }
  }
  .recall{
    .btn.btn-primary{
      text-align: center;
      padding-right:1.5625em;
    }
  }
  .recall-text {
    .recall-top-pane {
      h4{
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 27px;
        padding-bottom: 17px;
      }
      h5{
        margin-bottom: 20px;
      }
      p{
        font-size: 14px;
        margin-bottom: 36px;
      }
      .bt_area {
        margin-bottom: 40px;
        a{
          margin: 0px 0px;
          width: 220px;
          min-width: auto;
          display: inline-block;
        }
      }
    }
    .latest-recall{
      margin-bottom:50px;
      padding: 30px 20px;
      h6{
        margin-bottom:30px;
      }
    }
  }

  .vehicle-form{
    padding: 30px 0;
    h6{
      padding-bottom: 20px;
      margin: 0px 0px 30px 0px;
    }
    p{
      margin-bottom: 30px;
    }
    .col-md-8{
      width: 100%;
      .col-md-6{
        padding-bottom: 20px;
        margin-bottom: 20px;
        width: 36%;
        float: none;
        display: inline-block;
        padding: 0px 15px;
      }
    }
  }
  .result-table {
    border: 0px;
    &.row{
      display: block;
      .col-md-2{
        width: 100%;
        margin-bottom: 20px;
        ul{
          border: 1px solid #e5e5e5;
          border-top: 0px;
          li{
            float: left;
            a{
              padding: 0px 50px;
              border: 0px;
            }
          }
        }
      }
      .col-md-7{
        width: 100%;
        float: left;
        border: 1px solid #e5e5e5;
        border-top:0px;
        margin-bottom: 20px;
        .center-pane{
          padding-bottom: 0px;
        }
      }
      .col-md-3{
        width: 100%;
        float: left;
        border: 1px solid #e5e5e5;
        border-top:0px;
      }

    }
    .right-pane .right-pane-sub .btn.btn-primary{
      margin-bottom: 50px;
      width: 30%;
      float: left;
    }
    .right-pane .right-pane-sub.second-step {
      .questions{
        float: left;
        width: 100%;
        .row1 .col1{
          text-align: center;
          float: left;
          width: 50%;
        }
      }
      .btn.btn-primary{
        width:auto;
        float: left;
        padding-right:1.5625em;
      }
    }

  }
}


@media (max-width: 480px) {

  .recall-text .recall-top-pane .bt_area{
    span{
      display: block;
      margin-bottom: 5px;
    }
  }

  .vin-modal {

    .modal-dialog {
      .modal-content {
        .vin-left.col-md-12 ul li{
          line-height: 24px; 
        }
        .col-md-5 {
          .vin-text{
            font-size: 16px;
            line-height:18px;
            margin-bottom: 25px;
          }
          .vin-number{
            font-size: 16px;
            line-height:18px;
          }
        }
      }
    }
  }


  .recall-text {
    .recall-top-pane {
      .bt_area {
        a{
          margin-bottom: 5px;
        }
      }
    }
    .latest-recall {
      h6 {
        margin-bottom: 20px;
        span{
          padding: 0px;
        }
        &:after{
          display: none;
        }

      }
    }
  }
  .vehicle-form .col-md-8 .col-md-6{
    width: 100%;
  }
  body .search-result {
    .spec-pane {
      .col-md-4{
        margin-bottom: 0px !important;
        &.center-pane-head{
          margin-bottom: 20px !important;
        }
      }
      .dt-pane{
        margin-bottom: 20px;
        width: 100%;
        float: left;
        text-align: left;
        .title{
          margin-bottom: 8px;
        }
      }
    }
  }
  .result-table.row .col-md-2 ul li{
    float: left;
    width: 100%;
    text-align: center;
  }
  .result-table .col-md-7 .center-pane .cl b{
    width: 100%;
    margin-bottom: 5px;
  }
  .result-table .right-pane .right-pane-sub .btn.btn-primary{
    width: auto;
    padding-right: 1.5625em;
  }
  .contact-recall .main-head{
    font-size: 25px;
    line-height: 30px;
    padding-bottom:10px;
  }
  .contact-recall {
    .contact-detail-pane {
      .contact-detail {
        p{
          margin-bottom: 30px;
        }
        ul li{
          width: 100%;
          margin-bottom: 0px;
          border-bottom: 0px;
          &:last-child{
            margin-bottom: 0px;
            border: 0px;
          }
        }
      }
    }
    .enq-form{
      padding-left: 0px;
      padding-right: 0px;
      .form-group {
        .btn-group, input, textarea{
          padding-left: 10px;
          font-size: 12px;
          line-height: 32px;
          height: 32px;
          margin-bottom: 15px;
          .caret{
            top:1em;
          }
          .btn{
            height: 32px;
            .filter-option{
              font-size: 12px;
            }
          }
        }
        &.captcha .text-pane{
          width: 60%;
          &:last-child{
            width: 40%;
            padding: 3px 5px;
            height: 32px;
          }
        }
      }
    }

  }

}