.page-not-found{
  min-height:500px;
  padding: 120px 0 40px;
  text-align: center;
  h1{
    font-size: 100px;
    line-height: 100px;
  }

  hr {
    width: 50px;
    height: 1px;
    background: $brand-primary;
    border: none;
    margin: auto;
    margin-bottom: 30px;
  }
  h2{
    color: $brand-primary;
  }
}


