

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: none;
  outline-offset: 0;
}


.pre-search-container{
  overflow: inherit;
}



/* multiselect css start */
.cross{
  display: none;
}
.hamburger{
  display: none;
}
.dis-text{
  clear: left;
  margin-top: 25px;
  float: left;
  width: 100%;
  font-weight: bold;
}
span.multiselect-native-select {
  position: relative;
  display: inline-block;
  width: 100%;
  select {
    border: 0!important;
    clip: rect(0 0 0 0)!important;
    height: 1px!important;
    margin: -1px -1px -1px -3px!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 1px!important;
    left: 50%;
    top: 30px;
  }
  b.caret{
    float: right;
    border: 0;
    &:after{
      content: "";
      float: right;
      background: url("#{$websitePath}assets/images/pre-owned/arrow-down.png") no-repeat;
      width: 13px;
      height: 8px;
      margin-top: 5px;
    }
  }
  .open{
    b.caret{
      &:after{
        background: url("#{$websitePath}assets/images/pre-owned/arrow-up.png") no-repeat;
      }
    }
  }
  .multiselect.dropdown-toggle.btn-block {
    background-color: #e6e6e6;
  }
  .multiselect-container.dropdown-menu {
    /*width: 340px;*/
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    li{
      padding: 0;
      font-size: 12px;
      a{
        padding: 0;
        background: $brand-white;
        color: #313131;
        label{
          padding: 13px 35px;
        }
      }
      &.active{
        a{
          label{
            &:before{
              color: #313131;
              position: absolute;
              content: "\48";
              font-family: honda!important;
              font-style: normal!important;
              font-weight: 400!important;
              font-variant: normal!important;
              text-transform: none!important;
              speak: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              left: em(10);
              width: em(16);
              height: em(16);
              top: 0;
              bottom: 0;
              margin: auto 0;
            }
          }
        }
      }
    }

    .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
      margin-left: -24px;
    }
  }
  .multiselect-selected-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 90%;
  }
}


.multiselect-native-select .bootstrap-select.show-tick.multiselect-ui{
  display: none !important;
}
/* multiselect css end */



.btn-submit, .btn-reset{
  min-width: inherit !important;
  width: 46%;
  text-align: center;
  margin-bottom: 10px;

}
.btn-submit{
  margin-left:2%;
  margin-right: 1%;
}
.btn-reset{
  margin-right:2%;
  margin-left: 1%;
}
.pre-search{
  background: #f3f6fb;
  padding:em(50) 0px;
  overflow: visible;

  .s-filters {
    width: 100%;
    display: inline-block;
    text-align: initial;

    .search-by {
      color: $brand-primary;
      font-size: em(22);
      font-weight: 600;
      padding-bottom: 8px;
      display: inline-block;
      border-bottom: 2px solid $brand-primary;
      margin-right: em(50);
      width: 8%;
    }
    .filters {
      display: inline-block;
      width: 87%;
    }
    .form-group {
      display: inline-block;
      width: 37%;
      margin-right: 5.2%;
      margin-bottom: 0;
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #1d1d1d;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #1d1d1d;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #1d1d1d;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #1d1d1d;
      }
      .form-control {
        border: none;
        border-bottom: 1px solid #d2ced0;
        background: none;
        box-shadow: none;
        padding: 0 0 9px;
        font-size: 1.2em;
        color: $brand-black;
        border-radius: 0;
        text-transform: none;
      }
      .bootstrap-select {
        height: 42px;
        padding: 2px 0 0;
        .btn {
          color: #1d1d1d;
          .filter-option {
            font-weight: normal;
            color: #1d1d1d;
            font-size: em(22);
          }
          .caret {
            right: 5px;
            background: url("#{$websitePath}assets/svgs/dd-caret.svg") no-repeat 0 11px;
            width: 15px;
            height: 20px;
            background-size: 100%;
            &:before {
              display: none;
            }
          }
        }
        &.dropup {
          .btn .caret {
            @include rotate(180deg);
            margin-top: -14px;
          }
        }
      }
      .selectpicker {
        padding: 0;
        box-shadow: none;
        text-transform: none;
        li {
          a {
            font-size: em(18);
          }
        }
      }
    }
    .form-btn-sec {
      display: inline-block;
      width: 14%;
      .btn {
        min-width: 100%;
      }
    }
  }
  &.pre-search-second{
    background: none;
    margin-bottom: em(152);
    padding: 0;
    .s-filters {
      .filters{
        width: 100%;
        #preOwnedEnquiry.loading:before{
          background: $brand-white;
        }

        .form-group {
          width: 25%;
          margin-right: 3.3%;


          &.has-error {
            box-shadow: 0 0 0px 0 #a94442;
            border-bottom: 1px solid #a94442;
          }
          &.has-success {
            border-bottom: 1px solid #3c763d;
            box-shadow: 0 0 0px 0 #3c763d;
          }



          .form-control {
            border: none;
            border-bottom: 1px solid #d2ced0;
            background: none;
            box-shadow: none;
            padding: 0 0 9px;
            font-size: 1.2em;
            color: $brand-black;
            border-radius: 0;
            text-transform: none;
          }
          .bootstrap-select {
            height: 42px;
            padding: 2px 0 0;
            .btn {
              color: #1d1d1d;
              .filter-option {
                   font-weight: normal;
                color: #1d1d1d;
                font-size: em(22);
              }
              .caret {
                right: 5px;
                background: url("#{$websitePath}assets/svgs/dd-caret.svg") no-repeat 0 11px;
                width: 15px;
                height: 20px;
                background-size: 100%;
                &:before {
                  display: none;
                }
              }
            }
            &.dropup {
              .btn .caret {
                @include rotate(180deg);
                margin-top: -14px;
              }
            }
          }
          .selectpicker {
            padding: 0;
            box-shadow: none;
            text-transform: none;
            li {
              a {
                font-size: em(18);
              }
            }
          }
        }

      }
    }

  }
}
.pre-search-content{
  margin-top:em(115);
  h2{
    font-size: em(70);
    line-height: 70px;
    color: #222;
    font-weight: bold;
    font-family: $univers-condensed;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    margin-bottom: 28px;
  }
  p{
    font-size: em(20);
    text-align: center;
    color: #3f3f3f;
    margin-bottom: 111px;
  }
  .warranty_text{
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    margin-bottom: em(140);
    text-align: center;
    li{
      list-style: none;
      font-size: em(34);
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      display: inline-block;
      width: 33.1%;
      padding-top: 77px;
      font-family: $univers-condensed;
      i{
        top: 0;
        //content:"";
        height: 58px;
        width:  59px;
        position: absolute;
        left: 50%;
        margin-left: -29px;
      }
      //&.warranty-ico:before{
      //  background: url("#{$websitePath}assets/images/pre-owned/warranty.svg") no-repeat;
      //}
      //&.point-check-ico:before{
      //  background: url("#{$websitePath}assets/images/pre-owned/point-check.svg") no-repeat;
      //}
      //&.sales-service-ico:before{
      //  background: url("#{$websitePath}assets/images/pre-owned/sales-service.svg") no-repeat;
      //}
    }
  }
  .pre-img{
    padding-bottom: 10px;
    position: relative;
    margin-bottom: em(145);
    text-align: center;
    &:before{
      content: "";
      height: em(207);
      width: 100%;
      background: #f3f6fb;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    img{
      position: relative;
    }
  }
  h4{
    font-size: em(40);
    line-height: 40px;
    color: $brand-primary;
    font-weight: bold;
    font-family: $univers-condensed;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    margin-bottom: 25px;
  }
}


.form-wrapper .thanks.prethanks{
  background: $brand-white;
}
.left-pane{
  width: 20%;
  float: left;
  position: relative;
    h5{
    color:$brand-white;
    width: 100%;
    height: 60px;
    line-height:60px;
    background: $brand-primary;
    font-size: em(24);
    letter-spacing: 3px;
    text-transform: uppercase;
    padding-left: 25px;
    margin: 0;
    font-weight: bold;
    border-bottom: 1px #e3e6eb solid;

  }
}

/* left Menu start */
.auroramenu li > ul {
  display: none;
}
.auroramenu-default,
.auroramenu-default ul {
  padding: 0;
  list-style-type: none;
  border-right:1px solid #e3e6eb;
}
.auroramenu-default > li > a {
  border-bottom: 1px #e3e6eb solid;
}
.auroramenu-default > li > a:after {
  content:"";
  float: right;
  background: url("#{$websitePath}assets/images/pre-owned/arrow-down.png") no-repeat;
  width: 13px;
  height: 8px;
  margin-top: 9px;
}
.auroramenu-default > li > a.open:after {
  background: url("#{$websitePath}assets/images/pre-owned/arrow-up.png") no-repeat;
}
.auroramenu-default li a {
  color: #313131;
  background-color: #fff;
  display: block;
  font-size: em(20);
  padding: 13px 25px;
  @include transitions(0.5s);
  white-space: pre-wrap;
}
.auroramenu-default li {
    &:nth-child(2n + 2){
      a{
        background: #f9fafb;
      }
  }

  .range-slider{
    padding: em(5) 0 em(20);
    background-color: #f4f5f5;
    .slider{
      &.slider-horizontal{
        width: 100%;
      }
    }
    .rang-value{
      float: right;
      font-size: em(18);
      color: $brand-primary;
      font-weight: bold;
      margin-top: em(10);
      margin-bottom: em(10);
      &.rangmin{
        float: left;
      }
    }
    .slider-track{
      background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #c5c5c5 100%);
      background-image: -o-linear-gradient(top, #f5f5f5 0%, #c5c5c5 100%);
      background-image: linear-gradient(to bottom, #f5f5f5 0%, #c5c5c5 100%);
    }
  }

}
.auroramenu-default a:hover,
.auroramenu-default a:focus {
  text-decoration: none;
}
.auroramenu-default ul li {
  background-color: #f4f5f5;
  font-size: em(16);
  padding: 10px 30px;
  border-bottom: 1px #e3e6eb solid;
  input{
    float: left;
    height:em(20);
    width: em(18);
    margin: 0;
    margin-right: em(10);
    outline: none !important;
  }
  label{
    margin: 0;
    cursor: pointer;
  }
}
/* left Menu end */


/* right pane start */
.righ-pane{
  float: left;
  width: 74%;
  margin: 20px 3%;
  .search-view{
    margin-bottom: 50px;
    float: left;
    width: 100%;
    border: 1px solid #e3e6eb;
    background: #f3f6fb;
    font-size: em(20);
    padding: em(12) em(26);
    color: #313131;
    .value{
      font-weight: bold;
      float: left;
      line-height: 2.2em;
    }
    .type{
      float: right;
      text-align: right;
      span{
        margin-right: 15px;
      }
    }
  }

  .pro-list{
    margin-bottom: 10px;
    float: left;
    width: 100%;
    border: 1px solid #e3e6eb;
    .content-pane{
      float: left;
      padding: 9px;
      width: 75%;
      .pro-img{
        float: left;
        width: 41%;
        img{
          width: 100%;
        }
      }
      .pro-text{
        float: right;
        width: 57%;
        h3{
          font-size: em(30);
          font-weight: bold;
          color: $brand-black;
          margin: 10px 0px 5px 0px;
        }
        h4{
          font-size: em(20);
          color: #313131;
          line-height: 20px;
          position: relative;
          padding-bottom: em(15);
          margin-bottom: em(11);
          &:before{
            content: "";
            height: 2px;
            width: 30px;
            background: $brand-black;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        .pro-list-sub{
          float: left;
          width: 92%;
          padding: em(10) 0;
          border-bottom: 1px solid #e3e6eb;
          .pro-sub-head{
            float: left;
            width: 50%;
            font-size: em(20);
            color: #313131;
          }
          .pro-sub-value{
            float: left;
            width: 50%;
            font-size: em(20);
            color: #313131;
            font-weight: bold;
          }
          &:last-child{
            border-bottom: 0;
          }
        }
        
      }

    }
    .price-pane{
      background: #f3f6fb;
      float: left;
      width: 25%;
      min-height: inherit;
      .price{
        float: left;
        width: 100%;
        border-bottom: 1px solid #e4e7ed;
        padding: em(20) em(29);
        .title-text{
          line-height: 20px;
          font-size: em(20);
          color: $brand-black;
          display: block;
          margin-bottom: 5px;
        }
        h5{
          font-size: em(36);
          color: $brand-primary;
          margin: 0;
          font-weight: bold;
        }
        h6{
          font-size: em(20);
          font-weight: bold;
          color: $brand-black;
          margin: 0px;

        }
        .btn{
          padding-right: 68px;
        }
        &:last-child{
          border-bottom: 0;
          padding-bottom: em(20);
        }
      }

    }
  }


}
/* right pane end */


.social-wall-sec{
  margin-bottom:em(50);
}
.social-ft-logo{
  margin: em(60) 0 em(10) 0;
}

/* detail page start */
.backtoResults{
  margin-top: 85px;
  float: left;
  font-size: em(20);
  color: #313131;
  position: relative;
  margin-bottom: 31px;
  width: 100%;
  span{
    padding-left: 51px;
    float: left;
  }
  i{
    right: auto;
    left: 0;
    position: absolute;
    font-size: 1.75em;
    top: -2px;
    line-height: 1;
  }
    .icon-arrow-line:before {
      content: "\71";
    }

}
.detail-gallery-slider{
  margin-right: 5.2%;
  border: 1px solid #e3e6eb;
  padding: 10px;
  .slick-dots {
    position: absolute;
    bottom: 3%;
    left: 0;
    padding: 0;
    width:100%;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      margin: 0 20px 0 0;
      background: none !important;
      border: transparent 1px solid !important;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      cursor: pointer;
      -webkit-transition: all .5s ease-in-out;
      -moz-transition: all .5s ease-in-out;
      -o-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;

      button {
        display: none;
      }

      &:before {
        background: #fff;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;

      }

      &:hover {
        border: #fff solid 1px !important;

      }

      &.slick-active {
        border: #fff solid 1px !important;

      }

    }

  }
  .slick-next, .slick-prev{
    display: none !important;
  }
  .slick-slide{
    img{
      width: 100%;
    }
  }
}
.pre-search-detail-content{
  margin-bottom: em(90);
  .pro-text{
    //padding-left: 7%;
  float: right;
  width: 100%;
  h3{
    font-size: em(42);
    font-weight: bold;
    color: $brand-black;
    margin: 10px 0px 23px 0px;
  }
  h4{
    font-size: em(20);
    color: #313131;
    line-height: 20px;
    position: relative;
    padding-bottom: em(15);
    margin-bottom: em(11);
    &:before{
      content: "";
      height: 2px;
      width: 30px;
      background: $brand-black;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

   h5{
      font-size: em(47);
      font-weight: bold;
      color:$brand-primary;
      margin: 10px 0px 30px 0px;
   }
  .pro-list-sub{
    float: left;
    width: 100%;
    padding: em(10) 0;
    border-bottom: 1px solid #e3e6eb;
    .pro-sub-head{
      float: left;
      width: 50%;
      font-size: em(20);
      color: #313131;
    }
    .pro-sub-value{
      float: left;
      width: 50%;
      font-size: em(20);
      color: #313131;
      font-weight: bold;
    }
  }
  .button-pane{
      margin-top: em(30);
    float: left;
    width:100%;
    .btn{
      padding-right: em(68);
      span{
        letter-spacing: 1px;
      }
    }
  }

}
}
.map-pane{
  position: relative;
  h3.head{
   font-size: 40px;
    position: absolute;
    left: 9%;
    top:10%;
    color: $brand-black;
    font-weight: bold;
  }
  #content h3{
    margin-top: 0;
  }
}
.monthly-pay{
  background: #e9eef1;
  position: relative;
  padding: em(118) 0px em(110) 0px;
  h3{
    font-size: 40px;
    text-transform: uppercase;
    color: #313131;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 140px;
  }
  .cash-head{
    font-size: 20px;
    color: $brand-black;
    float: left;
    margin-bottom: em(45);
  }
  .cash-value{
    font-size: 20px;
    color:#313131;
    float: right;
    font-weight: bold;
  }

  .col-md-12{
    &:after{
      content: "";
      position: absolute;
      background: url("#{$websitePath}assets/images/sep-gray.png") repeat-y;
      right: 49.5%;
      width: 1px;
      bottom: 0;
      height: 100%;
    }
  }
  .range-slider{
    clear: left;
    float: left;
    width: 100%;
    margin-bottom: em(65);
    position: relative;
      &.last{
        margin-bottom: em(15);
      }
    .rang-head{
      color: #313131;
      font-weight: bold;
      font-size: em(20);
      float: left;
    }
    .rang-value{
      float: right;
      font-size: em(20);
      color: $brand-primary;
      font-weight: bold;
    }
    .slider-horizontal{
      margin-top: em(4);
      clear: left;
      float: left;
      width: 100%;
    }
  }


  //

  .pay-detail{
    float: right;
    h5{
      text-align: right;
      margin-top: 0;
      font-size: em(40);
      color: $brand-primary;
      font-weight: bold;
      font-family: $univers;
      margin-bottom: 13px;

    }
    h6{
      text-align: right;
      margin-top: 0;
      font-size: em(20);
      color: $brand-black;
      margin-bottom: 46px;
    }
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      .paid{
        list-style: none;
        border: 1px dashed #bac1c5;
        padding: 23px 25px;
        float: left;
        width: 100%;

        .paid-text{
          font-size: em(16);
          color: $brand-black;
          float: left;
        }
        .paid-value{
          font-size: em(16);
          color: $brand-primary;
          font-weight: bold;
          float: right;
        }
        //&:last-child{
        //  border-top:0px;
        //}
      }
    }

    .btn{
      margin-top: 40px;
      padding-right: em(68);
    }
  }
}
.similar-car-pane{
  padding: em(82) 0px em(130) 0px;
  text-align: center;
  h3{
    margin-bottom: 55px;
    color: #313131;
    font-size: 40px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  ul{
    list-style: none;
    padding: 0px;
    margin: 0;
    li{
      text-align: left;
      float: left;
      width: 30%;
      border: 1px solid #e3e6eb;
      padding: 0;
      margin: 0 1.6%;
    .pro-sub-img{
      padding: 9px;
      float: left;
      width: 100%;
      border-bottom: 1px solid #e3e6eb;
      margin-bottom: em(19);
      img{
        width: 100%;
      }
    }
      .pro-text-sub{
        .pro-text-sub1{
          padding: 0px 30px;
        }
        h4{
          margin: 0px;
          font-size: em(30);
          font-weight: bold;
          color: #313131;
          margin-bottom: em(8);
        }
        h5{
          font-size: em(20);
          color: #313131;
          line-height: 20px;
          position: relative;
          padding-bottom: em(15);
          margin-bottom: em(16);
          &:before{
            content: "";
            height: 2px;
            width: 30px;
            background: $brand-black;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        h6{
          font-size: em(36);
          font-weight: bold;
          color:$brand-primary;
          margin: 10px 0px 6px 0px;
          small{
            color:$brand-primary;
            font-size: 65%;
          }
        }

        ul{
          li{
            &.pro-list-sub{
              margin: 0;
              float: left;
              width: 100%;
              border: 0;
              padding: em(10) 30px;

              border-bottom: 1px solid #e3e6eb;
              .pro-sub-head{
                padding-right: em(20);
                float: left;
                width: 50%;
                font-size: em(20);
                color: #313131;
              }
              .pro-sub-value{
                float: left;
                width: 50%;
                font-size: em(20);
                color: #313131;
                font-weight: bold;
              }
              &:last-child{
                border-bottom: 0px;
                margin-bottom: 23px;
              }
            }
          }
        }

        .btn{
        margin-left: 30px;
        margin-bottom: em(30);
           padding-right: em(68);
           span{
             letter-spacing: 1px;
           }
         }


      }
    }
  }

}
/* detail page end */




/* Car Finance Calculator */
.checkbox input[type="checkbox"]:checked + label::after {
  content: '✔';
}
.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 0;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 0;
  color: #000;
  font-size: 13px
}
.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}




.radio {
  padding-left: 20px; }
.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border-radius: 50%;
  border: 1px solid #b2b2b2;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #c00;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
.radio input[type="radio"] {
  opacity: 0;
  z-index: 1; }
.radio input[type="radio"]:focus + label::before {
  outline: hidden;
  outline: none;
  outline-offset: 0;
}
.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }
.radio input[type="radio"]:disabled + label {
  opacity: 0.65; }
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }
.radio.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #337ab7; }
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #337ab7; }
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #337ab7; }

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f; }

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c; }




.line-select{
  .form-control {
    border: none;
    border-bottom: 1px solid #d2ced0;
    background: none;
    box-shadow: none;
    padding: 0 0 9px;
    font-size: 1.2em;
    color: $brand-black;
    border-radius: 0;
    text-transform: none;
  }
  .bootstrap-select {
    height: 42px;
    padding: 2px 0 0;
    &.btn-group{
      .btn {
        color: #1d1d1d;
        .filter-option {
          font-weight: normal;
          color: #1d1d1d;
          font-size: em(22);
        }
        .caret {
          right: 5px;
          background: url("#{$websitePath}assets/svgs/dd-caret.svg") no-repeat 0 11px;
          width: 15px;
          height: 20px;
          background-size: 100%;
          &:before {
            display: none;
          }
        }
      }
      &.dropup {
        .btn .caret {
          @include rotate(180deg);
          margin-top: -14px;
        }
      }
      .selectpicker {
        padding: 0;
        box-shadow: none;
        text-transform: none;
        li {
          a {
            font-size: em(18);
          }
        }
      }
    }
  }
}
.car-finance-box{
  background: #FFFFFF;
  .col-md-12{
    &:after{
      display: none;
    }
    .row{
      >[class^="col-"]{
        padding: 0 em(40);
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
  .car-pay-bx{
      h4{
        font-size: em(24);
        text-align: center;
        font-weight: bold;
        margin: 0 0 em(37);
        position: relative;
        color: $brand-black;
        &:after{
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 1px;
          width: 100%;
          background: #c6cbcd;
        }
        span{
          display: inline-block;
          background: #e9eef1;
          padding: 0 em(15);
          position: relative;
          z-index: 99;
          text-transform: uppercase;
        }

      }
      .car-pay-inr{
        background: #e9eef1;
        padding: em(50) em(30) em(40);
        text-align: center;
        ul {
          .paid{
            padding: 12px 25px;
            &:last-child{
              border-top:1px dashed #bac1c5;
            }
            .big-txt{
              float: left;
              font-size: em(40);
              color: $brand-primary;
              font-weight: bold;
              span{
                font-size: 20px;
              }
            }
            .paid-text{
              float: right;
              font-size: em(20);
              margin-top: em(18);
            }
          }
        }
      }
  }
  .select-boxes{
    margin: 0 0 em(35);
    width: 100%;
    display: inline-block;
    [class^="col-"]{
      padding: 0 em(20);
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }
}
.payment-checkbox {
  margin: 0 0 0 0;
  clear: both;
  padding: em(10) 0 0 0;
  h4{
    font-weight: bold;
  }
  .checkbox{
    display: inline-block;
    margin: em(10) em(15) 0 0;

    label{
      span{
        top: em(3);
        position: relative;
      }
    }
  }
  .radio{
    display: inline-block;
    margin: em(10) em(15) 0 0;
    input{
      cursor: pointer;
    }
    label{
      span{
        top: em(-1);
        position: relative;
      }
    }

  }

  .amount-bx{
    float: right;
    color: #b2b2b2;
    border: 1px solid #b2b2b2;
    padding: em(5) em(15);
    font-size: em(20);
    min-width: em(132);
    text-align: center;
    &.active-txt{
      border: 1px solid #c00;
      color: #c00;
      font-weight: bold;
    }
  }
}


.term-txt-bx.pre-text{
  border: 0;
  &:after{
    display: none;
  }
}


.term-txt-bx{
  border-top: 1px solid #c7ccd4;
  margin:em(70) 0 0;
  padding-top: em(55);
  h4{
    font-weight: bold;
    margin-bottom: em(5);
  }
}
.finance-list{
  list-style: none;
  padding: em(5) 0 0 0;
  li{
    position: relative;
    padding: 0 0 0 em(30);
    margin: em(12) 0;
    color: #949494;
    &:before{
      content: '';
      background: $brand-black;
      position: absolute;
      left: 0;
      width: em(10);
      height: 1.5px;
      top: em(8);
    }
  }
}

/* Car Finance Calculator */




@media (min-width: 1530px) and (max-width: 1600px) {


  .righ-pane {
    .pro-list {
      .content-pane {
        .pro-img{
          width: 42%;
        }
        .pro-text{
          width: 55%;
        }
      }
      //.price-pane{
      //  min-height: 262px;
      //}
    }
  }



  .pre-search {
    .s-filters {
      .search-by{
        margin-right: 2.5em;
      }
      .form-btn-sec{
        width: 16%;
      }
      .form-group {
        width: 36%;
        margin-right: 4.2%;
      }
    }
    &.pre-search-second .s-filters .filters .form-group{
      width: 24%;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {


  .backtoResults{
    margin-bottom: 21px;
    margin-top: 65px;
    span{
      padding-left: 37px;
    }
  }

  .monthly-pay {
    h3{
      margin-bottom: 100px;
    }
    .cash-head{
      margin-bottom: em(30);
    }
    .pay-detail{
      h6{
        margin-bottom: 36px;
      }
      ul {
        .paid{
          padding: 20px 25px;
        }
      }
    }

  }
  .pre-search-detail-content {
    .pro-text {
      h3{
        margin: 10px 0px 8px 0px;
      }
      h5{
        margin: 0px 0px 10px 0px;
      }
    }
  }
  .righ-pane {
    .search-view{
      margin-bottom: 30px;
    }
    //.pro-list .price-pane{
    //  min-height: 219px;
    //}
  }

  .pre-search-content{
     .pre-img img {
      width: 90%;
    }
    p{
      margin-bottom: 80px;
    }

    h2{
      line-height: 50px;
    }

    h4{
      line-height: 30px;
    }
  }
}

@media (min-width: 1200px) {
  .auroramenu-default li a:hover, .auroramenu-default li a.open {
    background-color: $brand-primary;
    color: $brand-white;
    padding-left: 30px;
    padding-right: 30px;

  }
  /* multi selectbox  start */

    span.multiselect-native-select .multiselect-container.dropdown-menu li a {
      &:hover,
      &:focus {
        background-color:$brand-primary;
        color: $brand-white;
        label{
          &:before{
            color: $brand-white;
          }
        }
      }
    }





  .auroramenu-default li {

    .dropdown-menu > .active > a {
      &,
      &:hover,
      &:focus {
        background-color: $brand-white;
        color: #313133;
      }
    }
  }
  /* multi selectbox  End */


}


@media (max-width: 1199px) and (min-width: 992px){
  .line-select .bootstrap-select.btn-group .btn .filter-option{
    font-size: em(18);
  }
}


@media (max-width: 1024px) {


  .pre-search-detail-content{
    .pro-text {
      h3{
        margin-bottom: 13px;
      }
      h5{
        margin: 20px 0px 20px 0px;
      }
      .button-pane {
        .btn{
          margin-bottom: em(8);
        }
      }
    }
  }

  body .righ-pane{
    width: 78%;
    margin: 20px 1%;
    .search-view{
      margin-bottom: 25px;
    }
    .pro-list {
      .content-pane{
        width: 78%;
        .pro-img{
          width: 45%;
        }
        .pro-text{
          width: 52%;
          h3{
          margin-top: 6px;}
        }
        }
      .price-pane{
        width: 22%;
        min-height: 200px;
        .price h5{
          font-size: em(32);
        }
      }
      }

    }


  .pre-search {
    .s-filters {
      .search-by{
        margin-right: 2.5em;
      }
      .form-btn-sec{
        width: 16%;
      }
      .form-group {
        width: 37.5%;
        margin-right: 4%;
      }
    }
    &.pre-search-second .s-filters .filters .form-group{
      width: 24%;
    }
  }
  .pre-search-content{
    .pre-img img {
      width: 90%;
    }
    p{
      margin-bottom: 80px;
    }

    h2{
      line-height: 50px;
    }

    h4{
      line-height: 30px;
    }
  }

  .left-pane h5{
    padding-left: em(10);
  }

}


@media (min-width: 768px) and (max-width: 991px){

  .detail-gallery-slider{
    margin-right: 0;
  }
  .backtoResults{
    margin-top: 45px;
    margin-left: 7px;
    span{
      padding-left: 31px;
    }
  }
  .pre-search-detail-content{
    .col-md-7.col-sm-12{
      margin-bottom: 20px;
    }
  }
  .similar-car-pane ul{
    li{
      margin: 0 0.6%;
      width: 32%;
      .pro-text-sub {
        .pro-text-sub1{
          padding: 0px 10px;
        }
        ul li.pro-list-sub{
          padding: .625em 10px;
        }
        .btn{
          margin-left: 10px;
        }

      }
    }
  }
  .monthly-pay {
    padding: 5.375em 0px 3em 0px;
    h3{
      margin-bottom: 70px;
      font-size: em(40);
    }
    .col-md-12{
      &:after{
        display: none;
      }
      .col-md-5.col-sm-12{
        margin-bottom: 50px;
      }
    }
    .pay-detail{
      h6{
        margin-bottom: 36px;
      }
    }
  }
   body {
    .righ-pane {
      .pro-list {
        .price-pane {
          min-height: 152px;
          .price {
            padding: 1em 1em;
            h5{
              font-size: 1.5em;
            }
            .btn{
              padding-right: em(20);
              width: 100%;
            }
            .title-text{
              margin-bottom: 0;
            }
          }
        }
        .content-pane {
          .pro-text {
            h3{
              margin-top: 0;
            }
            h4{
              margin-top: 0;
            }
            .pro-list-sub{
              padding: em(4);
            }
          }
        }
      }
    }
  }
  .left-pane h5{
    padding: 10px;
    height: auto;
    line-height:inherit;
    letter-spacing: 0;
  }
  .auroramenu-default li {
    a{
      padding: 10px;
    }
    ul li{
      padding: 10px 15px;
    }
  }
  .pre-search {
    .s-filters {
      .search-by{
        width: 11%;
      }
      .filters{
        width: 83%;
        .form-group{
          width: 34.5%;
        }
      }
      .form-btn-sec{
        width: 22%;
      }
    }
    &.pre-search-second .s-filters .filters .form-group{
      width: 22%;
    }
  }
  .pre-search-content {
    .warranty_text li{
      width: 33%;
    }
  }



  .car-finance-box {
    .col-md-12 {
      .row{
        >[class^="col-"]{
          padding: 0 em(15);
          &:first-child{
            padding-left: em(15);
          }
          &:last-child{
            padding-right: em(15);
          }
        }
      }
    }


    .car-pay-bx {
      .car-pay-inr{
        margin: em(30) 0 0 0;
      }
    }
  }


}

@media (max-width: 767px) {
  /* PreOwned Mobile Menu Start */
  .hamburger{
    background:none;
    position:absolute;
    top:0;
    left:0;
    line-height:45px;
    padding: 8px 15px 0px 15px;
    color:#fff;
    border:0;
    font-size:1.4em;
    font-weight:bold;
    cursor:pointer;
    outline:none;
    z-index:9;
    display: block;
  }
  .cross{
    background:none;
    position:absolute;
    top:0px;
    left:0;
    padding: 6px 15px 0px 17px;
    color:$brand-white;
    border:0;
    font-size:3em;
    line-height:65px;
    font-weight:bold;
    cursor:pointer;
    outline:none;
    z-index:9;
  }
  .left-pane h5{
    padding-left: em(36);
  }
  .glyphicon-home{
    color:white;
    font-size:1.5em;
    margin-top:5px;
    margin:0 auto;
  }
  /* PreOwned Mobile Menu End */
  .backtoResults{
    margin-left: 7px;
    margin-top: 45px;
  }
  .pre-search-detail-content{
    .col-md-7.col-sm-12, .col-md-5.col-sm-12{
      width: 100%;
      float: left;

    }
    .pro-text .button-pane .btn{
      padding: .6875em 1.5625em;
    }
  }
  .monthly-pay{
    padding: 5em 0px;

    &.car-finance-box{
      margin-top: 2em;
      h3{
        margin-bottom: 1em;
      }
    }

    .pay-detail .btn{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }


    .col-md-12:after{
      display: none;
    }
    .cash-head{
      margin-bottom: 1.8em;
    }
    .range-slider{
      margin-bottom: 2em;
    }
    h3{
      font-size: 2.5em;
      margin-bottom: 2em;
      margin-top: 0;
    }
    .pay-detail{
      width: 100%;
      margin-top: 50px;
    }
  }
  body .similar-car-pane {
    ul {
      li{
        width: 32%;
        margin: 0 0.6%;
        .pro-text-sub {
          .pro-text-sub1{
            padding: 0px 10px;
          }
          ul li.pro-list-sub{
            padding: .625em 10px;
            .pro-sub-head, .pro-sub-value{
              font-size: em(16);
            }
          }
          .btn{
            margin-left: 10px;
            padding: .6875em 1.5625em;
          }
          li.pro-list-sub:last-child{
            margin-bottom: 13px;
          }
        }

      }
    }
  }
  .detail-gallery-slider{
    margin-right: 0;
  }
  .similar-car-pane{
    padding: 5em 0px;
    h3{
      font-size: 2.5em;
      margin-bottom: 1.5em;
      margin-top: 0;
    }
  }
  body{
    .left-pane{
      width: 100%;
    }
    .righ-pane{
      width: 94%;
      margin: 10px 3%;
      .search-view{
        margin-bottom: 15px;
      }
      .pro-list {
        .price-pane {
          .price {
            h5 {
              font-size: 1.5em;
            }
            .btn {
              padding-right: 0;
              padding-left: 0;
            }
            &:last-child{
              padding-bottom: 10px;
            }
          }

        }
      }
    }


  }
  .pre-search .s-filters {
    .search-by{
      width: auto;
      margin-bottom: 15px;
    }
    .filters{
      width: 100%;
      .form-group{
        width: 46%;
        margin-right: 3.5%;
        margin-bottom: 15px;
      }
    }
  }
  .pre-search-content {
    margin-top: 5em;
    h2{
      font-size:em(48);
      line-height: 40px;
    }
    p{
      margin-bottom: 50px;
    }
    .warranty_text {
      li{
        width: 32%;
        font-size: 1.5em;
      }
    }
    .pre-img{
      margin-bottom: 5em;
    }
  }
  .pre-search.pre-search-second {
    margin-bottom: 5em;
    .s-filters .filters .form-group {
      width: 29%;
      margin-bottom: 15px;
    }
  }
  span.multiselect-native-select .multiselect.dropdown-toggle.btn-block{
    background-color: #e6e6e6;
    padding: 10px 15px;
    font-size: 14px;
  }


  .car-finance-box {
    .col-md-12 {
      .row{
        >[class^="col-"]{
          padding: 0 em(15);
          &:first-child{
            padding-left: em(15);
          }
          &:last-child{
            padding-right: em(15);
          }
        }
      }
    }
    .car-pay-bx {
      .car-pay-inr{
        margin: em(30) 0 0 0;
      }
    }
    .select-boxes {
      [class^="col-"]{
        padding: 0 0;
      }
    }
  }

}


@media (max-width: 480px) {

  .righ-pane .search-view .type{
    float: left;
  }

  .multiselect-selected-text{
    width: 87%;
    float: left;
    overflow: hidden;
  }

  body .similar-car-pane ul li {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
  body{
    .righ-pane {
      .pro-list {
        .content-pane{
          width: 100%;
          .pro-img{
            width: 100%;
          }
          .pro-text{
            margin-top: 10px;
            width: 100%;
            .pro-list-sub{
              width: 100%;
            }
          }
        }
        .price-pane{
          width: 100%;
          padding: 9px;
          min-height: inherit;
          .price{
            padding: .625em 0;
            &:last-child{
              padding-bottom: 0;
            }
            .title-text{
              float: left;
              width: 50%;
            }
            .btn{
              width: 100%;
            }
          }
        }
      }
    }
  }
  .pre-search {
    .s-filters {
      .filters {
        .form-group{
          width: 100%;
          margin-right: 0;
        }
      }
    }
    &.pre-search-second .s-filters .filters .form-group {
      width: 100%;
    }
  }
  .pre-search-content {
    .warranty_text {
      li{
        width: 100%;
        margin-bottom: 2em;
      }
    }
  }

  span.multiselect-native-select .multiselect-container.dropdown-menu{
    width: 272px;
  }


  .car-finance-box {
    .car-pay-bx {
      .car-pay-inr {
        ul {
          .paid {
            .big-txt{
              width: 100%;
              text-align: center;
            }
            .paid-text{
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }
  }
}


