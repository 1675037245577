.news-listings-section, .media-detail{
  margin-top: em(96);
}
.main-head{
  font-size: em(28);
  color: #313131;
  font-weight: bold;
  position: relative;
  padding-bottom: 14px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 40px;
  float: left;
  &:after{
    content: "";
    height: 2px;
    width: 20px;
    background: #313131;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.sort-by{
  float: right;
  .bootstrap-select.btn-group {
    .btn  {
      .filter-option{
        color: $brand-black;
        font-size: em(18);
        text-transform: none;
      }
      .caret{
        top: 0.7em;
        right: 0em;
      }
    }
  }

}
.news-listing{
  list-style: none;
  padding: 0;
  margin: 0;
  li{
    float: left;
    width: 32%;
    padding: 0;
    border: 1px solid #e9ebed;
    margin-right: 2%;
    margin-bottom: 30px;
    div.text-pane{
      padding: 0px em(28);
    }
    h5{
      font-size: em(18);
      margin: em(29) 0px em(18);
    }
    h6{
      font-size: em(22);
      font-weight: bold;
      margin: 0px 0px em(28);
      a{
        color: $brand-black;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }
    }
    .types{
      float: left;
      width: 100%;
      border-top: 1px solid #e9ebed;
      padding-left:em(30);
      height: em(58);
      img{
        margin-top: em(17);
        margin-bottom:em(17);
        float: left;
        margin-right: em(20);
        &.doc{
          width: em(19);
        }
        &.image{
           width: em(28);
         }
        &.video{
          width: em(33);
        }
      }
      a.more{
        text-transform: lowercase;
        background: #f9f9f9;
        float: right;
        width: em(137);
        border-left: 1px solid #e9ebed;
        @include transition(0.5s);
        height:em(57);
        color: $brand-primary;
        padding: em(18) em(24);
        font-size: em(16);
        min-width: auto;
        i{
          top: 0.55em;
        }
      }
    }
    &:nth-child(3n){
      float: right;
      margin-right: 0;
    }

    .newThumb {
      display: block;
      overflow: hidden;
      position: relative;
      width: 100%;
      float: left;
      margin-bottom: em(36);
      &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        @include transitions(0.5s);
        background: rgba($brand-primary, 0.4);
        opacity: 0;
  }
      img {
        width: 100%;
        @include scale(1);
        @include transitions(1s);
}
      i {
        position: absolute;
        left: 0;
        top: 0;
        bottom: em(-30);
        margin: auto;
        right: 0;
        color: $brand-white;
        @include transitions(0.5s);
        z-index: 99;
        font-size: em(50);
        width: em(16);
        height: em(16);
        opacity: 0;
      }
      .play-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: em(56);
        height: em(56);
        background: $brand-primary;
        margin: auto;
        border-radius: 50%;
        text-align: center;
        line-height: 65px;
        color: #fff;
        font-size: 20px;
        z-index: 2;
        @include transitions(0.5s);
        @include scale(1);
        i {
          opacity: 1;
          bottom: 0;
          font-size: 1.1em;
          width: .8em;
          height: 2.5em;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 6px solid rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          @include scale(1.2);
        }
      }
    }

  }
}




.media-pagenation{
  margin:em(32) 0px em(118) 0px;
  display: inline-block;
  width: 100%;
}


.no-banner{
  margin-top: em(130);
}






/* media-detail start */

.media-detail{
  .date{
    font-size: em(20);
    color: $brand-primary;
    font-weight: bold;
    margin-bottom: em(26);
  }
  .img{
    width: 100%;
    margin-bottom: em(50);
  }
  h6{
    font-size: em(20);
    margin-top: 0;
    margin-bottom: em(15);
  }
  p{
    margin-bottom: em(37);
  }
  .slider-pane{
    width: 100%;
    padding: 0px 10%;
    position: relative;
    .prevarrow{
      background: #ededed;
      height: 40px;
      width: 122px;
      position: absolute;
      left: -13%;
      top:50%;
      margin-top: -20px;
      border-radius:20px;
      font-size: em(13);
      text-transform: uppercase;
      color: $brand-black;
      padding: 12px 15px;
      cursor: pointer;
      @include transitions(0.5s);
      .icon-left{
        float: left;
        margin-right: 17px;
        margin-top: 2px;
        color: #be0000;
        @include transitions(0.5s);
        &:before {
          content: "\71";
        }
      }
    }
    .nextarrow{
      padding: 12px 15px;
      background: #ededed;
      height: 40px;
      width: 122px;
      position: absolute;
      right: -13%;
      top:50%;
      margin-top: -20px;
      border-radius:20px;
      font-size: em(13);
      text-transform: uppercase;
      cursor: pointer;
      color: $brand-black;
      .icon-right{
        float: right;
        margin-left: 17px;
        margin-top: 2px;
        color: #be0000;
        &:before{
          content: "\63";
        }

      }
    }
    .slick-slide{
      overflow: hidden;
      border-right: 1px solid rgba(49,49,49,0.66);
      >img{
        width: auto;
      }
    }
  }
  .download-image-btn{
  position: relative;
  margin:em(100) 0 em(130) 0;
  text-align: center;


  &:before{
    content: '';
    width: 100%;
    height: 1px;
    background: #e7e7e7;
    position: absolute;
    top: em(20);
    z-index: 0;
    left: 0;
    right: 0;
  }
  &:after{
    content: '';
    width: 20.875em;
    background: #fff;
    left: 0;
    right: 0;
    height: 40px;
    z-index: 1;
    position: absolute;
    margin: auto;
  }
  .btn{
    width:em(340);
    z-index: 2;
  }

}
  .news-rel-items{
    padding-top:em(125);

    .title{
      float: left;
      width:100%;
    .main-head{
     float:left;
      margin-bottom:em(60);
    }
      .btn{
        float:right;
        width:em(270);
      }
    }
    .single-item{
      position: relative;
      margin-bottom:em(98);
      min-height: 140px;
      .inner{
        padding-left:em(70);
      }
      img{
        position: absolute;
        left: 0;
        top: 0;
        max-width: em(65);;
      }
      p{
        font-weight:bold;
        font-size:em(22);
        margin-bottom: em(25);
      }
      a{
        position: relative;
        padding-left:em(57);
        span{
          float: left;
          font-weight: bold;
        }
      }
      .inner{
        a{
          img{
            position: relative;
            float: left;
            margin-left: em(20);
          }
        }
      }
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -46px;
    left: 0;
    padding: 0;
    width:100%;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      margin: 0 20px 0 0;
      background: none !important;
      border: transparent 1px solid !important;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      cursor: pointer;
      -webkit-transition: all .5s ease-in-out;
      -moz-transition: all .5s ease-in-out;
      -o-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;

      button {
        display: none;
      }

      &:before {
        background: #b30000;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;

      }

      &:hover {
        border: #b30000 solid 1px !important;

      }

      &.slick-active {
        border: #b30000 solid 1px !important;

      }

    }

  }
}






/* media-detail end */


@media (min-width: 1200px) {

  .news-listing li .types a.more{
    &:hover{
      color: $brand-white;
    }
  }

  .media-detail {
    .news-rel-items {
      .single-item {
        p{
          padding-right: 20px;
        }
      }
    }
    .slider-pane {
      .slick-arrow{
        &:hover{
          background: #be0000;
          color: $brand-white;
          @include transitions(0.5s);
          .icon-left, .icon-right{
            color: $brand-white;
            @include transitions(0.5s);
          }
        }
      }
    }
  }

  .news-listing{
    li{
      .newThumb {
        &:hover {
          img {

}
          &:after {
            opacity: 1;
          }
          i {
            bottom: 0;
            opacity: 1;
          }
          .play-icon {
            background: $brand-white;

            i{
              color: $brand-primary;
            }
          }
        }

      }
    }
  }

}








@media (min-width: 1200px) and (max-width:1366px){
  .media-detail{
    .slider-pane{
      .prevarrow{
        width: 92px;
        height: 30px;
        padding: 10px 15px;
        text-align: right;
      }
      .nextarrow{
        width: 92px;
        padding: 10px 15px;
        height: 30px;
      }
      .slick-slide{
        >img{
          width: auto;
          max-height: 300px;
        }
      }
    }
  }

  .media-detail {
    .news-rel-items {
      .single-item {
        img{

        }
        .inner{
          padding-left:em(85);
        }
      }
    }
  }

  .sort-by {
    .bootstrap-select{
      &.btn-group {
        .btn {
          .caret{
            top: 1.3em;
          }
        }
      }
    }
  }

}





@media (min-width: 991px) and (max-width:1199px){
  .media-detail{
    .news-rel-items{
      .single-item{
        .inner{
          padding-left:em(87);
          a{
            img{
              width: 5%;
            }
          }
        }
      }
    }
    .slider-pane{
      .prevarrow{
        width: 92px;
      }
      .nextarrow{
        width: 92px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width:990px){
  .media-detail{

    .news-rel-items{
      padding-top:0;
      .single-item{
        min-height:1px;
        .inner{
          padding-left:em(97);
          a{
            img{
              width: 4%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .news-listings-section{
    margin-top: em(50);
    .list{
      clear: both;
    }
    .news-listing{
      width: 100%;
      float: left;
      li{
        margin-right: 0;
        width: 100%;
      }
    }
  }

  .no-banner{
    margin-top: em(130);
  }

  .media-detail{
    margin-top: em(50);
    .slider-pane{
  padding:0;
}
    .news-rel-items{
      padding-top:em(70);
      .title{
        .btn{
          float:left;
          margin-bottom:em(50);
          clear: both;
        }
        .main-head{
          margin-bottom:em(30);
        }
      }
      .single-item{
        margin-bottom:em(30);
        .inner{
          padding-left:em(97);
          a{
            img{
              width: 2%;
            }
          }
        }
        p{
          margin-bottom:em(15);
        }
      }
    }
    .download-image-btn{
      margin:em(60) 0 em(40) 0;
      &:before{
        display: none;

      }
    }
  }
  .sort-by{
    .bootstrap-select{
      &.btn-group {
        .btn {
          .caret{
            top: 1.4em;
          }
        }
      }
    }

    .dropdown-menu{
      left: auto;
      right: 0;
    }
  }

}


@media (max-width: 480px){

  .media-detail {
    .news-rel-items {
      .single-item {
        .inner {
          a {
            img{
              width: 5%;
            }
          }
        }
      }
    }
  }

}
