



.innerBanner {
  display: block;
  overflow: hidden;
  img {
    height: 100%;
  }
  .container-fluid {
    position: absolute;
    top: 15em;
    left: 5.5em;
    .txt {
      h2 {
        font-size: em(76);
        font-weight: 700;
        color: $brand-white;
        font-family: "univers condensed";
        text-transform: uppercase;
        margin: 0 0 0.23em;
        line-height: 1em;
        text-shadow: 2px 2px 3px #7b7b7b;
      }
      p {
        font-size: em(34);
        color: $brand-white;
        margin-bottom: 1em;
        line-height: 1.35em;
        text-shadow: 2px 2px 3px #7b7b7b;
      }
      a.btn {
        width: em(267);
        text-align: center;
        padding-right: em(60);
      }
    }
  }

}

.about-timeline {
  display: block;
  padding: em(120) 0 em(119) 0;
  background: #f9f9f9;
  .headings {
    text-align: center;
    h2 {
      line-height: .8125em;
      margin: 0 0 .625em 0;
      font-weight: 700;
      font-family: "univers condensed";
      text-transform: uppercase;
      color: #222222;
      font-size: em(60);
    }
    p {
      font-size: em(22);
      margin-bottom: em(42);
    }
    a.btn {
      //width: em(257);
      margin-bottom: em(80);
      padding-right: em(80);
    }
  }

  .timeLinecrousel {
    width: 100%;
    margin: 0 auto;
    div {
      &:focus {
        outline: none;
      }
    }
    .box {
      background: $brand-white;
      padding: em(36) em(20) em(30);
      text-align: center;
      border: 1px solid #e9e9e9;
      margin: 0 15px;

      .icon {
        display: inline-block;
        margin: 0 auto em(16);
        height: em(99);
        width: em(96);
        img {
          max-height: 100%
        }
      }
      .text {
        display: flex;
        color: #898989;
        text-align: left;
        margin: 0 auto;
        width: 72%;
        &.with-percent {
          width: 90%;
          sup {
            position: absolute;
            font-size: em(8);
            top: 32px;
            right: 2px;
          }
          .number {
            padding-right: 38px;
          }
        }
        .number {
          font-size: 6.125em;
          font-family: "univers condensed";
          line-height: 90px;
          font-weight: 300;
          position: relative;
          margin: 0 12px 0 0;

        }
        .desc {
          color: #3f3f3f;
          font-weight: 300;
          padding-top: 8px;
          p {
            margin-bottom: 15px;
            font-size: 1em;
          }
        }
      }

      .media {
        color: #898989;
        text-align: left;
        margin: 0;
        padding-left: em(38);
        .media-left {
          font-size: em(98);
          font-family: "univers condensed";
          line-height: 90px;
          font-weight: 300;
          position: relative;
          sup {
            position: absolute;
            font-size: em(8);
            top: 32px;
            right: 14px;
          }
          &.with-percent {
            padding-right: 50px;
          }
        }
        .media-body {
          color: #3f3f3f;
          font-weight: 300;
          padding-top: 8px;
          p {
            margin-bottom: 15px;
            font-size: em(16);
          }
        }
      }
    }
    .slick-prev, .slick-next {
      position: absolute;
      border-radius: 20px;
      background: #e9e9e9;
      padding: 10px 22px;
      box-shadow: none;
      border: none;
      font-size: em(13);
      color: #000;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      top: 45%;

    }
    .slick-prev {
      left: -15%;

      padding-left: 70px;
      &:before {
        content: "\71";
        font-family: "honda" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 25px;
        top: 0;
        width: 25px;
        height: 10px;
        font-size: 25px;
        line-height: 36px;
        color: $brand-primary;

      }
    }
    .slick-next {
      right: -15%;
      padding-right: 70px;
      &:before {
        content: "\63";
        font-family: "honda" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        right: 25px;
        top: 0;
        width: 25px;
        height: 10px;
        font-size: 25px;
        line-height: 36px;
        color: $brand-primary;
      }
    }
  }
}

.powerofdream {
  display: block;
  background: $brand-white;
  padding: em(90) 0;
  h2 {
    color: $brand-primary;
    font-weight: 700;
    font-size: em(40);
    text-transform: uppercase;
    margin: 0 0 em(12);
  }
  p {
    font-size: em(20);
    margin-bottom: em(15);
  }
  .video-image {
    position: relative;
    a {
      display: block;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        @include transition(0.7s);
        @include scale(1);
      }
      .play-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 60px;
        height: 60px;
        background: $brand-primary;
        margin: auto;
        border-radius: 50%;
        text-align: center;
        line-height: 65px;
        color: $brand-white;
        font-size: 20px;
        z-index: 2;
        @include transition(0.3s);
        @include scale(1);
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border: 6px solid rgba($brand-white, 0.5);
          border-radius: 50%;
          @include scale(1.2);
        }
      }
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 1;
        @include transition(0.7s);
        background: rgba(204, 0, 0, 0.4);
        @include opacity(0);
      }
    }
  }
}

.container.about-container {
  width: 1430px;
}

.col-center {
  margin: 0 auto;
  float: none;
}

.message-f-p {
  display: block;
  padding: em(96) 0;
  background: #464f5d;
  color: #ebebeb;
  h2 {
    font-size: em(40);
    letter-spacing: em(8);
    color: $brand-white;
    text-transform: uppercase;
    margin: 0 0 em(40);
  }
  h3 {
    color: #ebebeb;
    margin: 0 0 em(24);
    font-size: em(32);
    font-family: "univers condensed";
    font-weight: 700;
    line-height: 1.4em;
    text-transform: uppercase;
  }
  p {
    font-size: em(13);
    margin-bottom: em(24);
  }
  .signature {
    display: block;
    margin: em(42) 0 em(22);

  }
}

.philosophy {
  display: block;
  padding: em(120) 0;
  h2 {
    font-size: em(40);
    letter-spacing: em(8);
    color: #313131;
    text-transform: uppercase;
    margin: 0 0 em(15);
  }
  p.lead {
    font-size: em(20);
    font-weight: 400;
    margin: 0 0 em(60);
  }
  h3 {
    text-transform: uppercase;
    font-size: em(28);
    font-weight: 700;
    margin: 0 0 em(18);
    &.withbar {
      position: relative;
      padding: 0 0 em(8);
      margin: 0 0 em(34);
      &:before {
        content: '';
        position: absolute;
        width: em(24);
        height: em(2.6);
        background: $brand-primary;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .respect {
    margin-bottom: em(142);
    .icon {
      width: em(112);
      height: em(112);
      border-radius: 50%;
      background: $brand-primary;
      text-align: center;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      img {
        margin: 14px auto;
      }
    }
    .media-left {
      padding-right: em(27);
    }
    .media-heading {
      font-size: em(22);
      margin-bottom: em(10);
      text-transform: uppercase;
      color: #313131;
      font-weight: 700;
    }
    p {
      font-size: em(14);
      line-height: em(25);
    }
  }
  .m-policies {
    .arrow-listing {
      padding-right: em(60);
    }
    .principle-box {
      width: 100%;
      padding: em(46) em(90) em(46) em(40);
      background: #292525;
      margin: 0 0 em(118);
      .media {
        color: $brand-white;
        .media-left {
          padding-right: em(20);
        }
        .media-heading {
          text-transform: uppercase;
          font-size: em(24);
          font-weight: 700;
          position: relative;
          padding: 0 0 em(8);
          margin: 0 0 em(20);
          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 40px;
            height: 4px;
            background: $brand-white;
          }
        }
        p {
          font-size: em(28);
          font-family: "univers condensed";
          font-weight: 300;
          position: relative;
          padding-left: em(26);
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 9px;
            width: 26px;
            height: 20px;
            background: url("#{$websitePath}assets/images/coma.png") no-repeat 0 0;
          }
        }
      }
    }
  }
  .three-joy {

    [class^="col-"] {
      padding-left: 8px;
      padding-right: 8px;
    }
    .box-image {
      padding: 10px;
      height: 100%;
      border: 1px solid #ebebeb;
      .image {
        margin: 0 0 em(47);
      }
      .txt {
        h4 {
          text-transform: uppercase;
          font-weight: 700;
          font-size: em(22);
          margin: 0 0 em(10);
        }
        p {
          font-size: em(14);

        }
      }
    }
  }
  .fp-tableCell {
    padding: em(110) 0 em(50);
  }
}

ul.arrow-listing {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 em(15) em(26);
    position: relative;
    font-size: em(20);
    &:before {
      content: "\63";
      font-family: "honda" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 20px;
      color: $brand-primary;
    }
  }
}

.firefox {
  .message-f-p h3 {
    margin: 0 0 1.2em;
  }
}


@media (min-width: 1601px) and (max-width: 2400px) {
  .about-timeline {
    .col-md-10 {
      width: 93%;
    }
    .timeLinecrousel {
      .slick-prev {
        left: -12%;
      }
      .slick-next {
        right: -12%;
      }
      .box {
        .text.with-percent {
          width: 78%;
        }
      }
    }
  }
}

@media (min-width: 1530px) and (max-width: 1600px) {
  .about-timeline {
    .timeLinecrousel {
      .box {
        .text {
          .number {
            font-size: em(80);
            line-height: 80px;
          }
          .desc {
            p {
              font-size: em(15);
            }
          }
        }
      }
      .slick-prev {
        left: -11.8%;
        padding-left: 60px;
      }
      .slick-next {
        right: -11.8%;
        padding-right: 60px;
      }
    }
  }
  .homepage.normal-pages {
    .container {
      width: 100em;
    }
    &.motorcycle-header{
      .header {
        .container {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1330px) and (max-width: 1366px) {
  .about-timeline {
    .timeLinecrousel {
      .box {
        .text {
          .number {
            font-size: em(80);
            line-height: 58px;
          }
          .desc {
            p {
              font-size: em(20);
            }
          }
          &.with-percent {
            .number {
              padding-right: 26px;
              sup {
                right: 2px;
                top: 30%;
              }
            }
          }
        }
      }
      .slick-prev {
        left: -12%;
        padding-left: 52px;
        &:before {
          left: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .slick-next {
        right: -12%;
        padding-right: 52px;
        &:before {
          right: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }
  .powerofdream {
  }
  .message-f-p {
    p {
      font-size: em(17);
    }
  }
  .philosophy {
    .respect {
      p {
        font-size: em(16);
      }
      .icon {
        img {
          width: 60%;
        }
      }
    }
    .m-policies {
      .principle-box {
        .media {
          p {
            &:before {
              top: 4px;
              left: -2px;
            }
          }
        }
      }
    }
    .three-joy .box-image .txt p {
      font-size: em(17);
    }
  }

  ul.arrow-listing {
    li {
      padding-left: 2.3em;
      &:before {
        top: -4px;
      }
    }
  }


}

@media (min-width: 1201px) and (max-width: 1280px) {
  .about-timeline {
    .timeLinecrousel {
      .box {
        .icon {
          display: inline-block;
          margin: 0 auto em(14);
          height: em(89);
          width: em(86);
        }
        .text {
          width: 76%;
          .number {
            font-size: em(80);
            line-height: 58px;
          }
          .desc {
            p {
              font-size: em(18);
            }
          }
          &.with-percent {
            .number {
              padding-right: 26px;
              sup {
                right: 2px;
                top: 30%;
              }
            }
          }
        }
      }
      .slick-prev {
        left: -11.2%;
        padding-left: 52px;
        &:before {
          left: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .slick-next {
        right: -11.2%;
        padding-right: 52px;
        &:before {
          right: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

  }
  .message-f-p {
    p {
      font-size: em(16);
    }
  }
  .philosophy {
    .respect {
      p {
        font-size: em(17);
      }
      .icon {
        img {
          width: 60%;
        }
      }
    }
    .m-policies {
      .principle-box {
        .media {
          p {
            &:before {
              top: 4px;
              left: -2px;
            }
          }
        }
      }
    }
    .three-joy .box-image {
      height: 370px;
    }
  }
  ul.arrow-listing {
    li {
      &:before {
        top: -1px;
      }
    }
  }

}

@media (min-width: 1280px) and (max-width: 1366px) {
  .homepage.normal-pages {
    .container {
      width: 93%;
    }
  }

}


@media (min-width: 1201px) and (max-width: 1280px) {
  .homepage.normal-pages {
    .container {
      width: 100%;
    }
  }

}


/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .powerofdream {
    .video-image {
      a {
        &:hover {
          img {
            @include scale(1.1);
          }
          &:before {
            @include opacity(1);
          }
          .play-icon {
            background: $brand-white;
            i {
              color: $brand-primary;
            }
          }
        }
      }
    }
  }
  .philosophy {
    .three-joy {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
    }
  }
  .about-timeline {
    .timeLinecrousel {
      .slick-prev, .slick-next {
        @include transition(0.5s);
        &:before{
          @include transition(0.5s);
        }
        &:after{
          box-shadow: 0 0 0 2px #ccc;
          position: absolute;
          left: 0;
          top: -9%;
          content: '';
          width: 100%;
          height:118%;
          @include borderRadius(3.125em);
          @include scale(1.3);
          @include transition(0.5s);
          @include opacity(0);
        }
        &:hover{
          background: $brand-primary;
          color: $brand-white;
          &:before {
            color: $brand-white;
          }
          &:after{
            @include opacity(1);
            @include scale(1.1);
          }
        }
      }
    }
  }
  .gecko, .ie {
    .philosophy .respect .icon img {
      height: 60px;
      margin: auto;
    }
  }
  .ie {
    .about-timeline .timeLinecrousel .box .text {
      .number {
        display: inline-block;
      }
      .desc{
        display: inline-block;
      }
    }
    .philosophy {
      .respect {
        .icon {
          position: relative;
          img {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
          }
        }
      }
    }
  }
  .about-timeline, .powerofdream, .message-f-p, .philosophy {
    padding: 0 !important;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

  /* obaid css start */
  .section.powerofdream, .section.message-f-p, .section.philosophy {
    padding: 4.375em 0 3.375em;
  }
  ul.arrow-listing li{
    padding: 0 0 .9375em 2.2em;
  }
  .section.philosophy{
    .fp-tableCell{
      padding: 0px;
      .respect{
        margin-bottom: em(48);
        p{
          font-size: 1em;
        }
      }
      p.lead{
        margin-bottom: em(48);
      }
      .m-policies .principle-box{
        margin: 0 0 4em;
      }
    }
  }
  /* obaid css start */




  .innerBanner {
    .container-fluid {
      .txt {
        h2 {
          font-size: em(66);
        }
        p {
          font-size: em(28);
        }
      }
    }
  }
  .about-timeline {
    padding: em(70) 0;
    .headings {
      p {
        br {
          display: none;
        }
      }
    }

    .timeLinecrousel {
      .box {
        padding: em(36) em(10) em(30);
        .icon {
          display: inline-block;
          margin: 0 auto em(16);
          height: em(69);
          width: em(66);
        }
        .text {
          width: 85%;
          .number {
            font-size: em(80);
            line-height: 58px;
          }

          &.with-percent {
            width: 95%;
            .number {
              padding-right: 26px;
              sup {
                right: 2px;
                top: 30%;
              }
            }
          }
        }
      }
      .slick-prev {
        left: -15%;
        padding-left: 52px;
        &:before {
          left: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .slick-next {
        right: -15%;
        padding-right: 52px;
        &:before {
          right: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }
  .philosophy {
    padding: em(70) 0;
    p.lead {
      br {
        display: none;
      }
    }
    .respect {
      .icon {
        img {
          width: 60%;
        }
      }
    }
    .m-policies {
      .principle-box {
        .media {
          p {
            &:before {
              top: 4px;
              left: -2px;
            }
          }
        }
      }
    }
    .three-joy {
      .box-image {
        height: 340px;
      }
    }
  }
  ul.arrow-listing {
    li {
      &:before {
        top: -3px;
      }
    }
  }
  .powerofdream {
    h2 {
      font-size: em(34);
    }
  }
  .message-f-p {
    padding: em(70) 0;
    h2 {
      margin-bottom: em(20);
    }
    p {
      font-size: em(16);
    }
    .president-img {
      margin-bottom: em(25);
    }
  }
  .homepage.fp-responsive {
    .innerBanner {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding-top: 5em;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .about-timeline {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
      }
    }
    .powerofdream  {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
      }
    }
    .message-f-p {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
      }
    }
    .philosophy {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
  }
  #fullpageInner {
    .fp-section, .fp-tableCell {
      height: initial !important;
      &.innerBanner {
        padding-top: 26px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .philosophy .respect p{
      font-size: 12px;
  }
  .philosophy .three-joy .box-image .txt p{
    font-size: 12px;
  }

  .innerBanner {
    .container-fluid {
      top: 10em;
      left: 3em;
      .txt {
        h2 {
          font-size: em(50);
        }
        p {
          font-size: em(24);
        }
        a.btn {
          i {
            top: .3125em;
          }
        }
      }
    }
  }
  .section.philosophy .fp-tableCell{
    padding: 0px;
  }
  .about-timeline {
    padding: em(60) 0;
    .headings {
      h2 {
        font-size: em(50);
      }
      p {
        br {
          display: none;
        }
      }
      a.btn {
        i {
          top: .3em;
        }
      }
    }

    .timeLinecrousel {
      .box {
        padding: em(36) em(10) em(30);
        .icon {
          display: inline-block;
          margin: 0 auto em(16);
          height: em(69);
          width: em(66);
        }
        .text {
          width: 85%;
          .number {
            font-size: em(80);
            line-height: 58px;
          }
          .desc {
            p {
              font-size: em(15);
            }
          }
          &.with-percent {
            width: 95%;
            .number {
              padding-right: 26px;
              sup {
                right: 2px;
                top: 30%;
              }
            }
          }
        }
      }
      .slick-prev {
        left: -15%;
        padding-left: 52px;
        &:before {
          left: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .slick-next {
        right: -15%;
        padding-right: 52px;
        &:before {
          right: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .slick-dots {
        position: absolute;
        bottom: em(-50);
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        li {
          position: relative;
          display: inline-block;
          height: 20px;
          width: 5px;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;
          button {
            background: transparent;
            border: 1px solid $brand-primary;
            display: block;
            height: 7px;
            width: 7px;
            outline: none;
            margin: 0 auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -o-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 0;
            cursor: pointer;
          }
          &.slick-active button {
            background: $brand-primary;
            border-color: $brand-primary;
          }
        }
      }
    }
  }
  .powerofdream {
    padding: em(46) 0;
    h2 {
      font-size: em(34);
    }
  }
  .message-f-p {
    padding: em(46) 0;
    h2 {
      margin-bottom: em(15);
    }
    p {
      font-size: em(16);
    }
    .president-img {
      margin-bottom: em(25);
    }
  }
  .philosophy {
    padding: em(46) 0;
    .respect{
      margin-bottom: em(40);
    }
    h3.withbar {
      margin-bottom: em(15);
    }
    p.lead {
      margin-bottom: em(40);
      br {
        display: none;
      }
    }
    .respect {
      .media {
        margin-bottom: em(30);
        .media-body {
          padding-top: 10px;
        }
      }
      .icon {
        img {
          width: 60%;
        }
      }
    }
    .m-policies {
      .principle-box {
        margin-bottom: em(40);
        .media {
          p {
            &:before {
              top: 4px;
              left: -2px;
            }
          }
        }
      }
    }
    .three-joy {
      .box-image {
        height: 370px;
      }
    }

  }



  ul.arrow-listing {
    margin-bottom: em(20);
    li {
      padding-left: em(36);
      &:before {
        top: -4px;
        font-size: 18px;
      }
    }
  }
  .homepage.fp-responsive {
    .innerBanner {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding-top: 5em;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .about-timeline {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
      }
    }
    .powerofdream  {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
      }
    }
    .message-f-p {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
      }
    }
    .philosophy {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
  }
  #fullpageInner {
    .fp-section, .fp-tableCell {
      height: initial !important;
      &.innerBanner {
        padding-top: 26px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .t-drive-cont {
    .bar {
      display: flex;
      align-items: center;
      h4{
        font-size: em(18);
        font-weight: 800;
        padding-top: em(3);
      }
    }
  }

  .t-drive-cont {
    .inner-t-drive{
      padding-top: em(75);
    }
  }
  .t-drive-cont {
    .inner-t-drive {
      .car-heading{
        margin-bottom: 0;
      }
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {


  .btn-primary{
    text-align: center;
  }
  .philosophy {
    .fp-tableCell{
      padding: 0px;
    }
    .respect{
      margin-bottom: em(32);
    }
  }


  .innerBanner {
    top: 4.0625em;
    margin-bottom: 4.0625em;
    position: relative;
    .container-fluid {
      top: 7.5em;
      left: 1em;
      .txt {
        h2 {
          font-size: em(36);
        }
        p {
          font-size: em(18);
          display: none;
        }
        a.btn {
          i {
            top: .3em;
          }
        }
      }
    }
  }
  .about-timeline {
    padding: em(60) 0;
    .headings {
      h2 {
        font-size: em(50);
      }
      p {
        br {
          display: none;
        }
      }
      a.btn {
        i {
          top: .2em;
        }
      }
    }

    .timeLinecrousel {
      .box {
        padding: em(36) em(10) em(30);
        height: 180px;
        .icon {
          display: inline-block;
          margin: 0 auto em(16);
          height: em(69);
          width: em(66);
        }
        .text {
          width: 85%;
          .number {
            font-size: em(65);
            line-height: 62px;
          }
          .desc {
            p {
              font-size: em(14);
              br {
                display: none;
              }
            }
          }
          &.with-percent {
            width: 95%;
            .number {
              padding-right: 26px;
              sup {
                right: 2px;
                top: 30%;
              }
            }
          }
        }
      }
      .slick-prev {
        left: -15%;
        padding-left: 52px;
        &:before {
          left: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .slick-next {
        right: -15%;
        padding-right: 52px;
        &:before {
          right: 17px;
          top: 0;
          width: 25px;
          height: 10px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .slick-dots {
        position: absolute;
        bottom: em(-50);
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        li {
          position: relative;
          display: inline-block;
          height: 20px;
          width: 5px;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;
          button {
            background: transparent;
            border: 1px solid $brand-primary;
            display: block;
            height: 7px;
            width: 7px;
            outline: none;
            margin: 0 auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -o-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 0;
            cursor: pointer;
          }
          &.slick-active button {
            background: $brand-primary;
            border-color: $brand-primary;
          }
        }
      }
    }
  }
  .powerofdream {
    padding: em(30) 0;
    h2 {
      font-size: em(34);
    }
  }
  .message-f-p {
    padding: em(30) 0;
    h2 {
      margin-bottom: em(15);
      font-size: em(32);
      letter-spacing: 8px;
    }
    p {
      font-size: em(16);
    }
    .president-img {
      margin-bottom: em(25);
    }
  }
  .philosophy {
    padding: em(50) 0;
    h2 {
      margin-bottom: em(15);
      font-size: em(32);
      letter-spacing: 8px;
    }
    h3.withbar {
      margin-bottom: em(15);
    }
    p.lead {
      margin-bottom: em(40);
      br {
        display: none;
      }
    }
    .respect {
      p {
        font-size: em(16);
      }
      .media {
        margin-bottom: em(30);
        .media-body {
          padding-top: 10px;
        }
      }
      .icon {
        img {
          width: 60%;
        }
      }
    }
    .m-policies {
      .principle-box {
        margin-bottom: em(40);
        .media {
          p {
            &:before {
              top: 4px;
              left: -2px;
            }
          }
          .media-left {
            display: table-cell;
            vertical-align: top;
            padding-right: 1.25em;
            float: left;
          }
          .media-body {
            display: table-cell;
            vertical-align: top;
            width: 80%;
            float: left;
          }
        }
      }
    }
    .three-joy {
      .box-image {
        margin-bottom: 10px;
        .image {
          img {
            width: 100%;
          }
        }
      }
    }

  }
  ul.arrow-listing {
    margin-bottom: em(20);
    li {
      padding-left: em(36);
      &:before {
        top: -1px;
        font-size: 18px;
      }
    }
  }
  .homepage.fp-responsive {
    .innerBanner {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        .fp-scrollable {
          height: auto !important;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .about-timeline {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
    .powerofdream  {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
    .message-f-p {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
    .philosophy {
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        padding: 4.375em 0;
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
  }
  #fullpageInner {
    .innerBanner  {
      img {
        height: auto;
        max-width: 100%;
      }
    }
    .fp-section,
    .fp-slide,
    .fp-tableCell{
      height: auto !important;
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {


  .about-timeline .timeLinecrousel{
    margin-bottom: 50px;
  }
  #fullpageInner .fp-section{
    padding-bottom: 0px;
  }
  //body .enq-form-sec{
  //  padding-top: 0px;
  //}

  .innerBanner {
    .container-fluid {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 80px;
      .txt {
        text-align: center;
        h2 {

        }
      }
    }
  }
  .about-timeline {
    .timeLinecrousel {
      .box {
        height: 200px;
        .text {
          .number {
            font-size: em(80);
            line-height: 75px;
          }
          .desc {
            p {
              font-size: em(18);
            }
          }
        }
      }
    }
  }
  .message-f-p {
    h2 {
      letter-spacing: 3px;
    }
    h3 {
      font-size: em(26);
    }
  }
  .philosophy {
    h2 {
      letter-spacing: 3px;
    }
    .m-policies {
      .principle-box {
        padding: 2.875em 2.5em 2.875em;
        .media {
          .media-left {
            width: 100%;
            display: inline-block;
            text-align: center;
            padding: 0;
            margin-bottom: 20px;
            img {
              margin: 0 auto;
            }
          }
          .media-heading {
            padding-bottom: 18px;
            &:before {
              left: 0;
              right: 0;
              margin: auto;
            }
          }
          .media-body {
            display: inline-block;
            width: 100%;
            text-align: center;
            p {
              padding: 0;
              &:before {
                top: -22px;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}