

.productShowCaseSection {
  background: #070707;
  width: 100%;
  height: 100%;
  z-index: 9999;

  /* dev version start */

//.bx-controls {
//    position: absolute;
//    right: em(40);
//  top: em(50);
//  }
//  .bx-next{
//    background-color: $brand-primary;
//    color: $brand-white;
//    border: none;
//    @include borderRadius(em(50));
//    width: em(151);
//    height: em(46);
//    padding: em(14) em(28);
//    text-transform: uppercase;
//    text-align: left;
//    font-size: em(13);
//    font-weight: 700;
//    position: relative;
//    float: right;
//    &:after {
//      z-index: 9;
//      position: absolute;
//      right: em(15);
//      font-size: em(26);
//      top: em(8);
//      line-height: 1;
//      content: "\63";
//      font-family: "honda" !important;
//      font-style: normal !important;
//      font-weight: normal !important;
//      font-variant: normal !important;
//      text-transform: none !important;
//      speak: none;
//      line-height: 1;
//      -webkit-font-smoothing: antialiased;
//    }
//    .bx-next {
//      width: 100%;
//      height: 100%;
//      display: block;
//      position: absolute;
//      top: 0;
//      left: 0;
//      color: transparent;
//      z-index: 99;
//    }
//    &:before {
//      box-shadow: 0 0 0 2px #ccc;
//      position: absolute;
//      left: em(-8);
//      top: em(-8);
//      content: '';
//      width: em(165);
//      height: em(63);
//      @include borderRadius(em(50));
//      @include scale(1.1);
//      @include transitions(0.5s);
//      opacity: 0;
//    }
//    &:hover {
//      &:before {
//        @include scale(1);
//        opacity: 1;
//      }
//    }
//  }
//  .bx-prev {
//    background-color: $brand-primary;
//    color: $brand-white;
//    @include borderRadius(em(50));
//    width: em(151);
//    height: em(45);
//    padding: em(14) em(28);
//    margin-right: 15px;
//    text-align: right;
//    text-transform: uppercase;
//    font-size: em(13);
//    font-weight: 700;
//    border: none;
//    position: relative;
//    float: left;
//    &:after {
//      content: "\63";
//      z-index: 9;
//      position: absolute;
//      left: em(15);
//      right: initial;
//      font-size: em(26);
//      top: em(5);
//      line-height: 1;
//      transform: rotate(180deg);
//      font-family: "honda" !important;
//      font-style: normal !important;
//      font-weight: normal !important;
//      font-variant: normal !important;
//      text-transform: none !important;
//      speak: none;
//      line-height: 1;
//      -webkit-font-smoothing: antialiased;
//    }
//    .bx-prev {
//      width: 100%;
//      height: 100%;
//      display: block;
//      position: absolute;
//      top: 0;
//      left: 0;
//      color: transparent;
//      z-index: 9;
//    }
//    &:before {
//      box-shadow: 0 0 0 2px #ccc;
//      position: absolute;
//      left: em(-8);
//      top: em(-8);
//      content: '';
//      width: em(165);
//      height: em(63);
//      @include borderRadius(em(50));
//      @include scale(1.1);
//      @include transitions(0.5s);
//      opacity: 0;
//    }
//    &:hover {
//      &:before {
//        @include scale(1);
//        opacity: 1;
//      }
//    }
//  }


  /* dev version end */


  /* HTMl version start */
  .bx-controls {
    position: absolute;
    right: em(40);
    top: em(50);
  }
  .nextButton {
    background-color: $brand-primary;
    color: $brand-white;
    border: none;
    @include borderRadius(em(50));

    width: em(151);
    height: em(50);
    padding: em(16) em(30);
    text-transform: uppercase;
    text-align: left;
    font-size: em(13);
    font-weight: 700;
    i {
      z-index: 9;
      position: absolute;
      right: em(15);
      font-size: em(26);
      top: em(8);
      line-height: 1;
    }
    .bx-next {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: transparent;
      z-index: 99;
    }
    &:before {
      box-shadow: 0 0 0 2px #ccc;
      position: absolute;
      left: em(-8);
      top: em(-8);
      content: '';
      width: em(165);
      height: em(67);
      @include borderRadius(em(50));
      @include scale(1.1);
      @include transitions(0.5s);
      opacity: 0;
    }
    &:hover {
      &:before {
        @include scale(1);
        opacity: 1;
      }
    }
  }
  .prevButton {
    visibility: hidden;
    background-color: $brand-primary;
    color: $brand-white;
    @include borderRadius(em(50));

    width: em(151);
    height: em(50);
    padding: em(16) em(30);
    text-align: right;
    text-transform: uppercase;
    font-size: em(13);
    font-weight: 700;
    border: none;
    position: absolute;
    i {
      z-index: 9;

      position: absolute;
      left: em(15);
      right: initial;
      font-size: em(26);
      top: em(5);
      line-height: 1;
      transform: rotate(180deg);
    }
    .bx-prev {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: transparent;
      z-index: 9;
    }
    &:before {
      box-shadow: 0 0 0 2px #ccc;
      position: absolute;
      left: em(-8);
      top: em(-8);
      content: '';
      width: em(165);
      height: em(67);
      @include borderRadius(em(50));
      @include scale(1.1);
      @include transitions(0.5s);
      opacity: 0;
    }
    &:hover {
      &:before {
        @include scale(1);
        opacity: 1;
      }
    }
  }

  /* HTMl version end */


  .filter-tabs {
    display: block;
    padding: 0;
    margin: 0;
    li {
      cursor: pointer;
      font-size: em(16);
      color: #5e5e5e;
      display: inline-block;
      text-transform: uppercase;
      padding: em(6) em(18);
      @include borderRadius(em(30));
      border: transparent 2px solid;
      font-weight: 700;
      @include transitions(0.5s);
      &.mixitup-control-active, &:hover {
        border: #cecece 2px solid;
        color: $brand-primary;
        background: #f7f7f7;
      }
    }
  }
  .showCase {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 0;
    & .content {
      width: 100%;
      height: 100%;
      @include transitions(0.5s);
      position: relative;
      .row {
        height: 100%;
        margin: 0;
      }
      .details {
        width: 50%;
        height: 100%;
        background: $brand-white;
        position: absolute;
        top: 0;
        z-index: 9;
        transform: translateX(200%);
        transition: all ease-in-out 0.3s;
        .header {
          height: 15%;
          padding: em(38) em(40) em(10) em(40);
          position: static;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          border-bottom: none;
          h2 {
            font-size: em(28);
            text-transform: uppercase;
            color: $brand-black;
            font-weight: 700;
            margin: 0 0 em(8) 0;
            span {
              color: #5e5e5e;
            }
          }
          [class^="col-"] {
            padding: 0;
          }
        }
      }
      .description {
        background-repeat: no-repeat;
        padding-left: em(50);
        .title {
          position: absolute;
          top: em(310);
          h3 {
            font-size: em(32);
            color: $brand-white;
            text-transform: uppercase;
            line-height: em(15);
            font-weight: 700;
            margin: 0;
            backface-visibility: hidden;
          }
          hr {
            display: inline-block;
            width: em(20);
            height: em(2);
            border: none;
            background: $brand-white;
            margin: em(12) 0;
          }
        }
        .title2 {
          @extend .title;
          top: inherit;
          bottom: em(40);
          opacity: 0;
          visibility: hidden;
          .btn-primary {
            min-width: em(200);
          }
        }
        .logo {
          position: absolute;
          top: em(70);
          margin: 0;
          img {
            width: 100%;
          }
        }
        .right-heading {
          position: absolute;
          right: 0;
          top: 0;
          width: em(145);
          height: 100%;
          background-position: right bottom;
          background-size: cover;
          @include transitions(1s);
          &.motorcycle {
            background-image: url("#{$websitePath}assets/images/showcase/cycle.png");
          }
          &.power {
            background-image: url("#{$websitePath}assets/images/showcase/equipments-text1.png");
          }
          &.marines-text {
            background-image: url("#{$websitePath}assets/images/showcase/marines-text.png");
          }
        }
      }
    }
    &.first {
      @include transitions(0.3s);
      height: 100%;
      width: 100%;
      .description {
        height: 50%;
        width: 30%;
        z-index: 9;
      }
      .thumb {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 50%;
        height: 50%;
        transition: all ease-in-out 0.3s;
        z-index: 8;
        background-image: url("#{$websitePath}assets/images/showcase/new-1.png");
        //background-image: url("/en-sa/assets/images/showcase/new-1.png");
        position: absolute;
        top: 0;
        &:before {
          background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.39) 20%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.02) 80%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.39) 20%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.02) 80%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.39) 20%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.02) 80%, transparent 100%);

          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: '';
          z-index: 1;
        }
      }
      .title {
        color: $brand-white !important;
        border-color: $brand-white !important;
      }
      .logo {
        width: em(146);
        height: em(92);
      }
    }
    &.second {
      overflow: hidden;
      .thumb {
        background-image: url('#{$websitePath}assets/images/showcase/2.jpg');
      }
      .description {
        background: $brand-white;
        position: relative;
        z-index:2;
        .title {
          h3 {
            color: $brand-black;
          }
          hr {
            background: $brand-black;
          }
        }
        &:before {
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10.5px 0 10.5px 12px;
          border-color: transparent transparent transparent $brand-white;
          content: '';
          margin: auto;
          right: -12px;
          z-index: 9;
          top: 0;
          bottom: 0;
        }
      }
      .logo {
        width: em(106);
        height: em(85);
      }
    }
    &.third {
      overflow: hidden;
      .thumb {
        background-image: url('#{$websitePath}assets/images/showcase/3.jpg');
      }
      .description {
        background-image: url("#{$websitePath}assets/images/showcase/marine.png");
        background-size: 100% 100%;
        background-position: right bottom;
        @include transitions(1s);
        .title {
          color: $brand-white !important;
          border-color: $brand-white !important;
        }
        .btn-primary {
          border: $brand-white 1px solid;
          position: relative;
          &:before {
            background: $brand-white;
          }
        }
        &:before {
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10.5px 12px 10.5px 0;

          border-color: transparent $brand-primary transparent transparent;
          content: '';
          margin: auto;
          left: -12px;
          z-index: 9;
          top: 0;
          bottom: 0;
        }
      }
      .logo {
        width: em(223);
        height: em(47);
      }
    }
    &.fourth {
      overflow: hidden;
      .thumb {
        background-image: url('#{$websitePath}assets/images/4.jpg');
      }
      .description {
        background-image: url("#{$websitePath}assets/images/showcase/equipments.png");
        background-size: 100% 100%;
        background-position: right bottom;
        @include transitions(1s);
        .title {
          color: $brand-white !important;
          border-color: $brand-white !important;
        }

        &:before {
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10.5px 12px 10.5px 0;
          //border-color: transparent $brand-primary transparent transparent;
          border-color: transparent #211d18 transparent transparent;
          content: '';
          margin: auto;
          left: -12px;
          z-index: 9;
          top: 0;
          bottom: 0;
        }
      }
      .logo {
        width: em(186);
        height: em(52);
      }
    }
    &:nth-child(1) {
      transform: translateX(0);
    }
    &:nth-child(2) {
      transform: translateX(100%);
    }
    &:nth-child(3) {
      transform: translateY(100%);
    }
    &:nth-child(4) {
      transform: translateX(100%) translateY(100%);
    }
    &.selected {
      width: 100%;
      height: 100%;
      overflow: visible;
      .title2 {
        @include transitions(0.3s);
        transition-delay: 0.4s;
        opacity: 1 !important;
        visibility: visible !important;
      }
      .expand {
        background: url("#{$websitePath}assets/images/showcase/close.png") center no-repeat;
        .pulse-ring {
          width: em(65);
          height: em(65);
          border: 1px dotted $brand-white;
          border-radius: em(100);
          opacity: 1;
        }
      }
      .thumb {
        width: 50%;
        height: 100%;
      }
      .content {
        background-position: center;
        .description {
          height: 100%;
        }
      }
      .ProductGallery {
        opacity: 1;
      }
    }
    .thumb {
      width: 50%;
      height: 100%;
      background-size: cover;
      background-position: center;
      display: grid;
      display: -ms-grid;

    }
    .description {
      height: 100%;
      transition: all ease-in-out 0.3s;
    }
  }
  .close {
    position: absolute;
    top: em(30);
    right: em(30);
    z-index: 9999999999999;
    display: none;
  }
  .expand {
    position: absolute;
    z-index: 99999;
    bottom: em(20);
    cursor: pointer;
    right: em(20);
    background: url("#{$websitePath}assets/images/showcase/expand.png") center no-repeat;
    .pulse {
      cursor: pointer;
    }
    .pulse-ring {
      width: em(65);
      height: em(65);
      border: 1px dotted $brand-white;
      border-radius: em(100);
      opacity: 0;
      &.active {
        -webkit-animation: pulsate 3 1.2s;
        animation: pulsate 3 1.2s;
      }
    }
  }
  .ProductGallery {
    border-top: $brand-gary 1px solid;
    border-bottom: $brand-gary 1px solid;
    height: calc(85%);
    opacity: 0;
    padding: 0;
    .product {
      margin: 0;
      display: block;
      height: 33.333333%;
      background-image: url('#{$websitePath}assets/images/gallery/product.jpg');
      background-repeat: no-repeat;
      background-position: 0px bottom;
      overflow: hidden;
      .car {
        @include transitions(0.5s);
        position: absolute;
        right: em(40);
        bottom: em(50);
        width: em(316);
        height: em(104);
      }
      .hover-state {
        position: absolute;
        right: -100%;
        width: em(316);
        height: em(104);
        bottom: em(50);
        @include transitions(0.5s);
      }
      .textLink {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        text-align: center;
        width: 100%;
        @include transitions(0.5s);
        opacity: 0;
        a {
          color: $brand-white;
          display: inline-block;
          position: relative;
          font-size: em(13);
          font-weight: 700;
          text-transform: uppercase;
          i {
            position: relative;
            margin-left: em(5);
            font-size: em(28);
            top: em(5);
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      .logo {
        @include transitions(0.5s);
        padding: em(32) em(15) em(26);
        height: inherit;
        display: block;
        width: em(280);
        .civic-prod-logo {
          width: em(139);
          height: auto;
          max-height: em(23);
        }
        .coup-prod-logo {
          width: em(192);
          height: em(16);
        }
        .sedan-prod-logo {
          width: em(192);
          height: em(16);
        }
        .city-prod-logo {
          width: em(106);
          height: em(16);
        }
        .crv-prod-logo {
          width: em(107);
          height: em(16);
        }
        .odyssey-prod-logo {
          width: em(216);
          height: em(13);
        }
        .odyssey-j-logo {
          width: em(159);
          height: em(30);
        }
        .odyssey-logo {
          width: em(159);
          height: em(16);
        }
        .pilot-logo {
          width: em(69);
          height: em(15);
        }
        p {
          font-weight: 300;
          font-size: em(13);
          text-transform: uppercase;
          margin: em(5) 0 0 0;
        }
        .logo-hover {
          display: none;
        }
        .civic-prod-logo-hover {
          display: none;
          width: em(139);
          height: auto;
          max-height: em(23);
        }
        .coup-prod-logo-hover {
          display: none;
          width: em(192);
          height: em(16);
        }
        .sedan-prod-logo-hover {
          display: none;
          width: em(192);
          height: em(16);
        }
        .city-prod-logo-hover {
          display: none;
          width: em(106);
          height: em(16);
        }
        .crv-prod-logo-hover {
          display: none;
          width: em(107);
          height: em(16);
        }
        .odyssey-prod-logo-hover {
          display: none;
          width: em(263);
          height: em(13);
        }
        .odyssey-j-logo-hover {
          width: em(159);
          height: em(30);
          display: none;
        }
        .odyssey-logo-hover {
          width: em(159);
          height: em(16);
          display: none;
        }
        .pilot-logo-hover {
          width: em(69);
          height: em(15);
          display: none;
        }
      }
      &:nth-child(odd) {
        border-right: $brand-gary 1px solid;
        border-bottom: $brand-gary 1px solid;
      }
      &:nth-child(even) {
        border-bottom: $brand-gary 1px solid;
      }
      &:nth-last-child(2), &:nth-last-child(1) {
        /*border-bottom: none;*/
      }
      &:hover {
        background-color: $brand-primary;
        background-image: url('#{$websitePath}assets/images/gallery/civic.jpg');
        &.civic-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/civic.jpg');
        }
        &.coupe-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/accord-bg.png');
        }
        &.sedan-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/accord-bg.png');
        }
        &.city-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/city-bg.png');
        }
        &.crv-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/crv-bg.png');
        }
        &.odyssey-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/odyssey-bg.png');
        }
        &.odyssey-j-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/odyssey-j-bg.png');
        }
        &.pilot-prod {
          background-color: $brand-primary;
          background-image: url('#{$websitePath}assets/images/gallery/pilot-bg.png');
        }
        .logo {
          color: $brand-white;
          transform: translateX(42%);
          .civic-prod-logo, .coup-prod-logo, .sedan-prod-logo, .city-prod-logo, .crv-prod-logo, .odyssey-prod-logo, .odyssey-j-logo, .pilot-logo, .odyssey-logo {
            display: none;
          }
          .civic-prod-logo-hover, .coup-prod-logo-hover, .sedan-prod-logo-hover, .city-prod-logo-hover, .crv-prod-logo-hover, .odyssey-prod-logo-hover, .odyssey-j-logo-hover, .pilot-logo-hover, .odyssey-logo-hover {
            display: block;
          }
        }
        .car {
          right: 100%;
        }
        .hover-state {
          right: em(81);
        }
        .textLink {
          bottom: 3%;
          opacity: 1;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  &.cars-only {
    .showCase {
      &.first {
        .thumb {
          height: 100%;
        }
      }
      &.second {
        display: none;
      }
      &.third {
        display: none;
      }
      &.fourth {
        display: none;
      }
      .content {
        .details {
          display: block;
          transform: translateX(100%);
          z-index: 9999999;

        }
      }
      .expand {
        display: none;}
    }

  }
  &.cars-bikes {
    .showCase {
      &.first {
        .thumb {
          height: 100%;
        }
      }
      &.second {
        height: 100%;
        .description {
          width: 0;
          margin: 0;
          padding: 0;
          &:before {
            display: none;
          }
          .logo {
            margin-left: 3.125em;
          }
          .title {
            margin-left: 3.125em;
            h3 {
              color: $brand-white;
              text-shadow: 2px 2px 3px #7b7b7b;
            }
            hr{
              background: $brand-white;
            }
          }
        }
        .thumb {
          height: 100%;
          width: 100%;
        }
      }
      &.third {
        display: none;
      }
      &.fourth {
        display: none;
      }
    }
  }
  &.cars-bikes-marines {
    .showCase {
      &.first {
        .thumb {
          height: 100%;
        }
      }
      &.third {
        display: none;
      }
    }
  }
  &.bikes-products-marines {
    .showCase {
      &.first {
        display: none;
      }
      &.second {
        transform: translateX(0);
        height: 100%;
        .description {
          width: 0;
          margin: 0;
          padding: 0;
          opacity: 1;
          background: none;
          &:before {
            display: none;
          }
          .logo {
            margin-left: 3.125em;
          }
          .title {
            margin-left: 3.125em;
            h3 {
              color: $brand-white;
              text-shadow: 2px 2px 3px #7b7b7b;
            }
          }
          .right-heading {
            display: none;
          }
        }
        .thumb {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
        }
      }
      &.third {
        transform: translateY(0);
        right: 0;
      }
    }
  }
  &.products-marines {
    .showCase {
      &.first {
        display: none;
      }
      &.second {
        display: none;
      }
      &.third {
        height: 100%;
        transform: translateY(0);
        .description {
          margin: 0;
          padding: 0;
          float: left;
          width: 0;
          position: absolute;
          opacity: 1;
          &:before {
            display: none;
          }
          .logo {
            margin-left: 3.125em;
          }
          .title {
            margin-left: 3.125em;
            h3 {
              color: $brand-white;
              text-shadow: 2px 2px 3px #7b7b7b;
            }
          }
        }
        .thumb {
          height: 100%;
          width: 100%;
        }
      }
      &.fourth {
        height: 100%;
        transform: translateY(0);
        transform: translateX(100%);
        .description {
          margin: 0;
          padding: 0;
          float: left;
          width: 0;
          position: absolute;
          opacity: 1;
          &:before {
            display: none;
          }
          .logo {
            margin-left: 3.125em;
          }
          .title {
            margin-left: 3.125em;
            h3 {
              color: $brand-white;
              text-shadow: 2px 2px 3px #7b7b7b;
            }
          }
          .btn-primary {
            border: none;
          }
        }
        .thumb {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}



.bx-wrapper {
  height: 100%;
  .bx-viewport {
    height: 100% !important;
    ul {
      height: 100%;
      padding: 0;
      li {
        height: 100%;
      }
    }
  }
}

.rest-mixer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
}

.productSlider {
  width: 2010%;
}







@media (min-width: 1367px) and (max-width: 1600px) {
  .productShowCaseSection {
    .showCase {
      .content {
        .description {
          .title {
            top: em(240);
          }
          .title2 {
            top: inherit;
            bottom: em(50);
          }
        }
        .details {
          .header {
            font-size: em(14);
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .productShowCaseSection {
    .showCase {
      .content {
        .description {
          .title {
            top: em(240);
          }
          .title2 {
            top: inherit;
            bottom: em(50);
          }
        }
        .details {
          .header {
            font-size: em(15);
          }
        }
      }
    }
  }
}


@media (min-width: 1201px) and (max-width: 1280px) {

  .productShowCaseSection .showCase.selected .expand .pulse-ring{
    width: em(75);
    height: em(75);
  }
}


@media (min-width: 1200px) {
  .expand {
    .pulse-ring {
      &:hover {
        -webkit-animation: pulsate 2 1.2s;
        animation: pulsate 2 1.2s;
      }
    }
  }
  .productShowCaseSection {
    .showCase {

      &.second, &.third, &.fourth {
        .description {
          &:hover {
            .right-heading {
              background-position: right top;
            }
          }
        }
      }
      &.third {
        .description {
          .btn-primary {
            &:hover {
              color: $brand-primary;
            }
          }
        }
      }
    }
    &.products-marines {
      .showCase {
        &.fourth {
          .description {
            .btn-primary {
              &:before{
                background: red;
              }
              &:hover {
                color: $brand-white;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:1024px){
  body .threeImages .item .item-heading{
    padding: 2em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {


  .car {
    .desktop{
      display: none;
    }
    .ipad{
      display: block;
    }
  }
  .showCase {
    position: relative !important;
    transform: none !important;
    float: left;
    width: 50%;
    height: 50%;
    .thumb {
      height: 100%;
      width: 50%;
      float: left;
    }
  }
  .productShowCaseSection {
    .showCase {
      .content {
        .description {
          padding: 0 em(30);
          width: 50%;
          height: 100%;
          float: left;
          .title {
            top: initial;
            bottom: em(50);
            h3 {
              position: initial;
              text-shadow: none;
              font-size: em(30);
              margin-bottom: em(10);
            }
            hr {
              display: none;
            }
            .btn {
              font-size: em(16);
            }
          }
          .logo {
            top: em(30);
          }
        }
      }
      &.first {
        .description {
          width: 100%;
          height: 100%;
        }
        .thumb {
          width: 100%;
          height: 100%;
        }
        width: 50%;
        height: 50%;
      }
      &.second {
        .description {
          float: right;
          background-position: right -40px;
          background-size: 30% auto;
          &:before {
            right: initial;
            left: em(-15);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10.5px 12px 10.5px 0;
            border-color: transparent $brand-white transparent transparent;
          }
        }
        .thumb {
          float: left;
        }
        width: 50%;
        height: 50%;

      }
      &.third {
        .description {
          float: left;
          &:before {
            //right: em(-18);
            left: -1.1em;
          }
        }
        //.thumb {
        //  float: right;
        //}
        width: 50%;
        height: 50%;
      }
      &.fourth {
        .description {
        }
        width: 50%;
        height: 50%;
      }
    }
    .expand {
      display: none;
    }
  }
  .productShowCaseSection {
    .showCase.fourth {
      .description:before{
        left: -1.1em;
      }
    }
    &.cars-bikes-marines .showCase.first {
      height: 100%;
    }
    &.cars-bikes {
      .showCase {
        &.first {
          height: 100%;

        }
        &.second {
          .description {
            float: left;
          }
        }
      }
    }
    &.cars-only {
      .showCase {
        &.first {
          height: 100%;
          width: 100%;
          .thumb {
            height: 100%;
            width: 100%;
          }
          .details {
            display: none;
          }
        }
        &.second {
          display: none;
        }
        &.third {
          display: none;
        }
        &.fourth {
          display: none;
        }

        .expand {
          display: none;}
      }

    }
    &.bikes-products-marines {
      .showCase {
        &.second {
          .description {
            float: left;
          }
        }

      }
    }
  }

}




@media (min-width: 768px) and (max-width: 991px) {


  .showCase {
    position: relative !important;
    transform: none !important;
    float: left;
    width: 50%;
    height: 50%;
    .thumb {
      height: 100%;
      width: 50%;
      float: left;
    }
  }
  .productShowCaseSection {
    .showCase {
      .content {
        .description {
          padding: 0 em(30);
          width: 50%;
          height: 100%;
          float: left;
          .title {
            top: initial;
            bottom: em(50);
            h3 {
              position: initial;
              text-shadow: none;
              font-size: em(30);
              margin-bottom: em(10);
            }
            hr {
              display: none;
            }
            .btn {
              font-size: em(16);
            }
          }
          .logo {
            top: em(24);
          }
        }
      }
      &.first {
        .description {
          width: 100%;
          height: 100%;
        }
        .thumb {
          width: 100%;
          height: 100%;
        }
        width: 50%;
        height: 50%;
      }
      &.second {
        .description {
          float: right;
          background-position: right -40px;
          background-size: 30% auto;
          &:before {
            right: initial;
            left: em(-15);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10.5px 12px 10.5px 0;
            border-color: transparent $brand-white transparent transparent;
          }
        }
        .thumb {
          float: left;
        }
        width: 50%;
        height: 50%;

      }
      &.third {
        .description {
          float: left;
          &:before {
            left: -1.1em;
          }
        }
        //.thumb {
        //  float: right;
        //}
        width: 50%;
        height: 50%;
      }
      &.fourth {
        .description {
          &:before{
            left: -1.1em;
          }
        }
        width: 50%;
        height: 50%;
      }
    }
    .expand {
      display: none;
    }

    &.cars-bikes-marines .showCase.first {
      height: 100%;
    }
    &.cars-bikes {
      .showCase {
        &.first {
          height: 100%;

        }
        &.second {
          .description {
            float: left;
          }
        }
      }
    }
    &.cars-only {
      .showCase {
        &.first {
          height: 100%;
          width: 100%;
          .thumb {
            height: 100%;
            width: 100%;
          }
          .details {
            display: none;
          }
        }
        &.second {
          display: none;
        }
        &.third {
          display: none;
        }
        &.fourth {
          display: none;
        }

        .expand {
          display: none;}
      }

    }
    &.bikes-products-marines {
      .showCase {
        &.second {
          .description {
            float: left;
          }
        }

      }
    }
  }
}

@media (max-width: 767px) {

  .showCase {
    position: relative !important;
    transform: none !important;
    height: em(283) !important;
    width: 100% !important;
    .thumb {
      height: 100%;
      width: 50%;
      float: left;
    }
  }
  .productShowCaseSection {
    .showCase {
      .content {
        .description {
          padding: 0 em(16);
          width: 50%;
          height: 100%;
          float: left;
          .title {
            top: initial;
            bottom: em(26);
            h3 {
              position: initial;
              text-shadow: none;
              font-size: em(20);
              margin-bottom: em(10);
            }
            hr {
              display: none;
            }
            .btn {
              font-size: em(15);
            }
          }
          .logo {
            top: em(30);

          }
        }
      }
      &.first {
        .description {
          width: 100%;
          height: 100%;
        }
        .thumb {
          width: 100%;
          height: 100%;
        }
      }
      &.second {
        .description {
          float: right;
          background-position: right -40px;
          background-size: 30% auto;
          &:before {
            right: initial;
            left: em(-15);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10.5px 12px 10.5px 0;
            border-color: transparent $brand-white transparent transparent;
          }
          .logo {
            width: em(80);
          }
        }
        .thumb {
          float: left;
        }
      }
      &.third {
        .description {
          float: left;
          &:before {
            right: -12px;
            left: initial;
            -ms-transform: rotate(180deg); /* IE 9 */
            -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
            transform: rotate(180deg);
          }
          .logo {
            width: em(163);
          }
        }
        .thumb {
          float: right;
        }
      }
      &.fourth {
        .description {
          &:before{
            left: -12px;
          }
          .logo {
            width: em(163);
          }
        }
      }
    }
    .expand {
      display: none;
    }
    &.cars-bikes {
      .showCase {
        height: 27em !important;
        width: 50% !important;
        float: left;
        &.first{
          .details{
            display: none;
          }
        }
        &.second {
          .description {
            float: left;
            .logo, .title {
              margin-left: 1em;
            }
          }
        }
      }
    }
    &.cars-only {
      .showCase {
        &.first {
          height: 100%;
          width: 100%;
          .thumb {
            height: 100%;
            width: 100%;
          }
          .details {
            display: none;
          }
        }
        &.second {
          display: none;
        }
        &.third {
          display: none;
        }
        &.fourth {
          display: none;
        }

        .expand {
          display: none;}
      }

    }
    &.bikes-products-marines {
      .showCase {
        &.second {
          height: 40em !important;
          .description {
            float: left;
            .logo, .title {
              margin-left: 1em;
            }
          }
        }
      }
    }
    &.products-marines {
      .showCase {
        height: 27em !important;
        width: 50% !important;
        float: left;
        &.third {
          .description {
            .logo, .title {
              margin-left: 1em;
            }
          }
        }
        &.fourth {
          .description {
            .logo, .title {
              margin-left: 1em;
            }
          }
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px)  {
  .productShowCaseSection {
    &.bikes-products-marines {
      .showCase {
        &.second {
          height: 27em !important;
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {




  .showCase {
    height: em(220) !important;
  }
  .productShowCaseSection {
    .showCase {

      &.first {
        .logo {
          width: em(110);
          height: em(69);
        }
      }
      &.second {
        .logo {
          width: em(60);
          height: em(48);
        }
      }
      &.third {
        .logo {
          width: em(112);
          height: em(26);
        }
      }
      &.fourth {
        .logo {
          width: em(122);
          height: em(34);
        }
      }
    }
  }
  .productShowCaseSection .showCase .content .description .title, .productShowCaseSection .showCase .content .description .title2 {
    bottom: 0;
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.8);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    -webkit-transform: scale(1.5);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.8);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    -webkit-transform: scale(1.5);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}


.video-container {
  position:relative;
  padding-bottom:56.25%;
  height:0 !important;
  overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position:absolute;
  top:0;
  left:0;
  width:100% !important;
  height:100% !important;
  margin-top: 0px !important;
}