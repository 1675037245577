section.search-result {
  h2 {
    line-height: .8125em;
    margin: 0 0 .625em 0;
    font-weight: 700;
    font-family: "univers condensed";
    text-transform: uppercase;
    color: #222222;
    padding-top: 4em;
    font-size: 2.75em;

  }
  hr.full {
    width: 100%;
    display: inline-block;
    background: $brand-primary ;
  }
  .fp-tableCell {
    /*padding-top: em(50);*/
    vertical-align: top;
  }
}

@media  (max-width: 1024px) {
  section.search-result h2{
    padding-top: 2.5em;
  }
}