
.safeTechItems {
  vertical-align: top;
  display: inline-block;
  float: none;
}

.video-section{
  // background:#070707;
  .l1-content{
    padding-top: em(190);
    //background: #070707;
    float: left;
    width: 39.5%;
    padding-left: em(45);
    padding-right: em(45);
    h3{
      font-family: $univers-condensed;
      font-weight: bold;
      text-transform: uppercase;
      font-size: em(49);
      color: #000000;
      margin-bottom: 20px;
      line-height: 1.05;
    }
    p
    {
      font-family: $univers;
      font-size: em(22);
      color: #000000;
      text-shadow: 0px 0px 0px;
    }
  }
  .l1-img{
    width: 60.5%;
    float:right;
    height: 100vh;

    .kw-tl{
      position: absolute;
      right: em(50);
      bottom: em(180);
      padding: em(12);
      min-width:auto;
      width:auto;
      span{
        font-family: "univers condensed";
        font-size: em(32);
        font-weight: 400;
        letter-spacing: 1px;
      }
    }
  }
}



.fancy_video{
    position: absolute;
    right: 0.5%;
    bottom: 7.5%;
    z-index: 999999;
    @include transition(0.5s);
    color: #fff;
  &:hover{
    color: #ff4545;
  }
}

.main-video-link{
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100vh;
  float: left;
}

.home-banner{
  .slick-dots {
    position: absolute;
    bottom: 10%;
    left: 0;
    padding: 0;
    width: auto;
    text-align: right;
    right: 23%;
    z-index: 1;
    li {
      position: relative;
      display: inline-block;
      margin: 0 20px 0 0;
      background: none !important;
      border: transparent 1px solid !important;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      cursor: pointer;
      -webkit-transition: all .5s ease-in-out;
      -moz-transition: all .5s ease-in-out;
      -o-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;

      button {
        display: none;
      }

      &:before {
        background: #fff;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;

      }

      &:hover {
        border: #fff solid 1px !important;

      }

      &.slick-active {
        border: #c00 solid 1px !important;
        &:before{
          background: #c00;
        }
      }

    }
  }
  .slick-next, .slick-prev{
    display: none !important;
  }
  .slick-slide{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}



.video-section{
  height: 825px;
  overflow: hidden;
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  z-index: 1;
  clear: both;
  .container{
    position: absolute;
    left:0;
    right:0;
    margin: auto;
    top:44.3%;
    z-index: 10;
    width:100%;
    text-align: center;
  }
  h1{
    color: $brand-white;
    margin: 0 0 em(1.3) 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: em(100);
    font-family:$univers-condensed;
    letter-spacing: em(2);
    text-shadow: 2px 2px 3px #7b7b7b;
  }
  p{
    color: $brand-white;
    font-size: em(20);
    margin-bottom: em(21);
    letter-spacing: em(-0.05);
    text-shadow: 2px 2px 3px #7b7b7b;
  }
  .btn{
    font-size: em(11);
    width:em(198);
    padding: em(13) em(27) em(12) em(27);
    text-shadow: none;
  }
  .bgVid {
    display: block;
    position: absolute;
    z-index: -101;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    /*background: url("#{$websitePath}assets/images/bg-hero-video.jpg") no-repeat;*/
    background-size: 100% 100%;
    background-position: center bottom;
    background-size: cover;
    object-fit: cover;
  }
}

.newTicker{

  height: em(60);
  // background: rgba(#010101, 0.4);
  width:100%;
  position: absolute;
  left:0;
  bottom:0;
  z-index: 9;
  float: left;
  .left-heading{
    float: left;
    width:19%;
    background: $brand-primary;
    height:100%;
    position: relative;
    overflow: hidden;
    padding: em(23) 0 0 em(88);
    &:after{
      position: absolute;
      left:-5%;
      top:-50%;
      content: '';
      background: #d50e0e;
      @include borderRadius(50%);
      width:em(142);
      height:em(137);
    }
    h3{
      color: $brand-white;
      font-size: em(18);
      margin: 0;
      position: relative;
      z-index: 1;
    }
    i{
      width: em(46);
      height:em(46);
      position: absolute;
      left:em(36);
      top:em(7);
      background: $brand-primary url("#{$websitePath}assets/svgs/bell.svg") no-repeat center;
      @include borderRadius(50%);
      z-index: 2;
      
      //img{
      //  width: em(18);
      //  height:em(21);
      //  left:0;
      //  right:0;
      //  top:0;
      //  bottom:0;
      //  margin: auto;
      //  position: absolute;
      //}
    }
  }
  .right-text{
    width:81%;
    float: left;
    height:100%;
    background: rgba(#010101, 0.8);
    padding: em(20) 0 0 em(38);
  }
}
.cookie-bar {
  background: #000000;
  padding: 0 em(36);
  width:100%;
  position: fixed;
  left:0;
  bottom:0;
  z-index: 9;
  float: left;
  overflow: hidden;
  display: flex;
  @include transition(0.8s);
   p{
     color: $brand-white;
     font-size: em(14);
     margin: 0;
     float: left;
     width: 80%;
     padding: em(44) 0;
   }
  .btnarea {
    float: right;
    position: relative;
    width: 20%;
    padding-top: 15px;
    .close-c-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      margin:  auto;
      right: 2px;
      width: em(22);
      height: em(22);
      img{
        width: em(22);
      }
    }
    .btn-hollow {
      color: $brand-white;
      text-transform: uppercase;
      font-size: em(13);
      text-align: center;
      border: 1px solid $brand-white;
      float: right;
      width: em(110);
      height: em(46);
      line-height: em(46);
      position: absolute;
      top: 0;
      bottom: 0;
      margin:  auto;
      right: 20%;
      padding: 0;
    }
  }
  &.inactive {
    @include opacity(0);
  }
}
.newSlider{
  margin: 0;
  padding: 0;
  li{
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    p{
      color: $brand-white;
      font-size: em(16);
      margin: 0;
      padding: 0;
    }
    .newsLink{
      font-size: em(12);
      color: $brand-primary;
      padding: em(3) em(60) em(3) 0;
      font-weight: 700;
      @include transitions(0.5s);
      overflow: hidden;
      text-transform: uppercase;
      right: em(42);
      top: em(3);
      position: absolute;
      i{
        position: absolute;
        right:em(3);
        top: em(-2);
        font-size: em(30);
        z-index: 99;
        color: $brand-white;
      }
      span{
        position: relative;
        z-index: 99;
      }
      &:hover, &:focus{
        text-decoration: none;
      }
      &:before{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 0;
        z-index: 1;
        content: '';
        background: lighten($brand-primary, 10%);
        -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      }
    }
  }
}




.newsSlickNav{
  float: left;
  width:19%;
  background: $brand-primary;
  height:100%;
  position: relative;
  overflow: hidden;
  /*padding: em(23) 0 0 em(88);*/
  &:after{
    position: absolute;
    left:-5%;
    top:-50%;
    content: '';
    background: #d50e0e;
    @include borderRadius(50%);
    width:em(142);
    height:em(137);
  }
  h3{
    color: $brand-white;
    font-size: em(22);
    margin: 0;
    position: relative;
    z-index: 1;
  }
  i{

    width: em(46);
    height:em(46);
    position: absolute;
    left:em(36);
    top:em(7);
    background: $brand-primary url("#{$websitePath}assets/svgs/bell.svg") no-repeat center;
    @include borderRadius(50%);
    z-index: 2;
  }
  .slick-list{
   z-index: 1;
  }
  .newsNav-item{
    padding: em(8) 0 em(8) em(88);
    height: em(60);
    position: relative;
    display: flex;
    align-items: center;
    a{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}
.newSlickSlider{
  margin: 0;
  padding: 0;
  .slick-slide{
   position: relative;
  }
  p{
    color: $brand-white;
    font-size: em(18);
    margin: 0;
    padding: 0 20% 0 0;
    text-shadow: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .newsLink{
    font-size: em(17);
    color: $brand-primary;
    padding: em(3) em(60) em(3) 0;
    font-weight: 700;
    @include transitions(0.5s);
    overflow: hidden;
    text-transform: uppercase;
    right: 10%;
    top: em(0);
    position: absolute;
    i{
      position: absolute;
      right:em(3);
      top: em(-2);
      font-size: em(30);
      z-index: 99;
      color: $brand-white;
    }
    span{
      position: relative;
      z-index: 99;
    }
    &:hover, &:focus{
      text-decoration: none;
    }
    &:before{
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0;
      z-index: 1;
      content: '';
      background: lighten($brand-primary, 10%);
      -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }
  .slick-dots{
    position: absolute;
    padding: 0;
    top: em(3);
    bottom: 0;
    margin: auto 0;
    right: em(30);
    li{
      display: inline-block;
      margin: 0 0 0 em(12);
      line-height: 1;
      button{
        width: em(15);
        height: em(15);
        padding: 0;
        border: 0;
        background: $brand-white;
        @include borderRadius(50%);
        @include transitions(0.5s);
        overflow: hidden;
        text-indent: -30px;
      }
      &.slick-active{
        button{
          background: $brand-primary;
        }
      }
    }

  }
}



.threeImages{
  float: left;
  width:100%;
  .item{
    position: relative;
    float: left;
    width:33.333%;
    overflow: hidden;
    h3{
      color: $brand-white;
      margin: 0;
      font-size: em(20);
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: em(1);
      z-index: 2;
    }
    .item-heading{
      position: absolute;
      left:0;
      top:0;
      z-index: 2;
      width:100%;
      padding: em(110) em(60) em(76) em(60);
      height:100%;
    }
    h2{
      color: $brand-white;
      margin: 0;
      font-size: em(50);
      position: absolute;
      bottom: em(40);
      span{
        color: $brand-white;
        font-size: 0.400em;
        display: block;
        margin-top: 0.400em;
        position: absolute;
      }
    }
    img{
      width:100%;
      @include transitions(1.5s);
    }
  }
  .fp-tableCell{
    vertical-align: bottom;
  }
}



.latest-news{
  padding: 0;
  float: left;
  width:100%;
  .container{
    width:100% !important;
    padding-right: em(60);
    padding-left: em(60);
    .row{
      margin-right: em(-60);
      margin-left: em(-60);
      [class^="col-"]{
        padding-left: em(60);
        padding-right: em(60);
      }
    }
  }
  .full-container{
    width:100%;
    padding: 0;
    .row{
      margin: 0;
      .item{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  h1{
    color: $brand-primary;
    font-size: em(42);
    margin: 0 0 em(28) 0;
    font-weight:700;
  }
  .txt{
    padding:0 em(60) em(60) em(60);
    min-height:em(190);
    border-right:solid 1px #c9c9c9;

  }
  h3{
    color: $brand-black;
    font-size: em(22);
    margin: 0 0 em(20) 0;
    font-weight:700;
  }
  p{
    color: $brand-black;
    font-size: em(15);
    margin: 0 0 em(20) 0;
  }
  .moreLink{
    font-size: em(16);
    color: $brand-primary;
    padding: em(3) em(40) em(3) 0;
    position: relative;
    font-weight: 700;
    @include transitions(0.5s);
    overflow: hidden;
    display: inline-block;
    line-height: em(16);
    i{
      position: absolute;
      right:em(3);
      top: 0;
      font-size: em(24);
      z-index: 99;
    }
    span{
      position: relative;
      z-index: 99;
    }
    &:hover, &:focus{
      text-decoration: none;
    }
    &:before{
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0%;
      z-index: 1;
      content: '';
      background: lighten($brand-primary, 10%);
      -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }
  .newThumb{
    display: block;
    overflow: hidden;
    position: relative;
    width:100%;
    float: left;
    &:after{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      content: '';
      @include transitions(0.5s);
      background: rgba($brand-primary, 0.4);
      opacity: 0;
    }
    img{
      width:100%;
      @include transitions(1s);
    }
    i{
      position: absolute;
      left:0;
      top:0;
      bottom:em(-30);
      margin: auto;
      right:0;
      color: $brand-white;
      @include transitions(0.5s);
      z-index: 99;
      font-size: em(50);
      width:em(16);
      height:em(16);
      opacity: 0;
    }
  }
  .fp-tableCell{
    vertical-align: bottom;
  }
}
.logo-section{
  padding:em(15) 0px em(45) 0;
  float: left;
  width:100%;
  z-index: 99;
  position: relative;
  background: #fff;
}
.honda-logo{
  display: inline-block;
  width:em(448);
  height:em(45);
  img{
    width:100%;
  }
}


.embed-container{
  width:100% ;
  height:110vh !important;
  position: absolute;
  left:0;
  top:0;


}


.img-desktop-none{
  display: none;
}

.tp-simpleresponsive {
  img{
    position: relative;
    left: -140px;
  }
}

@media (max-width: 1199px) and (min-width: 992px){
  .homepage .fullpage-wrapper .video-section.fp-section {
    height: auto !important;
    min-height: inherit !important;
  }
  .home-banner .slick-dots {
    left: 43%;
  }
}





@media (min-width: 1279px) and (max-width: 1280px){

  .mac {
    #myVideo{
      height: 780px !important;
    }
  }


}
.mac{
  .car-pay-inr{
    .btn{    min-width: 14.4375em;
      i{
        top:0.19em;
      }
    }
  }
}
.mac {
  #myVideo{
    margin-top: 0px !important;
  }
  .btn i{
    font-size: 2em;
    top: .2125em;

  }
  .tp-bullets.round .tp-bullet{
    top: 0.2em;
  }

}




#myVideo{border:none !important;}

@media (min-width: 1601px) and (max-width: 2400px){}

@media (min-width: 1600px) and (max-width: 1850px) {
  .latest-news {
    .newThumb {
      height: 380px;
    }
  }
#section5{
  padding-top: em(10);
}
  .logo-section{
    padding:em(5) 0px em(30) 0;
  }

}

@media (min-width: 1440px) and (max-width: 1600px){

}


@media (min-width: 1535px) and (max-width: 1550px){
  body .productShowCaseSection .ProductGallery .product {
    .car{
      bottom: 2em;
    }
    .logo{
      padding: 1em .9375em 1.625em;
      width: 17.625em;
    }
  }

  .video-section{
    .l1-content{
      padding-top: em(145);
    }
  }

}




@media (min-width: 1250px) and (max-width: 1280px){
  body .productShowCaseSection .ProductGallery .product {
    .car{
      bottom: 2em;
    }
    .logo{
      padding: 1em .9375em 1.625em;
      width: 17.625em;
    }
  }

}


@media (min-width: 1367px) and (max-width: 1600px){
  .newTicker {
    .left-heading {
      h3{
        font-size: em(16);
      }
    }
  }

  //.newsSlickNav{
  //  h3{
  //    font-size: em(16);
  //  }
  //}

  .latest-news h1{
    margin-bottom: em(16);
  }



}
@media (min-width: 1200px) and (max-width: 1366px){

  .card {
    .card-header {
      .h5{
        font-size: 17px;
      }
    }
  }
  .home-banner .slick-dots {
    bottom: 7%;
  }
  .video-section{
    .l1-content{
      padding-top: em(200);
      h3{
        font-size: em(51);
      }
      p{
        font-size: em(23);
      }
    }
  }
  .newTicker {
    .left-heading {
      h3{
        font-size: em(15);
      }
    }
  }

  //.newsSlickNav{
  //  h3{
  //    font-size: em(15);
  //  }
  //}

  //.latest-news{
  //  p{
  //    display: none;
  //  }
  //}

  .latest-news p {
    font-size: em(17);
  }
  .cookie-bar p {
    font-size: em(16);
  }
}

.mouse-icon{
  &.desk{
    display: block;
  }
  &.res{
    display: none;
  }
}

.mobile-portrait, .mobile-landscape{
  display: none;
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .threeImages {
    .grid_img {
      height: 100vh;
      img {
        height: 100%;
      }
    }
  }
  .threeImages{
    .item{
      &:hover{
        img{
          @include scale(1.1);
        }
      }
    }
  }
  .latest-news{
    .txt {
      height: 15.5em;
    }
    .newThumb{
      &:hover{
        img{
          @include scale(1.1);
        }
        &:after{
          opacity: 1;
        }
        i{
          bottom:0;
          opacity: 1;
        }
      }
    }
    .moreLink{
      &:hover{
        color: $brand-white;
        padding: em(3) em(40) em(3) em(5);
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
  }
  .newSlider {
    li {
      .newsLink{
        &:hover{
          color: $brand-white;
          padding: em(3) em(50) em(3) em(5);
          &:before {
            left: 0;
            right: auto;
            width: 100%;
          }
        }
      }
    }
  }
  .cookie-bar {
    .btn-hollow {
      span{
        position: relative;
        z-index: 9;
      }
      &:before{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 0;
        z-index: 1;
        content: '';
        background: red;
        @include transitions(0.5s);
      }
      &:hover{
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
    .close-c-bar{
      position: relative;
      img{
        position: relative;
        z-index: 2;
      }
      &:before {
        content: '';
        border-radius: 50%;
        background: $brand-primary;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        @include transitions(0.5s);
        @include scale(0);

      }
      &:hover{
        &:before{
          @include scale(2);
        }
      }
    }
  }

  .newSlickSlider {
    .slick-dots {
      li {
        button{
          &:hover{
            background: $brand-primary;
          }
        }
      }
    }
    .newsLink{
      &:hover{
        color: $brand-white;
        padding: em(3) em(50) em(3) em(5);
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
  }
}



.desktop-img{
  display: block !important;
}
.mobile-img{
  display: none !important;
}

@media (max-width: 1024px){
  .threeImages .item h2{
    text-shadow: 1px 1px 5px #000;
  }
  .threeImages .item h2 span{
    font-size: 0.6em;
  }
  .video-section {
    .l1-content {
      padding-top: em(120);
      h3{
        font-size: em(48);
      }
      p{
        font-size: em(23);
      }
    }
    .l1-img {
      height: 40vh;
    }

  }
}
/* Landscape tablets and medium desktops */
@media (min-width: 991px) and (max-width: 1200px){

  .newTicker .right-text{
    background: #333;
  }
  .main-video-link{
    height:92.08vh !important;
  }
  .embed-container{
    width:100vw !important;
    height:92.08vh !important;
    position: absolute;
    left:0;
    top:0;
    background: url("#{$websitePath}assets/images/video_banner.jpg") no-repeat top center;
    background-size: cover;
    iframe{
      display: none;
    }
  }
  .video-section {
    h1{
      font-size: em(100);
      letter-spacing: em(1);
    }
  }
  .newTicker {
    .left-heading{
      width: 22%;
    }
    .right-text{
      width:78%;
    }
  }
  .threeImages {
    .item {
      h2{
        font-size: em(44);
      }
    }
  }
  .latest-news{
      .txt {
        height: 13em;
      }
    p{
      display: none;
    }
  }
  .video-section {
    height:92.08vh !important;
    min-height: 92.08vh ;
    max-height: 92.08vh;
    .fp-tableCell{
      height:100% !important;
      vertical-align: top;
      display: block;
    }
  }
  .threeImages {
    .item {
      h2{
        font-size: em(36);
      }
    }
  }
  .latest-news {
    padding: em(60) 0 0 0;
    h1{
      font-size: em(40);
      margin-bottom: em(30);
    }
  }
  .newTicker {
    bottom:0;
    font-size: em(18);
    .left-heading{
      width: 25%;
    }
    .right-text{
      width:75%;
    }
  }

  .newsSlickNav{
    width: 25%;
  }
  .newSlickSlider {
    p{
      padding: 0 25% 0 0;
    }
    .newsLink{
      right: 12%;
    }
  }

  .timeLineSection,  .threeImages, .latest-news, .fp-auto-height{
    height: auto !important;
    .fp-tableCell{
      height: auto !important;
    }
  }
  .cookie-bar {
    p {
      font-size: em(18);
      padding: 1.875em 0;
    }
    .btnarea {
      .btn-hollow {
        right: 30%;
      }
    }
  }

  .home-banner .slick-dots{
    left: 59%;
    RIGHT: AUTO;
  }
}

@media (max-width: 768px){

  .home-banner .slick-dots{
    top:70%;
    bottom: auto;
  }

  .video-section {
    .l1-content {
      padding-top: 0;
      width: 100%;
      margin-bottom: 70px;
    }
    .l1-img {
      width: 100%;
      float: right;
    }
  }
}


@media (min-width: 768px) and (max-width: 991px){

  .homepage .fullpage-wrapper .video-section.fp-section {
    height: auto !important;
    min-height: inherit !important;
  }


  .newSlider li .newsLink{
    top: 1em;
  }
  .mouse-icon{
    &.desk{
      display: none;
    }
    &.res{
      display: block;
    }
  }
  .main-video-link{
    height:62vh !important;
  }
  .embed-container{
    width:100vw !important;
    height:62vh !important;
    position: absolute;
    left:0;
    top:0;
    background: url("#{$websitePath}assets/images/video_banner.jpg") no-repeat top center;
    background-size: cover;
    iframe{
      display: none;
    }
  }
  .video-section {
    .btn{
      font-size: em(13);
      padding: em(12) em(13) em(12) em(25);
    }
  }
  .latest-news{
    .txt {
      height: 14em;
    }
    p{
      display: none;
    }
  }
  .video-section, .timeLineSection, .threeImages, .latest-news, .fp-auto-height{
    height: auto !important;
    .fp-tableCell{
      height: auto !important;
    }
  }
  .productShowCaseSection{
    height:em(600) !important;
    .fp-tableCell{
      height: auto !important;
    }
  }
  .newTicker {
    .left-heading{
      width: 30%;
    }
    .right-text{
      width:70%;
      padding-top: em(18);
    }
  }
  .newsSlickNav{
    width: 30%;
  }
  .newSlider {
    li{
      p{
        float: left;
        width:75%;
      }
    }
  }

  .newSlickSlider{
    p{
      float: left;
      width:75%;
    }
    .newsLink{
      right: 18%;
    }
    .slick-dots{
      top: em(3);
    }
  }


  .video-section {
    height: 62vh !important;
    min-height: 62vh;
    h1{
      font-size: em(80);
    }
  }
  .threeImages {
    .item {
      h2{
        font-size: em(34);
      }
    }
  }
  .latest-news{
     padding-top: em(50);
     .container{
       padding-right: em(30);
       padding-left: em(30);
       &.full-container{
         padding-right: em(0);
         padding-left: em(0);
       }
     }
     .txt{
       padding: 0 em(30) em(30) em(30);
     }
   }
  .latest-news{
    padding-top: em(50);
    .container{
      padding-right: em(30);
      padding-left: em(30);
      &.full-container{
        padding-right: em(0);
        padding-left: em(0);
      }
    }
    .txt{
      padding: 0 em(30) em(30) em(30);
    }
  }
  .cookie-bar {
    p {
      font-size: em(18);
      padding: 1.5em 0;
    }
    .btnarea {
      .btn-hollow {
        right: 30%;
      }
    }
  }
  .home-banner .slick-dots{
    top:55%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }

}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .desktop-img{
    display: none !important;
  }
  .mobile-img{
    display: block !important;
  }
  .video-section .l1-content {
    padding-left: 1em;
    padding-right: 1em;


  }
  .main-video-link{
    height: 107vh;
  }
  .fancy_video{
    bottom: 15.5%;
  }
  .tp-rightarrow:after{
    display: none;
  }
  body{
    .tp-leftarrow, .tp-rightarrow{
      width: auto;
      right: auto !important;
    }
  }
  .mouse-icon{
    &.desk{
      display: none;
    }
    &.res{
      display: none;
    }
  }
  //#home-section{
  //  min-height: 400px !important;
  //  max-height: 400px !important;
  //}
  body .new-home-slider{
    &.video-section{
      min-height: 354px !important;
      max-height: 354px !important;
    }
  }
  .right-text li{
    margin-top: .3em;
  }
  .embed-container{
    width:100vw !important;
    height:120vh !important;
    position: absolute;
    left:0;
    top:0;
    //background: url("#{$websitePath}assets/images/video_banner.jpg") no-repeat top center;
    background-size: cover !important;
    display: none;
    &.mobile-portrait{
      display: none !important;
    }
    &.mobile-landscape{
      display: block !important;
    }
    iframe{
      display: none;
    }
  }
  .latest-news {
    padding: em(48) 0 0 0;
    .container{
      padding-right: 2.000em;
      padding-left: 2.000em;
      .row{
        margin-left: -2.000em;
        margin-right: -2.000em;
        [class^="col-"]{
          padding-right: 2.000em;
          padding-left: 2.000em;
        }
      }
    }
    .txt{
      padding:em(30) em(15) em(15) em(15);
      min-height: inherit;
      h3{
        margin: 0;
        font-size: em(17);
        br{
          display: none;
        }
      }
    }
    .item{
     &:last-child{
        .txt{
          border:none;
        }
      }
    }
    .moreLink{
      display: none;
    }
  }
  .video-section {
    min-height: 100vh;
    .container{
      top: 50%;
    }
    h1{
      font-size: em(50);
      margin-bottom: em(5);
      line-height: em(18);
    }
    .para{
      display: none;
    }
    .btn{
      font-size: em(14);
    }
    .l1-img{
     .kw-tl{
      right: em(15);
      bottom: em(65);
       padding: em(6);
       span{
         font-size: em(17);
       }
    }
    }
  }
  .newTicker{
    background: $brand-black;
    height:em(80);
    .left-heading{
      width:18%;
      padding:2%;
      font-size: em(15);
      padding-top: 17px;
      i, &:after{
        display: none;
      }
    }
    .right-text{
      width: 82%;
      padding:1% 2%;
      overflow: hidden;
    }
  }

  .newsSlickNav{
    width:18%;
    i, &:after{
      display: none;
    }
    .newsNav-item{
      padding: 1% 1% 1% 6%;
      height: em(80);
    }
  }

  .newSlider {
    li{
      background: $brand-black;
      margin-top: 7px;
      p{
        font-size: em(17);
        width:70%;

      }
      .newsLink{
        right: em(10);
        padding: 0;
        font-size: em(17);
        i{
          display: none;
        }
      }
    }
  }

  .newSlickSlider{
    p{
      font-size: em(16);
      width:70%;
      height: em(50);
      padding-top: em(18);
    }
    .newsLink{
      right: em(10);
      padding: 0;
      font-size: em(17);
      top: em(20);
      i{
        display: none;
      }
    }
  }


  .productShowCaseSection, .video-section, .timeLineSection, .threeImages, .latest-news, .fp-auto-height{
    height: auto !important;
    .fp-tableCell{
      height: auto !important;
    }
  }
  .threeImages {
    .item{
      width: 33.333%;
      margin-bottom: 1px;
      .item-heading{
        padding: em(30);
        h2{
          bottom:em(25);
          font-size: em(20);
          span{
            font-size: em(12);
            position: relative;
          }
        }
        h3{
          font-size: em(14);
        }
      }
    }
  }
  .latest-news {
    .txt {
      height: 13.5em;
    }
    h1{
      text-align: center;
      font-size: em(40);
      margin-bottom: em(20);
    }
    .full-container {
      .row {
        .item{
          &:first-child, &:nth-child(2){
            margin-bottom: em(30);
          }
        }
      }
    }
    p{
      display: none;
    }
  }
  .latest-news .full-container .row .item{
    margin-bottom: 0 !important;
  }


  body .bottom-form .form-wrapper .thanks .thanks-inner{
    height: 96px;
    padding-top: 40px;
  }
  .cookie-bar {
    padding-left: 15px;
    padding-right: 15px;
    p {
      font-size: em(16);
      padding: 1em 0;
    }
    .btnarea {
      .btn-hollow {
        right: 30%;
      }
    }
  }
  .home-banner .slick-dots {
    top: 56%;
    bottom: auto;
  }


}

/* Portrait phones and smaller */
@media (max-width: 480px) {

  .honda-logo {
      width: em(228);
    }

  .home-banner .slick-dots {
    top: 33vh;
    bottom: auto;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }


  .fancy_video{
    right: 1%;
    bottom: 13.5%;
  }

  .video-section h1{
    line-height: em(14);
  }
  .item{
    &:nth-child(2){
      .txt{
        border:none;
      }
    }
  }
  #home-section {
   height: 71vh !important;
   min-height: 71vh !important;
 }
  #numberCounter{
    top: 40%;
  }
  .newSlider li{
    margin-top: 4px;
    .newsLink{
      top: 0.8em;
    }
  }
  body .embed-container{
    //background: url("#{$websitePath}assets/images/video_banner_mobile.jpg") no-repeat top center;
    background-size: 100% !important;

    &.mobile-portrait{
      display: block  !important;
    }
    &.mobile-landscape{
      display: none !important;
    }

  }
  .newTicker{
    background: $brand-black;
    .left-heading{
      width:28%;
      padding: 4.5% 3%;
    }
    .right-text{
      width: 64%;
      padding:1.5% 2%;
      overflow: hidden;
    }
  }

  .newsSlickNav{
    width:36%;
    i{
      display: none;
    }
    .newsNav-item{
      padding: 1% 4% 1% 6%;
      height: em(80);
    }
  }


  .newSlider {
    li {
      height:initial;
      p{
        width: 80%;
        font-size: em(15);
      }
    }
  }

  .newSlickSlider{
    height:initial;
    p{
      width: 85%;
      font-size: em(15);
      padding-top: em(24);
    }
    .newsLink{
      top: em(22);
    }
  }

  .video-section {
    height: 80vh !important;
    min-height: 80vh;
  }
  .threeImages {
    .item {
      width:100%;
    }
  }
  .latest-news {
    .txt {
      height: 12em;
    }
    .full-container {
      .row {
        .item{
          width:50%;
        }
      }
    }
  }
  .img-moblie-none{
    display: none;
  }
  .img-desktop-none{
    display: block;
  }
  .cookie-bar {
    display: block;
    text-align: center;
    height: auto;
    p {
      font-size: em(16);
      max-width: 100%;
      width: 100%;
      padding: 1em 0;
    }
    .btnarea {
      width: 100%;
      padding-top: 0;
      margin-bottom: 1.2em;
      position: relative;
      .btn-hollow {
        float: none;
        display: inline-block;
        position: relative;
        margin: 0 auto;
        right: 0;
      }
      .close-c-bar {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

      }
    }

  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {


  .video-section {
    .hero-video{
      height: 31.5vh !important;
    }
  }
  .newTicker {
    .left-heading {
      h3{
        font-size: em(16);
      }
    }
  }

  .newsSlickNav{
    h3{
      font-size: em(16);
    }
  }



}


/*Galaxy S8 */
@media only screen and (min-device-width : 360px) and (max-device-width : 740px) and (orientation: portrait){
  .main-video-link{
    height: 71vh !important;
  }
  .embed-container {
    height: 71vh !important;
    background-size: cover;
  }
  .timeLineSection h1 {
    line-height: 40px;
  }
}

@media only screen and (min-device-width : 360px) and (max-device-width : 740px) and (orientation: landscape){
  .embed-container {
    height: 100% !important;
    background-size: cover;
  }
  .timeLineSection h1 {
    line-height: 40px;
  }
}