.rev_slider{
  .tp-caption{
    padding: 50px 30px 0px 70px !important;
  }
}

.timeLineSection {
  float: left;
  width: 100%;
  .fp-tableCell {
    display: block;
  }

  .tp-bgimg {
    //background-size: 100% 100% !important;
   /* background-position: 0 -130px !important;*/
    background-position:center bottom !important
  }
  position: relative;
  background-size: 100% 100%;
  h1 {
    text-transform: uppercase;
    font-size: em(40);
    color: #313131;
    letter-spacing: .625em;
    font-weight: 400;
    margin:45px 0px;
    padding: 15px;
    margin-top: 110px;
    background: #fff url("#{$websitePath}assets/images/heading1.png") no-repeat center center;

  }
}

#numberCounter {
  position: absolute;
  left: 0;
  top: 50%;
  text-align: center;
  font-size: em(525);
  color: rgba($brand-primary, 0.15);
  line-height: 0;
  width: 100%;
  font-family: $univers-condensed;
  font-weight: 700;
  mix-blend-mode: darken;
  //z-index: 999;
}

section {
  overflow: hidden;
}

.redcircle-layer {
  background: rgba(190,0,0, 0.85);
  @include borderRadius(50%);
  display: block;
  background-blend-mode: multiply;
  padding: 86px 0px 0px 46px;

  p {
    font-size: 30px;
    color: $brand-white;
    display: block;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: em(-1);
    margin: 0 0 em(10) 0;
    white-space: break-spaces;
    word-spacing: em(3);
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    font-size: 16px;
    color: $brand-white;
    padding: 3px 40px 3px 0;
    position: relative;
    font-weight: 700;
    @include transitions(0.5s);
    overflow: hidden;
    display: inline-block;
    line-height: 16px;
    i {
      position: absolute;
      right: 3px;
      top: 1px;
      font-size: 24px;
      z-index: 99;
    }
    span {
      position: relative;
      z-index: 99;
      font-size: em(16) !important;
    }
    &:hover, &:focus {
      text-decoration: none;
    }
    &:before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0;
      z-index: 1;
      content: '';
      background: $brand-white;
      -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }
}

//@media (min-width: 1601px) and (max-width: 2400px) {
//}
//
//@media (min-width: 1530px) and (max-width: 1600px) {
//}
//
//@media (min-width: 1200px) and (max-width: 1366px) {
//  #numberCounter{
//    font-size: em(350);
//  }
//}
//
///*==========  Non-Mobile First Method  ==========*/
//
///* Large desktops and laptops */
//@media (min-width: 1200px) {
//  .redcircle {
//    a {
//      &:hover {
//        color: $brand-primary;
//
//        &:before {
//          left: 0;
//          right: auto;
//          width: 100%;
//        }
//      }
//    }
//  }
//}
//
///* Landscape tablets and medium desktops */
//@media (min-width: 992px) and (max-width: 1199px) {
//  .tp-caption.redcircle-layer {
//    left: em(100) !important;
//
//    .redcircle {
//      width: 240px;
//      height: 240px;
//      padding: 49px;
//      padding-top: 61px;
//
//      p {
//        font-size: 23px;
//      }
//    }
//  }
//  .tparrows {
//    bottom: 32px !important;
//    top: initial !important;
//  }
//  #numberCounter{
//    font-size: em(413);
//    top: 49%;
//  }
//}
//
///* Portrait tablets and small desktops */
//@media (min-width: 767px) and (max-width: 991px) {
//
//  .tp-caption.redcircle-layer {
//    left: em(100) !important;
//
//    .redcircle {
//      width: 220px;
//      height: 220px;
//
//      P {
//        font-size: 23px;
//        margin-bottom: 15px;
//
//      }
//
//      a {
//        font-size: em(35);
//      }
//    }
//  }
//
//  .tparrows {
//    bottom: 32px !important;
//    top: initial !important;
//  }
//}
//
///* Landscape phones and portrait tablets */
//@media (max-width: 767px) {
//
//  .tp-caption {
//    top: em(176) !important;
//  }
//  .tp-caption.redcircle-layer {
//    left: em(60) !important;
//    top: 45% !important;
//  }
//  .redcircle {
//    width: em(350);
//    height: em(350);
//    padding: em(32) em(32) em(32) em(50);
//    padding-top: em(100);
//    P {
//      font-size: em(36);
//      margin-bottom: em(10);
//    }
//    a {
//      font-size: em(25);
//    }
//  }
//
//  .timeLineSection {
//    background-size: contain;
//    background-position: center center;
//    padding: em(50) 0 0 0;
//    h1 {
//      font-size: em(16);
//      margin-bottom: em(30);
//    }
//  }
//  #numberCounter {
//    font-size: em(300);
//  }
//  .tparrows {
//    bottom: em(-60);
//    top: initial !important;
//  }
//  .tp-bullets{
//    top:0 !important;
//  }
//  .tp-leftarrow.default{
//    left:25% !important;
//  }
//  .tp-rightarrow.default{
//    right:25% !important;
//  }
//  .tp-explore{
//    right:25% !important;
//  }
//}
//
///* Portrait phones and smaller */
//@media (max-width: 480px) {
//  .tp-caption {
//    top:em(80) !important;
//    bottom:0 !important;
//    margin: auto;
//  }
//  .tp-caption.redcircle-layer {
//    left: em(10) !important;
//  }
//  .redcircle {
//    width: em(140);
//    height: em(140);
//    padding: em(20);
//    padding-top: em(40);
//    font-size: em(60);
//    top:0 ;
//    bottom:0;
//    margin: auto;
//    left:0;
//    position: absolute;
//    p {
//      font-size: em(15);
//      margin-bottom: em(5);
//    }
//    a{
//      font-size: em(13);
//    }
//  }
//  #numberCounter{
//    font-size: em(200);
//  }
//}
//
///* Portrait phones and smaller */
//@media (max-width: 320px) {
//  .tp-caption.redcircle-layer {
//    left: em(10) !important;
//  }
//  .redcircle {
//    width: em(130);
//    height: em(130);
//    p {
//      font-size: em(14);
//    }
//    a {
//      font-size: em(10);
//    }
//  }
//  #numberCounter{
//    font-size: em(150);
//  }
//  .timeLineSection {
//    h1{
//      font-size: em(14);
//    }
//  }
//}

.mac{
  .bike{
    img{
    width: 800px !important;
    height: auto !important;
      margin-left: 150px !important;
    }
  }
  .events-line{
    top: 9px;
  }
}



@media (min-width: 1201px) and (max-width: 1280px) {
  .timeLineSection h1 {
    margin-top: 60px;
  }
}
@media (min-width: 1250px) and (max-width: 1280px) {

  .tp-caption img{
    height: 93vh !important;
    zoom:0.7 !important;
  }

  .homeslider .tp-parallax-wrap{
    left: 218px !important;
    //top:-50px !important;
  }
  .tp-resizeme{
    min-height: 250px !important;
    min-width: 250px !important;
    margin-left: -100px;
    margin-top: 100px !important;
  }
  .homeslider .redcircle-layer p{
    line-height: 26px !important;
  }

}

@media (max-width: 1280px) {
  .tp-parallax-wrap{

    left: 12px !important;

  }
  .timeLineSection {
    .tp-leftarrow {
      top: initial;
      bottom: 50px;
      right: 50px;
    }
    .tp-rightarrow, .tp-explore {
      top: initial !important;
      bottom: 50px;
      right: 50px  !important;
    }
  }

}

@media (min-width: 1200px) and (max-width: 1366px){
  .tp-bullets{
    margin-top: -30px !important;
  }
  .redcircle-layer p{
    font-size: 20px !important;
  }
}

@media (max-width: 1024px) {

  .tp-bullets.round {
    white-space: nowrap;
    .tp-bullet{
      top:2px !important;
    }
    .tp-bullet.disable {
      display: none;
    }
  }
  body .events-line{
    top: 9px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .bike{
    img{
      width: 700px !important;
      height: auto !important;
    }
  }
}


@media (min-width: 769px) and (max-width: 1024px) {


  body .fullwidthbanner-container{
    height: 100% !important;


    .caps9 .slotholder .tp-bgimg.defaultimg{
      background-position:-250px bottom !important;
    }


    .fullwidthabanner{

      .tp-revslider-slidesli >  :nth-child(2){
        top: -16px !important;
        left: -10px !important;
      }
      //.tp-revslider-slidesli >  :nth-child(3){
      //  margin-top: -30px !important;
      //  left: 8px !important;
      //  top:15% !important;
      //}
      .tp-caption{
        &.cap11{
          img{
            width: 1020px !important;
            height: 618px !important;
            margin-top: 100px;
          }

        }
        &.cap2{
          left: 36px !important;
        }

        &.cap3{
          img{
            width: 1017px !important;
            height: 600px !important;
          }
        }

        &.cap5{
          position: absolute !important;
          left: -60px !important;
          top: 2px !important;
        }

        &.cap6{
          top: 50px;
          img{
            width: 1070px !important;
            height: 651px !important;

          }

        }

        &.cap7{
          top: 68px !important;
        }
        &.cap8{
          top: 50px !important;
          img{
            width: 1070px !important;
            height: 651px !important;
          }
        }
        &.cap9{
          left: 50px !important;
          top: 82px !important;

          img{
            width: 970px !important;
            height: 601px !important;
          }
        }
        &.cap10{
          top:220px !important;
          left: 50px !important;
          position: absolute;
          img{
            height: 280px !important;
            width: 953px !important;
          }
        }

      }
    }
  }


  /* obaid css start */
  .timeLineSection h1{
    margin-top: 30px;
    margin-bottom: 20px;
  }
  #numberCounter{
    top:45%;
  }
  .fullwidthbanner-container{
    height: 100% !important;
    .fullwidthabanner{
      height: 700px !important;

      .tp-revslider-slidesli >  :nth-child(2){
        top: 0px !important;
      }
      .tp-revslider-slidesli >  :nth-child(3){
        top: 50% !important;
        margin-top: -130px !important;
      }
    }
  }
  /* obaid css end */




}
@media (min-width: 768px) and (max-width: 800px) {

  .tp-caption.bike img{
    height: 44vh !important;
  }
  body .fullwidthbanner-container{
    .fullwidthabanner{
      .tp-caption{

        &.cap4{
          left: 50px !important;
        }
        &.cap5{
          left: 50px !important;
        }
        &.cap7{
          top:0px !important;
        }
        &.cap10{
          top:110px !important;
          left: 50px;
        }



       /* &.cap1{
          img{
            width: 1020px !important;
            height: 600px !important;
            margin-top: 100px;
          }

        }
        &.cap2{
          left: 36px !important;
        }

        &.cap3{
          img{
            width: 1017px !important;
            height: 600px !important;
          }
        }

        &.cap5{
          position: absolute !important;
          left: -60px !important;
          top: 2px !important;
        }

        &.cap6{
          top: 50px;
          img{
            width: 1070px !important;
            height: 651px !important;

          }

        }

        &.cap7{
          top: -100px !important;
        }
        &.cap8{
          top: 50px !important;
          img{
            width: 1070px !important;
            height: 651px !important;
          }
        }
        &.cap9{
          left: 50px !important;
          top: 82px !important;

          img{
            width: 970px !important;
            height: 601px !important;
          }
        }
        &.cap10{
          top:220px !important;
          left: 50px !important;
          position: absolute;
          img{
            height: 280px !important;
            width: 953px !important;
          }
        }*/

      }
    }
  }





  #numberCounter{
    top:45%;
  }
  .fullwidthbanner-container{
    height: 100% !important;
    .fullwidthabanner{
      height: 500px !important;


      .tp-revslider-slidesli >  :nth-child(2){
        top: 50px !important;
      }


      .tp-revslider-slidesli >  :nth-child(3){
        top: 50% !important;
        margin-top: -130px !important;
      }
    }
  }
}





@media (max-width: 991px) {




  .timeLineSection {

    .tp-bullets {
      display: none;
    }

    .tp-leftarrow {
      top: initial;
      bottom: 50px;
      right: 50px;
    }
    .tp-rightarrow {
      top: initial;
      bottom: 50px;
      right: 50px;
    }
    h1{
      margin: 0;
      margin-top: 50px;
    }
  }

}

@media (max-width: 767px) {
  .video-section .btn{
    width: auto;
    min-width:inherit;
  }
  .tp-leftarrow:after, .btn i{
    display: none;
  }

  .fullwidthbanner-container{
    height: 100% !important;
    .fullwidthabanner{
      height: 300px !important;


      .tp-revslider-slidesli >  :nth-child(2){
        top: -26px !important;
        left: 10% !important;
      }
      .caps4.tp-revslider-slidesli>:nth-child(2){
        left: 18% !important;
      }
      .redcircle-layer{
        min-width: 200px !important;
        min-height:200px !important;
        padding-left: 32px !important;
        padding-right: 10px !important;
        p{
          font-size: 16px !important;
          line-height: 20px !important;

        }
      }

      .tp-revslider-slidesli >  :nth-child(3){
        top: 40% !important;
        margin-top: -90px !important;
      }
      .tp-caption{
        img{
          width: 486px !important;
          height: 300px !important;
        }
        &.cap10{
          top:100px !important;
          position: absolute;
          img{
            height: 177px !important;
          }
        }
        &.cap5{
          position: absolute !important;
          left:20px !important;
        }
        &.cap6{
          position: absolute !important;
          top: 40px !important;
        }
        &.cap7{
          position: absolute !important;
          top: 40px !important;
        }
        &.cap9{
          position: absolute !important;
          left: 70px !important;
          top: 10px !important;
        }
      }
    }
  }




  .timeLineSection {

    h1 {
      font-size: em(22);
      line-height: 25px;
      margin-top: 10px;

    }
    .tp-bullets {
      display: none;
    }

    .tp-leftarrow {
      top: initial;
      bottom: 20px;
      right: 20px;
    }
    .tp-rightarrow {
      top: initial;
      bottom: 20px;
      right: 20px;
    }
    .tp-explore {
      top: initial !important;
      bottom: 20px;
      right: 20px !important;
    }
    #numberCounter{
      font-size: 25em;
    }
  }

  .redcircle-layer {
    span {
      font-size: 10px !important;
    }
  }
}

@media (min-width: 700px) and (max-width: 767px) {

  .redcircle-layer a i{
    display: none;
  }


  body .fullwidthbanner-container .fullwidthabanner .tp-caption.cap5 {
    left: 60px !important;
    top: 25px !important;
   }
  .redcircle-layer a i{
    display: none;
  }
}




@media (max-width: 767px){
  .threeImages .item h3{
    text-shadow: 1px 1px 5px #000;
  }
}

@media (max-width: 568px) {

  body  .fullwidthbanner-container .fullwidthabanner .caps4.tp-revslider-slidesli>:nth-child(2){
    left: 14% !important;
  }
  body .fullwidthbanner-container .fullwidthabanner .tp-caption.cap5{
    left:-10px !important;
  }
 body .fullwidthbanner-container .fullwidthabanner .tp-caption.cap9{
    position: absolute !important;
    left: 10px !important;
  }
}
@media (max-width: 480px) {



  .redcircle-layer a span{
    font-size: 1.5em !important;
  }


  body .fullwidthbanner-container{
    height: 100% !important;
    .fullwidthabanner{
      height: 250px !important;


      .tp-revslider-slidesli >  :nth-child(2){
        top: -16px !important;
        left: -10px !important;
      }
      .redcircle-layer{
        min-width: 130px !important;
        min-height:130px !important;
        padding-left: 17px !important;
        p{
          font-size: 11px !important;
          line-height: 12px !important;
          white-space: normal;

        }
      }

      .tp-revslider-slidesli >  :nth-child(3){
        margin-top: -30px !important;
        left: 8px !important;
        top:15% !important;
      }
      .tp-caption{
        img{
          width: 340px !important;
          height: 37vh !important;
        }
        &.bike{
          left: 25px !important;
          top: 20px !important;
          zoom: 1.04;
        }
        &.cap1{
          left: 25px !important;
          top: 20px !important;
          zoom: 1.04;
        }
        &.cap2{
          left: 36px !important;
        }
        &.cap5{
          position: absolute !important;
          left: 18px !important;
          top: 22px !important;
        }
        &.cap7{
          top: 60px !important;
        }
        &.cap8{
          top: 15px !important;
        }
        &.cap9{
          left: 40px !important;
          top: 10px !important;
        }
        &.cap10{
          top:120px !important;
          position: absolute;
          left: 20px !important;
          img{
            height: 100px !important;
          }
        }
      }
    }
  }

  .timeLineSection {
    #numberCounter{
      font-size: 15em;
    }
  }
}


@media (min-width: 411px) and (max-width: 413px) {


  .redcircle-layer a i{
    display: none;
  }


  body .fullwidthbanner-container .fullwidthabanner {
    .tp-caption.cap3 {
      left: 50px !important;
    }
    .tp-caption.cap5 {
      left: 45px !important;
      top: 50px !important;
    }
    .tp-caption.cap6 {
      left: 40px !important;
    }
    .tp-caption.cap7 {
      left: 30px !important;
    }
    .tp-caption.cap8 {
      left: 50px !important;
    }
    .tp-caption.cap9 {
      left: 70px !important;
    }
  }
}

@media (max-width:320px){
  body .fullwidthbanner-container{
    height: 100% !important;
    .fullwidthabanner {
      height: 250px !important;

      .tp-caption {
        img {
          width: 320px !important;
          height: 206px !important;
        }
        &.cap9{
          left: 30px !important;
          top:10px !important;
          img{
            width: 320px !important;
            height: 206px !important;
          }
        }
        &.cap10 {
          left:20px !important;
          img {
            width: 300px !important;
            height: 95px !important;
          }
        }
        &.cap5 {
          position: absolute !important;
          left: 0px !important;
        }
      }
    }
  }

}