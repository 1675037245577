
[class^="revicon-"]:before, [class*=" revicon-"]:before {
  font-family: "revicons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  line-height: 1em;

  margin-left: .2em;

}

.tp-static-layers {
  position: absolute;
  z-index: 505;
  top: 0px;
  left: 0px
}

.tp-caption .frontcorner {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 0px solid transparent;
  border-top: 40px solid #00A8FF;
  position: absolute;
  left: -40px;
  top: 0px;
}

.tp-caption .backcorner {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #00A8FF;
  position: absolute;
  right: 0px;
  top: 0px;
}

.tp-caption .frontcornertop {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 40px solid #00A8FF;
  position: absolute;
  left: -40px;
  top: 0px;
}

.tp-caption .backcornertop {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 40px solid transparent;
  border-top: 40px solid #00A8FF;
  position: absolute;
  right: 0px;
  top: 0px;
}

/******************************
	-	WRAPPER STYLES		-
******************************/

.rev_slider_wrapper {
  position: relative;
  background: none !important;

  span.date {
    position: absolute;
    top: 30px;
    left: -13px;
    font-weight: 700;
    font-size: 16px;
  }
}

.rev_slider_wrapper > ul {
  visibility: hidden;
}

.rev_slider {
  position: relative;
  overflow: visible;
}

.rev_slider img {
  max-width: none !important;
  -moz-transition: none 0;
  -webkit-transition: none 0;
  -o-transition: none 0;
  transition: none 0;
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border: none;

}

.rev_slider .no-slides-text {
  font-weight: bold;
  text-align: center;
  padding-top: 80px;
}

.rev_slider ul {
  margin: 0px;
  padding: 0px;
  list-style: none !important;
  list-style-type: none;
  background-position: 0px 0px;
}

.rev_slider ul li,
.rev_slider > ul > li,
.rev_slider > ul > li:before {

  list-style: none !important;
  position: absolute;
  visibility: hidden;
  margin: 0px !important;
  padding: 0px !important;
  overflow-x: visible;
  overflow-y: visible;
  list-style-type: none !important;
  background-image: none;
  background-position: 0px 0px;
  text-indent: 0em;
}

.rev_slider .tp-caption,
.rev_slider .caption {
  position: absolute;
  visibility: hidden;
  white-space: nowrap
}

.tp-simpleresponsive img {
  max-width: none
}

/* ADDED FOR SLIDELINK MANAGEMENT */
.tp-caption {
  z-index: 1
}

.tp_inner_padding {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  max-height: none !important;
}

.tp-hide-revslider, .tp-caption.tp-hidden-caption {
  visibility: hidden !important;
  display: none !important
}

.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
  max-width: none !important
}

/******************************
	-	WRAPPER STYLES END	-
******************************/

/******************************
	-	IE8 HACKS	-
*******************************/
.noFilterClass {
  filter: none !important;
}

/******************************
	-	IE8 HACKS ENDS	-
*******************************/

/********************************
	-	FULLSCREEN VIDEO	-
*********************************/
.caption.fullscreenvideo {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%
}

.caption.fullscreenvideo iframe,
.caption.fullscreenvideo video {
  width: 100% !important;
  height: 100% !important;
  display: none
}

.tp-caption.fullscreenvideo {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%
}

.tp-caption.fullscreenvideo iframe,
.tp-caption.fullscreenvideo iframe video {
  width: 100% !important;
  height: 100% !important;
  display: none
}

.fullcoveredvideo video,
.fullscreenvideo video {
  background: #000
}

.fullcoveredvideo .tp-poster {
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px
}

.html5vid.videoisplaying .tp-poster {
  display: none
}

.tp-video-play-button {
  background: #000;
  background: rgba(0, 0, 0, 0.3);
  padding: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  color: #FFF;
  z-index: 3;
  margin-top: -27px;
  margin-left: -28px;
  text-align: center;
  cursor: pointer;
}

.html5vid .tp-revstop {
  width: 15px;
  height: 20px;
  border-left: 5px solid #fff;
  border-right: 5px solid #fff;
  position: relative;
  margin: 10px 20px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.html5vid .tp-revstop {
  display: none
}

.html5vid.videoisplaying .revicon-right-dir {
  display: none
}

.html5vid.videoisplaying .tp-revstop {
  display: block
}

.html5vid.videoisplaying .tp-video-play-button {
  display: none
}

.html5vid:hover .tp-video-play-button {
  display: block
}

.fullcoveredvideo .tp-video-play-button {
  display: none !important
}

/********************************
	-	FULLSCREEN VIDEO ENDS	-
*********************************/

/********************************
	-	DOTTED OVERLAYS	-
*********************************/
.tp-dottedoverlay {
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3
}

.tp-dottedoverlay.twoxtwo {
  background: url("#{$websitePath}images//gridtile.png")
}

.tp-dottedoverlay.twoxtwowhite {
  background: url("#{$websitePath}images//gridtile_white.png")
}

.tp-dottedoverlay.threexthree {
  background: url("#{$websitePath}images//gridtile_3x3.png")
}

.tp-dottedoverlay.threexthreewhite {
  background: url("#{$websitePath}images//gridtile_3x3_white.png")
}

/********************************
	-	DOTTED OVERLAYS ENDS	-
*********************************/

/**********************************************
	-	FULLSCREEN AND FULLWIDHT CONTAINERS	-
**********************************************/

.fullscreen-container {
  width: 100%;
  position: relative;
  padding: 0;
}

.fullwidthbanner-container {
  width: 100%;
  position: relative;
  padding: 0;
  overflow: hidden;
}

.fullwidthbanner-container .fullwidthabanner {
  width: 100%;
  position: relative;
}

/****************************************************************

	-	SET THE ANIMATION EVEN MORE SMOOTHER ON ANDROID   -

******************************************************************/

.tp-simpleresponsive .slotholder *,
.tp-simpleresponsive img {
  /*-webkit-transform: translateZ(0);
                                       -webkit-backface-visibility: hidden;
                                       -webkit-perspective: 1000;*/
}

/************************************************
	  - SOME CAPTION MODIFICATION AT START  -
*************************************************/
.tp-simpleresponsive .caption,
.tp-simpleresponsive .tp-caption {
  /*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";		-moz-opacity: 0;	-khtml-opacity: 0;	opacity: 0; */
  position: absolute;
  -webkit-font-smoothing: antialiased !important;
}

/******************************
	-	SHADOWS		-
******************************/
.tp-bannershadow {
  position: absolute;

  margin-left: auto;
  margin-right: auto;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tp-bannershadow.tp-shadow1 {
  background: url("#{$websitePath}images//shadow1.png") no-repeat;
  background-size: 100% 100%;
  width: 890px;
  height: 30px;
  bottom: -30px
}

.tp-bannershadow.tp-shadow2 {
  background: url("#{$websitePath}images//shadow2.png") no-repeat;
  background-size: 100% 100%;
  width: 890px;
  height: 60px;
  bottom: -60px
}

.tp-bannershadow.tp-shadow3 {
  background: url("#{$websitePath}images//shadow3.png") no-repeat;
  background-size: 100% 100%;
  width: 890px;
  height: 60px;
  bottom: -60px
}

/******************************
	-	BUTTONS	-
*******************************/

.tp-button {
  padding: 6px 13px 5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  height: 30px;
  cursor: pointer;
  color: #fff !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6) !important;
  font-size: 15px;
  line-height: 45px !important;
  background: url("#{$websitePath}images/gradient/g30.png") repeat-x top;
  font-family: arial, sans-serif;
  font-weight: bold;
  letter-spacing: -1px;
  text-decoration: none;
}

.tp-button.big {
  color: #fff;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
  font-weight: bold;
  padding: 9px 20px;
  font-size: 19px;
  line-height: 57px !important;
  background: url("#{$websitePath}images/gradient/g40.png") repeat-x top
}

.purchase:hover,
.tp-button:hover,
.tp-button.big:hover {
  background-position: bottom, 15px 11px
}

/*@media only screen and (min-width: 480px) and (max-width: 767px) {
    .tp-button	{	padding:4px 8px 3px; line-height:25px !important; font-size:11px !important;font-weight:normal;	}
    a.tp-button { -webkit-transition: none; -moz-transition: none; -o-transition: none; -ms-transition: none;	 }
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
    .tp-button	{	padding:2px 5px 2px; line-height:20px !important; font-size:10px !important}
    a.tp-button { -webkit-transition: none; -moz-transition: none; -o-transition: none; -ms-transition: none;	 }
}*/

/*	BUTTON COLORS	*/

.tp-button.green, .tp-button:hover.green,
.purchase.green, .purchase:hover.green {
  background-color: #21a117;
  -webkit-box-shadow: 0px 3px 0px 0px #104d0b;
  -moz-box-shadow: 0px 3px 0px 0px #104d0b;
  box-shadow: 0px 3px 0px 0px #104d0b;
}

.tp-button.blue, .tp-button:hover.blue,
.purchase.blue, .purchase:hover.blue {
  background-color: #1d78cb;
  -webkit-box-shadow: 0px 3px 0px 0px #0f3e68;
  -moz-box-shadow: 0px 3px 0px 0px #0f3e68;
  box-shadow: 0px 3px 0px 0px #0f3e68
}

.tp-button.red, .tp-button:hover.red,
.purchase.red, .purchase:hover.red {
  background-color: #cb1d1d;
  -webkit-box-shadow: 0px 3px 0px 0px #7c1212;
  -moz-box-shadow: 0px 3px 0px 0px #7c1212;
  box-shadow: 0px 3px 0px 0px #7c1212
}

.tp-button.orange, .tp-button:hover.orange,
.purchase.orange, .purchase:hover.orange {
  background-color: #ff7700;
  -webkit-box-shadow: 0px 3px 0px 0px #a34c00;
  -moz-box-shadow: 0px 3px 0px 0px #a34c00;
  box-shadow: 0px 3px 0px 0px #a34c00
}

.tp-button.darkgrey, .tp-button.grey,
.tp-button:hover.darkgrey, .tp-button:hover.grey,
.purchase.darkgrey, .purchase:hover.darkgrey {
  background-color: #555;
  -webkit-box-shadow: 0px 3px 0px 0px #222;
  -moz-box-shadow: 0px 3px 0px 0px #222;
  box-shadow: 0px 3px 0px 0px #222
}

.tp-button.lightgrey, .tp-button:hover.lightgrey,
.purchase.lightgrey, .purchase:hover.lightgrey {
  background-color: #888;
  -webkit-box-shadow: 0px 3px 0px 0px #555;
  -moz-box-shadow: 0px 3px 0px 0px #555;
  box-shadow: 0px 3px 0px 0px #555
}

/************************
	-	NAVIGATION	-
*************************/

/** BULLETS **/

.tpclear {
  clear: both
}

.tp-bullets {
  z-index: 999;
  position: absolute;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;

  //-webkit-transition: opacity 0.2s ease-out;
  //-moz-transition: opacity 0.2s ease-out;
  //-o-transition: opacity 0.2s ease-out;
  //-ms-transition: opacity 0.2s ease-out;
  //-webkit-transform: translateZ(5px);
  width: 100%;
  left: 0 !important;

  margin-left: initial !important;

  border-bottom: #c4c4c4 1px solid;
  height: 13px !important;
  width: 100% !important;
  transform: none !important;
  white-space:nowrap;
}

.tp-bannertimer {
  display: none;
}

.tp-bullets.navbar {
  border: 1px solid #666;
  border-bottom: 1px solid #444;
  background: url("#{$websitePath}images/boxed_bgtile.png");
  height: em(40);
  padding: 0px em(10);
  @include borderRadius(5px);
}

.tp-bullets.navbar-old {
  background: url("#{$websitePath}images//navigdots_bgtile.png");
  height: em(35);
  padding: 0px em(10);
  @include borderRadius(5px);
}

.tp-bullets.round .tp-bullet.last {
  margin-right: em(3)
}

.tp-bullets.round-old .tp-bullet {
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}images//bullets.png") no-repeat bottom left;
  width: 23px;
  height: 23px;
  margin-right: 0px;
  float: left;
  margin-top: 0px
}

.tp-bullets.round-old .tp-bullet.last {
  margin-right: 0px
}

/**	SQUARE BULLETS **/
.tp-bullets.square .tp-bullet {
  cursor: pointer;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}images//bullets2.png") no-repeat bottom left;
  width: 19px;
  height: 19px;
  margin-right: 0px;
  float: left;
  margin-top: 0px
}

.tp-bullets.square .tp-bullet.last {
  margin-right: 0px
}

/**	SQUARE BULLETS **/
.tp-bullets.square-old .tp-bullet {
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}images//bullets2.png") no-repeat bottom left;
  width: 19px;
  height: 19px;
  margin-right: 0px;
  float: left;
  margin-top: 0px
}

.tp-bullets.square-old .tp-bullet.last {
  margin-right: 0px
}

/** navbar NAVIGATION VERSION **/
.tp-bullets.navbar .tp-bullet {
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}images//bullet_boxed.png") no-repeat top left;
  width: 18px;
  height: 19px;
  margin-right: 5px;
  float: left;
  margin-top: 10px
}

.tp-bullets.navbar .tp-bullet.first {
  margin-left: 0px !important
}

.tp-bullets.navbar .tp-bullet.last {
  margin-right: 0px !important
}

/** navbar NAVIGATION VERSION **/
.tp-bullets.navbar-old .tp-bullet {
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}images//navigdots.png") no-repeat bottom left;
  width: 15px;
  height: 15px;
  margin-left: 5px !important;
  margin-right: 5px !important;
  float: left;
  margin-top: 10px
}

.tp-bullets.navbar-old .tp-bullet.first {
  margin-left: 0px !important
}

.tp-bullets.navbar-old .tp-bullet.last {
  margin-right: 0px !important
}

.tp-bullets .tp-bullet:hover,
.tp-bullets .tp-bullet.selected {
  background-position: top left;
}

.tp-bullets.round .tp-bullet:hover,
.tp-bullets.round .tp-bullet.selected,
.tp-bullets.navbar .tp-bullet:hover,
.tp-bullets.navbar .tp-bullet.selected {
  background-position: bottom left;
}

/*************************************
	-	TP ARROWS 	-
**************************************/
.tparrows {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  -ms-transition: opacity 0.2s ease-out;
  -webkit-transform: translateZ(1000px);
  -webkit-transform-style: preserve-3d;

}

.tparrows .tp-arr-imgholder {
  display: none
}

.tparrows .tp-arr-titleholder {
  display: none
}

.tp-leftarrow {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/large_left.png") no-repeat top left;
  width: 40px;
  height: 40px;
}

.tp-rightarrow {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/large_right.png") no-repeat top left;
  width: 40px;
  height: 40px;
}

.tp-leftarrow.round {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/small_left.png") no-repeat top left;
  width: 19px;
  height: 14px;
  margin-right: 0px;
  float: left;
  margin-top: 0px;
}

.tp-rightarrow.round {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/small_right.png") no-repeat top left;
  width: 19px;
  height: 14px;
  margin-right: 0px;
  float: left;
  margin-top: 0px
}

.tp-leftarrow.round-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrow_left.png") no-repeat top left;
  width: 26px;
  height: 26px;
  margin-right: 0px;
  float: left;
  margin-top: 0px;
}

.tp-rightarrow.round-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrow_right.png") no-repeat top left;
  width: 26px;
  height: 26px;
  margin-right: 0px;
  float: left;
  margin-top: 0px
}

.tp-leftarrow.navbar {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/small_left_boxed.png") no-repeat top left;
  width: 20px;
  height: 15px;
  float: left;
  margin-right: 6px;
  margin-top: 12px
}

.tp-rightarrow.navbar {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/small_right_boxed.png") no-repeat top left;
  width: 20px;
  height: 15px;
  float: left;
  margin-left: 6px;
  margin-top: 12px
}

.tp-leftarrow.navbar-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrowleft.png") no-repeat top left;
  width: 9px;
  height: 16px;
  float: left;
  margin-right: 6px;
  margin-top: 10px
}

.tp-rightarrow.navbar-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrowright.png") no-repeat top left;
  width: 9px;
  height: 16px;
  float: left;
  margin-left: 6px;
  margin-top: 10px
}

.tp-leftarrow.navbar-old.thumbswitharrow {
  margin-right: 10px;
}

.tp-rightarrow.navbar-old.thumbswitharrow {
  margin-left: 0px;
}

.tp-leftarrow.square {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrow_left2.png") no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-right: 0px;
  margin-top: 0px
}

.tp-rightarrow.square {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrow_right2.png") no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-left: 0px;
  margin-top: 0px
}

.tp-leftarrow.square-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrow_left2.png") no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-right: 0px;
  margin-top: 0px
}

.tp-rightarrow.square-old {
  z-index: 100;
  cursor: pointer;
  position: relative;
  background: url("#{$websitePath}assets/images/arrow_right2.png") no-repeat top left;
  width: 12px;
  height: 17px;
  float: left;
  margin-left: 0px;
  margin-top: 0px
}

.tp-leftarrow {
  visibility: hidden;
  z-index: 100;
  cursor: pointer;
  top: 44%;
  left: 30px;
  position: absolute;
  background: rgba(#b8b8b8, 0.25);
  width: em(122);
  height: em(40);
  @include borderRadius(em(30));
  text-transform: uppercase;
  font-weight: 700;
  padding: em(11) em(25);
  text-align: right;
  @include transitions(0.5s);
  color: #323232;
  margin-top: -14px;
  &:after {
    font-family: "honda" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\63";
    position: absolute;
    left: em(10);
    top: em(5);
    font-size: em(26);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    color: $brand-primary;
    @include transitions(0.5s);
  }
  &:before {
    box-shadow: 0 0 0 2px #ccc;
    position: absolute;
    left: em(-8);
    top: em(-8);
    content: '';
    width: em(138);
    height: em(56);
    @include borderRadius(em(50));
    @include scale(1.1);
    @include transitions(0.5s);
    opacity: 0;
  }
}

.tp-explore {
  opacity: 0;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.tp-rightarrow {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  background: rgba(#b8b8b8, 0.25);
  width: em(128);
  height: em(40);
  @include borderRadius(em(30));
  text-transform: uppercase;
  font-weight: 700;
  padding: em(11) em(25);
  @include transitions(0.5s);
  color: #323232;
  display: block;
  opacity: 0;
  top: 44%;
  right: 30px;
  margin-top: -14px;

  &:after {
    font-family: "honda" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\63";
    position: absolute;
    right: em(10);
    top: em(5);
    font-size: em(26);
    color: $brand-primary;
    @include transitions(0.5s);
  }
  &:before {
    box-shadow: 0 0 0 2px #ccc;
    position: absolute;
    left: em(-8);
    top: em(-8);
    content: '';
    width: em(144);
    height: em(56);
    @include borderRadius(em(50));
    @include scale(1.1);
    @include transitions(0.5s);
    opacity: 0;
  }
}

.gecko.desktop{
  .tp-rightarrow, .tp-leftarrow {
    &:after {
      font-size: 26px;
    }
  }
}
.tp-explore {
  z-index: 100;
  position: absolute;
  width: em(122);
  height: em(40);

  a {
    @include transitions(0.5s);
    color: #323232;
    padding: .5625em 1.5625em;
    @include borderRadius(em(30));
    text-transform: uppercase;
    font-weight: 700;
    background: rgba(#b8b8b8, 0.25);
    display: block;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.tp-leftarrow:hover,
.tp-rightarrow:hover {
  background-position: bottom left;
}

/****************************************************************************************************
	-	TP THUMBS 	-
*****************************************************************************************************

 - tp-thumbs & tp-mask Width is the width of the basic Thumb Container (500px basic settings)

 - .tp-bullet width & height is the dimension of a simple Thumbnail (basic 100px x 50px)

 *****************************************************************************************************/

.tp-bullets.tp-thumbs {
  z-index: 1000;
  position: absolute;
  padding: 3px;
  background-color: #fff;
  width: 500px;
  height: 50px; /* THE DIMENSIONS OF THE THUMB CONTAINER */
  margin-top: -50px;
  vertical-align: top;
}

.tp-bullets.tp-thumbs img {
  vertical-align: top
}

.fullwidthbanner-container .tp-thumbs {
  padding: 3px
}

.tp-bullets.tp-thumbs .tp-mask {
  width: 500px;
  height: 50px; /* THE DIMENSIONS OF THE THUMB CONTAINER */
  overflow: hidden;
  position: relative
}

.tp-bullets.tp-thumbs .tp-mask .tp-thumbcontainer {
  width: 5000px;
  position: absolute
}

.tp-bullets.tp-thumbs .tp-bullet {
  width: 100px;
  height: 50px; /* THE DIMENSION OF A SINGLE THUMB */
  cursor: pointer;
  overflow: hidden;
  background: none;
  margin: 0;
  float: left;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  /*filter: alpha(opacity=50);	*/
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;

  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
}

.tp-bullets.tp-thumbs .tp-bullet:hover,
.tp-bullets.tp-thumbs .tp-bullet.selected {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.tp-thumbs img {
  width: 100%;
}

/*****************************************
	-	NAVIGATION FANCY EXAMPLES	-
*****************************************/

/* NAVIGATION PREVIEW 1 */
.tparrows.preview1 {
  width: 100px;
  height: 100px;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  background: transparent
}

.tparrows.preview1:after {
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: "revicons";
  color: #fff;
  font-size: 30px;
  width: 100px;
  height: 100px;
  text-align: center;
  background: #fff;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  line-height: 100px;
  -webkit-transition: background 0.3s, color 0.3s;
  -moz-transition: background 0.3s, color 0.3s;
  transition: background 0.3s, color 0.3s
}

.tp-rightarrow.preview1:after {
  content: '\e825';
}

.tp-leftarrow.preview1:after {
  content: '\e824';
}

.tparrows.preview1:hover:after {
  background: rgba(255, 255, 255, 1);
  color: #aaa;
}

.tparrows.preview1 .tp-arr-imgholder {
  background-size: cover;
  background-position: center center;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*.tparrows.preview1 .tp-arr-iwrapper			{	  -webkit-transition: all 0.3s;transition: all 0.3s;
												-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";filter: alpha(opacity=0);-moz-opacity: 0.0;-khtml-opacity: 0.0;opacity: 0.0}
.tparrows.preview1:hover .tp-arr-iwrapper	{	  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";filter: alpha(opacity=100);-moz-opacity: 1;-khtml-opacity: 1;opacity: 1}*/

.tp-rightarrow.preview1 .tp-arr-imgholder {
  right: 100%;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0.0;
  -khtml-opacity: 0.0;
  opacity: 0.0;

}

.tp-leftarrow.preview1 .tp-arr-imgholder {
  left: 100%;
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0.0;
  -khtml-opacity: 0.0;
  opacity: 0.0;

}

.tparrows.preview1:hover .tp-arr-imgholder {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;

}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .tparrows.preview1,
  .tparrows.preview1:after {
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 24px
  }

}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .tparrows.preview1,
  .tparrows.preview1:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 20px
  }

}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .tparrows.preview1,
  .tparrows.preview1:after {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 12px
  }
}

/* PREVIEW 1 BULLETS */

.tp-bullets.preview1 {
  height: 21px;
}

.tp-bullets.preview1 .tp-bullet {
  cursor: pointer;
  position: relative !important;
  background: rgba(0, 0, 0, 0.15) !important;
  /*-webkit-border-radius: 10px;
  border-radius: 10px;*/
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 5px !important;
  height: 5px !important;
  border: 8px solid rgba(0, 0, 0, 0) !important;
  display: inline-block;
  margin-right: 5px !important;
  margin-bottom: 0px !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  -moz-transition: background-color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s;
  -ms-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
  float: none !important;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.tp-bullets.preview1 .tp-bullet.last {
  margin-right: 0px;
}

.tp-bullets.preview1 .tp-bullet:hover,
.tp-bullets.preview1 .tp-bullet.selected {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #aaa !important;
  width: 5px !important;
  height: 5px !important;
  border: 8px solid rgba(255, 255, 255, 1) !important;
}

/* NAVIGATION PREVIEW 2 */
.tparrows.preview2 {
  min-width: 60px;
  min-height: 60px;
  background: #fff;;

  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  overflow: hidden;
  -webkit-transition: -webkit-transform 1.3s;
  -webkit-transition: width 0.3s, background-color 0.3s, opacity 0.3s;
  transition: width 0.3s, background-color 0.3s, opacity 0.3s;
  backface-visibility: hidden;
}

.tparrows.preview2:after {
  position: absolute;
  top: 50%;
  font-family: "revicons";
  color: #aaa;
  font-size: 25px;
  margin-top: -12px;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s
}

.tp-rightarrow.preview2:after {
  content: '\e81e';
  right: 18px;
}

.tp-leftarrow.preview2:after {
  content: '\e81f';
  left: 18px;
}

.tparrows.preview2 .tp-arr-titleholder {
  background-size: cover;
  background-position: center center;
  display: block;
  visibility: hidden;
  position: relative;
  top: 0px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  white-space: nowrap;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 60px;
  padding: 0px 10px;
}

.tp-rightarrow.preview2 .tp-arr-titleholder {
  right: 50px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.tp-leftarrow.preview2 .tp-arr-titleholder {
  left: 50px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.tparrows.preview2.hovered {
  width: 300px
}

.tparrows.preview2:hover {
  background: #fff
}

.tparrows.preview2:hover:after {
  color: #000
}

.tparrows.preview2:hover .tp-arr-titleholder {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  visibility: visible;
  position: absolute;
}

/* PREVIEW 2 BULLETS */

.tp-bullets.preview2 {
  height: 17px;
}

.tp-bullets.preview2 .tp-bullet {
  cursor: pointer;
  position: relative !important;
  background: rgba(0, 0, 0, 0.5) !important;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 6px !important;
  height: 6px !important;
  border: 5px solid rgba(0, 0, 0, 0) !important;
  display: inline-block;
  margin-right: 2px !important;
  margin-bottom: 0px !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  -moz-transition: background-color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s;
  -ms-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
  float: none !important;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.tp-bullets.preview2 .tp-bullet.last {
  margin-right: 0px;
}

.tp-bullets.preview2 .tp-bullet:hover,
.tp-bullets.preview2 .tp-bullet.selected {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: rgba(255, 255, 255, 1) !important;
  width: 6px !important;
  height: 6px !important;
  border: 5px solid rgba(0, 0, 0, 1) !important;
}

.tp-arr-titleholder.alwayshidden {
  display: none !important
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .tparrows.preview2 {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
  }
  .tparrows.preview2:after {
    position: absolute;
    top: 50%;
    font-family: "revicons";
    font-size: 20px;
    margin-top: -12px
  }
  .tp-rightarrow.preview2:after {
    content: '\e81e';
    right: 11px
  }
  .tp-leftarrow.preview2:after {
    content: '\e81f';
    left: 11px
  }
  .tparrows.preview2 .tp-arr-titleholder {
    font-size: 12px;
    line-height: 40px;
    letter-spacing: 0px
  }
  .tp-rightarrow.preview2 .tp-arr-titleholder {
    right: 35px
  }
  .tp-leftarrow.preview2 .tp-arr-titleholder {
    left: 35px
  }

}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .tparrows.preview2 {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
  }
  .tparrows.preview2:after {
    position: absolute;
    top: 50%;
    font-family: "revicons";
    font-size: 14px;
    margin-top: -12px
  }
  .tp-rightarrow.preview2:after {
    content: '\e81e';
    right: 8px
  }
  .tp-leftarrow.preview2:after {
    content: '\e81f';
    left: 8px
  }
  .tparrows.preview2 .tp-arr-titleholder {
    font-size: 10px;
    line-height: 30px;
    letter-spacing: 0px
  }
  .tp-rightarrow.preview2 .tp-arr-titleholder {
    right: 25px
  }
  .tp-leftarrow.preview2 .tp-arr-titleholder {
    left: 25px
  }
  .tparrows.preview2 .tp-arr-titleholder {
    display: none;
    visibility: none
  }

}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .tparrows.preview2 {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
  }
  .tparrows.preview2:after {
    position: absolute;
    top: 50%;
    font-family: "revicons";
    font-size: 14px;
    margin-top: -12px
  }
  .tp-rightarrow.preview2:after {
    content: '\e81e';
    right: 8px
  }
  .tp-leftarrow.preview2:after {
    content: '\e81f';
    left: 8px
  }
  .tparrows.preview2 .tp-arr-titleholder {
    display: none;
    visibility: none
  }
  .tparrows.preview2:hover {
    width: 30px !important;
    height: 30px !important
  }
}

/* NAVIGATION PREVIEW 3 */
.tparrows.preview3 {
  width: 70px;
  height: 70px;
  background: #fff;
  background: rgba(255, 255, 255, 1);
  -webkit-transform-style: flat;
}

.tparrows.preview3:after {
  position: absolute;
  line-height: 70px;
  text-align: center;
  font-family: "revicons";
  color: #aaa;
  font-size: 30px;
  top: 0px;
  left: 0px;;
  background: #fff;
  z-index: 100;
  width: 70px;
  height: 70px;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s
}

.tparrows.preview3:hover:after {
  color: #000;
}

.tp-rightarrow.preview3:after {
  content: '\e825';
}

.tp-leftarrow.preview3:after {
  content: '\e824';
}

.tparrows.preview3 .tp-arr-iwrapper {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  z-index: 0;
  position: absolute;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  display: table;
  min-height: 90px;
  top: -10px
}

.tp-leftarrow.preview3 .tp-arr-iwrapper {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.tparrows.preview3 .tp-arr-imgholder {
  display: block;
  background-size: cover;
  background-position: center center;
  display: table-cell;
  min-width: 90px;
  height: 90px;
  position: relative;
  top: 0px;
}

.tp-rightarrow.preview3 .tp-arr-iwrapper {
  right: 0px;
  padding-right: 70px
}

.tp-leftarrow.preview3 .tp-arr-iwrapper {
  left: 0px;
  direction: rtl;
  padding-left: 70px
}

.tparrows.preview3 .tp-arr-titleholder {
  display: table-cell;
  padding: 30px;
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  position: relative;
  clear: right;
  vertical-align: middle
}

.tparrows.preview3:hover .tp-arr-iwrapper {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);

}

/* PREVIEW 3 BULLETS */
.tp-bullets.preview3 {
  height: 17px;
}

.tp-bullets.preview3 .tp-bullet {
  cursor: pointer;
  position: relative !important;
  background: rgba(0, 0, 0, 0.5) !important;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 6px !important;
  height: 6px !important;
  border: 5px solid rgba(0, 0, 0, 0) !important;
  display: inline-block;
  margin-right: 2px !important;
  margin-bottom: 0px !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  -moz-transition: background-color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s;
  -ms-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
  float: none !important;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.tp-bullets.preview3 .tp-bullet.last {
  margin-right: 0px;
}

.tp-bullets.preview3 .tp-bullet:hover,
.tp-bullets.preview3 .tp-bullet.selected {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: rgba(255, 255, 255, 1) !important;
  width: 6px !important;
  height: 6px !important;
  border: 5px solid rgba(0, 0, 0, 1) !important;
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  .tparrows.preview3:after,
  .tparrows.preview3 {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px
  }
  .tparrows.preview3 .tp-arr-iwrapper {
    min-height: 70px
  }
  .tparrows.preview3 .tp-arr-imgholder {
    min-width: 70px;
    height: 70px
  }
  .tp-rightarrow.preview3 .tp-arr-iwrapper {
    padding-right: 50px
  }
  .tp-leftarrow.preview3 .tp-arr-iwrapper {
    padding-left: 50px
  }
  .tparrows.preview3 .tp-arr-titleholder {
    padding: 10px;
    font-size: 16px;
  }

}

@media only screen and (max-width: 767px) {

  .tparrows.preview3:after,
  .tparrows.preview3 {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px
  }
  .tparrows.preview3 .tp-arr-iwrapper {
    min-height: 70px
  }
}

/* NAVIGATION PREVIEW 4 */
.tparrows.preview4 {
  width: 30px;
  height: 110px;
  background: transparent;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000;
  -moz-perspective: 1000
}

.tparrows.preview4:after {
  position: absolute;
  line-height: 110px;
  text-align: center;
  font-family: "revicons";
  color: #fff;
  font-size: 20px;
  top: 0px;
  left: 0px;
  z-index: 0;
  width: 30px;
  height: 110px;
  background: #000;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;

}

.tp-rightarrow.preview4:after {
  content: '\e825';
}

.tp-leftarrow.preview4:after {
  content: '\e824';
}

.tparrows.preview4 .tp-arr-allwrapper {
  visibility: hidden;
  width: 180px;
  position: absolute;
  z-index: 1;
  min-height: 120px;
  top: 0px;
  left: -150px;
  overflow: hidden;
  -webkit-perspective: 1000px;
  -webkit-transform-style: flat
}

.tp-leftarrow.preview4 .tp-arr-allwrapper {
  left: 0px
}

.tparrows.preview4 .tp-arr-iwrapper {
  position: relative
}

.tparrows.preview4 .tp-arr-imgholder {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 180px;
  height: 110px;
  position: relative;
  top: 0px;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}

.tparrows.preview4 .tp-arr-imgholder2 {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 180px;
  height: 110px;
  position: absolute;
  top: 0px;
  left: 180px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}

.tp-leftarrow.preview4 .tp-arr-imgholder2 {
  left: -180px
}

.tparrows.preview4 .tp-arr-titleholder {
  display: block;
  font-size: 12px;
  line-height: 25px;
  padding: 0px 10px;
  text-align: left;
  color: #fff;
  position: relative;
  background: #000;
  color: #FFF;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 11px;
  line-height: 2.75;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0.0;
  -khtml-opacity: 0.0;
  opacity: 0.0;

}

.tparrows.preview4:after {
  transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%
}

.tp-leftarrow.preview4:after {
  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%
}

@media only screen and (min-width: 768px) {
  .tparrows.preview4:hover:after {
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg)
  }
  .tp-leftarrow.preview4:hover:after {
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg)
  }

  .tparrows.preview4:hover .tp-arr-titleholder {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;

  }
}

/* PREVIEW 4 BULLETS */

.tp-bullets.preview4 {
  height: 17px;
}

.tp-bullets.preview4 .tp-bullet {
  cursor: pointer;
  position: relative !important;
  background: rgba(0, 0, 0, 0.5) !important;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 6px !important;
  height: 6px !important;
  border: 5px solid rgba(0, 0, 0, 0) !important;
  display: inline-block;
  margin-right: 2px !important;
  margin-bottom: 0px !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  -moz-transition: background-color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s;
  -ms-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
  float: none !important;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.tp-bullets.preview4 .tp-bullet.last {
  margin-right: 0px;
}

.tp-bullets.preview4 .tp-bullet:hover,
.tp-bullets.preview4 .tp-bullet.selected {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: rgba(255, 255, 255, 1) !important;
  width: 6px !important;
  height: 6px !important;
  border: 5px solid rgba(0, 0, 0, 1) !important;
}

@media only screen and (max-width: 767px) {
  .tparrows.preview4 {
    width: 20px;
    height: 80px
  }
  .tparrows.preview4:after {
    width: 20px;
    height: 80px;
    line-height: 80px;
    font-size: 14px
  }

  .tparrows.preview1 .tp-arr-allwrapper,
  .tparrows.preview2 .tp-arr-allwrapper,
  .tparrows.preview3 .tp-arr-allwrapper,
  .tparrows.preview4 .tp-arr-allwrapper {
    display: none !important
  }
}

/***************************************
	-	RESPONSIVE SETTINGS 	-
****************************************/

/*********************************************

	-	BASIC SETTINGS FOR THE BANNER	-

***********************************************/

.tp-simpleresponsive img {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tp-caption img {
  background: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
  zoom: 1;
  height: 85vh !important;
  object-fit: contain;
}

.tp-simpleresponsive ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tp-simpleresponsive > ul li {
  list-style: none;
  position: absolute;
  visibility: hidden;
}

/*  CAPTION SLIDELINK   **/
.caption.slidelink a div,
.tp-caption.slidelink a div {
  width: 3000px;
  height: 1500px;
  background: url("#{$websitePath}images//coloredbg.png") repeat
}

.tp-caption.slidelink a span {
  background: url("#{$websitePath}images//coloredbg.png") repeat
}

/******************************
	-	LOADER FORMS	-
********************************/

.tp-loader {
  top: 50%;
  left: 50%;
  z-index: 10000;
  position: absolute;

}

.tp-loader.spinner0 {
  width: 40px;
  height: 40px;
  background: url("#{$websitePath}images//loader.gif") no-repeat center center;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.tp-loader.spinner1 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
  animation: tp-rotateplane 1.2s infinite ease-in-out;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.tp-loader.spinner5 {
  background: url("#{$websitePath}images//loader.gif") no-repeat 10px 10px;
  background-color: #fff;
  margin: -22px -22px;
  width: 44px;
  height: 44px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

@-webkit-keyframes tp-rotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes tp-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.tp-loader.spinner2 {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  background-color: #ff0000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  -webkit-animation: tp-scaleout 1.0s infinite ease-in-out;
  animation: tp-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes tp-scaleout {
  0% {
    -webkit-transform: scale(0.0)
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes tp-scaleout {
  0% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

.tp-loader.spinner3 {
  margin: -9px 0px 0px -35px;
  width: 70px;
  text-align: center;

}

.tp-loader.spinner3 .bounce1,
.tp-loader.spinner3 .bounce2,
.tp-loader.spinner3 .bounce3 {
  width: 18px;
  height: 18px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: tp-bouncedelay 1.4s infinite ease-in-out;
  animation: tp-bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.tp-loader.spinner3 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.tp-loader.spinner3 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes tp-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes tp-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.tp-loader.spinner4 {
  margin: -20px 0px 0px -20px;
  width: 40px;
  height: 40px;
  text-align: center;
  -webkit-animation: tp-rotate 2.0s infinite linear;
  animation: tp-rotate 2.0s infinite linear;
}

.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: tp-bounce 2.0s infinite ease-in-out;
  animation: tp-bounce 2.0s infinite ease-in-out;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.tp-loader.spinner4 .dot2 {
  top: auto;
  bottom: 0px;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes tp-rotate {
  100% {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes tp-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg)
  }
}

@-webkit-keyframes tp-bounce {
  0%, 100% {
    -webkit-transform: scale(0.0)
  }
  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes tp-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.tp-bullets.simplebullets.round .tp-bullet {
  width: em(29);
  height: em(29);
  margin-left: 9%;
  @include borderRadius(50%);
  cursor: pointer;
  position: relative;
  display: inline-block;
  top: em(-2);
  background: transparent;
  left: 50%;
  border: solid 1px transparent;
  @include transitions(0.5s);
  &:before {
    background: $brand-black;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    right: 0;
    bottom: 0;
    width: em(7);
    height: em(7);
    @include borderRadius(50%);
    border: solid 2px $brand-white;
    @include transitions(0.5s);
  }
  &:after {
    position: absolute;
    content: attr(data-year);
    top: em(29);
    left: 0;
    font-weight: 600;
    font-size: em(17);
    padding-top: em(15);
    color: #8f8f8f;
    @include transitions(0.5s);
  }
  &:first-child {
    margin: 0 !important;
  }
  &.selected {
    background: $brand-white;
    border: solid 1px $brand-primary;
    &:before {
      background: $brand-primary;
      border: solid 2px $brand-primary;
    }
    &:after {
      color: $brand-black;
    }
  }
}

.tp-bullets.round .tp-bullet {
  width: em(29);
  height: em(29);
  margin-left: 9%;
  @include borderRadius(50%);
  cursor: pointer;
  position: relative;
  display: inline-block;
  top: em(-2);
  background: transparent;
  left: 50%;
  border: solid 1px transparent;
  @include transitions(0.5s);
  &:before {
    background: $brand-black;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    right: 0;
    bottom: 0;
    width: em(7);
    height: em(7);
    @include borderRadius(50%);
    //border: solid 2px $brand-white;
    @include transitions(0.5s);
  }
  &:after {
    position: absolute;
    content: attr(data-year);
    top: em(29);
    left: 0;
    font-weight: 600;
    font-size: em(17);
    padding-top: em(15);
    color: #8f8f8f;
    @include transitions(0.5s);
  }
  &:first-child {
    margin: 0 !important;
  }
  &.selected {
    background: $brand-white;
    border: solid 1px $brand-primary;
    &:before {
      background: $brand-primary;
      border: solid 2px $brand-primary;
    }
    &:after {
      color: $brand-black;
    }
  }
}

.events-line {
  width: em(4);
  height: em(1);
  background: $brand-black;
  display: block;
  position: absolute;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  min-width: em(4);
  left: em(13);
  top: 13px;
  @include borderRadius(50%);
  z-index: 0;
}

@media (min-width: 1601px) and (max-width: 2400px) {
}

@media (min-width: 1530px) and (max-width: 1600px) {
}

@media (min-width: 1330px) and (max-width: 1366px) {

  .tp-caption img
    {
      height: 70vh !important;
      zoom:1;
    }
  .tp-bullets.round .tp-bullet {
    top: em(4);
  }
}

@media (min-width: 1250px) and (max-width: 1280px) {
  .tp-caption img {
    height: 100vh !important;
    zoom: 0.7 !important;
  }
}

@media (min-width: 1280px) and (max-width: 1330px) {

  .tp-caption img
  {
    zoom:0.75;
  }
  .tp-bullets.round .tp-bullet {
    top: em(4);
  }
}

@media (min-width: 1201px) and (max-width: 1280px) {

  .car_logo{
    img{
      margin-top: -235px;
      width: 160px;
    }
  }
}

// Laptops small devices (desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .events-line{
    top: 8px;
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .tp-bullets.round .tp-bullet {

    &.disable {
      cursor: auto;
      display: none;
      &:hover {
        background: none;
        border-color: transparent;
        &:after {
          color: #8f8f8f !important;
        }
      }
    }
    &:hover {
      background: $brand-white;
      border: solid 1px $brand-primary;
      &:before {
        @include scale(1);
        opacity: 1;
      }
      &:after {
        color: $brand-black;
      }
    }
  }
  .tp-rightarrow, .tp-leftarrow {
    &:hover {
      background: $brand-primary;
      color: $brand-white;
      &:before {
        @include scale(1);
        opacity: 1;
      }
      &:after {
        color: $brand-white;
      }
    }
  }
  .tp-explore {
    a {
      &:hover {
        background: $brand-primary;
        color: $brand-white;
      }
    }
  }
}





/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {


  .myclassrevlolution_0{
    left: 40px !important;
  }
  .myclassrevlolution_1{
    left: 40px !important;
  }
  .myclassrevlolution_2{
    left: 40px !important;
  }
  .myclassrevlolution_3{
    left: 43px !important;
    img{
      height: 266px !important;
    }
  }
  .myclassrevlolution_4{
    left: 777px !important;
  }


}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {




  .fullscreen-container, #landing_rev_slider_forcefullwidth {
    height: 50vh !important;
  }


  .myclassrevlolution_0{
    left: 40px !important;
  }
  .myclassrevlolution_1{
    left: 40px !important;
  }
  .myclassrevlolution_2{
    left: 40px !important;
  }
  .myclassrevlolution_3{
    left: -160px !important;
    margin-top: -20px !important;
    img{
      height: 256px !important;
    }
  }
  .myclassrevlolution_4{
    margin-top: 73px;
  }

  .mainSlider .tp-bullets{
    left: 40px !important;
    margin-top: 12% !important;
    &.bullets{
      margin-top: 10% !important;
    }
  }




}

/* Landscape phones and portrait tablets */
@media (min-width: 481px) and  (max-width: 767px) {

  .myclassrevlolution_0 {
    left: 40px !important;
  }
  .myclassrevlolution_1 {
    left: 40px !important;
  }
  .myclassrevlolution_2 {
    left: 40px !important;
  }

  .myclassrevlolution_4 {
    margin-top: 19px;
    right: 20% !important;
    left: inherit !important;
  }



}
@media  (max-width: 767px) {

  .mainSlider .tp-bullets {

  }

  .fullscreen-container {
    margin-bottom: em(100);
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

  .myclassrevlolution_1{
    display: none;
  }

  #landing_rev_slider p{
    font-size: 16px !important;
    width: 50% !important;
    word-wrap: break-word !important;
  }
.description{
  float: left;
  width: 100%;
  a{
    margin-bottom: 10px;
    float: left;
  }
}

  .car_logo {
    img{
      width: 80px !important;
    }
  }

  #landing_rev_slider h1{
    letter-spacing: 0px !important;
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}
