

.desktop_contact{ display: block !important;}
.mobile_contact{display: none !important;}
.contact-dealer {

  &.inner-banner {

    h2 {
      font-size: em(44) !important;
    }
    .mobile-banner {
      display: none;
    }

  }

}



/* category-pane start */
.category-pane{
  margin: 0px auto;
  text-align: center;
  position: relative;
  &:before{
    content: "";
    height: 1px;
    width: 32%;
    background: #e5e5e5;
    float: left;
    position: absolute;
    left: 0;
    top:26px;
  }
  &:after{
    content: "";
    height: 1px;
    width: 32%;
    background: #e5e5e5;
    float: right;
    position: absolute;
    right: 0;
    top:26px;
  }
  .category-dropdown{
    float: none;
    margin: 0px auto;
    .form-control{
      font-size: 16px;
      height: 53px;
      border-radius: 0;
      &.bootstrap-select.btn-group
      {
        .btn {
          height: 52px;
          .caret{
            top:em(24);
            right:em(24);
            &:before{
              font-size: em(14);
            }
          }
          .filter-option{
            color: #1d1d1d;
            text-transform: none;
            font-size: em(24);
            font-weight: normal;
          }
        }
      }
    }
  }
}
/* category-pane end */


.contact-map-info {
  padding: em(70) 0 0  0;
  img {
    max-width: 100%;
  }
  h3 {
    font-size: em(20);
    color: #000000;
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: em(2);
    margin-bottom: em(16)
  }

  .autoList {
    margin-top: em(32);
    margin-bottom: 0px;
    li {

      h4 {
        font-family: "univers", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: em(20);
      }
      .span {
        color: #000;
        font-family: "univers", "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-transform: none;
        font-weight: 500;
        margin-top: em(15);

        a {
          color: #000;
        }

      }

      img {
        width: 70%
      }

      i {
        top: em(-8);
      }

    }
  }

  .hour-table {
    border: 1px solid #e5e5e5;
    float: left;
    width: 100%;
    color: #000;
    margin-top: 0;
    .head {
      font-size: em(20);
      background: #f3f6fa;
      padding: em(7) em(17);
      letter-spacing: em(2);
      font-weight: 700;
      text-transform: uppercase;
    }

    .col-sec-one {
      width: 50%;
      float: left;
    }
    .col-sec-two {
      width: 50%;
      float: left;
    }

    .col-sec {
      width: 100%;
      float: left;
      box-sizing: border-box;
      padding: em(15) em(20);
      border: 1px solid #e5e5e5;
      border-right: 0;
      min-height: em(53);
      color: #000;
      font-size: em(14);
      border-bottom: 0;

      span {
        width: 62%;
        display: inline-block;
        &.day {
          width: 34%;
        }
      }

      h4 {

        font-size: em(18);
        color: #000;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: em(1);
        margin: 0;

      }
    }

  }

}

.show-room-sec {

  padding: em(80) 0;
  h2 {

    font-size: em(32);
    text-transform: uppercase;
    font-weight: 700;

  }
  hr {
    background: #cc0000;
    width: em(71);
    height: em(4);
    float: left;
  }
  .viewallbtn {

    text-align: right;
    margin-top: em(15);
    .moreLink{
      font-size: em(16);
      /*padding: em(3) em(40) em(3) 0;*/
      padding: em(3) em(40) em(3) em(5);
      position: relative;
      font-weight: 700;
      color: $brand-primary;
      @include transitions(0.5s);
      overflow: hidden;
      display: inline-block;

      line-height: em(16);
      i{
        position: absolute;
        right:em(3);
        top: em(1);
        font-size: em(24);
        z-index: 99;
      }
      span{
        position: relative;
        z-index: 99;
        left: 0;
        color: $brand-primary;
        @include transition(0.5s);
      }
      &:hover, &:focus{
        text-decoration: none;
      }
      &:before{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 0;
        z-index: 1;
        content: '';
        background: lighten($brand-primary, 10%);
        -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      }
    }

  }

  //.viewallbtn {
  //
  //  text-align: right;
  //  margin-top: em(10);
  //
  //  .btn {
  //
  //    width: em(180);
  //    padding-right: 0;
  //
  //    i {
  //      right: 0;
  //    }
  //
  //    &:active {
  //      box-shadow: none;
  //    }
  //
  //  }
  //
  //}

  .box {

    border: 1px solid #dddddd;
    padding: em(15) em(25);
    display: table;
    width: 100%;
    border-top: 0;
    min-height: em(147);

    &.firstb {
      border-top: 1px solid #dddddd;
    }
    &.darkgrid {
      background: #fbfbfb;
    }

    h4 {
      font-size: em(15);
      font-family: "univers condensed";
      margin-top: em(5);
      color: #000;
      font-weight: 700;
      text-transform: uppercase;
    }

    .address-sec {

      .detail-area {
        width: 63%;
        float: left;

        p {

          font-size: em(13);
          color: #000;
          margin-bottom: em(5);

          span {
            color: #d02626;
          }

          a {
            color: #000;
          }

        }

      }
      .btn-area {
        width: 36%;
        float: right;
        margin-top: 5%;
        &.virtual-btn{
          margin-top: 17%;
          width: 36%;
          .btn {
            img {
              width: em(26);
              top: em(10);
            }
          }
          a{
            &:active,&:focus{
              color: #fff;
            }
          }
        }
        img {
          margin-right: em(10);
          position: relative;
          z-index: 9;
          top: -2px;
        }

        .btn {
          width: 100%;
          padding-left: em(50);

          img {
            position: absolute;
            left: em(10);
            top: em(7);
          }

        }

      }

    }

  }

}

.gm-style-mtc {
  display: none;
}

.enq-form-sec {

  padding: em(70) 0;
  background: #f8f8f8;

  h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.4em;
    margin: 0 0 1em;
    font-size: em(40);
  }
  p {
    text-align: center;
    font-size: em(19);
    padding: 0 em(20);
  }
  &.register-interest{
    background: #fff;
  }
  .form-group {
    width: 47%;
    float: left;
    margin-right: 5%;
    margin-top: 1.5%;
    margin-bottom: 0;
    &.last-child {
      margin-right: 0;
    }
    &.textareasec {
      width: 100%;
      margin: em(45) 0 0 0;

      textarea {
        min-height: 100px;
      }

    }

    .bootstrap-select > .btn {
      padding: 0;
      font-size: em(15);
      font-weight: normal;

      span {
        color: #000;
        text-transform: capitalize;
        font-size: em(17);

      }

      .filter-option {
        color: #000;

      }

      .caret {
        right: 0;
        top: em(16);
      }
      &:active {
        box-shadow: none;
      }

    }
    .btn-group.open .dropdown-toggle {
      -webkit-box-shadow: none;
      box-shadow: none;

    }

  }
  .has-success .bootstrap-select.btn-group .btn .filter-option {
    color: #000;
  }
  .form-control {
    border-bottom: solid 1px #e4e6e8;
    border-left: none;
    padding: 0 0;
    color: #000000;
    border-top: none;
    border-right: none;
    background: none;
    box-shadow: none;
    @include borderRadius(0);
    height: em(45);
    font-size: em(18);
    &:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

  }
  .form-wrapper {
    overflow: hidden;
    padding: 0 0 20px 0;
    margin-top: em(30);
    .loader {
      &:before {
        border-color: #f8f8f8;
      }
    }
    .thanks {
      background: #f8f8f8;
      .thanks-inner {
        height: 100%;
        padding-top: 0.7%;
        background: #f8f8f8;

        p {

          color: #000;
          font-size: em(25);
          margin-top: em(100);
        }

      }

    }
  }
  .btn-white {
    background: #cc0000;
    color: $brand-white;
    letter-spacing: em(1);
    height: em(52);
    min-width: em(228);
    img {
      position: absolute;
      left: em(15);
      top: em(12);
      z-index: 9;
    }
    &:focus {
      outline: none;
    }
    span {
      font-size: em(18);
      padding-left: em(22);
      letter-spacing: em(2);
    }

    i {
      top: em(7);
    }

  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #000;
    text-transform: capitalize;
    letter-spacing: em(1);
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #000;
    text-transform: capitalize;
    letter-spacing: em(1);
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #000;
    text-transform: capitalize;
    letter-spacing: em(1);
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #000;
    text-transform: capitalize;
    letter-spacing: em(1);
  }
  .has-error {
    .form-control {
      border-bottom: 1px solid $brand-primary;
    }
  }
  .has-success {
    .form-control {
      border-bottom: 1px solid #3c763d;
    }
  }

  .form-btn-sec {
    width: 100%;
    float: left;
    margin-top: em(65);
    text-align: center;
    position: relative;
    top: -5px;
  }

}

.form-wrapper .loading:before {
  background: #f8f8f8 ;
}

#eqyform .bootstrap-select.btn-group .dropdown-menu li a {
  font-size: em(18) !important;
  font-weight: 300;
  white-space: normal;
}

#map-canvas {
  height: em(650);
  width: 100%;
}
#map-canvas-detail {
  height: em(650);
  width: 100%;
}

hr {
  width: 27px;
  height: 2px;
  background: #ba8a3d;
  border: none;
  margin: 0 0 20px 0;
  position: relative;
  display: inline-block;
}

.main-popup {
  width: 300px !important;
  background: #fff !important;
}

.gm-style .gm-style-iw {
  top: 0 !important;
  position: relative !important;
  left: 0 !important;
  width: 100% !important;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}

.gm-style .gm-style-iw div {
  overflow: hidden !important;
  width: 100% !important;
  max-width: 100% !important;
}

.mapImg {
  margin-bottom: 20px;
  display: block;
}

#content h3 {
  font-size: em(16);
  margin-bottom: 10px;
  line-height: 16px;
  text-transform: capitalize;
  color: #000;
  font-weight: normal;
  padding: 0 em(20);

  span {
    text-transform: uppercase;
    font-size: em(23);
    line-height: em(30);

  }
  a {
    color: #000;
    @include transition(0.5s);
  }

}

#content hr {
  margin-top: em(30);
  width: 100%;
  background: #ededed;
}

#content .mapList {
  margin: 0;
  padding: 0;
}

#content .mapList li {
  display: block;
  width: 100%;
  color: #ccc;
  font-size: 14px;
  margin-bottom: 5px;
  overflow: hidden;
  font-family: 'Catamaran' !important;
}

#content .mapList li:last-child {
  margin-bottom: 0;
}

#content .mapList li span {
  float: left;
  width: 50%;
}

.autoList {
  float: left;
  width: 100%;
  padding: 0;
  li {
    margin: 0 0 em(20) 0;
    list-style: none;
    display: block;
    position: relative;
    padding: 0 em(50) 0 em(70);
    float: left;
    h4 {
      font-size: em(36);
      margin: 0;
      color: #222222;
      font-weight: 700;
      font-family: $univers-condensed;
    }
    span {
      color: #414141;
      font-size: em(16);
      font-family: $univers-condensed;
      display: block;
      text-transform: uppercase;
      font-weight: 500;
    }
    i {
      position: absolute;
      left: 0;
      top: 0;
      width: em(58);
      height: em(58);
      img {
        width: 100%;
      }
    }
  }
}

.homepage.normal-pages {
  section{
    &.contact-map-info {
      height: auto !important;
      height: initial !important;
      .fp-tableCell {
        height: auto !important;
        height: initial !important;
        padding-bottom: 3em;
        .fp-scrollable{
          height: auto !important;
        }

      }
    }
    &.show-room-sec {
      height: initial !important;
      .fp-tableCell {
        height: initial !important;
        padding-top: 2em;
        padding-bottom: 3em;
      }
    }
  }
}

.gm-style .close-btn {
  right: 10px !important;
  left: initial !important;
}
.gm-style .gm-style-iw div {
  .mCSB_scrollTools{
    width: 5px !important;
    top: 15px;
  }
}
#eqyform .bootstrap-select.btn-group .dropdown-menu {
  max-width: 100%;
  overflow-x: hidden;
}

@media (max-width: 1600px) and (min-width: 1550px){
  .show-room-sec {
    .box {
      .address-sec {
        .detail-area{
          width: 59%;
        }
        .btn-area {
            width: 41%;
          &.virtual-btn{
            width: 41%;
          }
        }
      }
    }
  }
}

@media (max-width: 1599px) and (min-width: 1200px) {

  .enq-form-sec .btn-white {
    img {
      width: em(25);
    }

  }

  .show-room-sec {
    .box {
      .address-sec {
        .btn-area {
          .btn {
            img {
              width: em(27)
            }
          }
        }
      }

    }

  }

}

@media (max-width: 1366px) and (min-width: 1280px) {
  #eqyform .bootstrap-select.btn-group .dropdown-menu {
    font-size: em(16);
  }
}

@media (min-width: 1200px) {

  .viewallbtn {
    .moreLink{
      &:hover{
        color: $brand-white;

        span {
          left: em(4);
          color: $brand-white;
        }
        &:before {
          left: 0;
          right: auto;
          width: 100%;

        }
      }
    }

  }
  #content {
    h3 {
      a {
       &:hover {
         color: $brand-primary;
       }
      }
    }
  }
  section.enq-form-sec {
    .fp-tableCell {
      padding-bottom: 0 !important;
    }
  }

}

@media (max-width: 1280px) and (min-width: 1201px) {

  .enq-form-sec {

    .btn-white {

      img {
        height: 15px;
      }

    }

  }

  .show-room-sec {
    .box {
      .address-sec {
        .btn-area {
          .btn {
            img {
              width: em(25)
            }
          }
        }
      }

    }

  }

}

#philo{
  overflow: initial;
}


@media (max-width: 1024px){

  .enq-form-sec .form-wrapper{
    overflow: inherit;
    float: left;
    width: 100%;
  }

  /* category-pane start */
  .category-pane{
    margin-top: em(30);
  }
  /* category-pane end */


  body #eqyform .bootstrap-select.btn-group .dropdown-menu li a {
    font-size: em(14) !important;
  }
  .contact-map-info {
    padding: 22px 0 0 0;
  }
  .enq-form-sec {
    padding: 22px 0 0 0;
  }
  body.homepage.normal-pages section.enq-form-sec .fp-tableCell {
    height: initial !important;
    padding-top: 3em;
    padding-bottom: 0em;
  }
  body  .show-room-sec{
    padding: 0px 0px 22px 0px;
  }
}


@media (max-width: 1199px) and (min-width: 992px) {

  .main-popup {

    img {
      width: 100%;
    }

    width: em(250) !important;

    .mapImg {
      margin-bottom: em(10);
    }

    #content {
      h3 {
        padding: 0;
        font-size: em(13);
        margin-bottom: 0;

        span {
          font-size: em(17);
          font-weight: 700;
        }

      }

      hr {
        margin-top: em(5);
        margin-bottom: em(5);
      }

    }

  }

  .enq-form-sec .btn-white {
    width: 15%;

    img {
      width: em(25);
    }

  }

  .show-room-sec .box .address-sec {

    .detail-area {
      width: 59%;

    }
    .btn-area {
      width: 40%;
      margin-top: 7%;
      &.virtual-btn{
        width: 40%;
      }
      .btn {
        img {
          width: em(25);
        }
      }
    }

  }

  .homepage.normal-pages {
    section{
      &.enq-form-sec {
        height: initial !important;
        .fp-tableCell {
          height: initial !important;
          padding-top: 3em;
          padding-bottom: 3em;
        }
      }
      &.show-room-sec {
        height: initial !important;
        .fp-tableCell {
          height: initial !important;
          padding-top: 3em;
          padding-bottom: 3em;
        }
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {




  /* category-pane start */
  .category-pane {
    &:before, &:after{
      width: 24%;
    }
    .category-dropdown {
      .form-control{
        height: 42px;
      }
      .form-control.bootstrap-select.btn-group .btn {
        padding-left: 0.7em;
        height: 42px;
        .filter-option{
          font-size: 1.2em;
        }
        .caret{
          top:1em;
          right: 1em;
        }
      }
    }

  }
  /* category-pane end */



  .main-popup {

    img {
      width: 100%;
    }

    width: em(250) !important;

    .mapImg {
      margin-bottom: em(10);
    }

    #content {
      h3 {
        padding: 0;
        font-size: em(13);
        margin-bottom: 0;

        span {
          font-size: em(17);
          font-weight: 700;
        }

      }

      hr {
        margin-top: em(5);
        margin-bottom: em(5);
      }

    }

  }

  .contact-map-info {
    .autoList {
      li {
        width: 50%;
      }
    }

    .hour-table {
      .col-sec {
        span {
          width: 63%;
        }

        .day {
          width: 34%;
        }

      }

    }

  }

  .enq-form-sec {
    .btn-white {
      width: 18%;

      img {
        width: em(25);
      }
    }

    .form-wrapper {
      min-height: 33em;
    }

  }

  .show-room-sec {
    .address-sec {
      .detail-area {
        width: 47% !important;
      }

      .btn-area {
        width: 50% !important;
        &.virtual-btn{
          margin-top: 31% !important;
          width: 50% !important;
        }
        .btn {
          img {
            width: em(25)
          }

          i {
            top: em(4);
          }

        }
      }

    }

  }

  .homepage.normal-pages {
    section{
      &.enq-form-sec {
        height: initial !important;
        .fp-tableCell {
          height: initial !important;
          padding-top: 3em;
          padding-bottom: 3em;
        }
      }
      &.show-room-sec {
        height: initial !important;
        .fp-tableCell {
          height: initial !important;
          padding-top: 3em;
          padding-bottom: 3em;
        }
      }
    }
  }

}

@media (max-width: 767px) {


  /* category-pane start */
  .category-pane {
    &:before, &:after{
      width: 24%;
    }
    .category-dropdown {
      width: 50%;
      .form-control{
        height: 42px;
      }
      .form-control.bootstrap-select.btn-group .btn {
        padding-left: 0.7em;
        height: 42px;
        .filter-option{
          font-size: 1.2em;
        }
        .caret{
          top:1em;
          right: 1em;
        }
      }
    }

  }
  /* category-pane end */


  .desktop_contact{ display: none !important;}
  .mobile_contact{display: block !important;}


  .main-popup {

    img {
      width: 100%;
    }

    width: em(250) !important;

    .mapImg {
      margin-bottom: em(10);
    }

    #content {
      h3 {
        padding: 0;
        font-size: em(13);
        margin-bottom: 0;

        span {
          font-size: em(17);
          font-weight: 700;
        }

      }

      hr {
        margin-top: em(5);
        margin-bottom: em(5);
      }

    }

  }

  .enq-form-sec {

    .form-wrapper {
      .thanks {
        .thanks-inner {

          padding-top: 0;

        }
      }
    }

  }

  .main-popup {

    width: em(280) !important;
  }
  .enq-form-sec {
    .btn-white {
      width: 20%;
      img {
        width: em(25)
      }
    }
  }

  .contact-dealer {

    &.inner-banner {

      h2 {
        font-size: em(30) !important;
      }

      p {
        font-size: em(18);
        br {
          display: none;
        }
      }

      .container-fluid {
        top: 30%;
      }

      img {
        display: none;

      }

      .mobile-banner {
        display: block;
        max-width: 100%;
        margin: 0;
      }

    }

  }

  .map-area-sec {
    margin-bottom: em(20);
  }


  .contact-map-info {
    h3 {
      font-size: em(25)
    }

  }

  .map-pane{
    .map-area-sec{
      margin-bottom: 0;
    }
  }

  .show-room-sec {
    .box {
      .address-sec {
        .detail-area {
          width: 60%;
        }

        .btn-area {
          width: 31%;
          &.virtual-btn{
            margin-top: em(15);
          }
          .btn {
            img {
              width: em(25)
            }

            i {
              top: em(6);
              display: block;
            }

          }
        }

      }

    }

  }

  .homepage.normal-pages {
    section{
      &.enq-form-sec {
        height: initial !important;
        .fp-tableCell {
          height: initial !important;
          padding-top: 3em;
          padding-bottom: 3em;
        }
      }
      &.show-room-sec {
        height: initial !important;
        .fp-tableCell {
          height: initial !important;
          padding-top: 0em;
          padding-bottom: 2em;
        }
      }
    }
  }

  .inner-banner .dealer-banner p {
    font-size: 1.125em;
  }

}

@media (max-width: 480px) {


  /* category-pane start */
  .category-pane {
    &:before, &:after{
      width: 14%;
    }
    .category-dropdown {
      width: 75%;
    }
  }
  /* category-pane end */

  .contact-map-info {
    .autoList {
      li {
        width: 100%;
        margin-bottom: 20px;
        span{
          margin-top: .5375em;
        }
      }

    }

    .hour-table {
      .col-sec {
        width: 100%;
      }
      .col-sec-one {
        width: 100%;
      }
      .col-sec-two {
        width: 100%;
      }
    }

  }

  .enq-form-sec {
    .form-group {
      width: 100%;
    }

    .form-group.textareasec {
      margin: em(30) 0 0 0;
    }

    .btn-white {
      width: 50%;
    }

    h2 {
      font-size: em(25) !important;
    }

  }

  .show-room-sec {
    padding: em(20) 0 em(60) 0;

    .detail-area {
      width: 100% !important;

    }

    .btn-area {
      width: 100% !important;
      margin-top: em(20)
    }

  }

  .about-dealer-sec {
    h2 {
      font-size: em(45) !important;
    }
  }

}
@media (max-width: 320px) {

  .contact-dealer.inner-banner {
    .container-fluid {
      top: 25%;
    }

    h2
    {
      font-size: em(25) !important;
    }

  }

  .show-room-sec {
    h2 {
      font-size: em(20);
    }

  }



}
