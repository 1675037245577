.inner-banner {

  .dealer-banner {
    &.t_sp{ margin-top: 5em}
    > img {
      width: 100%;
    }
    .container-fluid {
      position: absolute;
      top: 10em;
      left: 5.5em;
    }
    h2 {
      font-family: "univers condensed";
      color: #fff;
      font-size: em(40);
      text-transform: uppercase;
      font-weight: 700;
      text-shadow: 2px 2px 3px #7b7b7b;

    }
    hr {
      background: $brand-white;
      margin-top: 15px;
    }
    p{
      color: $brand-white;
      font-size: 1.625em;
      font-weight: 400;
      margin: 0 0 1.25em 0;
      letter-spacing: .0625em;
    }
    .banner-icon-logo {
      max-width: 100%;
      width: auto;
    }
  }


}


.about-deal {
  .mobile-banner {
    display: none;
  }
}

.about-dealer-sec {
  text-align: center;
  padding: 3em 0;
  font-size: 1.250em;

  h2 {
    font-size: em(30);
    text-transform: uppercase;
    letter-spacing: 0.4em;
    margin-bottom: 1em;
  }

  ul {
    margin-top: em(22);
    li {
      display: inline-block;
      padding: 0 em(14);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: em(8);
        right: 0;
        display: block;
        width: 1px;
        height: 14px;
        background: #d3d3d3;
      }

      &.last {
        &:before {
          display: none;
        }
      }
    }

  }
}

.company-intro-sec {
  background: #f8f8f8;
  padding: 6.250em 0;

  h2 {
    color: #cc0000;
    font-weight: 700;
    font-size: em(40);
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: em(12);
  }

  img {
    max-width: 100%;
  }
  p {

    font-size: em(20)

  }

}

.explore-sec {
  padding-top: em(70);
  margin-bottom: em(25);
  text-align: center;
  height: auto !important;
  //height:inherit !important;
  .fp-tableCell {
    padding-top: 0em;
    height: auto !important;
    //height:inherit !important;
  }
  h2 {
    font-size: em(40);
    text-transform: uppercase;
    letter-spacing: 0.4em;
    margin-bottom: 1em;
  }
  .services-box {

    text-align: left;
    float: left;
    position: relative;
    flex: 1 auto;
    margin-right: em(20);
    width: 27%;

    .box-one-text {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 2.5em 2.5em 2.5em;

      .btn {
        min-width: 12.5em;

        i {
          top: em(4);
        }

      }

      h3 {
        text-transform: uppercase;
        font-size: 1.875em;
        font-weight: bold;
        padding-right: 2.5em;
      }

      hr {
        background: #000;
      }

    }
    .box-two-text {
      position: absolute;
      left: 0;
      bottom: 0.625em;
      width: 100%;
      padding: 0 2.5em 2em 2.5em;

      .btn {
        min-width: 12.5em;
        border: 1px solid #fff;

        i {
          top: em(4);
        }
      }

      h3 {
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
        font-size: 1.875em;
      }
      p {
        color: #fff;
        font-size: 1.375em;
        font-weight: 300;
        line-height: 1.125em;
        padding: .625em 0 1.125em 0;
      }

    }
    .box-three-text {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 2.5em 2.5em 2.5em;

      .btn {
        min-width: 14.5em;
        i {
          top: em(4);
        }
      }

      h3 {
        text-transform: uppercase;
        font-size: 1.875em;
        font-weight: bold;
        padding-right: 2.5em;
        color: #fff;
      }

      hr {
        background: #fff;
      }

    }
    &.certified-car {

      width: 41%;

    }
    &.map-sec {

      border: 1px solid #e8e8e8;

    }

  }

  .main-box-sec {
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    -webkit-flex-flow: row wrap;
    hr {
      display: block;
    }
  }

  .contact-box {

    position: relative;
    width: 100%;
    float: left;
    margin-top: em(40);

    .text-sec {
      position: absolute;
      left: 0;
      top: 17%;
      width: 100%;
      padding: 0 em(110);

      h2 {
        font-size: em(40);
        text-transform: uppercase;
        letter-spacing: 0.4em;
        color: #fff;
        margin-bottom: em(10);
      }
      p {
        color: #fff;
        font-size: em(20);
        font-weight: 300;
        letter-spacing: 0.6px;
        line-height: 1.125em;
      }

    }

    .btn {
      margin-top: em(25);
      min-width: 23em;

      i {
        top: em(5);
      }
    }

  }

}

.disclaimer-txt {
  padding: 10px 0 0;
  display: block;
  p {
    font-size: em(14);
    color: #000;
    margin: 0;
    .star {
      font-size: em(18);
    }
  }
}


.about-deal.inner-banner {
  height: auto !important;
  //height:inherit !important;
  .fp-tableCell {
    height: auto !important;
    //height:inherit !important;
    padding-top: 0em;
  }
  &.mgt2{
    padding-top: 5em;
  }
}
.company-intro-sec {
  height: auto !important;
  //height:inherit !important;
  .fp-tableCell {
    height: auto !important;
    //height:inherit !important;
    padding-top: 3em;
    padding-bottom: 3em;
  }
}

.recall-sec {
  height:inherit !important;
  background: #f8f8f8;
  &.white{
    background: $brand-white;
  }
  .fp-tableCell {
    height:inherit !important;
    padding-top: 5em;
    padding-bottom: 5em;
    img {
      &.for-mobile {
        display: none;
      }
    }
  }
}

@media (min-width: 1601px) and (max-width: 2400px) {
}

@media (min-width: 1530px) and (max-width: 1600px) {

}
@media (min-width: 1201px) and (max-width: 1280px) {
  .inner-banner .dealer-banner .container-fluid{
    top:em(112);
  }

  .message-f-p h2{
    margin:em(48) 0 em(20);
  }

  body {
    .tow-column-section {
      padding: em(62) 0 em(0) 0;
    }
    .tow-column-section.unlimited .container{
      padding: em(62) 0 em(0) 0;
    }
    .unlimited .col-md-6{
      height: 78vh;
    }
    .page-title{
      margin: em(15) 0 em(15) 0;
    }
    .innerMenu>li .dropDown ul li{
     min-height: em(250);
    }

  }
}


@media (max-width: 1366px) {
  //.about-deal.inner-banner .fp-tableCell{
  //  padding-top: em(80);
  //}
}


@media (min-width: 1330px) and (max-width: 1366px) {




  .explore-sec .services-box {
    .box-two-text {
      padding: 0 2.5em 2.5em 2.5em;
    }
    &.calc-sec {
      .box-two-text {
        + img {
          height: 251px
        }
      }
    }
  }

  .ie {
    .explore-sec .services-box {
      &.map-sec {
        .box-one-text {
          + img {
            height: 248px;
          }
        }
      }
      &.calc-sec {
        .box-two-text {
          + img {
            height: 250px;
          }
        }
      }
    }
  }

}

@media (min-width: 1201px) and (max-width: 1280px) {
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .explore-sec .services-box {

    .box-one-text {
      .img-responsive {
        width: 38%;
      }
    }

    .box-three-text {

      .img-responsive {
        width: 30%;
      }

    }

  }

  .ie {
    .explore-sec .services-box {
      img {
        height: 100%;
      }
    }
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {



  .explore-sec {
    .contact-box{
      margin-top:3em;
    }
  }

  .inner-banner .dealer-banner .container-fluid{
    top: 6.5em;
  }

  .about-dealer-sec {
    padding: 1em 0em 2.2em;
    ul {
      padding-left: 0;
      li {
        padding: 0 .6em 0 0;
        margin: 0 0.2em 0 0;
        &:before {
          top: .25em;
        }
      }
    }
  }
  .explore-sec {
    h2{
      margin-top: em(16);
    }
    height: initial !important;
    padding: 0em 0 3em;
    .fp-tableCell {
      height: initial !important;
      padding: 0em;
    }
    .box-one-text .img-responsive {
      width: auto

    }
    .box-three-text {

      .img-responsive {
        width: 25%;
      }

    }
    .services-box {
      .box-two-text {
        bottom: -0.375em;
        h3 {
          font-size: em(20);
        }

      }
      .box-three-text {
        padding: 0 2.5em 2.5em 2.5em;
        h3 {
          font-size: em(20);
        }

      }
      .box-one-text {
        padding: em(25) em(19);
        h3 {
          font-size: em(20);
        }
      }

    }

  }
  .company-intro-sec{
    padding: 0px;
    p{
      margin-bottom: 0px;
    }
  }
  .homepage.normal-pages {
    section {
      .fp-tableCell {
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
  }

  .hideMobileBannerText{
    .inner-banner{
      .txt-sec{
        display: none;
      }
    }
  }



}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .explore-sec {
    .contact-box{
      margin-top:3em;
    }
  }
  .company-intro-sec{
    padding: 0px;
    p{
      margin-bottom: 0px;
    }
  }
  .about-dealer-sec {
    padding: 1em 0em 2.2em;
    ul {
      li {
        margin: em(4) 0;

      }
    }
  }
  .inner-banner .dealer-banner{
    .container-fluid {
      top: 6.5em;
    }

    h2 {
      font-size: em(35);
      margin-top: em(5);
    }
  }
  .explore-sec {
    h2{
      margin-top: em(16);
    }
    height: initial !important;
    padding: 0em 0 3em;
    .fp-tableCell {
      height: initial !important;
      padding: 0em;
    }
    .services-box {

      margin-right: em(8);
      margin-left: em(8);

      .box-one-text {
        padding: em(10) em(12) !important;
        img {
          width: 31%;
        }

        h3 {
          margin-top: em(11);
          font-size: em(24);
        }

      }

      .box-two-text {
        padding: em(12) em(11) !important;
        p {

          padding: 0 !important;
        }

        h3 {
          font-size: em(20) !important;

        }

      }

      .box-three-text {
        padding: em(13) em(9) !important;
        h3 {
          margin-top: em(10);
          font-size: em(24);
        }
        .img-responsive {
          width: 25%;
        }

      }
    }

    .contact-box {
      height: em(430);
      overflow: hidden;

      img {
        max-width: none;
      }

    }

  }
  .homepage.normal-pages {
    section {
      .fp-tableCell {
        .fp-scrollable {
          height: auto !important;
        }
      }
    }
  }

  .hideMobileBannerText{
    .inner-banner{
      .txt-sec{
        display: none;
      }
    }
  }

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {



  /* about dealear page */
  .company-intro-sec{
    padding: 0px;
    .fp-tableCell{
      padding: 2em 0;
    }
   }

  .about-deal {
    img {
      display: none;
    }

    .mobile-banner {
      display: block;
      width: 100%;
      margin: 0;
    }

    .txt-sec {
      img {
        display: block;
        margin-left: 0;
      }
    }

  }

  .inner-banner {
    .dealer-banner {
      height: 22em;
      display: block;
      position: relative;
      overflow: hidden;
      .container-fluid {
        left: em(30);
        top: 5em;

      }
      h2 {
        font-size: em(35);
        line-height: em(20);
        margin-top: em(10);

      }
       p {
        font-size: 1.125em;
      }
    }
    .about-dealer-sec {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: em(6);

      /* about dealear page */
      padding-bottom: em(16);
    }
  }





  .explore-sec {
    padding: 0 em(20);
    height: initial !important;
    .contact-box {
      height: em(480);
      overflow: hidden;
      margin-top: 0;
      margin-bottom: em(15);
      .img-responsive {

        max-width: none;
      }
      .text-sec {
        top: 9%;
        padding: 0 em(10);

        p {

          line-height: em(20);
        }
      }

    }
    .services-box {
      width: 100%;
      margin: 0 0 em(20) 0;

      &.certified-car {
        width: 100%
      }

      img {

        width: 100%;

        &.flogosec {
          width: auto;
        }

      }

      .box-one-text {
        img {
          width: auto;
        }
        + img {
          border: 1px solid #efefef;
        }
        h3 {
          font-size: em(20);
          br {
            display: none;
          }
        }
      }

      .box-two-text {
        h3 {
          font-size: em(20);
        }
      }

      .box-three-text {
        h3 {
          font-size: em(20);
        }
      }
    }

    .fp-tableCell {
      height: initial !important;
      padding-top: 1em;
    }
  }

  .about-dealer-sec {

    ul {
      padding: 0;

      li {
        margin-bottom: em(10)
      }

    }

  }

  .homepage.normal-pages {
    section {
      .fp-tableCell {
        .fp-scrollable {
          height: auto !important;
        }
      }
      .fp-scroller {
        height: auto !important;
      }
    }
  }
  .contact-dealer.inner-banner h2 {
    font-size: 1.875em ;
  }

  .inner-banner .dealer-banner p {
    br {
      display: none;
    }
  }

  .recall-sec {
    .fp-tableCell {
      padding-top: 2em;
      padding-bottom: 2em;
      img {
        &.for-desktop {
          display: none;
        }
        &.for-mobile {
          display: block;
        }
      }
    }
  }

  .hideMobileBannerText{
    .inner-banner{
      .txt-sec{
        display: none;
      }
    }
  }


}





/* Portrait phones and smaller */
@media (max-width: 480px) {

  .about-deal {
    .kuwait-banner-logo {
      width: em(250);
    }

  }


  .inner-banner .dealer-banner {
    position: relative;
    height: 12.5em;
    display: block;
    /*margin-top: em(50);*/
    h2 {
      font-size: em(20);
    }
    .txt-sec {
      > img{
        width: 70%;
      }
    }
    .container-fluid {
      left: em(30);
      top: 2em;

    }
  }

  .about-dealer-sec {
    padding: em(30) 0;

  }

  .company-intro-sec {
    padding: em(0) 0;

  }

  .explore-sec {
    padding: em(0) em(20);
    h2 {
      font-size: em(26);
    }
    .fp-tableCell{
      padding-top: 0px;
    }
    .services-box {
      margin-bottom: em(20);
      margin-right: 0;

      .box-two-text {
        padding: em(10) em(15);

      }

      .box-one-text {
        padding: em(10) em(15);

        img {
          width: em(150);

        }

      }

      .box-three-text {
        padding: em(10) em(15);

        h3 {

          font-size: em(23);
          margin: em(8) 0;

          br {
            display: none;

          }

        }

        img {
          width: em(170);

        }

      }

    }

  }

  .company-intro-sec {

    h2 {
      font-size: em(30);

    }

  }



  .inner-banner .dealer-banner p {
    font-size: 1em;
    br {
      display: none;
    }
  }



}

/* Portrait phones and smaller */
@media (max-width: 320px) {
  .inner-banner .dealer-banner{
    h2 {
      font-size: em(20);
    }

  }
  .explore-sec {
    .services-box {

      img {
        &.flogosec {
          width: em(200);

        }

        &.kuwait-logo {
          width: em(100);

        }

      }

    }

  }
}
