@media (min-width: 1601px) and (max-width: 2400px) {
}

@media (min-width: 1530px) and (max-width: 1600px) {
}

@media (min-width: 1330px) and (max-width: 1366px) {
}

@media (min-width: 1201px) and (max-width: 1280px) {
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

}

/* Portrait phones and smaller */
@media (max-width: 480px) {


  .t-drive-cont .inner-t-drive form .fields-set{
    margin-bottom: 0px !important;
    border-bottom: 0 !important;
  }
  .t-drive-cont .inner-t-drive form .form-group .bootstrap-select>.btn{
    width: 100% !important;
  }


}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}