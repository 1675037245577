
.offer-promo {
  &.owner{
    .promo-box .image{
      .img-responsive{
        max-width: inherit;
        width: 100%;
      }
    }
  }
}


.common.inner-banner {
  &.owner-banner{
    padding-top: 0;
  }
}


.con-mob, .loc-mob, .conf-bt{
  display: none;
}





.main-pro-banner {
  &.landing-slider{
    margin-bottom: em(82);
  }

  div {
    position: relative;

    .text-sec {
      position: absolute;
      top: 35%;
      left: 3%;
      width: 50%;

      h1 {
        line-height: 70px;
        color: #fff;
        text-transform: capitalize;
        margin-bottom: em(5);
        text-shadow: 2px 2px 3px #7b7b7b;
        font-size: em(72);
      }
      p {
        /*font-size: 26px;*/
        font-family: "univers";
        color: #fff;
        font-weight: 400;
        text-transform: capitalize;
        margin-top: 10px;
        display: block;
        text-shadow: 2px 2px 3px #7b7b7b;
        font-size: 2.125em;
      }

      .btn {
        margin-top: em(20);
        width: auto;
        min-width: initial;
        padding: .6875em 4.5625em .6875em 1.5625em;
      }

    }

    .logos-sec {
      position: absolute;
      bottom: 10%;
      right: 5%;
    }

    .showonmobile {
      display: none;
    }

    &.slick-current{
      .btn.btn-primary{
        i{
          padding-top: 0px;
        }
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 10%;
    left: 0;
    padding: 0;
    width:100%;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      margin: 0 20px 0 0;
      background: none !important;
      border: transparent 1px solid !important;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      cursor: pointer;
      -webkit-transition: all .5s ease-in-out;
      -moz-transition: all .5s ease-in-out;
      -o-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;

      button {
        display: none;
      }

      &:before {
        background: #fff;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;

      }

      &:hover {
        border: #fff solid 1px !important;

      }

      &.slick-active {
        border: #fff solid 1px !important;

      }

    }

  }

  .slick-next, .slick-prev {
    display: none !important;
  }
}

a.btn-auto{
  min-width: inherit;
  padding-right: 63px;
}

.f40{
  font-size: em(40);
}
.light_black{
  color: #313131;
}
.center{
  text-align: center;
}
.l-spc-20{
  letter-spacing: 20px;
}

.uppercase{
  text-transform: uppercase;
}

.mgb-50{
  margin-top: 0;
  margin-bottom: 50px;
}

.mgt-30{
  margin-top:em(30);
}


.owner-news-box{
  margin-bottom: em(100);
}





.managing-pane{
  background: #f3f6fb;
  padding:em(185) 0 em(160) 0;
  position: relative;
  height: 100vh;
  h4{
    font-size: em(70);
    font-weight: bold;
    margin-bottom: 69px;
    margin-top: 0;
    font-family: $univers-condensed;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      margin: 0px 1%;
      float: left;
      width: 31.33%;
      background: $brand-white;
      padding: 148px 2% 60px 2%;
      position: relative;
      text-align: center;
      span.ico{
        background: url("#{$websitePath}assets/images/owners/ico.png") no-repeat center top;
        height: 63px;
        width: 63px;
        float: left;
        position: absolute;
        left: 50%;
        margin-left: -31.5px;
        top: 40px;
      }
      h5{
        font-size: em(20);
        color: $brand-black;
        text-transform: uppercase;
        margin-bottom: em(20);
        text-align: center;
        margin-top: 0;
        font-weight: bold;
        letter-spacing: 2px;
      }
      p{
        color: #3f3f3f;
        font-size: em(18);
        min-height: 86px;
        margin-bottom: 22px;
      }
    }
  }

  .mouse-icon{
    bottom: 6%;
    &.desk{
      border-color: #c00;
      height: em(50);
      width: em(30);
    }
    .wheel{
      background: #c00;
    }
  }

}



.configure_banner{
  //background: url("#{$websitePath}assets/images/owners/vehicle-banner.jpg") no-repeat top left;
  .container{
    position: relative;
    .f-right{
      float: right;
      position: absolute;
      right: 0;
      top: em(45);
      padding-left: 5em;
      padding-right: 2em;
      p{
        font-size: em(16);
        color:#3f3f3f;
        margin-bottom:em(20);
      }
    }
    h4{
      position: absolute;
      color: $brand-black;
      font-size: 1.875em;
      font-weight: bold;
      top: 60px;
      padding-left: 98px;
      left: 40px;
      text-transform: uppercase;
      &:before{
        background: url("#{$websitePath}assets/images/set-icon.png") no-repeat;
        content: "";
        height: 68px;
        width: 68px;
        position: absolute;
        left: 0px;
        top: 0;
        background-size: 100%;
      }
    }

  }


}

.locate-banner{
  img{
    width: 100%;
  }
  position: relative;
  margin-bottom: 110px;
  .container{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    top: 7.4em;
    padding-left: em(30);
    h6{
      font-size: em(30);
      color: $brand-black;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0px 0px 14px 0px;
    }
    p{
      font-size: em(18);
      color: $brand-black;
      font-weight: bold;
      margin-bottom: 33px;

    }
  }
}

.offer-promo{
  &.owner{
  padding-top: 0;
    padding-bottom: 65px;
    text-align: center;

    .col-sm-6.col-md-4{
      display: inline-block;
      float: none;
      width: 33%;
      .promo-box{
        float: left;
      }
    }

    h4{
      font-size: em(70);
      font-weight: bold;
      margin-bottom: 45px;
      margin-top: 0;
      font-family: $univers-condensed;
    }
  }

}

.configure_banner{
  margin-bottom: em(100);
  img{
    width: 100%;
  }
}



@media (min-width: 1530px) and (max-width: 1600px) {
  .configure_banner .container .f-right{
    top: em(32);
  }
  .managing-pane{
    padding:em(120) 0 em(150) 0;
    .mouse-icon{
      bottom: 4%;
    }
    ul {
      li {
        p{
          min-height: 97px;
        }
      }
    }
  }
}



@media (min-width: 1200px) and (max-width: 1366px) {



  .main-pro-banner.landing-slider {
    div {
      .text-sec {
        top: 25%;
        left: 3%;

        h1 {

          line-height: 40px;
          font-size: em(50);

        }
        p {
          font-size: 20px;
          margin-top: 10px;
        }

      }

      img {
        width: 100%;
      }

    }

  }


  .configure_banner {
    .container {
      h4{
        padding-left: 65px;
        top: 40px;
        &:before{
          background-size: 66%;
        }
      }
    }
  }

  .locate-banner{
    margin-bottom: 70px;
  }
  .offer-promo.owner{
    padding-bottom: 45px;
  }
  .managing-pane {
    padding:em(140) 0 em(160) 0;
    h4{
      margin-bottom: 30px;
    }
    ul {
      li {
        padding: 130px 2% 50px 2%;
        p{
          min-height: 75px;
        }
      }
    }
  }



}

@media (min-width: 1200px){
  .main-pro-banner.landing-slider .slick-slide>img {
    width: 100%;
  }
}
@media (max-width: 1024px){
  .main-pro-banner.landing-slider .slick-slide>img {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {


  .configure_banner .container h4{
    top: 25px;
    padding-left: 88px;
  }



  .main-pro-banner.landing-slider {
    div {

      .text-sec {
        top: 25%;
        left: 3%;

        h1 {

          line-height: 40px;
          font-size: em(50);

        }
        p {
          font-size: 20px;
          margin-top: 10px;
        }
      }
      .logos-sec {
        bottom: 10%;
        right: 5%;

        img {
          width: 50%;
          float: right;
        }

      }

      img {
        width: 100%;
      }

    }

  }
}

@media (min-width: 768px) and (max-width: 991px){
  body .mgb-50{
    margin-top: 0;
    margin-bottom: 50px;
  }

  body .main-pro-banner.landing-slider{
    margin-bottom: em(70);
  }
  body .owner-news-box{
    margin-bottom: em(70);
  }
  body .managing-pane {
    padding: em(130) 0px em(90) 0px;
    height: auto;
    h4{
      margin-bottom: 35px;
    }
  }
  body .offer-promo.owner{
    padding-bottom: em(50);
    h4{
      margin-bottom: 25px
    }
  }
  body .locate-banner {
    margin-bottom: em(50);
    .container{
      top: 4.4em;
      h6{
        margin: 0px 0px 8px 0px;
      }
      p{
        margin-bottom: 13px;
      }
    }}

  .main-pro-banner.landing-slider {
    div {

      .text-sec {
        top: 25%;
        left: 3%;

        h1 {

          line-height: 30px;
          font-size: em(40);

        }
        p {
          font-size: 15px;
          margin-top: 10px;
        }
      }

      .logos-sec {
        bottom: 10%;
        right: 5%;

        img {
          width: 50%;
          float: right;
        }

      }

      img {
        width: 100%;
      }

    }

  }
  .configure_banner .container .f-right{
    top: 1em;
    padding-left: 3em;
  }

  .configure_banner .container h4{
    top: 20px;
    left: 30px;
    padding-left: 52px;
    &:before{
      height: 38px;
      width: 38px;
    }
  }
}




@media (min-width: 481px) and (max-width: 767px){
  .managing-pane ul li h5{
    min-height: 32px;
  }
}
@media (max-width: 767px) {

  .con-mob{
    display: block;
  }
  .con-des{
    display: none;
  }

  .locate-banner .container{
    top: 2.4em;
    h6{
      margin: 0px 0px 8px 0px;
    }
    p{
      margin-bottom: 12px;
    }
  }

  a.btn-auto{
    min-width: inherit;
    padding:.6875em 1.5625em;

  }

  .offer-promo.owner{
    padding-bottom: em(20);
  }

  .locate-banner{
    margin-bottom: em(35);

  }
  .offer-promo.owner {
    h4{
      font-size: em(35);
      margin-bottom: 25px;
    }
  }
  .managing-pane {
    padding: em(120) 0 em(35);
    height: auto;
    h4{
      font-size: em(35);
    }

    ul li {
      p{
        min-height: 116px;
      }
    }
  }


  body .mgb-50 {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .col-md-12.col-sm-12.center.mgt-30{
    float: left;
    width: 100%;
  }

  .owner-news-box{
    margin-bottom: em(35);
  }

  .main-pro-banner.landing-slider {
    margin-bottom: 3.125em;
    div {
      .showonmobile{
        display: none;
      }
      .text-sec {
        top: 25%;
        left: 3%;

        h1 {

          line-height: 25px;
          font-size: em(30);

        }
        p {
          font-size: 15px;
          line-height: 20px;
          margin-top: 10px;
        }

        .btn {
          margin-top: 0;
          padding: .6875em 1.5625em .6875em 1.5625em;
          i {
            top: em(4);
          }
        }

      }

      .logos-sec {
        bottom: 10%;
        right: 5%;

        img {
          width: 50% !important;
          float: right;
        }

      }

      img {
        display:block;
        width: 100% !important;
      }

    }

    .slick-dots {
      li {
        margin: 0 3px;
      }
    }

  }

  .configure_banner {
    .container {
      h4{
        top: 26px;
        left: 7%;
        padding-left: 0;
        &:before{
          display: none;
        }
      }
      .conf-bt{
        display: inline-block;
        font-size: 12px;
        margin-top: 10px;

      }
      .f-right{

        display: none;
        padding: 0;
        top: 10px;
        p{
          display: none;
        }
      }
    }
  }
}

@media (max-width: 480px) {

  .loc-des{
    display: none;
  }
  .loc-mob{
    display: block;
  }
  .main-pro-banner.landing-slider {
    div {

      .text-sec {

        top: 31%;
        left: 3%;

        h1 {

          line-height: 20px;
          font-size: em(20);

        }
        p {

          font-size: 15px;
          margin-top: 10px;
          display: none;

        }

        .btn {
          margin-top: em(10);
          padding: em(7) em(10);

          i {
            top: em(2);
          }

        }

      }

      .logos-sec {
        bottom: 10%;
        right: 5%;

        img {
          width: 30% !important;
          float: right;
        }

      }

      img {
        width: 100% !important;
        display: none;
      }
      .showonmobile {
        display: block;
      }

    }

    .slick-dots {
      li {
        margin: 0 3px;
      }
    }

  }

  .managing-pane ul li{
    width: 100%;
    margin-bottom: 10px;
    p{
      min-height: inherit;
    }
  }

  .locate-banner .container {
    width: 50%;
    left: 5px;
    right: auto;
    top: 38px;
    p{
      display: none;
    }
  }
  .configure_banner .container {
    h4{
      top: 10px;
      font-size: em(18);
      .conf-bt{
        font-size: 9.7px;
      }
    }

  }
}

@media (max-width: 480px){

  .offer-promo.owner .col-sm-6.col-md-4{
    width: 100%;
  }
  .configure_banner .container h4{
    top: -3px;
  }

}
