.mouse-icon {
  border: 2px solid $brand-white;
  @include borderRadius(16px);
  height: em(40);
  width: em(24);
  display: block;
  position: absolute;
  left:0;
  right:0;
  margin: auto;
  bottom:10%;
  z-index: 999;
}
.mouse-icon .wheel {
  -webkit-animation-name: drop;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  -webkit-animation-name: drop;
  animation-name: drop;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.mouse-icon .wheel {
  position: relative;
  @include borderRadius(10px);
  background: $brand-white;
  width: em(2);
  height: em(6);
  top: em(4);
  margin-left: auto;
  margin-right: auto;
  display: inherit;
}
@-webkit-keyframes drop {
  0% {
    top: em(8);
    opacity: 0;
  }
  30% {
    top: em(12);
    opacity: 1;
  }
  100% {
    top: em(25);
    opacity: 0;
  }
}
@keyframes drop {
  0% {
    top: em(8);
    opacity: 0;
  }
  30% {
    top: em(12);
    opacity: 1;
  }
  100% {
    top: em(25);
    opacity: 0;
  }
}



@media (min-width: 1601px) and (max-width: 2400px) {
}

@media (min-width: 1530px) and (max-width: 1600px) {
}

@media (min-width: 1330px) and (max-width: 1366px) {
}

@media (min-width: 1201px) and (max-width: 1280px) {
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .mouse-icon{
    bottom: 18%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .mouse-icon{
    bottom: 5%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}