.historyPage-slider {
  display: block;
  width: 100%;
  overflow: hidden;
  padding:0;
//  background: $brand-white url("#{$websitePath}assets/images/history-page/history-bg.jpg") no-repeat center bottom;
  background-size: 100%;
  .collage-text {
    margin-bottom: em(54);
    h3 {
      font-size: em(40);
      letter-spacing: em(5);
      margin: 0 0 em(12);
      text-transform: uppercase;
    }
    p {
      font-size: em(20);
      text-align: justify;
    }
    .image{
      float: right;
    }
  }
  .headings{
    text-align: center;
    h2 {
      font-size: em(42);
      margin: 0 0 em(20) 0;
      font-weight: 700;
      font-family: "univers condensed";
      text-transform: uppercase;
      color: #000000;
    }
  }

}


.h-legacy {
  .owl-carousel{
    position: relative;
    .owl-controls{
      display: block !important;
      position: absolute;
      top: em(-500);
      width: 100%;
      //.owl-nav{
      //  float: right;
      //  .owl-prev, .owl-next{
      //    float: left;
      //  }
      //
      //}
      .owl-nav {
        position: absolute;
        left: 0;
        top: em(424);
        width: 100%;
        height: 0;
        .owl-prev {
          float: left;
          margin-top: 12px;
          margin-left: 0px;
          &.disabled{
            .icon-left, .icon-right{
              opacity: 0.2;
            }
          }
        }
        .owl-next {
          float: right;
          margin-right: 40px;
          .icon-left {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
          &.disabled{
            .icon-left, .icon-right{
              opacity: 0.2;
            }
          }
        }

        .icon-left {
          font-size: 2.5em;
          color: #c00;
          position: absolute;

        }

      }
    }
  }

}
.historyPage-slider{
  .cd-image-container{
    width: 74%;
  }
}
.h-history{
  .owl-item.active.center{
    text-align: left;
  }

}

.mac .h-legacy .h-history .item .text-area{
  height: em(202);
}
.h-legacy {
  margin-bottom: em(0);
  .h-history{
    margin-top: 35px;
  }
  /*OWL CROUSEL*/
  /*
 *  Owl Carousel - Animate Plugin
 */
  .owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .owl-carousel .owl-animated-in {
    z-index: 0;
  }
  .owl-carousel .owl-animated-out {
    z-index: 1;
  }
  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  /*
   * 	Owl Carousel - Auto Height Plugin
   */
  .owl-height {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
  }

  /*
   *  Core Owl Carousel CSS File
   */
  .owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
  }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
  }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
    margin-bottom: 0px;
  }
  .owl-carousel .owl-controls .owl-nav .owl-prev,
  .owl-carousel .owl-controls .owl-nav .owl-next,
  .owl-carousel .owl-controls .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .owl-carousel.owl-loaded {
    display: block;
  }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }
  .owl-carousel.owl-hidden {
    opacity: 0;
  }
  .owl-carousel .owl-refresh .owl-item {
    display: none;
  }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
  }
  .owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }
  .owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
  }
  .owl-carousel.owl-rtl {
    direction: rtl;
  }
  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }
  .h-history-years .owl-item.active.center{
    text-align: right;
  }


  /* No Js */
  .no-js .owl-carousel {
    display: block;
  }

  /*
   * 	Owl Carousel - Lazy Load Plugin
   */
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
  }
  .owl-carousel .owl-item img {
    transform-style: preserve-3d;
  }

  /*
   * 	Owl Carousel - Video Plugin
   */
  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }
  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transition: scale 100ms ease;
    -moz-transition: scale 100ms ease;
    -ms-transition: scale 100ms ease;
    -o-transition: scale 100ms ease;
    transition: scale 100ms ease;
  }
  .owl-carousel .owl-video-play-icon:hover {
    -webkit-transition: scale(1.3, 1.3);
    -moz-transition: scale(1.3, 1.3);
    -ms-transition: scale(1.3, 1.3);
    -o-transition: scale(1.3, 1.3);
    transition: scale(1.3, 1.3);
  }
  .owl-carousel .owl-video-playing .owl-video-tn,
  .owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
  }
  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    -webkit-transition: opacity 400ms ease;
    -moz-transition: opacity 400ms ease;
    -ms-transition: opacity 400ms ease;
    -o-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
  }
  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
  }

  /*OWL CROUSEL*/
  .h-history {
//    min-height: 424px;
    .item {
      background: #e6e9ed;
      padding: 0;
      transition: .8s;
      -webkit-transition: .8s;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      opacity: 1;
      width: em(410);
      direction: ltr;
      &.__loading {
        opacity: 0;
        transform: translateX(40px);
        -webkit-transform: translateX(40px);
      }
      .image-area {
        display: block;
        img{
          width: 100%;
        }
      }
      .text-area {
        display: block;
        padding: em(20);
        height: em(210);
        h4 {
          font-size: em(18);
          font-weight: bold;
          color:  $brand-primary;
          margin: 0 0 em(5);
        }
        h5 {
          font-size: em(14);
          font-weight: normal;
          color:  $brand-primary;
          margin: 0 0 em(22);
        }
        hr {
          width: 100%;
          background: none;
          position: relative;
          &:before {
            content: '';
            width: em(20);
            height: 2px;
            background: $brand-primary;
            margin-top: 0;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        p {
          font-size: 1em;
          font-weight: bold;
          color: #000;
          margin: 0 0 em(18);
          display: inline-block;
          width: 87%;
        }
        .plus {
          position: absolute;
          right: em(15);
          bottom: em(12);

          width: em(48);
          height: em(48);
          @include borderRadius(50%);
          @include transitions(0.5s);
          &:before{
            content: '';
            position: absolute;
            left: 0;
            top:0;
            width: em(48);
            height: em(48);
            background: $brand-white;
            @include transitions(0.3s);
            @include scale(1.1);
            @include borderRadius(50%);
          }
          &:after{
            content: '';
            position: absolute;
            left: 0;
            top:0;
            width: em(48);
            height: em(48);
            background: $brand-primary;
            @include transitions(0.5s);
            @include borderRadius(50%);

          }
          img {
            width: em(18);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            z-index: 2;
          }
        }
      }
      .expand{
        opacity: 0;
        display: block;
        height: em(524);
        position: absolute;
        top: 0;
        z-index: -1;
        background: #e6e9ed;
       .text-area{
         height: auto;
         p{
           width: 100%;
         }
         .close-ico{
           position: absolute;
           z-index: 0;
           color:  $brand-primary;
           padding-top: 0;
           font-size: 12px;
           text-decoration: underline;
           @include transitions(0.5s);
           background: url("#{$websitePath}assets/images/cross.png") no-repeat;
           cursor: pointer;
           width: 20px;
           height: 20px;
           right: 9px;
           top: 9px;
         }
       }
        &.show{
          opacity: 1;
          z-index: 9;
          width: 100%;
          .close-ico{
            z-index: 9;
          }
        }
      }
    }
  }
  .h-history-years {
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #b8c5ce;
      position: absolute;
      top: 25px;
      left: 0;
    }
    .owl-item {
      padding-top: 18px;
      margin-top: 48px;
      margin-bottom: 40px;
      &:before {
        content: '';
        height: 1px;
        background-color: #1b1b1b;
        width: 0%;
        right: 38px;
        position: absolute;

        top: -23px;
        @include transitions(0.3s);
      }
      .btn-filter {
        color: #93989c;
        font-size: 16px;
        font-weight: bold;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        top: 0;
        height: 36px;
        @include transitions(0.3s);
        &:before {
          content: '';
          width: 5px;
          height: 5px;
          position: absolute;
          right: 15px;
          bottom: initial;
          top: -16px;
          margin: auto;
          background: #323232;
          border: 1px solid #d2ecfa;
          @include transitions(0.3s);
          @include borderRadius(50%);
        }
        &:after {
          content: '';
          width: 5px;
          height: 5px;
          position: absolute;
          right: 15px;
          bottom: initial;
          top: -16px;
          margin: auto;
          border: 1px solid #d2ecfa;
          @include scale(1.1);
          @include transitions(0.3s);
          @include borderRadius(50%);
        }
      }
      &.hilighted {
        &:before {
          width: 88%;
        }
        .btn-filter {
          color: $brand-primary;
          font-weight: bold;
          font-size: 24px;
          pointer-events: none;
          &:before{
            background: $brand-primary;
            width: 7px;
            height: 7px;
            right: 24px;
            top: -16px;
          }
          &:after{
            width: 20px;
            height: 20px;
            right: 18px;
            top: -22.5px;
            border: 1px solid $brand-primary;
          }
        }

      }
    }
  }
}



@media (min-width: 1601px) and (max-width: 2400px) {

}

@media (min-width: 1530px) and (max-width: 1600px) {
  .h-legacy {
    .h-history {
      .item{
        width: 23em;
        .text-area {
          height: 14em;

        }
      }
    }
  }
}

@media (min-width: 1330px) and (max-width: 1366px) {

  .h-legacy .owl-carousel .owl-controls{
    top: -356px;
  }
  .h-legacy {
    .h-history-years .owl-item{
      .btn-filter {
        font-size: 14px;
      }
      &.hilighted {
        &:before {
          right: 32px;
        }
        .btn-filter{
          font-size: 18px;
          &:before {
            top: -17px;
            right: 16px;
          }
          &:after {
            top: -24.5px;
            right:  10px;
          }
        }
      }
    }
    .h-history {
        .item {
          .text-area {
            .plus {
              width: 2em;
              height: 2em;
              &:before, &:after {
                width: 2em;
                height: 2em;
              }
            }
          }
        }
    }
  }
}

@media (min-width: 1201px) and (max-width: 1280px) {

  .recall .container {
    width: 100em;
  }

  .recall .btn{
    padding: 1.2em 1.5625em;
  }
  .h-legacy {
    .h-history-years .owl-item{
      .btn-filter {
        font-size: 14px;
      }
      &.hilighted {
        &:before {
          right: 32px;
        }
        .btn-filter{
          font-size: 18px;
          &:before {
            top: -17px;
            right: 16px;
          }
          &:after {
            top: -24.5px;
            right:  10px;
          }
        }
      }
      .h-history {
        .item {
          .text-area {
            .plus {
              width: 2em;
              height: 2em;
              &:before, &:after {
                width: 2em;
                height: 2em;
              }
            }
          }
        }
      }
    }
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .h-legacy .owl-carousel .item .text-area .plus {
    &:hover{
      &:after {
        background: lighten($brand-primary, 5%);
      }
      &:before {
        @include scale(1.4);
      }
    }
  }
}




/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

  .h-legacy .owl-carousel .owl-controls{
    top:-370px;
  }
  .h-legacy {
    .h-history-years .owl-item{
      .btn-filter {
        font-size: 14px;
      }
      &.hilighted {
        &:before {
          right: 32px;
        }
        .btn-filter{
          font-size: 16px;
          &:before {
            top: -17px;
            right: 15px;
          }
          &:after {
            top: -24px;
            right:  9px;
          }
        }
      }
    }
    .h-history {
      .item {
        .text-area {
          .plus {
            width: 2em;
            height: 2em;
            &:before, &:after {
              width: 2em;
              height: 2em;
            }
          }
        }
      }
    }
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .h-legacy .owl-carousel .owl-controls{
    top:-349px;
  }
  .h-legacy {
    .h-history-years .owl-item{
      .btn-filter {
        font-size: 14px;
      }
      &.hilighted {
        &:before {
          right: 32px;
        }
        .btn-filter{
          font-size: 16px;
          &:before {
            top: -17px;
            right: 15px;
          }
          &:after {
            top: -24px;
            right:  9px;
          }
        }
      }
    }
    .h-history {
      .item {
        width: 22em;
        .text-area {
          height: 14em;
          p {
            width: 85%;
          }
          .plus {
            width: 2em;
            height: 2em;
            &:before, &:after {
              width: 2em;
              height: 2em;
            }
          }
        }
      }
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .historyPage-slider .collage-text .image {
    margin: 0px auto;
    text-align: center;
    display: inline-block;
    width: 100%;
    img {
      text-align: center;
      display: inline-block;
    }
  }



  .historyPage-slider{
    margin-bottom: em(20);
  }
  .h-legacy .owl-carousel .owl-controls{
   display: none !important;
  }
  .h-legacy {
    .h-history-years .owl-item{
      .btn-filter {
        font-size: 14px;
      }
      &.hilighted {
        &:before {
          right: 32px;
        }
        .btn-filter{
          font-size: 16px;
          &:before {
            top: -17px;
            right: 15px;
          }
          &:after {
            top: -24px;
            right:  9px;
          }
        }
      }
    }
    .h-history {
      .item {
        width: 28em;
        .text-area {
          height: 14em;
          p {
            width: 85%;
          }
        }
        .plus {
          width: 2em;
          height: 2em;
          &:before, &:after {
            width: 2em;
            height: 2em;
          }
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .h-legacy {
    .h-history {
      .item {
        width: em(320);
        .text-area {
          height: 18em;
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}