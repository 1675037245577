/*
 * Droid Arabic Kufi (Arabic) http://www.google.com/fonts/earlyaccess
 */


$websitePath: '/';

//Local Path
//$websitePath : '/en-sa/';
@font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 400;
  src: url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Regular.eot");
  src: url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Regular.eot?#iefix") format('embedded-opentype'),
  url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Regular.woff2") format('woff2'),
  url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Regular.woff") format('woff'),
  url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Regular.ttf") format('truetype');
}


@font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 700;
  src: url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Bold.eot");
  src: url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Bold.eot?#iefix") format('embedded-opentype'),
  url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Bold.woff2") format('woff2'),
  url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Bold.woff") format('woff'),
  url("#{$websitePath}assets/fonts/droidarabickufi/DroidKufi-Bold.ttf") format('truetype');
}


@font-face {
  font-family: AvenirNext-Light;
  src: url("#{$websitePath}assets/fonts/733cb7bd-50e1-4dee-893a-0b40ef382b02.ttf?1495477880") format('truetype'), url("#{$websitePath}assets/fonts/174d458a-81e0-4174-9473-35e3bf0a613c.woff2?1495477879") format('woff2'), url("#{$websitePath}assets/fonts/57a79aa3-9b06-4ba7-a9a4-2b766d826ecf.woff?1495477879") format('woff'), url("#{$websitePath}assets/fonts/594135c6-6c4f-4880-a0d2-ba923b5ef38e.svg?1495477880#594135c6-6c4f-4880-a0d2-ba923b5ef38e") format('svg'),  url("#{$websitePath}assets/fonts/5db267f9-3612-485c-ae18-5698d2692816.eot?&1495477880#iefix") format('embedded-opentype')
}

@font-face {
  font-family: AvenirNext-Regular;
  src: url("#{$websitePath}assets/fonts/276b3566-1c3b-4bc1-8915-15314f091f29.ttf?1495477879") format('truetype'), url("#{$websitePath}assets/fonts/7db1f672-3a8f-4d19-9c49-7f61aed450b5.woff2?1495477880") format('woff2'), url("#{$websitePath}assets/fonts/4ab86b35-c0c2-42b5-98ad-4b6eba66b197.woff?1495477879") format('woff'), url("#{$websitePath}assets/fonts/5d02f5f4-46e7-453a-aef9-3e7106d7bb68.svg?1495477880#5d02f5f4-46e7-453a-aef9-3e7106d7bb68") format('svg'), url("#{$websitePath}assets/fonts/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?&1495477879#iefix") format('embedded-opentype')
}

@font-face {
  font-family: AvenirNext-Medium;
  src: url("#{$websitePath}assets/fonts/ead8b64b-1abd-4d5b-a642-a21dfe2f463b.ttf?1495477541") format('truetype'), url("#{$websitePath}assets/fonts/b0b84e4d-2164-45c7-a674-1662f19f3ba6.woff2?1495477541") format('woff2'), url("#{$websitePath}assets/fonts/e91d1bbf-3fea-45e2-b003-a22b12ce6e5f.woff?1495477541") format('woff'), url("#{$websitePath}assets/fonts/e536e1c2-92a4-4db4-8a41-1c55354d11b7.svg?1495477541#e536e1c2-92a4-4db4-8a41-1c55354d11b7") format('svg'), url("#{$websitePath}assets/fonts/c6970a14-8b0f-4629-9072-71c7e123908f.eot?&1495477541#iefix") format('embedded-opentype')
}

@font-face {
  font-family: AvenirNext-DemiBold;
  src: url("#{$websitePath}assets/fonts/cbc021cc-b96f-4c82-ba0d-59cb0b7449c0.ttf?1495477541") format('truetype'), url("#{$websitePath}assets/fonts/40d36b4a-60c6-460a-bf43-4c948c23563e.woff2?1495477879") format('woff2'), url("#{$websitePath}assets/fonts/45b78f45-e639-4836-8612-e0892e120f14.woff?1495477879") format('woff'), url("#{$websitePath}assets/fonts/c6778bcf-ca82-4a86-8a5f-08b70c9a71fd.svg?1495477541#c6778bcf-ca82-4a86-8a5f-08b70c9a71fd") format('svg'), url("#{$websitePath}assets/fonts/fbf4298d-ef75-419c-b615-47f209a48ed2.eot?&1495477541#iefix") format('embedded-opentype')
}

@font-face {
  font-family: AvenirNext-Bold;
  src: url("#{$websitePath}assets/fonts/bce3274e-f498-48ae-b269-a6930df496e7.ttf?1495477541") format('truetype'), url("#{$websitePath}assets/fonts/4c04fb14-abdb-4f93-82bb-734d1b84307b.woff2?1495477879") format('woff2'), url("#{$websitePath}assets/fonts/04d2f223-2786-40c9-8481-be9dd47d8e7f.woff?1495477878") format('woff'), url("#{$websitePath}assets/fonts/68f0375d-f66f-4f88-822a-5a0b61d2efc0.svg?1495477880#68f0375d-f66f-4f88-822a-5a0b61d2efc0") format('svg'), url("#{$websitePath}assets/fonts/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?&1495477541#iefix") format('embedded-opentype')
}

@font-face {
  font-family: AvenirNext-MediumItalic;
  src: url("#{$websitePath}assets/fonts/03aefdc0-0198-4662-a9c7-640a4734063e.ttf?1495477878") format('truetype'), url("#{$websitePath}assets/fonts/cc0a88c2-524b-4c90-b6f0-a80570222c30.woff?1495477541") format('woff'), url("#{$websitePath}assets/fonts/349e5647-5161-46bb-a19f-8a609ae235e4.woff2?1495477879") format('woff2'), url("#{$websitePath}assets/fonts/132a539d-37fa-48fb-92ec-1f4591f98ee1.svg?1495477879#132a539d-37fa-48fb-92ec-1f4591f98ee1") format('svg'), url("#{$websitePath}assets/fonts/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?&1495477879#iefix")format('embedded-opentype')
}

@font-face {
  font-family: "univers";
  font-style: normal; font-weight: 300; src: url("#{$websitePath}assets/fonts/universltstd-light-webfont.woff?v=1.0.0") format("woff")
}

@font-face {
  font-family: "univers";
  font-style: normal;  font-weight: 400; src: url("#{$websitePath}assets/fonts/universltstd-webfont.woff?v=1.0.0") format("woff")
}

@font-face {
  font-family: "univers";
  font-style: normal; font-weight: 700; src: url("#{$websitePath}assets/fonts/universltstd-bold-webfont.woff?v=1.0.0") format("woff")
}


@font-face {
  font-family: "univers condensed";
  font-style: normal; font-weight: 300; src: url("#{$websitePath}assets/fonts/universltstd-lightcn-webfont.woff?v=1.0.0") format("woff")
}

@font-face {
  font-family: "univers condensed";
  font-style: normal; font-weight: 400; src: url("#{$websitePath}assets/fonts/universltstd-cn-webfont.woff?v=1.0.0") format("woff")
}

@font-face {
  font-family: "univers condensed";
  font-style: normal; font-weight: 700; src: url("#{$websitePath}assets/fonts/universltstd-boldcn-webfont.woff?v=1.0.0") format("woff")
}

@font-face {
  font-family: "univers ultra condensed";
  font-style: normal; font-weight:700; src: url("#{$websitePath}assets/fonts/universltstd-ultracn-webfont.woff?v=1.0.0") format("woff")
}

$avenir-next-light: AvenirNext-Light;
$avenir-next-regular: AvenirNext-Regular;
$avenir-next-medium: AvenirNext-Medium;
$avenir-next-demibold: AvenirNext-DemiBold;
$avenir-next-bold: AvenirNext-Bold;
$avenir-next-mediumitalic: AvenirNext-MediumItalic;
$univers: "univers";
$univers-condensed: "univers condensed";
$univers-ultra-condensed: "univers ultra condensed";

@font-face {
  font-family: "honda";
  src:url("#{$websitePath}assets/fonts/honda.eot?v=2");
  src:url("#{$websitePath}assets/fonts/honda.eot?#iefix?v=2") format("embedded-opentype"),
  url("#{$websitePath}assets/fonts/honda.woff?v=2") format("woff"),
  url("#{$websitePath}assets/fonts/honda.ttf?v=2") format("truetype"),
  url("#{$websitePath}assets/fonts/honda.svg#honda?v=2") format("svg");
  font-weight: normal;
  font-style: normal;

}


[data-icon]:before {
  font-family: "honda" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "honda" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//
//.icon-search:before {
//  content: "\61";
//}
//.icon-arrow-line:before {
//  content: "\63";
//}
//.icon-phone:before {
//  content: "\64";
//}
//.icon-email:before {
//  content: "\65";
//}
//.icon-cret:before {
//  content: "\62";
//}
//.icon-instagram:before {
//  content: "\66";
//}
//.icon-youtube:before {
//  content: "\67";
//}
//.icon-facebook:before {
//  content: "\68";
//}
//.icon-twitter:before {
//  content: "\6a";
//}
//.icon-plus-icon:before {
//  content: "\6c";
//}
//.icon-left:before {
//  content: "\6d";
//}
//.icon-linkdine:before {
//  content: "\69";
//}
//.icon-key:before {
//  content: "\6e";
//}
//.icon-android-add:before {
//  content: "\6f";
//}
//.icon-top-icon:before {
//  content: "\70";
//}
//.icon-download:before {
//  content: "\6b";
//}
//.icon-left-1:before {
//  content: "\71";
//}
//.icon-play:before {
//  content: "\72";
//}
//.icon-email-rtl:before {
//  content: "\73";
//}
//.icon-youtube-play:before {
//  content: "\75";
//}
//.icon-share02:before {
//  content: "\76";
//}
//.icon-phone-rtl:before {
//  content: "\74";
//}
//.icon-refresh:before {
//  content: "\77";
//}
//.icon-phone-icon:before {
//  content: "\78";
//}
//.icon-right:before {
//  content: "\79";
//}
//.icon-down-arrow:before {
//  content: "\7a";
//}
//.icon-trims-grey:before {
//  content: "\42";
//}
//.icon-wheels-grey:before {
//  content: "\43";
//}
//.icon-accessories-grey:before {
//  content: "\41";
//}
//.icon-basket-icon:before {
//  content: "\44";
//}
//.icon-cross:before {
//  content: "\45";
//}
//.icon-setting-icon:before {
//  content: "\46";
//}
//.icon-download-icon:before {
//  content: "\47";
//}
//.icon-checkmark-round:before {
//  content: "\48";
//}
//.icon-classcalendar:before {
//  content: "\49";
//}
//.icon-day-icon:before {
//  content: "\4a";
//}
//.icon-night-icon:before {
//  content: "\4b";
//}


.icon-search:before {
  content: "\61";
}
.icon-arrow-line:before {
  content: "\63";
}
.icon-phone:before {
  content: "\64";
}
.icon-email:before {
  content: "\65";
}
.icon-cret:before {
  content: "\62";
}
.icon-instagram:before {
  content: "\66";
}
.icon-youtube:before {
  content: "\67";
}
.icon-facebook:before {
  content: "\68";
}
.icon-twitter:before {
  content: "\6a";
}
.icon-plus-icon:before {
  content: "\6c";
}
.icon-left:before {
  content: "\6d";
}
.icon-linkdine:before {
  content: "\69";
}
.icon-key:before {
  content: "\6e";
}
.icon-android-add:before {
  content: "\6f";
}
.icon-top-icon:before {
  content: "\70";
}
.icon-download:before {
  content: "\6b";
}
.icon-left-1:before {
  content: "\71";
}
.icon-play:before {
  content: "\72";
}
.icon-email-rtl:before {
  content: "\73";
}
.icon-youtube-play:before {
  content: "\75";
}
.icon-share02:before {
  content: "\76";
}
.icon-phone-rtl:before {
  content: "\74";
}
.icon-refresh:before {
  content: "\77";
}
.icon-phone-icon:before {
  content: "\78";
}
.icon-right:before {
  content: "\79";
}
.icon-down-arrow:before {
  content: "\7a";
}
.icon-trims-grey:before {
  content: "\42";
}
.icon-wheels-grey:before {
  content: "\43";
}
.icon-accessories-grey:before {
  content: "\41";
}
.icon-basket-icon:before {
  content: "\44";
}
.icon-cross:before {
  content: "\45";
}
.icon-setting-icon:before {
  content: "\46";
}
.icon-download-icon:before {
  content: "\47";
}
.icon-checkmark-round:before {
  content: "\48";
}
.icon-classcalendar:before {
  content: "\49";
}
.icon-day-icon:before {
  content: "\4a";
}
.icon-night-icon:before {
  content: "\4b";
}
.icon-tik-tok:before {
  content: "\4d";
}
.icon-snapchat:before {
  content: "\4e";
}


