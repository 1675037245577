.form-wrapper, .file-upload-sec{
  position: relative;
  .thanks{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #070707;
    text-align: center;
    overflow: hidden;
    .thanks-inner{
      position: absolute;
      top: 0;
      bottom: 0;
      height: em(110);
      width: 100%;
      margin: auto 0;
      h3{
        margin: 0 0 em(10) 0;
        color: $brand-white;
      }
      p{
        margin: 0;
        color: $brand-white;
      }
    }
  }
  .loading, &.loading{
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: #f8f8f8;
    }
  }
  .loader{
    min-width: em(100);
    padding-top: em(50);
    color: #fff;
    width: auto!important;
    height: auto!important;
    text-align: center;
    font-style: normal;
    display: block;
    font-size: em(15);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    z-index: 1000;
    -webkit-transform: translateX(-50%)translateY(-50%);
    -ms-transform: translateX(-50%)translateY(-50%);
    transform: translateX(-50%)translateY(-50%);
    &:before {
      content: '';
      width: em(50);
      height: em(50);
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.66);
    }
    &:after{
      content: '';
      width: em(50);
      height: em(50);
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      -webkit-animation: loader .6s linear;
      animation: loader .6s linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: $brand-primary transparent transparent;
      border-style: solid;
      border-width: .2em;
      box-shadow: 0 0 0 1px transparent;
    }
  }
}



.contact-loading{
  float: left;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: $brand-white;
    opacity: 0.8;
  }
}
.contact-loader, .default-loader{
  min-width: em(100);
  padding-top: em(50);
  color: #fff;
  width: auto!important;
  height: auto!important;
  text-align: center;
  font-style: normal;
  display: block;
  font-size: em(15);
  position: absolute;
  top: 5%;
  left: 50%;
  margin: 0;
  z-index: 1000;
  -webkit-transform: translateX(-50%)translateY(-50%);
  -ms-transform: translateX(-50%)translateY(-50%);
  transform: translateX(-50%)translateY(-50%);
  &:before {
    content: '';
    width: em(50);
    height: em(50);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.66);
  }
  &:after{
    content: '';
    width: em(50);
    height: em(50);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    -webkit-animation: loader .6s linear;
    animation: loader .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: $brand-primary transparent transparent;
    border-style: solid;
    border-width: .2em;
    box-shadow: 0 0 0 1px transparent;
  }
}




.default-loading{
  float: left;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: $brand-white;
    opacity: 1;
  }
  .contact-loader{
    top: 20%;
  }
}


.enq-form {
  .form-wrapper {
    .thanks{
      background: #464f5d;
    }
    .loading{
      &:before{
        background: #464f5d;
      }
    }
  }
}
.captcha-img{
  position: relative;
  &.form-group{
    margin-bottom: 21px;
  }
}
.img-box{
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  color: $brand-white;
  position: absolute;
  right:0;
  top:0;
  height: 44px;
  width:130px;
  a{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    color: #56565e;
    display: inline-block;
    width: 100% ;
    position: relative;
    line-height: 44px;
    z-index: 9;
    &:before{
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 25px;
      line-height: 22px;
      font-family: "scbe" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    &:hover, &:focus{
      text-decoration: none;
    }

  }
  img{
    max-width: 100%;
  }
  .refresh-c{
    position: absolute;
    right:0;
    width: 60px;
    top:0;
    height: 23px;
    bottom:0;
    margin: auto;
    border-left:solid 1px #56565e;
    padding-left: 20px;
    color: #56565e;
  }
}
textarea{
  resize: none;
}



.location-map{
  &.loading{
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: rgba(0,0,0, 0.5);
    }
  }
  .loader{
    min-width: em(100);
    padding-top: em(50);
    color: #fff;
    width: auto!important;
    height: auto!important;
    text-align: center;
    font-style: normal;
    display: block;
    font-size: em(15);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    z-index: 1000;
    -webkit-transform: translateX(-50%)translateY(-50%);
    -ms-transform: translateX(-50%)translateY(-50%);
    transform: translateX(-50%)translateY(-50%);
    &:before {
      content: '';
      width: em(50);
      height: em(50);
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.66);
    }
    &:after{
      content: '';
      width: em(50);
      height: em(50);
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      -webkit-animation: loader .6s linear;
      animation: loader .6s linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: $brand-primary transparent transparent;
      border-style: solid;
      border-width: .2em;
      box-shadow: 0 0 0 1px transparent;
    }
  }
}



@-webkit-keyframes loader { from {
  -webkit-transform:rotate(0deg);
  transform:rotate(0deg);
}
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}
@keyframes loader { from {
  -webkit-transform:rotate(0deg);
  transform:rotate(0deg);
}
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}


.fv-form-bootstrap .help-block{
  display: none !important;
}



.h-legacy, .newsDiv, .safetyDiv, .eventsDiv, .offersDiv, .pp-page{
  &.loading{
    position: relative;
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: $brand-white;
      display: block;
    }
  }
  .loader{
    min-width: em(100);
    padding-top: em(50);
    color: #fff;
    width: auto!important;
    height: auto!important;
    text-align: center;
    font-style: normal;
    display: block;
    font-size: em(15);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    z-index: 1000;
    -webkit-transform: translateX(-50%)translateY(-50%);
    -ms-transform: translateX(-50%)translateY(-50%);
    transform: translateX(-50%)translateY(-50%);
    &:before {
      content: '';
      width: em(50);
      height: em(50);
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.66);
    }
    &:after{
      content: '';
      width: em(50);
      height: em(50);
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      -webkit-animation: loader .6s linear;
      animation: loader .6s linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: $brand-primary transparent transparent;
      border-style: solid;
      border-width: .2em;
      box-shadow: 0 0 0 1px transparent;
    }
  }
}
.pp-page{
  &.loading{
    &:after{
      background: #f3f6fa !important;
    }

  }
}



/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

}

/* Portrait phones and smaller */
@media (max-width: 480px) {

}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}