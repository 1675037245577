
.cd-image-container {
  position: relative;
  width: 66%;
  margin: 0 auto em(30) auto;
  img {
    display: block;
  }
}
.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-right: #cc0000 5px solid;
  img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: auto;
    max-width: none;
  }
}

.is-visible .cd-resize-img {
  width: 60%;
  /* bounce in animation of the modified image */
  -webkit-animation: cd-bounce-in 0.7s;
  -moz-animation: cd-bounce-in 0.7s;
  animation: cd-bounce-in 0.7s;

}

@-webkit-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 65%;
  }
  100% {
    width: 60%;
  }
}

@-moz-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 65%;
  }
  100% {
    width: 60%;
  }
}

@keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 65%;
  }
  100% {
    width: 60%;
  }
}

.cd-handle {
  position: absolute;
  height: em(70);
  width: em(70);
  left: 60%;
  top: 50%;
  margin-left: em(-38);
  margin-top: em(-22);
  border-radius: 50%;
  background: url("#{$websitePath}assets/images/after-before.png") no-repeat center center;
  cursor: move;
  background-size: cover;
  /* box-shadow: 0 0 0 6px rgba(0,0,0,0.2), 0 0 10px rgba(0,0,0,0.6), inset 0 1px 0 rgba(255,255,255,0.3); */
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0) scale(0);
  -moz-transform: translate3d(0, 0, 0) scale(0);
  -ms-transform: translate3d(0, 0, 0) scale(0);
  -o-transform: translate3d(0, 0, 0) scale(0);
  transform: translate3d(0, 0, 0) scale(0);
}

.cd-handle.draggable {
  /* change background color when element is active */
  background-color: #445b7c;
}

.is-visible .cd-handle {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  -moz-transform: translate3d(0, 0, 0) scale(1);
  -ms-transform: translate3d(0, 0, 0) scale(1);
  -o-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0s 0.7s;
  -moz-transition: -moz-transform 0.3s 0.7s, opacity 0s 0.7s;
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
}

@media (max-width: 480px)  {
  .cd-image-container {
    width: 100%;
  }
  .cd-handle {
    top: 42%
  }
}