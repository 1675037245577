
.video-with-text-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  .revealTo {
    &.video {
      width: 50%;
    }
  }
  .revealTo-content{
    width: 50%;
  }
}
.accord-hev-video-sec{
    padding: em(100) 0;
    &.with-bg{
      background: #f8f8f8;
    }
    iframe{
      height: 500px !important;
      width: 100% !important;
      margin-bottom: em(20);
    }
  p{
      width: 60%;
      text-align: center;
      margin: 5px auto 0 auto;
      font-size: em(18);
      line-height: em(27);
  }
  .revealTo-content{
    text-align: center;
    h2{
      font-family: "univers condensed";
      font-size: 1.75em;
      font-weight: 700;
      margin-top: 0;
    }
    p{
      width: 90%;
    }
  }
  img{
    margin: 0 auto;
    display: block;
  }
  .img-with-text{
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;
     background: #fff;
     padding-bottom: em(75);
    transition: 1s;
     &.sticky-bar{
       position: fixed;
       -webkit-box-shadow:0 5px 5px 0 rgba(0, 0, 0, 0.10);
       -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.10);
       box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.10);
       border-bottom:solid 1px #e3e3e3;
       width: 100%;
       top: 0;
       right: 0;
       left: 0;
       z-index: 99;
       padding: em(8) 0;
       .sticky-register-btn{
         right: em(102);
       }
     }
     img{
       width: em(350);
       margin: 0;
     }
     p{
       display: inline-block;
       width: auto;
       margin: 0;
       font-family: "univers condensed";
       font-size: em(20);
       font-weight: 700;
       color: #000;
       text-transform: uppercase;
     }
   }
  .revealTo {
    &.video {
      height: auto;
    }
  }
}

.enq-form-sec {
  &.register-interest{
    h2{
      font-family: "univers condensed";
      font-size: em(28);
      letter-spacing: 18px;
      font-weight: 700;
    }
    p{
      font-size: em(18);
      line-height: em(27);
    }
  }
  .captcha-feild {
    position: relative;
    span{
      position: absolute;
      bottom: -25px;
      left: 0;
      color: #900;
    }
    .img-box{
      bottom: em(3);
      top: auto;
      text-align: right;
    }
  }
  .dropdown-menu{
    top:0
  }
  .bootstrap-select.btn-group {
    .dropdown-menu {
      max-height: 210px !important;
    }
  }
}

.sticky-register-btn{
  //margin: 0 auto;
  //min-width: em(500);
  //display: table;
  //text-align: center;
  //padding: em(12) em(10) em(8) em(10);

  position: absolute;
  right: em(-86);
  min-width: em(300);
  z-index: 99;
  transition: none;
  padding: em(12) em(25) em(11) em(25);
}

.tel-input-field{
  display: flex;
  align-items: center;
  span {
    margin-right: em(10);
    color: #000000;
    font-size: em(18);
  }
}

.accord-hev-video-sec{
  iframe{
    background: #fff;
  }
}

.d-desktop{
  display: none;
}

.d-mobile{
  display: none;
}

.inner-banner {
  &.dct-banner {
    & + .accord-hev-video-sec {
      padding: em(15) 0 em(48);
    }
  }
}

@media (min-width: 1601px) and (max-width: 2400px) {
}

@media (min-width: 1530px) and (max-width: 1600px) {
  .sticky-register-btn{
    right: em(-22);
  }
  .accord-hev-video-sec {
    .img-with-text {
      &.sticky-bar {
        padding: em(10) 0;
        .sticky-register-btn {
          right: em(102);
        }
      }
    }
    iframe {
      height: 370px !important;
    }
  }
}

@media (min-width: 1367px) and (max-width: 1440px){
  .sticky-register-btn{
    right: em(-326);
  }
  .accord-hev-video-sec {
    .img-with-text {
      &.sticky-bar {
        .sticky-register-btn {
          right: em(54);
        }
      }
    }
  }
}

@media (min-width: 1330px) and (max-width: 1366px) {
  .sticky-register-btn {
    position: absolute;
    right: em(6);
  }
  .accord-hev-video-sec {
    .img-with-text {
      &.sticky-bar {
        padding: em(10) 0;
        .sticky-register-btn {
          right: em(86);
        }
      }
    }
    iframe {
      height: 330px !important;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1280px) {
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .d-desktop{
    display: block;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .sticky-register-btn {
    right: em(25);
  }
  .accord-hev-video-sec {
    .img-with-text {
      &.sticky-bar {
        .sticky-register-btn {
          right: em(25);
        }
      }
    }
  }
  .enq-form-sec {
    &.register-interest {
      h2 {
        letter-spacing: 6px;
      }
    }
    .dropdown-menu{
      top:em(-8);
    }
  }
  .accord-hev-video-sec {
    .video-iframe {
      height: auto !important;

      iframe {
        height: 340px !important;
      }
    }
  }
  .mobileText {
    display: none !important;
  }
  .d-desktop{
    display: block;
  }

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .sticky-register-btn {
    right: em(25);
  }
  .accord-hev-video-sec {
    .img-with-text {
      p{
        font-size: em(16);
      }
      img{
        width: em(260);
      }
      &.sticky-bar {
        padding: em(10) 0;
        .sticky-register-btn {
          right: em(25);
        }
      }
    }
  }
  .enq-form-sec {
    &.register-interest {
      h2 {
        letter-spacing: 6px;
      }
    }
    .dropdown-menu{
      top:em(-8);
    }
  }
  .accord-hev-video-sec {
    .video-iframe {
      height: auto !important;

      iframe {
        height: 260px !important;
      }
    }
  }
  .d-desktop{
    display: block;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .video-with-text-wrapper{
    flex-direction: column;
    .revealTo {
      &.video {
        width: 100%;
      }
    }
    .revealTo-content{
      width: 100%;
    }
  }
  .accord-hev-video-sec {
    .video-iframe {
      height: auto !important;

      iframe {
        height: 260px !important;
      }
    }
  }
  .d-mobile{
    display: block;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .video-with-text-wrapper{
    flex-direction: column;
    .revealTo {
      &.video {
        width: 100%;
      }
    }
    .revealTo-content{
      width: 100%;
    }
  }
  .enq-form-sec {
    &.register-interest {
      h2 {
        letter-spacing: 6px;
        line-height: 30px;
      }
    }
    .form-group {
      .bootstrap-select {
        & > .btn {
          width: 100% !important;
        }
      }
    }
    .dropdown-menu{
      top:em(-6);
    }
  }
  .accord-hev-video-sec {
    p{
      width: 100%;
    }
    iframe{
      height: 100% !important
    }
    .img-with-text {
      transition: none;
      padding: 0 em(0) em(30) em(0);
      row-gap: 10px;
      img{
        width: em(300);
      }
      &.sticky-bar{
        padding: em(15) em(50) em(15) em(50);
        img{
          width: em(250);
        }
      }
    }
  }
  .sticky-register-btn {
    position: initial;
    min-width: 100%;
    padding: em(16) em(25) em(11) em(25);
  }
  .accord-hev-video-sec {
    .video-iframe {
      height: auto !important;

      iframe {
        height: 260px !important;
      }
    }
  }
  .inner-banner{
    h1 {
      position: absolute;
      margin-top: 10%;
      transform: translateY(-50%);
    }
  }
  .revealTo {
    &.video {
      margin-top: em(30);
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}

