@-moz-keyframes bounce22 {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(em(-5));
    transform: translateY(em(-5));
  }
  60% {
    -moz-transform: translateY(em(-3));
    transform: translateY(em(-3));
  }
}
@-webkit-keyframes bounce22 {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(em(-5));
    transform: translateY(em(-5));
  }
  60% {
    -webkit-transform: translateY(em(-3));
    transform: translateY(em(-3));
  }
}
@keyframes bounce22 {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(em(-5));
    -ms-transform: translateY(em(-5));
    -webkit-transform: translateY(em(-5));
    transform: translateY(em(-5));
  }
  60% {
    -moz-transform: translateY(em(-3));
    -ms-transform: translateY(em(-3));
    -webkit-transform: translateY(em(-3));
    transform: translateY(em(-3));
  }
}

//.video-content-img{
//  height: em(980);
//  width: 100%;
//  float: left;
//  object-fit: cover;
//}

.sticky-right-icon{
  position: fixed;
  right: em(88);
  bottom: em(40);
  transform: translateY(-50%);
  background: #fff;
  z-index: 999999;
  list-style-type: none;
  width: em(56);
  height: em(56);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 0px;
  border-radius: 45px;
  margin: 0;
  transition: 0.5s;
    img{
      width: em(28);
      height: em(28);
      transition: 0.2s;
  }
  a{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.bottom-form{
  text-align: center ;
  .container{
    display: inline-block;
  }
  background: #070707;
  padding:em(32) 0;
  border-top:solid 5px #070707;
  border-bottom: 1px solid #121212;
  float: left;
  width:100%;
  position: relative;
  h3{
    font-size: em(18);
    color: $brand-white;
    margin: em(12) 0 0 0;
    padding: 0 0 0 em(66);
    position: relative;
    font-weight: 400;
    text-align: left;
    i{
      position: absolute;
      left:0;
      top:em(-3);
      width:em(47);
      height:em(21);
      img{
        width:100%;
      }
    }
  }
  .form-group{
    width:33.333%;
    float: left;
    margin-right: 4.666%;
    margin-bottom: 0;
    &.last-child{
      margin-right: 0;
      width: 23.80%;
    }
  }
  .form-control{
    border:solid 1px #161616;
    border-left:none;
    border-top: none;
    background: $brand-black;
    @include borderRadius(0);
    /*height:em(61);
    font-size: em(11);*/
    height:em(41);
    font-size: em(16);
    color: $brand-white;
    &:focus{
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
  .form-wrapper{
    overflow: hidden;
    padding-left: 5%;
    .loader{
      font-size: em(8);
    }
    .thanks {
      .thanks-inner{
        height:100%;
        padding-top: 0.7%;
      }
    }
  }
  .btn-white{
    background: none;
    border:solid 1px #666666;
    color: $brand-white;
    letter-spacing: em(1);
    height:em(52);
    min-width:100%;
    &:focus{
      outline: none;
    }
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #6d6d6d;
    text-transform: uppercase;
    letter-spacing: em(1);
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #6d6d6d;
    text-transform: uppercase;
    letter-spacing: em(1);
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #6d6d6d;
    text-transform: uppercase;
    letter-spacing: em(1);
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #6d6d6d;
    text-transform: uppercase;
    letter-spacing: em(1);
  }
  .has-error {
    .form-control{
      border: 1px solid $brand-primary;
    }
  }
  .has-success {
    .form-control{
      border: 1px solid  #3c763d;
    }
  }
  .form-wrapper .loading:before {
    background: #070707 ;
  }
}

footer{
  background-size: cover !important;
  padding: em(70) 0;
  float: left;
  width:100%;
  position: relative;
  overflow: hidden;
  .container{
    position: relative;
    z-index: 2;
    &.ft-desktop-none{ margin-bottom:em(130);}

  }
  h4{
    color: $brand-white;
    text-transform: uppercase;
    font-size: em(16);
    margin: 0 0 em(30) 0;
    font-weight: 700;
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      list-style:none;
      display: block;
      margin: 0 0 em(10) 0;
      a{
        color: #808080;
        font-size: em(16);
        @include transitions(0.5s);
        position: relative;
        overflow: hidden;
        display: inline-block;
        word-spacing: 3px;
        &:after{
          width:100%;
          height:1px;
          position: absolute;
          content: '';
          @include transitions(0.5s);
          background: $brand-white;
          left:-100%;
          bottom:0;
        }
        &:hover, &:focus{
          text-decoration: none;
        }
        &:focus{
          color: #fff;
        }
      }
    }
  }
  .col-md-2{
    width:20%;
  }
  h1{
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 22%;
    font-size: em(130);
    margin: 0;
    text-transform: uppercase;
    font-family: $univers-condensed;
    font-weight: 700;
    color: rgba(139,139,139,0.4);
    letter-spacing: em(3.5);
    white-space: nowrap;
    mix-blend-mode: color-dodge;
  }
  .socialList{
    width:100%;
    position: relative;
    z-index: 2;
    margin:0 0 em(35) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .socialListInner{
      width: 33.333%;
      &:nth-child(2){
        text-align: center;
        h4{
          text-align: center;
        }
      }
      &:last-child{
        text-align: right;
        h4{
          text-align: right;
        }
      }
    }
    .socialListInner:only-child {
      margin: 0 auto;
      text-align: center;
      h4{
        text-align: center;
      }
    }
    h4{
      margin-left: em(8);
      text-align: left;
    }
    //&:after, &:before{
    //  position: absolute;
    //  content: '';
    //  top:0;
    //  width:40%;
    //  background: #1f1f1f;
    //  mix-blend-mode: screen;
    //  height: 1px;
    //  bottom: 6px;
    //  margin: auto;
    //}
    &:after{
      left:0;
    }
    &:before{
      right:0;
    }
    ul{
      margin: auto;
      display: block;
      padding: 0;
      li{
        display: inline-block;
        margin: 0 em(8);
        a{
          color: $brand-white;
          font-size: em(19);
          @include transitions(0.5s);
          position: relative;
          overflow: visible;
          &:after{
            display: none;
          }
          &:before{
            width:em(35);
            height:em(35);
            position: absolute;
            left:em(-9);
            top:em(-8);
            content: '';
            background: $brand-primary;
            @include transitions(0.3s);
            @include borderRadius(50%);
            @include scale(0);
          }
          &.facebookIcon{
            &:before{
              left:em(-10);
            }
          }
          i{
            position: relative;
            z-index: 1;
          }
          &:hover, &:focus{
            text-decoration: none;
          }
        }
      }
    }
  }
  p{
    color: $brand-white;
    margin: 0;
    font-size: em(13);
  }
}
.gecko footer h1, .chrome46 footer h1, .ie footer h1{
  color: rgba(139,139,139,0.04);
}
.gecko.firefox footer h1{
  color: rgba(139,139,139,0.4);
}
//.gecko .bootstrap-select.btn-group .btn .caret,
.ie .bootstrap-select.btn-group .btn .caret{
  top:em(12);
}

.gecko.firefox .bootstrap-select.btn-group .btn .caret{
  top:em(4);
}


.pro_landing{
  footer{
    /*height: 100vh;*/
  }
  footer{
    h1{
      bottom: 31.4%;
    }
  }
}

.homepage.normal-pages {
  .margin-b{
    margin-bottom: em(40);
  }
  footer{
    margin-bottom: 0px;
  }
}

.margin-b{
  margin-bottom: em(55);
}
.lastList{
  margin: 0;
  padding: 0;
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  li{
    list-style:none;
    float: left;
    margin: 0 em(15) 0 0;
    border-right: solid 1px #bcbcbc;
    padding-right: em(15);
    line-height: em(12);
    &:last-child{
      margin: 0;
      padding: 0;
      border:none;
    }
    a{
      color: $brand-white;
      font-size: em(13);
      @include transitions(0.5s);
      padding-bottom: em(3);
      &:hover, &:focus{
        text-decoration: none;
      }
      &:after{
        background: #808080;
      }
    }
  }
}
.back-to-top{
  color: $brand-white;
  text-transform: uppercase;
  font-size: em(11);
  position: relative;
  @include transitions(0.5s);
  &:after{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 em(6) em(7) em(6);
    border-color: transparent transparent #b4b4b4 transparent;
    position: absolute;
    left:0;
    right:0;
    margin: auto;
    top:em(-25);
    content: '';
    @include transitions(0.5s);
  }
  &:hover, &:focus{
    text-decoration: none;
  }
  &:focus{
    color: $brand-white;
  }
}

.mobile-ftList{
  display: none;
}


.motorcycle-home-ftr{
  footer{
    padding: 2.375em 0;
    h4{
      margin: 0 0 1.275em;
    }
    .container{
      &.ft-desktop-none{
        margin-bottom: 2em;
      }
    }
  }
  .margin-b{
    margin-bottom: 2.4375em;
  }
}

.feedback-text{
  background: #c00;
  color: #fff;
  display: inline-block;
  padding: em(3) em(45);
  margin:em(20) 0 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: em(15);
  text-align: center;
}

.innerMenu {
  &> li {
    .dropDown {
      ul {
        li {
          .logo-text {
            span {
              &.disclaimer-text {
                color: #c00;
                a {
                  color: inherit;
                  font-weight: 600;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

.recall-text-dropdown{
  .dropdown-menu{
    max-height: 260px !important;
    padding-top: 0;
  }
}

@media (min-width: 1700px) and (max-width: 1800px){
  .mac{
    body{
      font-size: 15px;
      .sticky-right-icon {
        bottom: em(72);
      }
    }
  }
}

@media (min-width: 1367px) and (max-width: 1600px){
  footer .socialList{
    margin: 6.125em 0 1.75em 0;
  }

  .pro_landing{
    footer .socialList{
      margin: 4.125em 0 1.75em 0;
    }
  }


  .motorcycle-home-ftr{
    footer{
      padding: 2.375em 0;
      h4{
        margin: 0 0 1em;
      }
      .container{
        &.ft-desktop-none{
          margin-bottom: 3.125em;
        }
      }
    }
    .margin-b{
      margin-bottom: 1.2375em;
    }
  }

}

@media (min-width: 1500px) and (max-width: 1600px){
  .latest-news {
    .txt {
      min-height: em(250);
    }
  }
  footer{
    padding-top: em(40);
    .container {
      &.ft-desktop-none {
          margin-bottom: 0;
      }
    }
    .socialList{
      margin: em(15) 0 em(25);
    }
  }
}



@media (min-width: 1200px) and (max-width: 1366px){
  footer {
    .container {
      &.ft-desktop-none {
        margin-bottom: em(70);
      }
    }
  }
  .latest-news {
    .txt {
      min-height: em(250);
    }
  }
  .convenience-section .banner-img.video .openvideolink{
    bottom: -14%;
  }
  footer {
    .socialList{
      margin-top: em(66);
      //margin-bottom: em(22);

    }
    ul li a {
      font-size: em(18);
    }
    p {
      font-size: em(16);
    }
    .lastList li a {
      font-size: em(16);
      padding-top: 5px;
    }
    .back-to-top {
      font-size: em(14);
    }
    h1{
      bottom: 20%;
    }
  }
  .bottom-form {
    .form-control {
      font-size: em(16);
    }
    .thanks .thanks-inner p {
      font-size: 1.2em;
    }
  }

  .motorcycle-home-ftr{
    footer{
      padding: 2.375em 0;
      h4{
        margin: 0 0 1em;
      }
      .container{
        &.ft-desktop-none{
          margin-bottom: 3.125em;
        }
      }
    }
    .margin-b{
      margin-bottom: 2em;
    }
  }

}



@media (min-width: 1201px) and (max-width: 1280px) {

  footer{
    .lastList li a{
      padding-bottom: em(5);
    }
  }
  .pro_landing{
    footer{
      height: auto;

      h1 {
        bottom: 21.3%;
      }
    }

  }

  .motorcycle-home-ftr{
    footer{
      padding: 2.375em 0;
      h4{
        margin: 0 0 1em;
      }
      ul{
        li{
          a{
            font-size: 0.9em;
          }
        }
      }
      .container{
        &.ft-desktop-none{
          margin-bottom: 3.125em;
        }
      }
    }
    .margin-b{
      margin-bottom: 2em;
    }
  }

}






/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  footer {
    ul {
      li {
        a{
          &:hover{
            color: $brand-white;
            &:after{
              left:0;
            }
          }
        }
      }
      &.moto-scroll{
        max-height: 340px;
      }
    }
    .socialList{
      li{
        a{
          &:hover{
            color: $brand-white;
            &:before{
              @include scale(1);
            }
          }
        }
      }
    }
  }
  .lastList {
    li {
      a{
        &:hover{
          color: #808080;
        }
      }
    }
  }
  .back-to-top{
    &:hover{
      color: #808080;
      &:after{
        border-color: transparent transparent #808080 transparent;
        -moz-animation: bounce22 2s infinite;
        -webkit-animation: bounce22 2s infinite;
        animation: bounce22 2s infinite;
      }
    }
  }
  .sticky-right-icon{
    &:hover{
      img{
        transform: scale(0.9);
      }
    }
  }
}

@media (min-width: 1024px){
  .bottom-form .form-control{
   // font-size: .7875em;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  footer{
    font-size: em(20);
    .col-md-2{
      width:20%;
    }
    h1{
      bottom: 21.5%;
    }
  }
  .bottom-form {
    h3{
      font-size: em(17);
    }
  }

  .search-box{
    padding: 0.9em 0;
    .magnify{
      top:0.2em;
    }
    .cross-btn{
      width: 2em;
      height: 2em;
      top: 1.2em;
    }
  }
  .feedback-text{
    width: 100%;
    text-align: center;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {


  .bottom-form h3 {
    margin:0px 0 0 0;
    i{
      top: 0.5em;
    }
  }


  .bottom-form .btn-white{
    height: 3em;
    i {
      top: .33em;
      right: 0.7em;
    }
  }


    .tow-column-section .large-img{
      float: right;
      height: 55vh;
    }

  .pro_landing footer {
    height: auto;
  }

  .search-box{
    padding: 0.9em 0;
    .magnify{
      top:0.2em;
    }
    .cross-btn{
      width: 2em;
      height: 2em;
      top: 1.2em;
    }
  }
  footer{
   .col-md-2{
      width:20%;
      float: left;
    }
  }
  .bottom-form {
    .form-wrapper{
      padding-left: 0;
    }
  }
  .feedback-text{
    width: 100%;
    text-align: center;
  }
}


/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .lastList{
    justify-content: center;
  }

  footer .socialList ul {
    display: inline-block;
    li{
      width: auto;
      padding: 0px 10px;
    }

  }

  .copyright{
    position: absolute;
    bottom: 0px;
  }
  //
  //.f_menu_mobile{
  //  margin-top: -50px;
  //}


  .mobile-center{
    padding-bottom: 50px;
  }

  .ft-desktop-none{
    display: none;
  }
  .bottom-form {
    padding: em(50) 0;
    background: #0b0b0b;
    h3{
      margin: 0 0 em(15) 0;
      text-align: center;
      font-size: em(20);
      i{
        margin: 0 0 0 -50px;
        display: inline-block;
        left:initial;
        top:em(-1);
      }
    }
    .form-wrapper{
      padding-left: 0;
    }
    .btn{
      font-size: em(15);
      text-align: center;
      display: inherit;
      margin-left: 0px;
      min-width: a;
      span{
        margin-right:0;
      }
      i{
        right: initial;
        display: none;
      }
    }
    .form-control{
      font-size: em(13);
    }
    .form-group{
      width:49%;
      margin-right: 1%;
      margin-bottom: em(30);
      &:nth-child(2){
        float: right;
      }
      &.last-child{
        width:auto;
        margin-bottom: 0;
      }
    }
  }
  body .bottom-form .form-wrapper .thanks .thanks-inner{
    height: 96px;
    padding-top: 40px;
  }
  .mobile-center{
    text-align: center;
  }
  .back-to-top{
    margin-bottom: em(20);
    display: none;
  }
  footer {
    background-size: 100% 100% !important;
    padding: em(40) 0;
    height: auto !important;

    p{
      margin-bottom: em(20);
      font-size: em(18);
    }
    .col-md-2{
      width:50%;
    }
    h1{
      display: none;
    }
    h4{
      font-size: em(24);
      position: relative;
      @include transitions(0.5s);
      margin-bottom: 0;
      padding-top: em(12);
      padding-bottom: em(12);
      i{
        position: absolute;
        right:0;
        top:0;
        width:em(50);
        height:100%;
        background: #1e1e1e;
        text-align: center;
        &:before{
          position: absolute;
          left:0;
          right:0;
          top:0;
          bottom:0;
          margin: auto;
          background: url("#{$websitePath}assets/svgs/plus.svg") no-repeat;
          background-size: 100% 100%;
          content: '';
          display: block;
          width:em(15);
          height:em(15);
        }
      }
      &.active{
        color: $brand-primary;
        margin-bottom: em(10);
        i{
          &:before{
            background: url("#{$websitePath}assets/svgs/minus.svg") no-repeat;
            width:em(20);
            height:em(3);
          }
        }
      }
    }
    .ft-collapse{
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      li{
        a{
          font-size: em(20);
        }
      }
    }
    .ftcon{
      padding-left: 0;
      padding-right: 0;
      .row{
        margin-right: 0;
        margin-left: 0;
        [class^="col-"]{
          border-bottom:solid 1px #303030;
          padding: em(15) em(30);
        }
      }
    }
    .socialList{
      z-index: 99999;
      margin: em(0) 0 em(0) 0;
      float: left;
      &:after, &:before{
        display: none;
      }
      ul{
        li{
          //width:20%;
          text-align: center;
          margin: 0;
          float: left;
          &:last-child{
            margin: 0;
          }
          a{
            font-size: em(25);
          }
        }
      }
    }
  }
  .margin-b{
    margin-bottom: 0;
  }
  .lastList{
    float: none;
    margin: 0 auto;
    li{
      display: inline-block;
      float: none;

      a{
        font-size: em(16);
        overflow: visible;
        &:after{
          display: none;
        }
      }
    }
  }
  .mobile-ftList{
    padding: em(15);
    display: block;
    ul{
      margin: 0;
      padding: 0;
      width:100%;
      text-align: center;
      li{
        display: inline-block;
        float: none;
        list-style:none;
        margin: 0 em(0) em(30) 0;
        border-right: solid 1px #bcbcbc;
        padding-right: em(8);
        padding-left: em(6);
        line-height: em(14);
        &:last-child{
          margin: 0;
          padding: 0;
          border:none;
        }
        a{
          color: $brand-white;
          font-weight: 700;
          text-transform: uppercase;
          font-size: em(16);
          i{
            width:em(30);
            height:em(30);
            display: block;
          }
        }
      }
    }
  }
  body #section5{
    padding-top: 0px;
  }
  .feedback-text{
    width: 100%;
    text-align: center;
  }
  .sticky-right-icon{
    right: em(25);
    bottom: 0;
  }
}
@media (min-width: 700px) and (max-width: 767px) {

  body  .mobile-ftList ul li{
    border:0px;
    padding-left: 0px;
    padding-right: 0px;
    a{
      line-height:1em;
      position: relative;
      padding-right: .6em;
      padding-left: .375em;

      &:after{
        left: auto;
        width: 1px;
        content: '';
        position: absolute;
        right: 0px;
        top:-1px;
        background:#bcbcbc;
        height: 12px;
      }
    }
    &:last-child{
      a{
        &:after{
          display: none;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  footer {
    .socialMb{
      padding: 0;
    }
    .socialList{
      flex-direction: column;
      margin-top: em(30);
      .socialListInner{
        margin-bottom: em(45);
        text-align: center;
        position: relative;
        width: 100%;
        &:before{
          content: '';
          background: #1f1f1f;
          position: absolute;
          width: 100%;
          height: 1px;
          top: em(-25);
          left: 0;
        }
        &:nth-child(3){
          text-align: center;
          h4{
            text-align: center;
          }
        }
        &:last-child {
          text-align: center;
          h4 {
            text-align: center;
          }
        }
      }
      h4{
        text-align: center;
      }
    }

  }
  .feedback-text{
    width: 100%;
    text-align: center;
  }
  .sticky-right-icon{
    right: em(25);
    bottom: 0;
  }
}

@media (min-width: 411px) and (max-width: 413px) {

  body  .mobile-ftList ul li{
    border:0px;
    padding-left: 0px;
    padding-right: 0px;
    a{
      line-height:1em;
      position: relative;
      padding-right: .6em;
      padding-left: .375em;

      &:after{
        left: auto;
        width: 1px;
        content: '';
        position: absolute;
        right: 0px;
        top:-1px;
        background:#bcbcbc;
        height: 12px;
      }
    }
    &:last-child{
      a{
        &:after{
          display: none;
        }
      }
    }
  }

}
/* Portrait phones and smaller */
@media (max-width: 480px) {

  .logo-section{
    padding: 1.9375em 0px 1.8125em 0;
  }

  .bottom-form h3 {
    text-align: left;
    padding-left: 50px;
    i{
      margin: 0 0 0 -50px;
    }
  }
  .bottom-form {
    .form-group{
      width:100%;
      margin-bottom: em(15);
      &.last-child{
        margin-bottom: 0;
        width:auto;

      }
    }
    .btn{
      display: block;
      width: 100%;
    }

  }
  footer {
    .col-md-2{
      width:100%;
    }
  }
  .sticky-right-icon{
    right: em(25);
    bottom: 0;
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {
  .mobile-center {
    padding-bottom: 66px;
  }
  .lastList {
    li {
      a{
        font-size: em(15);
      }
    }
  }
  .sticky-right-icon{
    right: em(25);
    bottom: 0;
  }
}



