.headings{
  .btn-area{
    margin-top: em(40);
    margin-bottom: em(20);
  }
}
.full-three-col.no-margin .btn-area {
  text-align: center;
  .btn{
    float: none;
    margin: 0 em(40);
    display: inline-block;
    min-width: em(280);
    height: em(56);
    line-height: em(56);
    padding: 0 em(18);
    font-size: 1em;

    i {
      top: .5em;
    }
  }
  .btn-hollow{
    border: 1px solid $brand-primary;
    @include transitions(0.5s);
    span {
      position: relative;
      z-index: 9;
    }
    i{
      z-index: 9;
    }
    .icon {
      width: em(32);
      height: 100%;
      background: url("#{$websitePath}assets/svgs/pdf-down.svg") center no-repeat;
      margin-right: 1em;
      background-size: 100%;
      float: left;
      z-index: 9;
      line-height: 3.5em;
      position: relative;
      @include transitions(0.5s);
    }
    &:before{
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0;
      z-index: 1;
      content: '';
      background: lighten($brand-primary, 10%);
      -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }
}

.service-menu {
  display: block;
  background: #f1f4f9;
  padding: em(70) 0;
  text-align: center;
  overflow: visible;
   h2 {
     font-size: em(30);
     margin:  0 auto em(20);
     position: relative;
     display: inline-block;
     text-align: center;
      text-transform: uppercase;
     font-weight: bold;
     color: #000;
     &:before {
       content: '';
       position: absolute;
       right: initial;
       left: em(-160);
       width: em(120);
       top: 0;
       bottom: 0;
       margin: auto;
       height: 1px;
       background: #949494;
     }
     &:after {
       content: '';
       position: absolute;
       left: initial;
       right: em(-160);
       width: em(120);
       top: 0;
       bottom: 0;
       margin: auto;
       height: 1px;
       background: #949494;
     }
   }
  .service-dd {
    text-align: center;
    font-size: 1em;
    .form-group {
      float: none;
      width: 18%;
      margin: 0 3% em(80);
      display: inline-block;
      box-shadow: 0 0 15px 0 #dadee4;
      .form-control {
        height: em(58);
        border: none;
        border-radius: 0;
        .btn{
          height: 100%;
          .filter-option {
            font-size: 1.23em;
            font-weight: normal;
          }
          .caret {
            right: 20px;
            top: 1.3em;
          }
        }
        &.dropup {
          .caret{
            top: 2em;
          }
        }
      }
      &.has-error {
        box-shadow: 0 0 0px 0 #a94442;
        border: 2px solid #a94442;
      }
      &.has-success {
        border: 2px solid #3c763d;
        box-shadow: 0 0 0px 0 #3c763d;
      }
    }

  }
  .btn-area {
    text-align: center;
    .btn{
      float: none;
      margin: 0 em(40);
      display: inline-block;
      min-width: em(280);
      height: em(56);
      line-height: em(56);
      padding: 0 em(82) 0 em(18);
      font-size: 1em;

      i {
        top: .5em;
      }
    }
    .btn-hollow{
      border: 1px solid $brand-primary;
      @include transitions(0.5s);
      span {
        position: relative;
        z-index: 9;
      }
      i{
        z-index: 9;
      }
      .icon {
        width: em(32);
        height: 100%;
        background: url("#{$websitePath}assets/svgs/pdf-down.svg") center no-repeat;
        margin-right: 1em;
        background-size: 100%;
        float: left;
        z-index: 9;
        line-height: 3.5em;
        position: relative;
        @include transitions(0.5s);
      }
      &:before{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 0;
        z-index: 1;
        content: '';
        background: lighten($brand-primary, 10%);
        -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
      }
    }
    &.left-sty{
      float: left;
    }
    &.right-sty{
      float: right;
    }
  }
  .form-wrapper {
    position: relative;
    .thanks-sMenu {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: #414a58;
      text-align: center;
      overflow: hidden;
      .thanks-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 6.875em;
        width: 100%;
        margin: auto 0;
        p {
          margin: 0;
          color: #fff;
          font-size: 1.5625em;
        }
      }
    }
    .loading{
      &:before {
        background: #f1f4f9;
        top: -10%;
        height: 110%
      }
    }
  }
}
.terms_text_ser{
  margin-top: 70px;
  h6{
    color: #c00;
  }
  p, li{
    font-size: 12px;
  }

}
.s-search-result {
  display: none;
  padding: em(100) 0 em(100);
  h2 {
    font-size: em(30);
    text-transform: uppercase;
    font-weight: bold;
    color: #222222;
    padding: 0 0 em(10);
    margin: 0 0 em(30);
    position: relative;
    &:before {
      content: '';
      width: 20px;
      height: 2px;
      background: #222222;
      position: absolute;
      left: 0;
      bottom: 0;

    }
  }
  .box {
    display: inline-block;
    width: 24%;
    position: relative;
    margin-right: 9%;
    vertical-align: top;
    &:nth-child(3) {
      &:before{
        display: none;
      }
    }
    &:before {
      content: '';
      width: 1px;
      height: em(80);
      position: absolute;
      right:0;
      border-right: 1px dashed #959da9;
    }

    h4 {
      color: #737373;
      font-size: em(22);
      margin: 0 0 em(8);
    }
    h3 {
      font-size: em(28);
      text-transform: uppercase;
      font-weight: bold;
      color: #222222;
      padding: 0 0 em(10);
      margin: 0 0 em(30);
    }

  }
  .spliter {
    width: 92%;
    display: block;
    height: 1px;
    background: #caced4;
    margin-bottom: em(46);
  }
  .including {
    h5{
      font-size: em(20);
      color: $brand-primary;
      margin: 0 0 em(46);
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 0 9% 0 0;
        padding: 0 0 0 em(20);
        font-size: em(18);
        position: relative;
        display: inline-block;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width:  em(13);
          height: 100%;
          background: url("#{$websitePath}assets/images/services/listing.png") center no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .price-box{
    background: #f6f8fb;
    border: 1px dashed #959da9;
    text-align: center;
    padding: em(70) 0;
    width: 100%;
    p {
      text-transform: uppercase;
      font-size: em(22);
      color: #222222;
      letter-spacing: 2px;
      position: relative;
      text-align: center;
      padding: 0 0 em(16);
      &:before{
        content: '';
        width: 20px;
        height: 2px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: #222222;
      }
    }
    .total-price {
      font-weight: bold;
      color: $brand-primary;
      font-size: em(54);
    }
  }
}
.book-service {
  display: block;
  background: #414a58;
  padding: em(76) 0;
  overflow: inherit;
  h2 {
    font-size: em(30);
    text-transform: uppercase;
    font-weight: bold;
    color: $brand-white;
    padding: 0 0 em(10);
    margin: 0 0 em(30);
    position: relative;
    &:before {
      content: '';
      width: 20px;
      height: 2px;
      background: $brand-white;
      position: absolute;
      left: 0;
      bottom: 0;

    }
  }
  .form-wrapper {
      #serviceForm{
            //position: relative;
            padding: 10px;
          }
    .thanks-book {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: #414a58;
      text-align: center;
      overflow: hidden;
      .thanks-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 6.875em;
        width: 100%;
        margin: auto 0;
        p {
          margin: 0;
          color: #fff;
          font-size: 1.5625em;
        }
      }
    }
    .loading{
      &:before {
        background: #414a58;
      }
    }
    form {
      .row {
        margin: 0;
      }
    }
  }
  form {
    .row {
      .col-md-4 {
        padding-right: em(62);
        padding-left: em(62);
        &:nth-child(1){
          padding-left: 15px;
          padding-right: em(110);
        }
        &:nth-child(3){
          padding-right: 15px;
          padding-left: em(110);
          float: right;
        }
      }
    }

  }
  .form-group {
    width: 100%;
    margin-bottom: em(40);
    .form-control {
      height: em(50);
      border-radius: 0;
      font-size: em(15);
      font-weight: normal;
      .selectpicker {
        font-size: 1em;
        height: 100%;
        .filter-option {
          font-size: em(15);
          font-weight: normal;
          color: #7f7f7f;
        }
        .caret {
          top: em(12);
          right: em(15);
        }
      }
    }
    &.location-caret{
      .bootstrap-select.dropup.btn-group .btn .caret{
        top: inherit !important;
        transform: rotate(0deg);
      }

    }

    .addButton {
      background: none;
      font-size: em(13);
      float: right;
      color: #d2d4d6;
      padding: em(8) 0 0;
      font-weight: 300;
      margin-bottom: 2em;
     img {
       position: relative;
       margin-right: em(8);
       top: em(-2);
       width: em(13);
     }
    }
    .removeButton {
      background: none;
      font-size: em(13);
      float: right;
      color: #d2d4d6;
      padding: em(15) 0 0;
      font-weight: 300;
      img {
        position: relative;
        top: -2px;
        width: em(15);
        height: 2px;
        margin: 0 em(10) 0 0;
      }
    }
    &.twin {
      width: 48%;
      float: left;
      +.twin {
        float: right;
      }
    }
    &.beforeadded {

    }
    &.added {
      margin-bottom: em(20);
      display: inline-block;
      .bootstrap-select {
        width: 76%;
        float: left;
      }
    }
    &.captcha-cont {
      position: relative;
      .img-box {
        width: em(156);
        height: 100%;
        a {
          line-height: em(48);
          height: 100%;
          background: #525c6b;
          color: $brand-white;
          border: 1px solid #6c7789;
          padding-left: em(22);
          font-size: em(16);
          &:after{
            content: '';
            position: absolute;
            width: 1px;
            height: em(22);
            left: em(40);
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: #868d98;
          }
          &:before{
            content: '';
            position: absolute;
            width: 0.68em;
            height: 0.8em;
            right: 0.6em;
            top: 0.4em;
            z-index: 9;
            background: url("#{$websitePath}assets/svgs/captcha-refresh-ico.svg") center no-repeat;
            background-size: 100%;
          }
        }
        i {
          position: absolute;
          right: em(24);
          top: em(16);
          z-index: 9;
          img{
            width: em(16);
          }
        }
      }
    }
    &.location-caret {
      .bootstrap-select {
        > .btn {
          .caret {
            background: url("#{$websitePath}assets/svgs/location-dd.svg") center no-repeat;
            width: em(20);
            height: em(26);
            right: em(5);
            background-size: 70%;
            &:before {
              display: none;
            }
          }
        }
      }
    }
    textarea.form-control {
      border-radius: 0;
      height: 134px;
    }
    .input-append.date {
      width: 100%;
      position: relative;
      .form-control {
        border-right: 0;
        box-shadow: none;
        padding-right: 2.2em;
      }
      .input-group-addon {
        border-radius: 0;
        background: $brand-white;
        position: absolute;
        padding: 0;
        right: em(8);
        top: em(12);
        z-index: 5;
        border: none;
        background: #fff;
        width: em(18);
        img {
          width: em(15);
          height: em(15);
        }
      }
    }
    &.has-error {
     .form-control {
       box-shadow: 0 0 0px 0 #a94442 !important;
       border: 2px solid #a94442;
     }
    }
    &.has-success {
      .form-control {
        border: 2px solid #3c763d;
        box-shadow: 0 0 0px 0 !important;
      }
    }
  }
  .btn-primary {
    height: 3.1em;
    font-size: em(15);
    width: 100%;
    i{
      top: .4em;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #7e7e7e;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #7e7e7e;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #7e7e7e;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #7e7e7e;
  }
}

#serviceForm{
  .bootstrap-select>.btn{
    padding-left: .75em;
  }
}



@media (min-width: 1601px) and (max-width: 2400px) {

}

@media (min-width: 1530px) and (max-width: 1600px) {
}

@media (min-width: 1330px) and (max-width: 1366px) {
  .service-menu {
    h2 {
      margin: 0 auto 2em;
    }
    .service-dd {
      .form-group {
        width: 20%;
        margin-bottom: 4em;
        .form-control {
          .btn {
            .filter-option {
              font-size: 1.3em;
            }
          }
        }
      }
    }
    .btn-area {
      .btn {
        font-size: 1.04em;
      }
    }
  }
  .s-search-result {
    .price-box {
      padding: 3em;
      width: 100%;
    }
  }
  .book-service {
    form {
      .row {
        .col-md-4{
          padding-right: 2em;
          padding-left: 2em;
          &:nth-child(1) {
            padding-left: 15px;
            padding-right: 4em;
          }
          &:nth-child(3) {
            padding-left: 4em;
            padding-right: 15px;
          }
        }
      }
    }
    .form-group {
      .form-control {
        font-size: em(16);
        .selectpicker {
          padding-left: .75em;
          padding-right: 1.3em;
          .filter-option {
            font-size: em(16);
          }
          .caret {

          }
        }

      }
      .input-append.date {
        .input-group-addon {

          right: em(5);
          top: em(7);
          img {
            width: em(12);
            height: em(12);
          }
        }
      }
      &.captcha-cont {
        .img-box {
          width: 8em;
          font-size: em(18);
          a {
            padding-left: 1em;
            line-height: 2.7em;
            &:before{
              width: 0.6em;
              height: 0.6em;
              right: 0.3em;
              top: 0.3em;
            }
          }
        }
      }
      &.beforeadded {

      }
    }
    .btn-primary {
      height: 3.3em;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1280px) {
  .service-menu {
    h2 {
      margin: 0 auto 2em;
    }
    .service-dd {
      .form-group {
        width: 20%;
        margin-bottom: 4em;
        .form-control {
          .btn {
            .filter-option {
              font-size: 1.3em;
            }
          }
        }
      }
    }
    .btn-area {
      .btn {
        font-size: 1.04em;
      }
    }
  }
  .s-search-result {
    .price-box {
      padding: 3em;
      width: 100%;
    }
  }
  .book-service {
    form {
      .row {
        .col-md-4{
          padding-right: 2em;
          padding-left: 2em;
          &:nth-child(1) {
            padding-left: 15px;
            padding-right: 4em;
          }
          &:nth-child(3) {
            padding-left: 4em;
            padding-right: 15px;
          }
        }
      }
    }
    .form-group {
      .form-control {
        font-size: em(16);
        .selectpicker {
          padding-left: .75em;
          padding-right: 1.3em;
          .filter-option {
            font-size: em(16);
          }
          .caret {

          }
        }

      }
      .input-append.date {
        .input-group-addon {

          right: em(5);
          top: em(7);
          img {
            width: em(12);
            height: em(12);
          }
        }
      }
      &.captcha-cont {
        .img-box {
          width: 8em;
          font-size: em(18);
          a {
            padding-left: 1em;
            line-height: 2.7em;
            &:before{
              width: 0.6em;
              height: 0.6em;
              right: 0.3em;
              top: 0.3em;
            }
          }
        }
      }
      &.beforeadded {

      }
    }
    .btn-primary {
      height: 3.3em;
    }
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {

  .book-service .form-group.location-caret .bootstrap-select>.btn .caret{
    background-size: 60%;
    padding-right:15px;
  }
  .full-three-col.no-margin .btn-area {
    .btn-hollow{
      &:hover{
        color: $brand-white;
        &:before{
          left: 0;
          right: auto;
          width: 100%;
        }
        .icon {
          background: url("#{$websitePath}assets/svgs/pdf-down-hover.svg") center no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .service-menu {
    .btn-area {
      .btn-hollow{
        &:hover{
          color: $brand-white;
          &:before{
            left: 0;
            right: auto;
            width: 100%;
          }
          .icon {
            background: url("#{$websitePath}assets/svgs/pdf-down-hover.svg") center no-repeat;
            background-size: 100%;
          }
        }
      }
    }
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {


  .service-menu .service-dd .form-group .form-control .btn .caret {
    right: 15px;
    top: 1.7em;
    &:before{
      font-size: em(10);
    }

  }

  .service-menu {
    h2 {
      margin: 0 auto 2em;
    }
    .service-dd {
      .form-group {
        width: 20%;
        margin-bottom: 4em;
        .form-control {
          .btn {
            .filter-option {
              font-size: 1.3em;
            }
          }
        }
      }
    }
    .btn-area {
      .btn {
        font-size: 1.04em;
      }
    }
  }
  .s-search-result {
    .price-box {
      padding: 3em 0;
      .total-price {
        font-size: 3em;
      }
    }
  }
  .book-service {
    form {
      .row {
        .col-md-4{
          padding-right: 1em;
          padding-left: 1em;
          &:nth-child(1) {
            padding-left: 15px;
            padding-right: 2em;
          }
          &:nth-child(3) {
            padding-left: 2em;
            padding-right: 15px;
          }
        }
      }
    }
    .form-group {
      .form-control {
        font-size: em(16);
        .selectpicker {
          padding-left: .75em;
          padding-right: 1.5em;
          .filter-option {
            font-size: em(16);
          }
          .caret {

          }
        }

      }
      &.captcha-cont {
        .img-box {
          width: 8em;
          font-size: em(18);
          a {
            padding-left: 1em;
            line-height: 2.7em;
            &:before{
              width: 0.6em;
              height: 0.6em;
              right: 0.3em;
              top: 0.3em;
            }
          }
        }
      }
      .input-append.date {
        .input-group-addon {

          right: em(5);
          top: em(7);
          img {
            width: em(12);
            height: em(12);
          }
        }
      }
    }
    .btn-primary {
      height: 3.4em;
    }
  }


}
@media (max-width: 768px){
  .s-search-result {
    .box{
      text-align: center;
      width: 33%;
      margin-right: 0;
    }
    .spliter{
      width: 100%;
    }
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .service-menu .service-dd .form-group .form-control .btn .caret {
    right: 10px;
    top: 1.7em;
    &:before{
      font-size: em(12);
    }
  }
  .service-menu {
    h2 {
      margin: 0 auto 2em;
    }
    .service-dd {
      .form-group {
        width: 20%;
        margin-bottom: 4em;
        .form-control {
          .btn {
            .filter-option {
              font-size: 1.3em;
            }
          }
        }
      }
    }
    .btn-area {
      .btn {
        font-size: 1.04em;
      }
    }
  }
  .s-search-result {
    .price-box {
      padding: 3em 0;
      width: 30%;
      margin: 0 auto 31px;
      .total-price {
        font-size: 3em;
      }
    }
  }
  .book-service {
    form {
      .row {
        .col-md-4{
          padding-right: 15px;
          padding-left: 15px;
          &:nth-child(1) {
            padding-left: 15px;
            padding-right: 15px;
          }
          &:nth-child(3) {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
    .form-group {
      &.added {
        .bootstrap-select {
          width: 74%;
        }
      }
      .addButton img {
        width: 1em;
      }
      .form-control {
        font-size: em(16);
        .selectpicker {
          padding-left: .75em;
          padding-right: 1.5em;
          .filter-option {
            font-size: em(16);
          }
          .caret {

          }
        }

      }
      &.captcha-cont {
        .img-box {
          width: 8em;
          font-size: em(18);
          a {
            padding-left: 1em;
            line-height: 2.7em;
            &:before{
              width: 0.6em;
              height: 0.6em;
              right: 0.3em;
              top: 0.3em;
            }
          }
        }
      }
      .input-append.date {
        .input-group-addon {

          right: em(5);
          top: em(5);
          img {
            width: em(12);
            height: em(12);
          }
        }
      }
      &.beforeadded {
        margin-bottom: 0.6em;
      }
    }
    .btn-primary {
      height: 3.4em;
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .location-caret{
    .dropdown-menu{
      width: 100%;
    }
  }


  body .service-menu .btn-area.left-sty{
    float: none;
    margin-top: 20px;
    .btn{
      margin-bottom: 0;
    }
  }

  .s-search-result .box{
    width: 32.33%;
    margin-right: 0;
    text-align: center;
  }



  #sMenu{
    padding-top: 10px;
  }

  .service-menu .form-wrapper .loading:before{
    left: -15px;
    top: 0;
    width: 104%;
    height: 150%;

  }
  .service-menu {
    h2 {
      margin: 0 auto 2em;
      &:before {
        left: -7em;
        width: 5em;
      }
      &:after {
        right: -7em;
        width: 5em;
      }
    }
    .service-dd {
      .form-group {
        width: 30%;
        margin: 0 1% 3em;
        .form-control {
          &.dropup{
            .btn {
              .caret{
                top:em(40);
              }
            }
          }
          .btn {
            .filter-option {
              font-size: 1.3em;
            }
            .caret {
              right: 11px;
              top:em(27);
            }
          }
        }
      }
    }
    .btn-area {
      .btn {
        font-size: 1.04em;
        margin: 0 1em;
        min-width: 15em;
        text-align: center;
        //.icon {
        //  display: none;
        //}
      }

    }
  }
  .s-search-result {
    .price-box {
      padding: 3em 0;
      width: 40%;
      margin: 0px auto 31px;
      .total-price {
        font-size: 3em;
      }
    }
    .row {
      &:last-child {
        text-align: center;
        .box {
          width: 32%;
          margin-right: 0;
        }
        .spliter {
          width: 100%;
        }
        .including{
          text-align: left;
          h5 {
            margin: 0 0 1em;
          }
          ul {
            li {
              width: 40%;
            }
          }
        }
      }
    }
  }

  .book-service {
    form {
      .row{
        .col-md-4{
          padding-right: 15px;
          padding-left: 15px;
          &:nth-child(1) {
            padding-left: 15px;
            padding-right: 15px;
          }
          &:nth-child(3) {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
    .form-group {
      .input-append.date {
        .input-group-addon {

          right: em(5);
          top: em(7);
          img {
            width: em(12);
            height: em(12);
          }
        }
      }
      &.captcha-cont {
        .img-box {
          a {
            line-height: 2.3em;
            &:before {
              width: 0.5em;
              top: 0.25em;
            }
          }
        }
      }
    }
  }

  body {
    .service-menu {
      .btn-area {
        &.right-sty{
          float: none;
          margin-top: 15px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

  .service-menu .form-wrapper .loading:before{
    height: 120%;
  }

  body .service-menu .btn-area.left-sty{
    margin-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .service-menu .btn-area .btn{
    padding: 0 em(18) 0 em(18);
    .icon{
      position: absolute;
    }
    &.btn-hollow{
      span{
        padding-left: 35px;
      }
    }

  }
  .s-search-result .including ul li{
    width: 100% !important;
  }

  .service-menu .form-wrapper .loading:before{
    width: 108%;
  }

  .service-menu {
    h2 {
      &:before, &:after{
        display: none;
      }
    }
    .service-dd {
      .form-group {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }
    .btn-area{
      .btn {
        width: 100%;
        margin: 0 0 2em;
      }
    }
  }

  .s-search-result {
    .row{
        text-align: left;
        .box{
          width: 100% !important;
          text-align: left;
          margin-bottom: em(20);
          h3 {
            margin-bottom: 0;
          }
          &:before {
            height: 1px;
            width: 50%;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: initial;
            border-right: 0;
            border-bottom: 0px dashed #959da9;
          }
        }
        .price-box {
          width: 80%;
        }
      }

  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {
  .service-menu .btn-area .btn.btn-hollow span{
    padding-left: 37px;
  }
  .service-menu .btn-area .btn .icon{
    left: 24%;
  }

}