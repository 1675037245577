body{
  overflow: hidden;
  &.loaded{
    overflow-y: scroll;
  }
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999;
}
#loader {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right:0;
  bottom:0;
  width: em(320);
  height: em(150);
  margin: auto;
  background: url("#{$websitePath}assets/svgs/honda-loader.gif") no-repeat center;
  background-size: 100%;
  z-index: 9999999;
}

/*#loader:before {
  content: "";
  position: absolute;
  top: em(5);
  left: em(5);
  right: em(5);
  bottom: em(5);
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $brand-primary;
  -webkit-animation: spin 3s linear infinite; !* Chrome, Opera 15+, Safari 5+ *!
  animation: spin 3s linear infinite; !* Chrome, Firefox 16+, IE 10+, Opera *!
}

#loader:after {
  content: "";
  position: absolute;
  top: em(15);
  left: em(15);
  right: em(15);
  bottom: em(15);
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $brand-primary;

  -webkit-animation: spin 1.5s linear infinite; !* Chrome, Opera 15+, Safari 5+ *!
  animation: spin 1.5s linear infinite; !* Chrome, Firefox 16+, IE 10+, Opera *!
}*/

/*@-webkit-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  !* Chrome, Opera 15+, Safari 3.1+ *!
    -ms-transform: rotate(0deg);  !* IE 9 *!
    transform: rotate(0deg);  !* Firefox 16+, IE 10+, Opera *!
  }
  100% {
    -webkit-transform: rotate(360deg);  !* Chrome, Opera 15+, Safari 3.1+ *!
    -ms-transform: rotate(360deg);  !* IE 9 *!
    transform: rotate(360deg);  !* Firefox 16+, IE 10+, Opera *!
  }
}
@keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  !* Chrome, Opera 15+, Safari 3.1+ *!
    -ms-transform: rotate(0deg);  !* IE 9 *!
    transform: rotate(0deg);  !* Firefox 16+, IE 10+, Opera *!
  }
  100% {
    -webkit-transform: rotate(360deg);  !* Chrome, Opera 15+, Safari 3.1+ *!
    -ms-transform: rotate(360deg);  !* IE 9 *!
    transform: rotate(360deg);  !* Firefox 16+, IE 10+, Opera *!
  }
}*/

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #efefef;
  z-index: 999999;
  -webkit-transform: translateX(0);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0);  /* IE 9 */
  transform: translateX(0);  /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);  /* IE 9 */
  transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);  /* IE 9 */
  transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%);  /* IE 9 */
  transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

/* JavaScript Turned Off */
.no-js #loader-wrapper {
  display: none;
}