@import "em-to-px";
@import "fonts";
@import "bootstrap";
@import "mixin";
@import "header";
@import "jquery.fancybox";
@import "compare.scss";
@import "scroller";
@import "about";
@import "recall";
@import "dealer-contacts";
@import "about-dealer";
@import "slick";
@import "home-page";
@import "404";
@import "news";
@import "preowned";
@import "range-slider-common";
@import "service-menu";
@import "history-page";
@import "popUp";
@import "timeLine-section";
@import "footer";
@import "toggle-button";
@import "bootstrapselect";
@import "formValidation";
@import "loading";
@import "search";
@import "revolution";
@import "after-before";
@import "products";
@import "pageLoader";
@import "animate";
@import "mediaQuery";
@import "jquery.fullPage";
@import "mouse-scroll";
@import "bootstrap-datetimepicker";
@import "landing-slider";
@import "media";
@import "owners-slider";
@import "accord-hev";









#seoAnalytics{
  display: none !important;
}

.red{
  color:$brand-primary;
}

.grecaptcha-badge{
  right: -500px !important;
}

.fp-auto-height.fp-section, .fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell {
  height: auto !important;
}

::-moz-selection {
  color: $brand-white;
  background: $brand-primary;
}

::selection {
  color: $brand-white;
  background: $brand-primary;
}

.btn, a, button {
  &:hover, &:focus, &:active, &:visited {
    text-decoration: none;
    outline: none;
  }
}

.homepage {
  font-size: 16px;
  line-height: 1.42857143;
}

.container {
  width: em(1600);
}



.bnr-video-link{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.45);
  .icon-play{
    position: absolute;
    width: em(100);
    height: em(100);
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $brand-white;
    border: 1px solid $brand-white;
    border-radius: 50%;
    @include transitions(0.5s);
    @include transform(scale(1));
    z-index: 1;
    &:before{
      font-size: em(50);
      position: absolute;
      left: 0;
      right: 0;
      top:0;
      bottom: 0;
      margin: auto;
      width: em(13);
      height: em(16);
    }
  }
}










.video-section {
  .l1-img{
    position: relative;

  }
}

[data-toggle="tooltip"]{
  position: relative;
}


.hideMobileBannerText{
  .txt-sec{
    hr{
      display: none;
    }
  }
}

.desktop-none{
  display: none;
}


@media (min-width: 1367px) and (max-width: 1600px) {
  .homepage {
    font-size: 15px;
  }
  .container {
    width: em(1440);
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .homepage {
    font-size: 11px;
  }
  .container {
    width: em(1420);
  }
}

.mac {
  body {
    font-size: 11.5px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .homepage {
    font-size: 11px;
  }
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .homepage {
    font-size: 10px;
  }
  .container {
    width: 100%;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .homepage {
    font-size: 12px;
  }
  .container {
    width: 100%;
  }

  .bnr-video-link {
    .icon-play{
      top: 15%;
    }
  }
  .mobile-none{
    display: none !important;
  }
  .desktop-none{
    display: block;
  }

}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .mobile-none{
    display: none !important;
  }
  .desktop-none{
    display: block;
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}

a:focus {
  outline: 0 auto -webkit-focus-ring-color;
  outline-offset: 0;
}

@media (min-width: 1200px) {
  .safari {
    .btn {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    a {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .bnr-video-link{
    &:hover{
      .icon-play{
        color: $brand-primary;
        border-color: $brand-primary;
        @include transform(scale(0.9));
        @include transitions(0.5s);
      }
    }
  }
}



.ie, .ie9, .ie8 {
  .btn {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.revealed {
  overflow: hidden;
  > * {
    visibility: hidden;
  }

  .revealTo {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    //transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
    @include transition(all 1s ease-in-out);
    background: #c00;
    visibility: visible !important;
    z-index: 99999999999999999999999999999999999999999
  }

  &.up {
    .revealTo {
      transform: translateY(-100%);
      left: 0;
    }

    &.rest {
      .revealTo {
        transform: translateY(0%);
      }
    }

    &.animated {
      > * {
        visibility: visible;
      }
      .revealTo {
        transform: translateY(100%);
      }
    }

  }

  &.left {
    .revealTo {
      top: 0;
      transform: translateX(-100%);
    }

    &.rest {
      .revealTo {
        transform: translateX(0%);
      }
    }

    &.animated {
      > * {
        visibility: visible;
      }
      .revealTo {
        visibility: visible;

      }

      .revealTo {
        transform: translateX(100%);
      }
    }

  }
}

.embed-container{
  &:after{
    opacity: 0;
    background: #000;
    height: 100%;
    width: 100%;
    content: "";
    float: left;
    position: absolute;
    left:0px;
    top:0px;
    z-index: 1;
  }

}

#section5{
  padding-top: 30px;
}

