.loader-new{
  min-width: em(100);
  padding-top: em(50);
  color: #fff;
  width: auto!important;
  height: 100% !important;
  text-align: center;
  font-style: normal;
  display: block;
  font-size: em(15);
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  z-index: 1000;
  &:before {
    content: '';
    width: em(50);
    height: em(50);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.66);
  }
  &:after{
    content: '';
    width: em(50);
    height: em(50);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    -webkit-animation: loader .6s linear;
    animation: loader .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: $brand-primary transparent transparent;
    border-style: solid;
    border-width: .2em;
    box-shadow: 0 0 0 1px transparent;
  }
}

.new-footer{
  #section5{
    z-index: 99999999999999999999;
    position: relative;
  }
}


.st{
  z-index: 999999999;
  position: fixed;
  top: 0px;
  width: 1600px;

}

.compare {
  margin-top:em(195);
  table{
    width: 100%;
    thead{
      background:#080808;
      padding: 10px;
      font-size: 20px;
      line-height: 18px;
      vertical-align: middle;
      color: $brand-white;
      font-weight: 600;
      td{
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        font-size: 20px;
        text-align: center;
        &:first-child{
          background:#080808;
        }
        a{
          color: $brand-white;
          .caret{
            position: absolute;
            right: em(15);
            top: 50%;
            margin-top: -2px;
            //border-right: em(6) solid transparent;
            //border-left: em(6) solid transparent;
            //border-top: em(6) dashed;
            //border-top: em(6) solid \9;

            border-left: em(6) solid transparent;
            border-right: em(6) solid transparent;
            border-top: em(6) solid #fff;

          }
        }
      }

    }
    }
    tr{
      &.img{
        text-align: center;
        td{
          position: relative;
        }
      }
      td{
        font-size: em(18);
        padding: em(19) em(20);
        //padding: em(19) em(0);
        border-style: solid;
        border-width: 1px;
        border-color:rgba(239,239,239,0.5);
        width: 24%;
        img{
            width: 80%;
        }
        &:first-child{
          background:#f9f9f9;
          width: 28%;
          img{
            width: auto;
          }
        }
        //&:nth-child(2n), &:nth-child(3n), &:nth-child(4n) {
        //  width: 24%;
        //
        //}

      }
      &:nth-child(2n){
        &.head{
          td{background:#f3f6fa !important;}
        }
        td{
          background: #fdfdfd;
          &:first-child{
            background: #f2f2f2;
          }
        }
      }
      &.head{
        td{
          background:#f3f6fa;
          color: $brand-primary;
          font-weight: bold;
          text-transform: uppercase;
          font-size: em(20);

        }
      }
    }
  &.specifications{
    display: block;


    /* col 1 start */
    .col1.spec_head thead tr td{
      width: 50%;
      &:first-child{
        width: 50%;
      }
    }

    .desktop_sec.col1 table tbody tr td{
      width: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      &:first-child{
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
      }
    }
    /* col 1 end */

    /* col 2 start */
    .col2.spec_head thead tr td{
      width: 33.3333%;
      &:first-child{
        width: 33.3333%;
      }
    }

    .desktop_sec.col2 table tbody tr td{
      width: 33.3333%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      &:first-child{
        width: 33.3333%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
      }
    }
    /* col 2 end */

    /* col 3 start */
    .col3.spec_head thead tr td{
      width: 24%;
      &:first-child{
        width: 28%;
      }
    }

    .desktop_sec.col3 table tbody tr td{
        width: 24%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      align-content: center;
        &:first-child{
          width: 28%;
          display: block;
        }
    }
    /* col 3 end */

    /* col 4 start */
    .col4.spec_head thead tr td{
      width: 18%;
      &:first-child{
        width: 28%;
      }
    }

    .desktop_sec.col4 table tbody tr td{
      width: 18%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      &:first-child{
        width: 28%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
      }
    }
    /* col 4 end */


    /* col 5 start */
    .col5.spec_head thead tr td{
      width: 14.4%;
      &:first-child{
        width: 28%;
      }
    }

    .desktop_sec.col5 table tbody tr td{
      width: 14.4%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      &:first-child{
        width: 28%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
      }
    }
    /* col 5 end */





    /* col 7 start */
    .col7.spec_head thead tr td{
      width: 12%;
      &:first-child{
        width: 28%;
      }
    }

    .desktop_sec.col7 table tbody tr td{
      width: 12%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      &:first-child{
        width: 28%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
      }
    }
    /* col 7 end */


    /* col 8 start */
    .col8.spec_head thead tr td{
      width: 10.71%;
      &:first-child{
        width: 25%;
      }
    }

    .desktop_sec.col8 table tbody tr td{
      width: 10.71%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      &:first-child{
        width: 25%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
      }
    }
    /* col 8 end */


    /* col 9 start */
    .col9.spec_head thead tr td{
      width: 10.71%;
      &:first-child{
        width: 25%;
      }
    }

    .desktop_sec.col9 table tbody tr td{
      width: 10%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
      &:first-child{
        width: 10%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
      }
    }
    /* col 9 end */



    .gecko.desktop{

    }

    .desktop_sec.col3{
      margin-top: 6px;
    }


    .spec_head{
      &.head_full{
        thead{
          tr{
            td{
              width: 24%;
              &:first-child{
                width: 28%;
              }
            }
          }
        }
      }
      thead{
        width: 100%;
        display: inline-block;
        padding: 0;
        tr{
          width: 100%;
          display: inline-flex;
          &:first-child{
            border-top: 1px solid rgba(239,239,239,0.5);
          }
          td{
            display: inline-block;
            border: 0;
            border-right: 1px solid rgba(239,239,239,0.5);
            border-bottom: 1px solid rgba(239,239,239,0.5);
            width: calc(100% / 8.3);
            &:first-child{
              width: 28%;
              border-left: 1px solid rgba(239,239,239,0.5);
            }
          }
        }
      }
    }

    .desktop_sec, .mg_5{
      //tr{
      //  td:nth-child(2n),
      //  td:nth-child(3n),
      //  td:nth-child(4n),
      //  td:nth-child(5n),
      //  td:nth-child(6n),
      //  td:nth-child(7n){
      //    width: 12%;
      //    text-align: center;
      //  }
      // }
      table{
        tbody{
          width: 100%;
          display: inline-block;
          tr{
            width: 100%;
            display: inline-flex;
            &:first-child{
              border-top: 1px solid rgba(239,239,239,0.5);
            }
            td{
              text-align: center;
              border: 0;
              border-right: 1px solid rgba(239,239,239,0.5);
              border-bottom: 1px solid rgba(239,239,239,0.5);
              display: inline-block;
              width: calc(100% / 8.3);
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              align-content: stretch;
              &:first-child{
                text-align: left;
                width: 28%;
                border-left: 1px solid rgba(239,239,239,0.5);
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                align-content: stretch;
                b{
                  display: block;
                  font-weight: 500;
                  font-size: em(13);
                  margin-top:em(10)
                }
              }
            }
          }
        }
      }
    }

    .car-comparision{
      .col-md-12{
        .h3{
          text-align: center;
          width: 100%;
          position: relative;
          font-size: em(70);
          line-height:55px;
          padding-bottom: 35px;
          margin-bottom: 40px;
          &:before{
            content: "";
            height: 4px;
            background: $brand-primary;
            width: 40px;
            left: 50%;
            margin-left: -20px;
            bottom: 0px;
            position: absolute;
          }
        }
      }
      .spec_bt{
        text-align: center;
        position: relative;
        margin-bottom: 100px;
        .sub{
          background: #fff;
          width: 419px;
          margin: 0px auto;
          display: inline-block;
          position: relative;
          .sep{
            width: 40px;
            display: inline-block;
            background: #fff;
            height: 4px;
            position: relative;
            &:before{
              content: "";
              width: 20px;
              height: 1px;
              background: #d6d6d6;
              position: absolute;
              left: 10px;
              top: 0px;
            }
          }
          a.bt_1{
            margin: 0px;
          }
        }
        &:before{
          content: "";
          width: 100%;
          height: 1px;
          background: #d6d6d6;
          position: absolute;
          left: 0px;
          top: 49%;
        }
      }
      .bt_1{
        float: none;
        text-align: center;
      }


    }
    tr td:first-child{
      cursor: auto;
      b{
        display: block;
        font-weight: 500;
        font-size: em(16) !important;
        margin-top:em(10)
      }
    }
  }
  }
.center{
  text-align: center;
}
.table{
  display: none;
}
.car-comparision{
  float: left;
  width: 100%;
  margin-bottom: 33px;
}

.bt_2{
  float: right;
  border: 0px;
  height: 40px;
  margin-right: 10px;
  box-shadow: inset 0 0px 0px rgba(0,0,0,0.125);
  text-align: center;
  line-height: 40px;
  padding: 0px 63px 0px 19px;
  i.icon-arrow-line{
    top:9.5px;
  }
}
.bt_3{
  float: right;
  border: 0px;
  height: 40px;
  box-shadow: inset 0 0px 0px rgba(0,0,0,0.125);
  text-align: center;
  line-height: 40px;
  padding: 0px 63px 0px 55px;
  i{
    top:9.5px;
  }
  .compare-icon{
    left: 15px;
    right: auto;
    width: 25px;
    top:8px;
    img{
      width: 100%;
    }
  }
}
.bt_4{
  float: right;
  border: 0px;
  height: 40px;
  box-shadow: inset 0 0px 0px rgba(0,0,0,0.125);
  text-align: center;
  line-height: 40px;
  padding: 0px 63px 0px 55px;
  min-width: inherit !important;
  margin-right: 10px;
  i{
    top:9.5px;
  }
  .compare-icon{
    left: 15px;
    right: auto;
    width: 25px;
    top:8px;
    img{
      width: 100%;
    }
  }
}
.bt_1{
  text-align: center;
  margin-left: em(28.8);
  box-shadow: inset 0 0px 0px rgba(0,0,0,0.125);
  border: 2px solid $brand-primary !important;

  .caret{
    margin-left: 20px;
    transform: rotate(270deg);
    transition: transform 0.25s ease-out;
    //border-right: em(6) solid transparent;
    //border-left: em(6) solid transparent;
    //border-top: em(6) dashed;
    //border-top: em(6) solid \9;

    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid;
  }
  &.active{
    &:before{
      display: none;
    }
    color: $brand-primary !important;
    border: 2px solid $brand-primary !important;
    background: $brand-white !important;
    .caret{
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
    &:hover{
      color: $brand-primary;
      span{
        color: $brand-primary;
      }
    }
  }
  //&:focus{
  //  color: $brand-primary !important;
  //}
  .plus-icon {
    margin-right:em(20);
      position: relative;
      width: em(14);
      height: em(14);
      display: inline-block;
      &:before,
      &:after{
        content: "";
        position: absolute;
        background-color: white;
        transition: transform 0.25s ease-out;
      }

      /* Vertical line */
      &:before{
        top: em(2);
        left: 50%;
        width: em(2);
        height: 100%;
        margin-left: em(-1);
      }

      /* horizontal line */
      &:after{
        top: 50%;
        left: 0;
        width: 100%;
        height: em(2);
        margin-top: em(1);
      }

    }
  &:hover{
    .plus-icon{
      cursor: pointer;
      &:before{ transform: rotate(90deg); }
      &:after{ transform: rotate(180deg); }
    }
    .caret{
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
}
.f-right{
  float: right;

}
.h3{
  font-size: em(36);
  text-transform: uppercase;
  margin: 0px;
  color: #000;
  font-weight: bold;
}
.remove{
  position: absolute;
  right: em(20);
  font-size:10px;
  bottom: em(20);
  color: $brand-primary;
  text-transform: uppercase;
  @include transition(0.5s);
  font-weight: bold;
  &:before{
    content: "";
    width: 0px;
    height: 1px;
    bottom: 0px;
    right: 0px;
    background: red;
    position: absolute;
    @include transition(0.5s);
  }
  &:hover{
    color: red;
    &:before{
      width: 100%;
      right: auto;
      left: 0px;
    }
  }
}
.add-car{
  padding-top: 68px;

  h4{
    font-size: em(30);
    color: #000;
    line-height:30px;
    margin: 0px 0px 25px 0px;
    text-transform: uppercase;
    text-align: center;
    padding: 0px 15px;
    font-weight: bold;
  }
  p {
    font-size: em(18);
    margin: 0px 0px 30px 0px;
    text-align: center;
    padding: 0px 15px;
  }
  .head{
    height:56px;
    line-height:56px;
    background: #f3f6fa;
    color: $brand-primary;
    display: block;
    width: 100%;
    padding: 0px 30px;
    text-transform: uppercase;
    font-weight: bold;
    //letter-spacing: 1px;
    letter-spacing: 0.009em;
    font-size: em(19.2);
  }
  ul{
    list-style: none;
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    li{
      cursor: pointer;
      display: block;
      width: 100%;
      padding: 1px 0px;
      border-bottom:1px solid $brand-white;
      border-top:1px solid $brand-white;
      line-height: 147px;
      @include transition(0.5s);
      .img{
        padding-left: 30px;
        width: 50%;
        display: inline-block;
        text-align: center;
      }
      h6{
        font-size: em(20);
        margin: 0px;
        display: inline-block;
        width: 49%;
        padding-right: 2%;
        vertical-align:middle;
      }
      &:hover{
        border-bottom:1px solid $brand-primary;
        border-top:1px solid $brand-primary;
        background:#efefef;
      }
    }
  }
  .done{
    cursor: pointer;
    background: $brand-primary;
    height:40px;
    line-height: 40px;
    text-transform: uppercase;
    color: $brand-white;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 13px;
    font-weight: bold;
  }
}

.stepPopup2{
  .modal-lg{
    width: 500px;
    border: 1px solid #efefef;
  }
  .modal-content{
    height: 100%;
    padding-right: 0px;
    border-radius: 0;
    box-shadow: 0 0px 0px rgba(0,0,0,0.5);
    border: 0px;

    &.loading-new{
      &:before{
        content: "";
        height: 100%;
        width: 100%;
        background: #ccc;
        opacity: 0.8;
        position: absolute;
        left: 0;
        top:0;
      }
    }

  }
  .close{
    background: $brand-primary;
    width: 1.375em;
    height: 1.375em;
    opacity: 1;
    position: absolute;
    right: 1px;
    top: 1px;
    @include transition(0.5s);
    &:before{
      background: url("#{$websitePath}assets/svgs/cross-out.svg") no-repeat;
      background-size: cover;
      width: 1.125em;
      height: 1.125em;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      -webkit-transform: scale(0.6);
      -moz-transform: scale(0.6);
      -ms-transform: scale(0.6);
      -o-transform: scale(0.6);
      transform: scale(0.6);
    }
    }
  }







.card{
  float: left;
  width: 100%;
  .card-header{
    //float: left;
    width: 100%;
    position: relative;
  }
}
.card-header{
  a{
    .toggle_bt{
      position: absolute;
      right: 30px;
      top: 2px;
      .plus-icon {
        position: relative;
        width: em(19);
        height: em(19);
        display: inline-block;
        z-index: 99;
        &:before,
        &:after{
          content: "";
          position: absolute;
          background-color: #000;
          transition: transform 0.25s ease-out;
        }

        /* Vertical line */
        &:before{
          top: em(2);
          left: 50%;
          width: em(3);
          height: 100%;
          margin-left: em(-1);
          transform: rotate(-90deg);


        }

        /* horizontal line */
        &:after{
          top: 50%;
          left: 0;
          width: 100%;
          height: em(3);
          margin-top: em(1);
        }
      }
    }
    &.collapsed{
      .plus-icon{
        cursor: pointer;
        &:before{ transform: rotate(0deg); }
        &:after{ transform: rotate(180deg); }
      }
    }
  }
}




.h5{
  font-size: 20px;
  padding: em(0) em(20);
   background: #f3f6fa;
  line-height: 65px;
  height: 65px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 5px;
  a{
    color: #cc0000;
  }
}
  .card-body{
    > table{
      padding-bottom: 20px;
      display: block;
    }
}



.mg_5{
  margin-bottom: 5px;
}
.mg_t{
  margin-top: em(10);
}


@supports (-ms-ime-align:auto) {
  .compare {
    tr {
      display: flex;
      td{
        display: inline-flex;
        width: 24%;
        align-items: center;
        align-content: center;
        img{
          height: auto;
          margin: 0 auto;
        }
      }
    }
  }
}




.mobile_sec{display: none;}

@media (min-width: 1500px) and (max-width: 1600px) {

  .car-comparision{
    .bt_1{
      margin-left: em(16);
      padding: .6875em 1.2625em;
    }
  }

}

@media (min-width: 1330px) and (max-width: 1366px) {


  .car-comparision{
    .bt_1{
      margin-left: 0.5em;
      padding: .6875em 1.2625em;
    }
  }

  .compare {
    tr {
      td {
        img{
          width: 70%;
        }
      }
    }
  }

}


@media (min-width: 1201px) and (max-width: 1280px) {
 .bt_1{
   margin-left: 0.4em;
 }
}


/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .car-comparision{
    .col-md-3{
      width: 33.33%;
      .bt_1{
        margin-left: 10px;
      }
    }
  }

  .compare.specifications {
    .car-comparision {
      .col-md-12{
        .h3{
          padding-bottom: 16px;
          margin-bottom: 40px;
        }
      }
      .bt_1{
        height: 40px;
        line-height: 38px;
        padding: 0px 19px;
        span{
          font-size: 12px;
          &.caret{
            line-height: 12px;
            height: 10px;
            margin-top: -4px;
          }
        }
        &.active{
          span{
            &.caret{
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
  .compare thead{
    tr td{
      font-size: em(10);
    }
  }
  .bt_3, .bt_2{
    font-size: 12px;
  }
}


@media (min-width: 768px) and (max-width: 991px) {

  .second-pane{
    .col-sm-8{
      a{
        padding:0px 10px;
        i{
          display: none;
        }
      }
    }
  }
  .compare tr td:first-child img {
    width: 54%;
  }
  .remove{
    bottom: 0.5em
  }
  .compare .head_full tr {
    td{
      font-size: .65em;
      padding: 1.1875em 0.25em;
    }
  }


  .sub{
    .btn{
      font-size: 11px;
    }
  }
  .bt_3, .bt_2{
    font-size: 11px;
  }
  .car-comparision{
    .col-md-3{
      width: 33.33%;
      float: left;
      .bt_1{
        margin-left: 10px;

      }
    }
  }



  .compare.specifications {
    .car-comparision {
      .col-md-12{
        .h3{
          padding-bottom: 16px;
          margin-bottom: 40px;
        }
      }
      .bt_1{
        height: 40px;
        line-height: 38px;
        padding: 0px 19px;
        span{
          font-size: 12px;
          &.caret{
            line-height: 12px;
            height: 10px;
            margin-top: -4px;
          }
        }
        &.active{
          span{
            &.caret{
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
  .compare thead{
    tr td{
      font-size: em(12);
    }
  }
  .col-md-6.mg_t{
    float: left;
  }
}


@media (max-width: 767px) {


  .car_row1.DrivebyWireThrottleSystem.dot_Dev, .car_row2.DrivebyWireThrottleSystem.dot_Dev{
    svg{
      width: 6px !important;
      height: 6px !important;
    }
  }

  .add-car ul li{
    line-height: inherit;
  }
  .select_trim{
    width: 100%;
    display: inline-block;
    margin: 0px auto;
    text-align: center;
    .bootstrap-select{
      border: 1px solid #e4e6e8;
      width: 300px;
      display: inline-block;
      float: none;
      margin: 0px auto;
      margin-bottom: 20px;
      &.btn-group {
        .btn {
          padding-top: 8px;
          .filter-option{
            font-size: 14px;
          }
          .caret{
            right: 1em;
            width: auto;
            height: auto;
            top: 1.5em;
            &:before{
              font-size: 1em;
            }
          }
        }
      }
    }
    select{
      width: 300px;
    }
  }

  .compare.specifications {
    .car-comparision {
      .spec_bt{
        margin-bottom: 40px;
      }
    }
  }


  .compare{
    margin-top: em(140);
    .car-comparision{
      padding: 0px 15px;
      margin-bottom: 0px;
      text-align: center;
      .col-md-3{
        float: left;
        width: 100%;
        text-align: center;
        &:first-child{
          float: left;
          width: 100%;
          text-align: center;
          margin-bottom: 16px;
        }
        &:last-child{
          float: right;
          .bt_1{
            margin-right: 0px;
          }
        }

      }
      .bt_1{
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
        float: none;
        display: inline-block;
      }
    }
    table{
      display: none;
    }
    .table{
      display: block;
      .row_1 {
        &:first-child{
          float: left;
          width: 100%;
          background: #080808;
        }
        .head_pane{
          float: left;
          width: 50%;
          text-align: left;
          padding: 10px;
          font-size: 1.125em;
          background: #080808;
          color: #fff;
          a{
            color: #fff;
          }

          &:first-child{
            border-right-style: solid;
            border-right-width: 1px;
            border-right-color: rgba(239,239,239,0.5);
          }
        }
        .img_pane{
          background: #fff;
          float: left;
          width: 50%;
          text-align: center;
          padding-top: 10px;
          position: relative;
          padding-bottom: 35px;
          &:first-child{
            border-right-style: solid;
            border-right-width: 1px;
            border-right-color: rgba(239,239,239,0.5);
          }
          img{
            width: 70%;
          }
        }

      }
      .specs_pane{
        background: #fdfdfd;
        float: left;
        width: 100%;
        h5{
          background: #f3f6fa;
          color: #c00;
          font-weight: bold;
          text-transform: uppercase;
          font-size: em(16);
          display: block;
          text-align: center;
          margin: 0;
          padding: 10px;
        }
        span{
          border-right-style: solid;
          border-right-width: 1px;
          border-right-color: rgba(239,239,239,0.5);
          padding: 10px;
          font-size: em(14);
          float: left;
          width: 50%;
          text-align: center;
        }
        &:nth-child(2n){
          background: #fff;
        }
        .w-100{
          width: 100% !important;
        }
      }
    }

  }

  .stepPopup2 .modal-lg{
    width: 310px;
    text-align: center;
    margin: 10px auto;
  }
  .add-car{
    padding-top: 48px;
    .head{
      text-align: left;
      height: 46px;
      line-height: 46px;
    }
    h4{
      margin-bottom: 5px;
    }
  }
  .add-car ul li {
    .img{
      width: 100%;
      display: inline-block;
      text-align: center;
      padding: 10px;
    }
    h6{
      font-size: em(14);
      width: 100%;
      line-height: normal;
      text-align: center;
      margin-bottom: 5px;
    }
  }
  .spec_bt{
    .sub{
      a{
        font-size: 12px;
        padding: 0px 10px;
        height: 40px;
        line-height:38px;
        .caret{
          display: none;
        }
      }
    }
  }
  .second-pane{
    .col-md-4{
      text-align: center;
      margin-bottom: 20px;
      a{
        display: inline-block;
        float: none;
        font-size: 12px;
        padding: 0px 10px;
        margin: 0px 5px;
      }
    }
    .col-md-8{
      display: inline-block;
      margin-bottom: 15px;
      text-align: center;
      width: 100%;
      a{
        margin-bottom: 5px;
        float: none;
        display: inline-block;
        margin-right: 0;

        padding: 0px 20px;
      }
    }
  }
  .desktop_sec{
    display: none;
  }
  .mobile_sec{
    display: block;
    table{
      display: block;
    }
  }

  .card{
    .card-header{
      h5{
        padding-right: em(55);
}
    }
  }

  b.fuel-discription {
    color: #3f3f3f;
    display: block;
    margin-top: 10px;
    font-weight: 400;
    font-size: .875em;
    line-height: 15px;
    text-transform: initial;
  }
}



@media (max-width: 480px)  {
  .second-pane .col-md-6{
    &.mg_t{
      img{
        width: 50%;
      }
    }
  }
  .compare.specifications .car-comparision .spec_bt .sub{
   width: auto;
    padding: 0px 10px;
    a.bt_1{
      min-width: auto;
    }
  }
  .compare.specifications .car-comparision .col-md-12 .h3{
    font-size: 2.5em;
    padding-bottom: 5px;
  }
  .h5{
    font-size: 15px;
    height: auto;
    line-height: 22px;
    padding: 12px 1.25em;
    a {
      .toggle_bt{
        top: 50%;
        margin-top: -11px;
      }
    }

  }
}