.owners-slider{
    //.slick-slide{
    //  width: 400px;
    //}

    .l-box {
      border: 0;
      .text-area{
        padding-top: em(36);
        border: 1px solid #ccc;
        padding-bottom: em(45);
      }
      .newThumb{
        margin: 0;
      }
    }
  .slick-arrow{
    font-size: em(50);
    cursor: pointer;
    i{
      margin-top: em(3);
      font-size: em(30);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .prevarrow{
    text-align: right;
    background: #ededed;
    height: 40px;
    width: 122px;
    position: absolute;
    left: em(-160);
    top:50%;
    margin-top: -20px;
    border-radius:20px;
    font-size: em(13);
    text-transform: uppercase;
    color: $brand-black;
    padding: 12px 15px;
    cursor: pointer;
    @include transitions(0.5s);
    .icon-left{
      left: em(10);
      float: left;
      margin-right: 17px;
      color: #be0000;
      @include transitions(0.5s);
      &:before {
        content: "\71";
      }
    }
  }
  .nextarrow{
    text-align: left;
    padding: 12px 15px;
    background: #ededed;
    height: 40px;
    width: 122px;
    position: absolute;
    right: em(-160);
    top:50%;
    margin-top: -20px;
    border-radius:20px;
    font-size: em(13);
    text-transform: uppercase;
    cursor: pointer;
    color: $brand-black;
    .icon-right{
      right: em(10);
      float: right;
      margin-left: 17px;
      color: #be0000;
      &:before{
        content: "\63";
      }

    }
  }
  .slick-disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.tel-input-field{
  display: flex;
  align-items: center;
  span{
    margin-right: em(10);
    color: #000000;
    font-size: em(18);
  }
}

.enq-form-sec{
  overflow: visible;
  #eqyform .bootstrap-select.btn-group .dropdown-menu{
    max-height: 160px !important;
    padding: 0;
  }
}

@media (min-width: 1601px) and (max-width: 2400px) {
}

@media (min-width: 1530px) and (max-width: 1600px) {
  .owners-slider {
    .prevarrow {
      height: 35px;
      width: 95px;
      left: em(-152);
      font-size: 11px;
      padding: 10px 15px;
    }
    .nextarrow{
      height: 35px;
      width: 95px;
      right: em(-152);
      font-size: 11px;
      padding: 10px 15px;
    }
  }
}

@media (min-width: 1530px) and (max-width: 1550px) {
  .owners-slider {
    .prevarrow {
      height: 27px;
      padding: 7px 12px;
      width: 71px;
      left: em(-116);
      font-size: 10px;
      .icon-left{
        font-size: em(26);
      }
    }
    .nextarrow{
      height: 27px;
      padding: 7px 12px;
      width: 71px;
      right: em(-116);
      font-size: 10px;
      .icon-right{
        font-size: em(26);

      }
    }
  }
}

@media (min-width: 1330px) and (max-width: 1366px) {
  .owners-slider {
    .prevarrow {
      height: 35px;
      width: 95px;
      left: em(-155);
      font-size: 11px;
      padding: 10px 15px;
    }
    .nextarrow{
      height: 35px;
      width: 95px;
      right: em(-155);
      font-size: 11px;
      padding: 10px 15px;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1280px) {
  .owners-slider {
    .prevarrow {
      height: 25px;
      padding: 7px 12px;
      width: 71px;
      left: em(-114);
      font-size: 10px;
      .icon-left{
        font-size: em(26);
      }
    }
    .nextarrow{
      height: 25px;
      padding: 7px 12px;
      width: 71px;
      right: em(-114);
      font-size: 10px;
      .icon-right{
        font-size: em(26);

      }
    }
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
  .owners-slider {
    .slick-arrow {
      &:hover {
        background: #be0000;
        color: $brand-white;
        @include transitions(0.5s);

        .icon-left, .icon-right {
          color: $brand-white;
          @include transitions(0.5s);
        }
      }
    }
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .owner-news-box{
    .l-spc-20 {
      letter-spacing: 5px;
    }
  }
  .owners-slider {
    padding-top: em(80);
    .slick-arrow {
      font-size: em(18);
    }
    .prevarrow {
      top: em(30);
      left: em(30);
    }
    .nextarrow {
      top: em(30);
      right: em(30);
    }
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .owner-news-box{
    .l-spc-20 {
      letter-spacing: 5px;
    }
  }
  .owners-slider {
    padding-top: em(80);
    .slick-arrow {
      font-size: em(18);
    }
    .prevarrow {
      top: em(30);
      left: em(30);
    }
    .nextarrow {
      top: em(30);
      right: em(30);
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .owner-news-box{
    .l-spc-20 {
      letter-spacing: 5px;
    }
  }
  .owners-slider {
    padding-top: em(80);
   .slick-arrow{
      font-size: em(18);
      padding: 11px 15px;
     i{
       margin-top: em(4);
       top: 47%;
     }
    }
    .prevarrow {
      top: em(30);
      left: em(20);
    }
    .nextarrow {
      top: em(30);
      right: em(20);
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 320px) {

}